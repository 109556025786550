<template lang="pug">
.ds-main-seo-text(:class="{ 'wrapped': isWrapping }")
  .text(v-html="textHtml")
  .wrap(v-if="isWrapping" @click="()=>{this.isShowText=!this.isShowText;}") {{ isShowText ? 'Скрыть' : 'Развернуть' }}
</template>

<script>
import { StaticDataMixin } from '~/mixins/staticData';
import { columnSeparator } from '~/shop-config';

export default {
  mixins: [StaticDataMixin],
  props: {
    isWrapping: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isShowText: false,
  }),
  computed: {
    textHtml() {
      const text = this.static.seo;
      if (!this.isWrapping) {
        return this.toHtml(
          text
            .split(columnSeparator)
            .map((_) => `<div>${_}</div>`)
            .join(''),
        );
      }
      if (this.isShowText) {
        return this.toHtml(text);
      } else {
        const split = text.split(columnSeparator); // split by paragraphs
        return this.toHtml(split.slice(0, Math.trunc(split.length / 2)).join(columnSeparator));
      }
    },
  },
  methods: {
    toHtml(text) {
      return text.replace(/\n/g, '<div class="separator"></div>');
    },
  },
};
</script>

<style lang="stylus">
.ds-main-seo-text
  margin-top 36px
  a
    color BlueBrand()

  .separator
    height 8px
  &:not(.wrapped)
    .text
      Text(BodyM Long)
      line-height 18px
      margin 0 auto
      display flex
      margin-bottom 1rem
      div
        width 50%
        &:not(:last-child)
          margin-right 1rem
      +Media(Mobile, Tablet)
        flex-direction column
        div
          width 100%
          &:not(:last-child)
            margin-right 0
            margin-bottom 1rem
      +Media(DesktopMax)
        width 100%
        div:not(:last-child)
          margin-right 3rem
  &.wrapped
    Text(BodyM Long)
    width 660px
    .wrap
      cursor pointer
      width min-content
      font-weight 500
      color OrangeBrand()
      margin-top 16px
    +Media(Mobile, Tablet)
      width 100%
</style>
