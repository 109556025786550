<template lang="pug">
.ui-wrapper(v-show="askAdult")
  .adult-notification(v-if="askAdult")
    template(v-if="!media.isMobileOrTablet")
      icon-ui(name="status/adult_fill" size="48px" style="min-width: 48px")
      .action-block
        .info В результатах поиска есть товары «для взрослых». Можем показать их — скажите, вам уже есть 18 лет?
        .button-adult-block
          button-ui.disagree(@click="allowAdult(false)") Нет
          button-ui.agree(@click="allowAdult(true)") Да, мне есть 18
    template(v-else)
      .action-block
        icon-ui(name="status/adult_fill" size="48px" style="min-width: 48px")
        .info В результатах поиска есть товары «для взрослых». Можем показать их — скажите, вам уже есть 18 лет?
      .button-adult-block
        button-ui.disagree(@click="allowAdult(false)") Нет
        button-ui.agree(@click="allowAdult(true)") Да, мне есть 18
</template>

<script>
import Vue, { PropType } from 'vue';
import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  components: {
    ButtonUi,
    IconUi,
  },
  inject: ['media'],
  computed: {
    ...mapState('main', ['askAdult']),
  },
  methods: {
    allowAdult(val) {
      this.$store.commit('main/updateAdult', val);
    },
  },
});
</script>

<style lang="stylus" scoped>
.adult-notification
  display: flex;
  margin-top: 24px
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  border: 1px solid var(--Border-Subtle, rgba(54, 54, 64, 0.08));
  +Media(Tablet, Mobile)
    flex-direction column
  .action-block
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    +Media(Tablet, Mobile)
      flex-direction row

    .info
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      +Media(Tablet, Mobile)
        font-size: 16px;

  .button-adult-block
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .disagree
      Button(Secondary)
      width: 58px
      height: 40px

      +Media(Tablet, Mobile)
        width: 50%
    .agree
      Button(Primary)
      width: 140px
      height: 40px
      +Media(Tablet, Mobile)
        width: 50%
</style>
