
import ClickOutside from 'vue-click-outside';
import { Component, Emit, Inject, Prop, Vue } from 'vue-property-decorator';
import { cartData_sku } from '~/apollo-api/types';

import { format } from '~/filters/currency';

const FADEOUT_DELAY = 5000000;
// need fix or review
export interface getCartListWithChanged {
  __typename: 'CartItem';
  amount: number;
  sku: cartData_sku;
  oldAmount: number;
  oldPrice: number;
  changed: string;
}

export type UpdateAmountCheckoutPopupProps = {
  data: getCartListWithChanged[];
};

@Component({
  directives: {
    ClickOutside,
  },
})
export default class UpdateAmountCheckoutPopup extends Vue {
  $eventManager!: any;

  @Inject() media;

  @Prop(Object) propsData!: UpdateAmountCheckoutPopupProps;

  @Emit('close')
  onClose() {}
  // eslint-disable-next-line no-undef
  fadeoutTimer: NodeJS.Timeout | undefined;

  get trueTitle() {
    if (this.propsData.data.length != 1) {
      if (this.propsData.data.every((_) => _.changed == 'amount')) {
        return 'Количество товаров успело измениться';
      }
      if (this.propsData.data.every((_) => _.changed == 'price')) {
        return 'Стоимость товаров успело измениться';
      }
      return 'Стоимость и количество товаров успели измениться';
    } else {
      if (this.propsData.data[0].changed == 'amount') {
        return 'Количество товара успело измениться';
      }
      if (this.propsData.data[0].changed == 'price') {
        return 'Цена товара успело измениться';
      }
      return 'Стоимость и количество товара успели измениться';
    }
  }

  mounted() {
    this.fadeoutTimer = setTimeout(() => {
      this.onClose();
    }, FADEOUT_DELAY);
  }

  beforeDestroy() {
    if (this.fadeoutTimer) {
      clearTimeout(this.fadeoutTimer);
    }
  }

  formatPrice(price: number): string {
    return format(price);
  }

  photo(item) {
    return item.sku?.photos ? item.sku?.photos[0].link.high : item.sku?.photo.link.high;
  }

  differencePrice(newValue, oldValue) {
    if (newValue > oldValue) {
      return `Стало дороже на ${Math.trunc((newValue - oldValue) / 100)} ₽/шт.`;
    } else {
      return `Стало дешевле на ${Math.trunc((oldValue - newValue) / 100)} ₽/шт.`;
    }
  }
}
