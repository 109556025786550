
import Vue, { PropType } from 'vue';

import { MakeSearch_makeSearch_items, MakeSearch_makeSearch_items_catalogCard_SkuGroupCard } from '~/apollo-api/types';

import ProductCardDs from '~/components/product-card.vue';

import { IFormattedProduct } from '~/modules/search/models';

import { staticData } from '~/shop-config';
import { observerMixin } from '~/plugins/gtm-datalayer/observer';

export default Vue.extend({
  components: {
    ProductCardDs,
  },
  mixins: [observerMixin],
  props: {
    products: {
      type: Array as PropType<MakeSearch_makeSearch_items[] | null>,
      default: null,
    },
    itemListName: {
      type: String,
      default: null,
    },
  },
  computed: {
    formattedProducts(): IFormattedProduct[] {
      if (!this.products) {
        return [];
      }

      return this.products.map((product, index) => {
        const catalogCard = product.catalogCard as MakeSearch_makeSearch_items_catalogCard_SkuGroupCard;
        return {
          index,
          id: catalogCard.productId,
          title: catalogCard.title,
          photos: catalogCard.photos,
          minSellPrice: catalogCard.minSellPrice,
          minFullPrice: catalogCard.minFullPrice,
          skuGroupId: catalogCard.id,
          skuGroup: {
            characteristicIds: catalogCard.characteristicValues.map((value) => value.characteristic.id),
            characteristicValueIds: catalogCard.characteristicValues.map((value) => value.id),
          },
          feedbackQuantity: catalogCard.feedbackQuantity,
          rating: catalogCard.rating,
          badges: staticData.isShowBadges ? catalogCard.badges : [],
          favorite: catalogCard.favorite,
          adult: catalogCard.adult,
        };
      });
    },
  },
  methods: {
    observerIntersect(count) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$gtm.push({ ecommerce: null });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$gtm.push(this.$dataLayer.listingEvent(this.formattedProducts, this.itemListName));
    },
  },
});
