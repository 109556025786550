<template lang="pug">
.ui-tabs
  .tab-titles
    slot(name="title")
  .tab-contents
    slot(name="content")
</template>

<script>
export default {
  provide() {
    return {
      rootTabs: this,
    };
  },
  model: {
    event: 'select',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: this.value || '',
    };
  },
  watch: {
    selected(val) {
      this.$emit('select', val);
    },
    value(val) {
      this.selected = val;
    },
  },
  methods: {
    select(name) {
      this.selected = name;
    },
  },
};
</script>

<style lang="stylus">
.ui-tabs
  .tab-titles
    display flex
    align-items center
    border-bottom 1px solid Divider()
</style>
