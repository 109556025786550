export const state = () => ({
  list: [],
});

export const mutations = {
  updateList(state, payload) {
    state.list = payload;
  },
  updateItem(state, payload) {
    const foundProduct = state.list.find((el) => el.sku.id === payload?.product?.sku?.id);

    if (foundProduct) {
      foundProduct.amount = payload.amount;
    }
  },
  addItem(state, payload) {
    const isStock = payload.sku.availableAmount;
    if (isStock) {
      state.list.push(payload);
    }
  },
  removeItem(state, payload) {
    state.list = state.list.filter((_) => _.sku.id !== payload);
  },
};

export const actions = {
  update({ commit }, payload) {
    commit('updateList', payload);
  },
  add({ commit }, payload) {
    commit('addItem', payload);
  },
  changeAmount({ commit }, payload) {
    commit('updateItem', payload);
  },
  remove({ commit }, payload) {
    commit('removeItem', payload);
  },
};

export const getters = {
  getList: (state) => (state.list ? [...state.list].reverse() : []),
};
