import { productBadgesFragment } from '~/apollo-api/fragments/productBadges';
import { gql } from './index';
import { cachedQuery } from './utils';

import { DisplayType } from './types';

export const dshopMain = (productsSize = 20, displayType: DisplayType = DisplayType.DESKTOP) => {
  return cachedQuery({
    query: gql`
      query DshopMain($productsSize: Int!, $displayType: DisplayType!) {
        dshopMain(type: $displayType) {
          offers {
            id
            title
            productsPage(size: $productsSize) {
              content {
                id
                title
                shortDescription
                rating
                feedbackQuantity
                favorite
                photos {
                  link(trans: PRODUCT_540) {
                    high
                  }
                }
                skuList {
                  id
                  availableAmount
                  sellPrice
                  fullPrice
                  characteristicValues {
                    title
                    value
                    id
                    characteristic {
                      id
                      title
                    }
                  }
                }
                skuGroups {
                  id
                  favorite
                }
                badges {
                  ...ProductBadgesFragment
                }
                category {
                  id
                  title
                  parentList {
                    id
                    title
                  }
                }
              }
            }
          }
          dshopTexts {
            additionalTexts {
              value
            }
          }
        }
        deliveryInfo {
          freeDeliveryMinPrice
        }
      }
      ${productBadgesFragment}
    `,
    variables: { productsSize, displayType },
    parser: (data) => ({ ...data.dshopMain, deliveryInfo: data.deliveryInfo }),
  });
};
