import { mapProductCartItem } from './event-addtocart';

export const goToPaymentEvent = function (orderItems, price, shipping, purchaseFunnel) {
  return {
    event: 'go_to_payment',
    ecommerce: {
      value: price / 100,
      currency: 'RUB',
      shipping: shipping / 100,
      purchase_funnel: purchaseFunnel,
      items: orderItems.map((item) => {
        return mapProductCartItem(item.sku.product, item.sku, item.amount);
      }),
    },
  };
};
