<template lang="pug">
.ds-checkout-date-time
  .ui-input-grid
    .ui-input-row.ui-input-grid
      div
        dropdown(
          :list="dateList"
          :value="value.date"
          @select="updateDate"
        )
          labeled-input(
            label="Дата:"
            placeholder="Выберите дату"
            :value="dateVisualValue"
            :is-error="!isValidDate && isShowWarnings"
            readonly
          )
        .error(v-if="isValidTime && !isValidDate && isShowWarnings") Заполните дату доставки

      div
        dropdown(
          :list="options"
          :value="value.time"
          :valueAttribute="'label'"
          @select="updateTime"
        )
          labeled-input(
            label="Время:"
            placeholder="Выберите время"
            readonly
            :value="optionVisualValue"
            :is-error="!isValidTime && isShowWarnings"
          )
        .error(v-if="isValidDate && !isValidTime && isShowWarnings") Заполните время доставки

  .error(v-if="!isValidDate && !isValidTime && isShowWarnings") Заполните дату и время доставки
</template>

<script>
import { mapState } from 'vuex';
import { formatDeliveryDates } from '~/modules/checkout/business/delivery';
import LabeledInput from '~/components/labeled-input';
import Dropdown from '~/components/dropdown';
import { CheckoutFieldMixin } from '~/mixins/business/checkout';

export default {
  components: {
    LabeledInput,
    Dropdown,
  },
  mixins: [CheckoutFieldMixin],
  data: () => ({
    field: 'dateTime',
  }),
  computed: {
    ...mapState('checkout', {
      city: (state) => state.city,
      value: (state) => ({
        date: state.date,
        time: state.time,
      }),
    }),
    ...mapState('checkout', ['deliveryOptions', 'isShowWarnings']),
    isValid() {
      return Object.values(this.value).every((el) => !!el);
    },
    dateList() {
      return this.city ? formatDeliveryDates(this.deliveryOptions) : [];
    },
    options() {
      return this.value.date ? this.value.date.options || [] : [];
    },
    dateVisualValue() {
      return this.value.date ? this.value.date.label : '';
    },
    optionVisualValue() {
      return this.value.time ? this.value.time.label : '';
    },
    isValidDate() {
      return Boolean(this.value.date);
    },
    isValidTime() {
      return Boolean(this.value.time);
    },
  },
  watch: {
    city() {
      this.updateDate();
      this.updateTime();
    },
  },
  methods: {
    updateDate(value) {
      this.$store.commit('checkout/updateDate', value);
      if (value?.options.length === 1) {
        this.updateTime(value.options[0]);
      } else {
        this.updateTime();
      }
    },
    updateTime(value) {
      this.$store.commit('checkout/updateTime', value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-checkout-date-time
  .error
    Text(CaptionM)
    color Red()
    margin-top 8px
</style>
