<template lang="pug">
.ds-input--counter.ds-component
  button-ui.action(@click.native="decrement" :disabled="isDisabledLeft")
    icon-ui(
        name="operations/minus_small-line"
        :size="20"
    )
  input.counter(
    type="number"
    :disabled="disabled"
    :value="value"
    @input="onInput"
    @blur="onBlur"
  )
  button-ui.action(@click.native="increment" :disabled="isDisabledRight")
    icon-ui(
      name="operations/plus_small-line"
      :size="20"
    )
</template>

<script>
import IconUi from '~/components/common/icon';
import ButtonUi from '~/components/common/button';

export default {
  name: 'InputCounter',
  components: {
    IconUi,
    ButtonUi,
  },
  props: {
    value: {
      default: 0,
      type: Number,
    },
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: undefined,
      type: Number,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    isDisabledLeft() {
      return this.value <= this.min;
    },
    isDisabledRight() {
      return this.value >= this.max;
    },
  },
  watch: {
    min() {
      if (this.value < this.min) {
        this.$emit('input', this.min);
      }
    },
    max() {
      if (this.value > this.max) {
        this.$emit('input', this.max);
      }
    },
  },
  methods: {
    onInput(event) {
      const value = event.target.value;
      this.$emit('input', value ? Number(value) : null);
    },
    onBlur() {
      if (this.value > this.max) {
        this.$emit('input', this.max);
      } else if (this.value >= this.min) {
        this.$emit('input', this.value);
      } else {
        this.$emit('input', this.min);
      }
    },
    decrement() {
      if (this.disabled) {
        return;
      }

      if (this.value > this.min) {
        this.$emit('input', Number(this.value) - 1);
      }
    },
    increment() {
      if (this.disabled) {
        return;
      }

      if (!this.max || this.value < this.max) {
        this.$emit('input', Number(this.value) + 1);
      }
    },
  },
};
</script>

<style lang="stylus">
/* disable input controls */

/* Chrome, Safari, Edge, Opera */
.counter::-webkit-outer-spin-button,
.counter::-webkit-inner-spin-button
  -webkit-appearance none
  margin 0

/* Firefox */
.counter[type=number]
  -moz-appearance textfield

/* disable input controls END */

.ds-input--counter
  border 1px solid Gray(L32)
  display flex
  justify-content space-between
  width 115px
  .action
    Button()
    box-sizing content-box
    user-select none
    height 40px
    width 40px
    padding 0
    &.disabled svg path
        fill Gray(L32)

  .counter
    text-align center
    border none
    flex 36px 1 1
    margin 0 4px
    width 32px
</style>
