export const settings = {
  apiKey: '0ed052d1-8cab-4737-8c3d-50d57e073d97',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
};

export const deliveryMapOptions = {
  maxMapZoom: 22, // Макс. значение зума карты
  maxClusterZoom: 17, // Макс. значение зума карты, на котором видны кластеры. Далее, распадаются на отдельные маркеры
};
