import { mapState } from 'vuex';
import Vue from 'vue';
import { getDadataOptions, EDadataService } from '~/api/getDadataOptions';
import { DadataStreetSearch } from '../models';

import { StaticDataMixin } from '~/mixins/staticData';

export const AddressSearchMixin = Vue.extend({
  mixins: [StaticDataMixin],
  data: () => ({
    streets: [],
    filteredText: '',
  }),
  computed: {
    ...mapState('checkout', ['city', 'isShowWarnings', 'checkStreets']),
  },
  methods: {
    async updateAddress() {
      const current = this.localCities.find((city) =>
        this.city.name ? this.city.name.includes(city) : this.city.city ? this.city.city.includes(city) : false,
      );
      let cityString = this.city.city;
      if (current) {
        const cityArr = this.city.city.split(' ');
        cityString = cityArr[cityArr.length - 1];
      }

      try {
        this.streets = (
          await getDadataOptions({
            type: EDadataService.Address,
            query: this.filteredText,
            options: {
              locations: [{ city: cityString, settlement: this.city.settlement, region: this.city.region }],
              from_bound: { value: 'settlement' },
              to_bound: { value: 'house' },
              restrict_value: true,
            },
          })
        )
          /**
           * include all fias levels for streets && settlement in city
           */
          .filter(
            (dadataValue) =>
              dadataValue.data.fias_level === '65' ||
              dadataValue.data.fias_level === '7' ||
              dadataValue.data.fias_level === '75' ||
              dadataValue.data.fias_level === '8',
          )
          .map((dadataValue) => {
            /**
             * @param area_with_type - need for searching Moscow addresses
             * case for Moscow with area_with_type
             */
            if (this.city.name.includes('Москва') && dadataValue.data.area_with_type) {
              return {
                name: `${dadataValue.data.area_with_type}, ${dadataValue.value}`,
                area_with_type: dadataValue.data.area_with_type,
                substring: this.cutString(dadataValue.unrestricted_value),
                house: dadataValue.data.house,
                street: `${dadataValue.data.area_with_type}, ${dadataValue.value}`,
                settlement_with_type: dadataValue.data.settlement_with_type,
              };
            } else if (
              this.city.name.includes('Зеленоград') ||
              this.city.name.includes('Улан-Удэ') ||
              this.city.name.includes('Санкт-Петербург')
            ) {
              return {
                name: dadataValue.value,
                substring: this.cutString(dadataValue.unrestricted_value),
                house: dadataValue.data.house,
                street: dadataValue.value,
                settlement_with_type: dadataValue.data.settlement_with_type,
              };
            } else {
              return {
                name: dadataValue.value,
                substring: this.cutString(dadataValue.unrestricted_value),
                house: dadataValue.data.house,
                street: dadataValue.value,
                house_type: dadataValue.data.house_type,
                street_with_type: dadataValue.data.street_with_type,
                settlement_with_type: dadataValue.data.settlement_with_type,
              };
            }
          })
          .filter((value, index, array) => array.findIndex((_) => _.name === value.name) === index); // distinct
        if (this.streets.length > 0) {
          this.$store.commit('checkout/updateCheckStreets', this.streets);
        }
      } catch (error) {
        console.error(error);
        this.streets = [];
      }
    },
    cutString(string) {
      const lastCommaIndex = string.lastIndexOf(',');
      const secondLastCommaIndex = string.lastIndexOf(',', lastCommaIndex - 1);
      if (secondLastCommaIndex !== -1) {
        return string.slice(0, secondLastCommaIndex);
      } else {
        return string;
      }
    },
  },
});
