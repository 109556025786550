import { Options_calcCourierDeliveryOffers } from '~/apollo-api/types';
import { dateToLocale } from '~/modules/common/date';

export interface IFormattedDeliveryDate {
  label: string;
  value: number;
  options?: {
    from: string;
    to: string;
    price: number;
  }[];
}

export function formatDeliveryDates(dates: Options_calcCourierDeliveryOffers[]): IFormattedDeliveryDate[] {
  if (!dates?.length) {
    return [];
  }
  return dates.map((el) => {
    const today = new Date();
    const date = new Date(el.date);
    const weekdays = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];
    const suffix = date.getDay() === today.getDay() ? 'сегодня' : weekdays[date.getDay()];
    const localedDate = dateToLocale(date, {
      month: 'long',
      day: 'numeric',
    });
    const label = `${localedDate}, ${suffix}`;

    return {
      label,
      value: el.date,
      options: el.options.map((option) => ({ ...option, label: `${option.from}-${option.to}` })),
    };
  });
}
