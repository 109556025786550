import { gql, client } from './index';

export const dshopTexts = async (textsType: string) => {
  //hack for apollo-types
  const newQuery = `
  query DshopText {
    dshopMain {
      dshopTexts {
        ${textsType}
      }
    }
  }`;

  const { data } = await client.query({
    query: gql(newQuery),
  });

  return data.dshopMain.dshopTexts;
};
