export const DeliveryType = Object.freeze({
  COURIER: 'COURIER',
  DELIVERY_POINT: 'DELIVERY_POINT',
  RUSSIAN_POST: 'RUSSIAN_POST',
});

export const DeliveryTypeForCard = Object.freeze({
  DP: 'DpDelivery',
  COURIER: 'CourierDelivery',
  RUPOST: 'RuPostDelivery',
});
