import Cookies from 'js-cookie';
import { mergeCart } from '~/modules/cart/mergeCart';
import { updateUser } from '~/apollo-api/updateUser';

import { CACHE_SEARCH_CAPACITY } from '~/modules/search-suggestions';

export const SignUpState = Object.freeze({ Phone: 1, Code: 2, Password: 3 });

const updateContacts = () => {}; // @TODO

const getInitialTokens = () => ({
  accessToken: '',
  refreshToken: '',
});

const initialState = {
  tokens: getInitialTokens(),
  registration: {
    lastCodeSentAt: null,
    state: SignUpState.Phone,
    phone: '',
    code: '',
    email: '',
    attempt: 0,
    hasResendCodeRequest: false,
  },
  isAuth: false,
  isLoadingComplete: false,
  customer: {
    id: null,
    contacts: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
  },
  searchHistory: [],
};

export const state = () => initialState;

export const mutations = {
  logout(state) {
    Cookies.remove('userId');
    Cookies.remove('auth');
    state.isAuth = !state.isAuth;
  },
  setTokens(state, payload) {
    Cookies.set('auth', JSON.stringify(payload), { expires: 14, secure: process.env.NODE_ENV !== 'development' });
  },
  clearRegistrationData(state) {
    state.registration = initialState.registration;
  },
  setCodeRequestTime(state, payload) {
    state.registration.lastCodeSentAt = payload;
  },
  setCustomer(state, payload) {
    state.customer = payload;
    Cookies.set('userId', payload.id);
  },
  setContacts(state, payload) {
    state.customer.contacts = payload;
  },
  setRegistrationEmailPhone(state, { email = '', phone = '' }) {
    state.registration.email = email;
    state.registration.phone = phone;
  },
  setState(state, payload) {
    state.registration.state = payload;
  },
  setCode(state, payload) {
    state.registration.code = payload;
  },
  incrementAttempt(state) {
    state.registration.attempt++;
  },
  setHasResendCodeRequest(state, payload) {
    state.registration.hasResendCodeRequest = payload;
  },
  updateLoadingComplete(state, payload) {
    state.isLoadingComplete = payload;
  },
  updateContacts(state, payload) {
    state.customer.contacts = payload;
  },
  setRegistrationToken(state, payload) {
    Cookies.set('auth', JSON.stringify(payload), { expires: 14, secure: process.env.NODE_ENV !== 'development' });
  },
  pushSearchHistoryValue(state, payload) {
    if (!state) {
      return;
    }
    const wordIndex = state.searchHistory.findIndex((word) => word === payload.toLowerCase());

    if (state?.searchHistory.length && wordIndex > -1) {
      state.searchHistory.splice(wordIndex, 1);
    }

    if (payload) {
      state.searchHistory = [payload.toLowerCase(), ...state.searchHistory.slice(0, CACHE_SEARCH_CAPACITY - 1)];
    }
  },

  removeSearchHistoryValue(state, payload) {
    if (state.searchHistory.length) {
      state.searchHistory = state.searchHistory.filter((item) => item !== payload);
    }
  },

  clearSearchHistory(state) {
    state.searchHistory = [];
  },
  loggedIn(state) {
    state.isAuth = !state.isAuth;
  },
};

export const actions = {
  async loggedIn(context) {
    context.commit('loggedIn');
    await mergeCart(context);
  },
  loggedOut({ dispatch }) {
    dispatch('cart/update', [], { root: true });
    dispatch('checkout/clearCheckoutData', null, { root: true });
  },

  clearRegistrationData({ commit }) {
    commit('clearRegistrationData');
  },
  setCodeRequestTime({ commit }, payload) {
    commit('setCodeRequestTime', payload);
  },
  setTokens({ commit }, payload) {
    commit('setTokens', payload);
  },
  setCustomer({ commit }, payload) {
    commit('setCustomer', payload);
  },
  setContacts({ commit }, payload) {
    commit('setContacts', payload);
  },
  setRegistrationEmailPhone({ commit }, payload) {
    commit('setRegistrationEmailPhone', payload);
  },
  setRegistrationToken({ commit }, payload) {
    commit('setRegistrationToken', payload);
  },
  setState({ commit }, payload) {
    commit('setState', payload);
  },
  setCode({ commit }, payload) {
    commit('setCode', payload);
  },
  incrementAttempt({ commit }) {
    commit('incrementAttempt');
  },
  setHasResendCodeRequest({ commit }, payload) {
    commit('setHasResendCodeRequest', payload);
  },
  logInWithToken({ dispatch, state }, payload) {
    dispatch('setTokens', payload);
    dispatch('loggedIn', payload);
  },
  logOut({ commit, dispatch }) {
    commit('logout');
    dispatch('loggedOut');
  },
  updateContacts({ commit }, payload) {
    const tokens = Cookies.get('auth');
    if (!tokens) {
      return;
    }

    updateContacts(payload, JSON.parse(tokens).accessToken);
    commit('updateContacts', payload);
  },
  async updateUserData({ commit }, payload) {
    commit('updateLoadingComplete', true);
    const contacts = await updateUser(payload);
    commit('setContacts', contacts.data.updateUser);
    commit('updateLoadingComplete', false);
  },
};

// export const getters = {
//   isAuth: () => {
//     const tokens = Cookies.get('auth')
//     if (tokens) {
//       return !!JSON.parse(tokens).accessToken
//     }
//     else false
//   },
//   accessToken: () => {
//     const tokens = Cookies.get('auth')
//     if (tokens) {
//       return JSON.parse(tokens).accessToken
//     }
//   },
// };
