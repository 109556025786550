<template lang="pug">
.ds-header-catalog
  button-ui.catalog-button(v-if="!media.isMobileOrTablet" @click="toggleCatalog") Каталог
    catalog-icon-ds.catalog-button-icon(:is-open="isCatalogOpen" background="#F2F4F7" slot="before")
  client-only
    catalog-categories-ds(
      :is-menu-open="isMenuOpen"
      :is-catalog-open="isCatalogOpen"
      :is-show-close-icon="isShowCloseIcon"
      @close="closeCatalog"
    )
</template>

<script>
import ButtonUi from '~/components/common/button';
import CatalogIconDs from '~/components/header/catalog/catalog-icon';
import CatalogCategoriesDs from '~/components/header/catalog/catalog-categories';

export default {
  components: {
    ButtonUi,
    CatalogIconDs,
    CatalogCategoriesDs,
  },
  inject: ['media'],
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    isCatalogOpen: {
      type: Boolean,
      default: false,
    },
    isShowCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCatalog() {
      this.$emit('toggle');
    },
    closeCatalog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-header-catalog
  .catalog-button
    Text(BodyL Medium Short)
    padding 10px 12px
    background #F2F4F7
    color BlueBrand()
    .catalog-button-icon
      margin-right 8px
</style>
