<template lang="pug">
form(@submit.prevent="saveUserHandler")
  .ds-profile-settings
    .setting-block
      .title Контактная информация
      labeled-input-ds(
        mask="+7 ### ###-##-##"
        :value="`+7${contacts.phone}`"
        @input="update('phone', $event)"
        label="Ваш номер телефона:"
      )
      .subtitle Уведомим об изменениях статуса заказа
      labeled-input-ds(
        :value="contacts.email"
        @input="update('email', $event)"
        label="Электронная почта:"
      )
      .error-contacts(v-if="!!contactsError.emailOrPhoneError") {{ contactsError.emailOrPhoneError }}
      .subtitle Пришлем электронный чек заказа
    .setting-block
      .title Личные данные
      labeled-input-ds(
        :value="contacts.lastName"
        @input="update('lastName', $event)"
        label="Фамилия:"
      )
      labeled-input-ds(
        :value="contacts.firstName"
        @input="update('firstName', $event)"
        label="Имя:"
      )
      .error-contacts(v-if="!!contactsError.fullNameError") {{ contactsError.fullNameError }}
      .subtitle(slot="after")
        span Фамилия и имя полностью, как в паспорте.</br>Может понадобиться при получении заказа
      button-ui.button-save-contacts(:disabled="isForbiddenProcces" type="submit") Сохранить
        spinner-ds(v-if="isLoadingComplete" :size="18" :stroke="1" slot="before")
    .setting-block.security(v-if="false")
      .title Безопасность
      labeled-input-ds(
        :type="passwordType('old')"
        v-model="password"
        label="Текущий пароль:"
      )
        .action(
          slot="after"
          @click="togglePasswordVisible('old')"
        )
          icon-ui(:name="iconPassword('old')")
      label.error-contacts(v-html="error")
      .edit-password-action(v-if="!isEditingPassword" @click="editPassword") Изменить
      .edit-password(v-else)
        labeled-input-ds(
          :type="passwordType('new')"
          v-model="newPassword"
          label="Новый пароль:"
        )
          .action(
            slot="after"
            @click="togglePasswordVisible('new')"
          )
            icon-ui(:name="iconPassword('new')")
        checklist-ds(:input="newPassword" :validations="validations")
        .actions
          button-ui.update(:disabled="!isCorrectNewPassword" @click="updatePassword") Обновить пароль
          button-ui.cancel(@click="cancelEditing") Отмена

</template>

<script>
import isEqual from 'lodash.isequal';
import { mapState } from 'vuex';
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import LabeledInputDs from '~/components/labeled-input';
import SpinnerDs from '~/components/spinner';
import ChecklistDs from '~/components/checklist';
import { INPUT_MAX_LENGTH } from '~/utils/input.constant';
import { checkError } from '~/utils/checkError';
import { getUser } from '~/apollo-api/getUser.ts';
const validateEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value?.toLowerCase(),
  );

const validatePhone = (value) => value?.replace('+7', '').replace(/[^0-9]/g, '')?.length === 10;
export default {
  name: 'ProfileSettingPage',
  components: {
    ChecklistDs,
    LabeledInputDs,
    SpinnerDs,
    ButtonUi,
    IconUi,
  },
  data: () => ({
    contacts: {},
    error: '',
    password: '',
    newPassword: '',
    isChangeUserData: false,
    isSubmiting: false,
    contactsError: {
      fullNameError: '',
      emailOrPhoneError: '',
      maxLengthError: '',
    },
    validations: [
      {
        title: 'Не менее 8 символов',
        predicate: (input) => input.length >= 8,
      },
      {
        title: 'Состоит из цифр и латинских букв',
        predicate: (input) => /\d.*[a-zA-Z]|[a-zA-Z].*\d/.test(input),
      },
      {
        title: 'Содержит строчные и заглавные буквы',
        predicate: (input) => /[a-z].*[A-Z]|[A-Z].*[a-z]/.test(input),
      },
    ],
    isEditingPassword: false,
    isNeedActions: false,
    isPasswordVisible: {
      old: false,
      new: false,
    },
    update: () => {},
  }),
  computed: {
    ...mapState('user', ['customer', 'isLoadingComplete']),

    storeContacts() {
      return {
        firstName: this.customer.contacts?.firstName,
        lastName: this.customer.contacts?.lastName,
        phone: this.customer.contacts?.phone,
        email: this.customer.contacts?.email,
      };
    },
    isCorrectNewPassword() {
      return this.validations.every((v) => v.predicate(this.newPassword));
    },
    truePhone() {
      return this.contacts.phone.replace('+7', '').replace(/[^0-9]/g, '');
    },
    isValidPhone() {
      return validatePhone(this.contacts.phone);
    },
    isValidEmail() {
      return validateEmail(this.contacts.email);
    },
    isValidFirstName() {
      return !!this.contacts.firstName?.length;
    },
    isValidLastName() {
      return !!this.contacts.lastName?.length;
    },
    fullNameError() {
      if (!this.isValidLastName && !this.isValidFirstName) {
        return 'Заполните фамилию и имя';
      }
      if (this.isValidFirstName && !this.isValidLastName) {
        return 'Заполните фамилию';
      }
      if (this.isValidLastName && !this.isValidFirstName) {
        return 'Заполните имя';
      }
      return '';
    },
    credErrorError() {
      if (this.contacts.phone === '' && this.contacts.email === '') {
        return 'Заполните телефон и email';
      }

      if (this.isValidPhone && this.contacts.email === '') {
        return 'Заполните email';
      }

      if (this.isValidEmail && this.contacts.phone === '') {
        return 'Заполните телефон';
      }

      if (!this.isValidEmail && !this.isValidPhone) {
        return 'Неверный формат телефона и email';
      }
      if (!this.isValidPhone) {
        return 'Неверный формат телефона';
      }
      if (!this.isValidEmail) {
        return 'Неверный формат email';
      }
      return '';
    },
    isValidMaxLength() {
      return (
        this.contacts.email?.length <= INPUT_MAX_LENGTH &&
        this.contacts.firstName?.length <= INPUT_MAX_LENGTH &&
        this.contacts.lastName?.length <= INPUT_MAX_LENGTH
      );
    },
    isForbiddenProcces() {
      return !this.isChangeUserData || this.isLoadingComplete || checkError({ ...this.contactsError });
    },
  },
  watch: {
    password() {
      this.error = '';
    },
    contacts(contact) {
      this.isChangeUserData = !isEqual({ ...contact }, this.storeContacts);
    },
    storeContacts(store) {
      this.isChangeUserData = !isEqual({ ...store }, { ...this.contacts });
    },
    credErrorError(error) {
      if (this.isSubmiting) {
        this.contactsError.emailOrPhoneError = error;
      }
    },
    fullNameError(error) {
      if (this.isSubmiting) {
        this.contactsError.fullNameError = error;
      }
    },
    isValidMaxLength(e) {
      if (this.isSubmiting) {
        this.contactsError.maxLengthError = !e ? 'error' : '';
      }
    },
  },
  async mounted() {
    // this.setLocalContacts();
    await getUser()
      .then((data) => {
        if (data) {
          const inputData = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
          };
          this.$store.dispatch('user/setContacts', inputData);
          this.contacts = inputData;
          this.$store.commit('checkout/updateRecipient', inputData);
        }
      })
      .catch((err) => console.log(err));
    this.update = this.updateHandler;
  },
  methods: {
    updateHandler(name, event) {
      this.$set(this, 'contacts', {
        ...this.contacts,
        [name]: name === 'phone' ? event.replace('+7', '').replace(/[^0-9]/g, '') : event.trim(),
      });
    },

    async setLocalContacts() {
      await this.$set(this, 'contacts', { firstName: '', lastName: '', phone: '', email: '', ...this.storeContacts });
    },
    async saveUserHandler() {
      if (this.fullNameError) {
        this.contactsError.fullNameError = this.fullNameError;
      }

      if (this.credErrorError) {
        this.contactsError.emailOrPhoneError = this.credErrorError;
      }

      if (!this.isValidMaxLength) {
        this.contactsError.maxLengthError = 'error';
      }

      if (checkError({ ...this.contactsError })) {
        this.isSubmiting = true;
        return null;
      }

      if (!this.isForbiddenProcces) {
        await this.$store.dispatch('user/updateUserData', { ...this.contacts });
        this.$store.commit('checkout/updateRecipient', this.contacts);
      }
    },
    togglePasswordVisible(name) {
      this.isPasswordVisible[name] = !this.isPasswordVisible[name];
    },
    iconPassword(name) {
      return this.isPasswordVisible[name] ? 'operations/eye-hide_line' : 'operations/eye-show_line';
    },
    passwordType(name) {
      return this.isPasswordVisible[name] ? 'text' : 'password';
    },
    editPassword() {
      let flag = true;
      try {
        // @todo query for verify password
      } catch (error) {
        flag = false;
        const errors = error.graphQLErrors.map((x) => x.extensions?.exceptionClass);
        if (errors.includes('InvalidGrantException')) {
          this.error = 'Неверный пароль';
        } else {
          this.error = 'Что-то пошло не так. Повторите попытку';
        }
      }
      if (flag) {
        this.isEditingPassword = true;
        this.password = '';
      }
    },
    updatePassword() {
      if (!this.isCorrectNewPassword) {
        return;
      }
      // @todo query for save new password
      this.isEditingPassword = false;
      this.newPassword = '';
    },
    cancelEditing() {
      this.isEditingPassword = false;
      this.newPassword = '';
    },
  },
};
</script>

<style lang="stylus">
.error-contacts
  Text(CaptionM)
  color Red()
  margin-top 8px
.button-save-contacts
  Button(Primary Large)
  Text(BodyL Semibold)
  width 100%
  margin-top 34px
.ds-profile-settings
  margin-top 36px
  .setting-block
    width 360px
    &:not(:first-child)
      margin-top 64px
    .title
      Text(TitleM)
    .subtitle
      Text(CaptionM)
      color Gray(L8)
      margin-top 8px
    .ds-labeled-input
      margin-top 24px
      .action
        margin-right 12px
        margin-top 3px
        svg path
          fill #C4C4C4
        &:hover svg path
          fill Gray(D32)
    &.security
      .edit-password-action
        Text(BodyM Semibold)
        margin-top 35px
        cursor pointer
        &:hover
          text-decoration underline
      .edit-password
        margin-top 35px
        .ds-checklist
          margin-top 18px
        .actions
          display flex
          margin-top 34px
          .update
            Button(Large Primary)
          .cancel
            Button(Medium)

  +Media(Mobile,Tablet)
    margin-top 8px
    .setting-block
      width 100%
      &:not(:first-child)
        margin-top 32px
      .title
        Text(TitleS)
      .ds-labeled-input
        margin-top 16px
</style>
