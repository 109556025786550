<template lang="pug">
.ds-summary.ds-component
  min-price(v-if="!media.isMobileOrTablet && hasMinPriceLimit" :min-price="minPrice" :price="summary.price")
  .ds-card-summary
    .title Ваш заказ
    table
      tbody
        tr(v-if="summary.weight")
          td Вес заказа
          td {{ summary.weight | formatWeight }}
        tr
          td {{ summary.amount <= 1 ? 'Товар' : 'Товары' }} ({{ summary.amount | formatNumber }})
          td {{ summary.price | formatPrice }}
        tr(v-if="discountAmount < 0")
          td Применен промокод
          td {{ discountAmount | formatPrice }}
      tfoot
        tr
          td.warning Итого
          td.sum {{ (summary.price + (discountAmount ? discountAmount : 0)) | formatPrice }}
        tr
          td.eco(colspan="2" v-if="summary.saving > 0") Вы экономите <span>{{ (summary.saving - (discountAmount ? discountAmount : 0)) | formatPrice }}</span>
    promo-code-ds(:isLoading="isPromoLoad" style="margin-bottom: 12px" :amount-products="summary.amount")
    max-weight-plate(v-if="hasMaxWeightLimit")
    min-price(v-if="media.isMobileOrTablet && hasMinPriceLimit" :min-price="minPrice" :price="summary.price")
    button-ui.button(:disabled="hasUnavailableProducts || hasMaxWeightLimit || hasMinPriceLimit || isLoad" @click.native="isLoad ? '' : clickSubmit()") Перейти к оформлению

    .info * Окончательная стоимость будет рассчитана после выбора адреса доставки при оформлении заказа.
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ButtonUi from '~/components/common/button';
import MaxWeightPlate from '~/components/max-weight-plate';
import MinPrice from '~/pages/cart/components/min-price';
import { currencyFormatMixin, numberFormatMixin, weightFormatMixin } from '~/mixins';
import PromoCodeDs from '~/pages/checkout/components/promo-code.vue';
import { MAX_WEIGHT_RUSSIAN_POST } from '~/shop-config';

export default {
  components: {
    ButtonUi,
    MaxWeightPlate,
    MinPrice,
    PromoCodeDs,
  },
  mixins: [currencyFormatMixin, numberFormatMixin, weightFormatMixin],
  inject: ['media'],
  props: {
    hasUnavailableProducts: {
      default: false,
      type: Boolean,
    },
    isWeightLimitsDisable: {
      default: false,
      type: Boolean,
    },
    isLoad: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    MAX_WEIGHT_RUSSIAN_POST,
  }),
  computed: {
    ...mapGetters('cart', ['getList']),
    ...mapState('checkout', ['discountAmount', 'isPromoLoad']),
    cartProducts() {
      return this.getList;
    },
    hasMaxWeightLimit() {
      return !this.isWeightLimitsDisable && this.summary.weight >= MAX_WEIGHT_RUSSIAN_POST;
    },
    minPrice() {
      return this.$store.getters['main/freeDeliveryMinPrice'];
    },
    hasMinPriceLimit() {
      return this.minPrice > this.summary.price > 0;
    },
    summary() {
      const payload = {
        price: 0,
        saving: 0,
        amount: 0,
        weight: 0,
      };

      this.cartProducts.forEach((product) => {
        payload.price += product.sku.sellPrice * product.amount;
        payload.saving += product.sku.fullPrice * product.amount - product.sku.sellPrice * product.amount;
        payload.amount += product.amount;
        if (product.sku.weight) {
          payload.weight += product.sku.weight * product.amount;
        }
      });

      return payload;
    },
  },
  methods: {
    clickSubmit() {
      if (!this.hasUnavailableProducts && !this.hasMaxWeightLimit && !this.hasMinPriceLimit) {
        this.$gtm.push({ ecommerce: null });
        this.$gtm.push(this.$dataLayer.beginCheckoutEvent(this.cartProducts, this.summary.price, 'Купить из корзины'));

        this.$router.push({ name: 'checkout' });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-card-summary
  border 1px solid Divider()
  padding 24px
  +Media(Mobile, Tablet)
    padding 16px 0
    border none

.title
  Text(TitleM Short Secondary)
  text-align center
  color TextPrimary()
  +Media(Mobile, Tablet)
    text-align left

.button
  Button(Primary Large)
  Text(BodyL Semibold)
  width 100%
  +Media(Mobile, Tablet)
    display none

table
  margin 16px 0
  width 100%
tfoot
  td
    padding-top 8px
    padding-bottom 0
    &.eco
      color TextSecondary()
      +Media(Laptop, Desktop, DesktopMax)
        text-align center
      span
        font-weight 600
td
  Text(BodyM Short)
  color TextPrimary()
  padding-bottom 8px
  &:last-child
    text-align right
  &:first-child:not([colspan])
    &::after
      content ':'
    &.warning
      Text(BodyM Semibold Short)
      &::after
        content ': *'
  &[colspan]
    text-align center
    Text(CaptionM)
    +Media(Mobile, Tablet)
      text-align right
  &.sum
    Text(BodyL Semibold Short)

.info
  Text(CaptionM Long Light)
  margin-top 16px
  color TextSecondary()
</style>
