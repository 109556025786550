
import Vue, { PropType } from 'vue';

import IconUi from '~/components/common/icon.vue';

// import { ICategorySuggest } from '~/modules/search-suggestions/models';
// @todo from backend fix catalog block
type ICategorySuggest = any;

export default Vue.extend({
  components: {
    IconUi,
  },
  props: {
    block: {
      type: Object as PropType<ICategorySuggest>,
      required: true,
    },
  },
  methods: {
    selectSuggestion(value) {
      this.$emit('select-suggestion', value);
    },
    mouseenter(value) {
      this.$emit('hover', value);
    },
    mouseleave() {
      this.$emit('leave');
    },
  },
});
