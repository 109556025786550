import { Product, Product_product } from '~/apollo-api/types';
import { client, gql } from './index';
import { productBadgesFragment } from '~/apollo-api/fragments/productBadges';
import { productCharacteristicsFragment } from '~/apollo-api/fragments/productCharacteristics';

export const getProduct = async (id: number): Promise<Product_product> => {
  const {
    data: { product },
  } = await client.query<Product>({
    query: gql`
      query Product($id: Int!) {
        product(id: $id) {
          id
          description
          title
          shortDescription
          favorite
          photos {
            key
            link(trans: PRODUCT_240) {
              high
            }
            original {
              high
            }
          }
          category {
            title
            id
            parentList {
              title
              id
            }
          }
          skuList {
            id
            fullPrice
            sellPrice
            skuTitle
            availableAmount
            weight
            photo {
              key
              link(trans: PRODUCT_240) {
                high
              }
            }
            characteristicValues {
              title
              value
              id
              characteristic {
                id
                title
              }
            }
          }
          skuGroups {
            id
            favorite
            minFullPrice
            minSellPrice
            photos {
              key
              link(trans: PRODUCT_240) {
                high
              }
            }
            characteristicValues {
              id
              characteristic {
                id
                title
              }
              photo {
                key
                link(trans: PRODUCT_240) {
                  high
                }
              }
              title
              value
            }
          }
          comments {
            type
            value
          }
          characteristics {
            ...ProductCharacteristicsFragment
          }
          badges {
            ...ProductBadgesFragment
          }
        }
      }
      ${productBadgesFragment}
      ${productCharacteristicsFragment}
    `,
    variables: {
      id,
    },
  });

  return product;
};
