import { client, gql } from './index';
export const getUser = async () => {
  const { data } = await client.query<any>({
    query: gql`
      query User {
        user {
          email
          firstName
          lastName
          patronymic
          phone
          sex
        }
      }
    `,
  });

  return data.user;
};
