<template lang="pug">
.ds-shop-card.ds-component
  link-ui.link--title.card-image-wrapper(:to="to")
    img-smooth.card-image(
      :src="product.sku.photo.link.high"
      :alt="product.sku.product.title"
    )
  .card-item.wrapper
    .card-item.card-content
      div
        .prices(v-if="!isNotAvailable")
          .price.current(:class="product.sku.fullPrice!== product.sku.sellPrice ? 'discount' : ''") {{ product.sku.sellPrice * product.amount | formatPrice }}
          .price.old(v-if="product.sku.fullPrice !== product.sku.sellPrice") {{ product.sku.fullPrice * product.amount | formatPrice }}
        link-ui.link--title.title(:to="to") {{product.sku.product.title}}
        ul.properties(v-if="product.sku.characteristicValues.length")
          li(
            v-for="char in product.sku.characteristicValues"
            :key="char.characteristic.title"
          )
            span.property-key {{ char.characteristic.title }}
            span {{ char.title }}
      div.warning_amount(v-if="product.oldAmount > 0 && product.sku.availableAmount > 0")
        icon-ui.icon( size="24" name="status/error_fill" fill="#EA5614")
        span.warning_amount_last Осталось {{ product.sku.availableAmount }} шт
        span.warning_amount_old {{ product.oldAmount }} шт
      div.warning_amount(v-else-if="product.oldAmount !== undefined && product.sku.availableAmount == 1")
        icon-ui.icon( size="24" name="navigation/bestsellers_line" fill="#EA5614")
        span.warning_amount_last Остался последний
      .card-item--footer
        template(v-if="!isNotAvailable")
          input-counter-ui(
            :value="product.amount"
            :min="1"
            :max="product.sku.availableAmount"
            @input="onAmountChange($event, product)"
          )
          .input-price(v-if="product.amount > 1"): span.input-price-data {{ product.sku.sellPrice | formatPrice }}
        template(v-else)
          .warning-available(v-if="product.sku.availableAmount != 0") Товар не доставляется в ваш регион
          .warning-available(v-else) Товар закончился
    .card-item.card-footer
      .actions
        //- icon-ui.action.icon-favorite(name="navigation/favorite_line")
        icon-ui.action.icon-cart(
          style="padding: 0"
          name="operations/trash_sharp"
          @click.native="onRemoveItem"
        )
</template>

<script>
import LinkUi from '~/components/common/link';
import IconUi from '~/components/common/icon';
import ButtonUi from '~/components/common/button';
import InputCounterUi from '~/components/input-counter';
import { currencyFormatMixin } from '~/mixins';

export default {
  components: {
    LinkUi,
    IconUi,
    ButtonUi,
    InputCounterUi,
  },

  mixins: [currencyFormatMixin],

  props: {
    product: { type: Object, default: () => {} },
    isNotAvailable: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    input: 0,
  }),

  computed: {
    to() {
      return { name: 'product', params: { id: this.product.sku.product.id } };
    },
  },

  methods: {
    onRemoveItem() {
      this.$emit('remove', this.product.sku.id);
      this.$gtm.push(
        this.$dataLayer.removeFromCartEvent(this.product.sku.product, this.product.sku, this.product.amount),
      );
    },
    onAmountChange(amount, product) {
      this.$emit('change', {
        product,
        amount,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-shop-card
  display flex
  color TextPrimary()
  position relative
  padding 16px 0

.card-item
  &.wrapper
    display flex
    flex-grow 1
    +Media(Mobile, Tablet)
      flex-direction column
      margin-left 12px

    +Media(Laptop)
      margin-left 16px

    +Media(Desktop, DesktopMax)
      margin-left 40px

  &.card-content
    display flex
    flex-direction column
    justify-content space-between
    flex-grow 1
    .title
      Text(BodyM Medium Short)
      margin 8px 0 4px
      display inline-block

  &.card-footer
    display flex
    flex-direction column
    justify-content flex-end
    +Media(Mobile, Tablet)
      order -1
      flex-direction row
      margin-bottom 10px

.card-item--footer
  display flex
  align-items center
  .warning-available
    Text(BodyM Short)
    color Orange(LT2)
    margin-bottom 20px
  +Media(Mobile, Tablet)
    flex-direction column
    align-items flex-start
    .input-price
      width 115px //- width of ds-input counter
      text-align left
      margin 4px 0

.card-image-wrapper
  flex-shrink 0
  width 108px
  height 144px
  +Media(Mobile, Tablet)
    width 84px
    height 112px

.card-image
  width 100%
  height 100%
  object-fit contain

.properties
  Text(BodyS Short)
  color TextSecondary()
  margin 8px 0

.property-key
  &::after
    content ': '

.link--title
  color inherit

.title
  Text(BodyM Semibold)

.description
  Text(CaptionM Light)
  margin 8px 0
  +Media(Mobile, Tablet)
    font-size TextXS
.input-price
  color Gray(L8)
  margin-left 16px
  +Media(Mobile, Tablet)
    margin-top 4px
    height 21px

.input-price-data
  &::before
    content 'x '

.action
  cursor pointer
  &:not(:first-child)
    margin-left 40px
  &.icon-cart
    fill IconSecondary()
    +Media(Mobile, Tablet)
      position absolute
      right 0
      bottom 16px

.actions
  display flex
  justify-content flex-end
  margin-bottom 10px

.prices
  display flex
  align-items center
  +Media(Mobile, Tablet)
    order -1

.price
  &:not(:last-child)
    margin-right 8px

  &.current
    Text(BodyL Semibold)
    +Media(Mobile, Tablet)
      order -1

  &.old
    Text(BodyM Short LineThrough)
    color TextSecondary()
.warning_amount
  display flex
  align-items center
  font-family 'Inter'
  font-size 14px
  margin-bottom 7px
  &_last
    color #EA5614
    margin 0 7px
  &_old
    color #8B8E99
    text-decoration line-through
</style>
