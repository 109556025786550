<template lang="pug">
.cabinet-order-page
  tabs-ds(v-model="activeTab")
    tabs-title-ds(name="active" slot="title") Активные
    tabs-title-ds(name="all" slot="title") Все заказы
    tabs-content-ds(name="active" slot="content")
      .orders-list
        order-card(
          v-for="order in orders.active"
          :key="order.id"
          :order="order"
          :is-show-map-init="false"
        )
        infinite-loading(ref="infiniteActive" spinner="spiral" @infinite="infiniteHandlerActive")
          div(slot="no-more")
          div(slot="spinner")
          .not-results(slot="no-results") Активных заказов нет
    tabs-content-ds(name="all" slot="content")
      .orders-list
        order-card(
          v-for="order in orders.all"
          :key="order.id"
          :order="order"
          :is-show-map-init="false"
        )
        infinite-loading(ref="infiniteAll" spinner="spiral" @infinite="infiniteHandlerAll")
          div(slot="no-more")
          div(slot="spinner")
          .not-results(slot="no-results") Заказов нет
</template>
<script>
import { OrderStatus } from '~/apollo-api/types';
import OrderCard from './components/card';
import TabsDs from '~/components/tabs';
import TabsTitleDs from '~/components/tabs/title';
import TabsContentDs from '~/components/tabs/content';
import { activeStatuses } from '~/modules/orders/data';
import { mapState } from 'vuex';
import { orders } from '~/modules/orders/api';
import { throttle } from '~/modules/common/throttle';
import InfiniteLoading from 'vue-infinite-loading';
import { AuthenticatedMixin } from '~/mixins/authenticated';

const throttleTime = 500;
const pageSize = 2;

const ORDERS_TYPE = Object.freeze({
  ACTIVE: 'active',
  ALL: 'all',
});

const requestInterval = (orders) => (orders.some((order) => order.status === OrderStatus.CREATED) ? 2000 : 60000);

export default {
  name: 'Orders',
  components: {
    OrderCard,
    TabsContentDs,
    TabsDs,
    TabsTitleDs,
    InfiniteLoading,
  },
  mixins: [AuthenticatedMixin],
  data: () => ({
    activeTab: ORDERS_TYPE.ACTIVE,
    orders: {
      active: [],
      all: [],
    },
    page: {
      [ORDERS_TYPE.ACTIVE]: 0,
      [ORDERS_TYPE.ALL]: 0,
    },
    infiniteHandlerActive: () => {},
    infiniteHandlerAll: () => {},
    requestIntervalId: null,
  }),
  watch: {
    accessToken(val) {
      if (val) {
        this.$refs?.infiniteActive.attemptLoad();
        this.$refs?.infiniteAll.attemptLoad();
      }
    },
    activeTab() {
      this.$refs?.infiniteActive?.stateChanger?.reset?.();
      this.$refs?.infiniteAll?.stateChanger?.reset?.();
    },
  },
  created() {
    this.infiniteHandlerAll = throttle(this.infiniteHandler(ORDERS_TYPE.ALL), throttleTime);
    this.infiniteHandlerActive = throttle(this.infiniteHandler(ORDERS_TYPE.ACTIVE), throttleTime);
  },
  mounted() {
    this.requestIntervalId = setInterval(() => {
      window.location.reload();
    }, requestInterval(this.orders[this.activeTab]));
  },
  beforeDestroy() {
    clearInterval(this.requestIntervalId);
  },
  methods: {
    infiniteHandler(type) {
      return async ($state) => {
        if (!this.accessToken) {
          $state.complete();
        }
        const ordersPage = await orders({
          page: this.page[type],
          size: pageSize,
          statuses: type === ORDERS_TYPE.ACTIVE ? activeStatuses : [],
        });

        if (ordersPage.length) {
          this.page[type]++;
          this.orders[type].push(...ordersPage);
          $state.loaded();
        } else {
          $state.complete();
        }
      };
    },
  },
};
</script>
<style lang="stylus">
.cabinet-order-page
  margin-top 32px
  .ui-tabs .tab-titles .ui-tab-title
    +Media(Tablet, Laptop, Desktop, DesktopMax)
      Text(BodyL Semibold)
      width min-content
      white-space nowrap
      padding 0 16px
  .orders-list
    margin-top 45px
    .ds-order-card
      padding-bottom 24px
      &:not(:first-child)
        padding-top 42px
        border-top 1px solid Divider()
      +Media(Mobile, Tablet)
        padding-bottom 16px
        &:not(:first-child)
          padding-top 26px
    .infinite-loading-container
      position relative
      top -20px
</style>
