<template lang="pug">
.ds-dropdown-field
  dropdown-ds(
    ref="dropdown"
    :list="rawData"
    value-attribute="id"
    label-attribute="name"
    :value="{ name: value }"
    @select="value => onSelect(value ? value.name : '')"
    @focus.native.capture="isActive = true"
    @blur.native.capture="isActive = false"
  )
    template(v-slot:option="props")
      div.option(v-html="toBold(value, props.option.name)")
    input-ui(
      ref="input"
      v-bind="$attrs"
      :key="keyInput"
      :value="value"
      @input="onInput"
      @keypress.enter="onEnter"
    )
      icon-ui.clear-btn(
        v-if="!isActive"
        name="operations/chevron-down_small-line"
        slot="after"
        @mousedown.native.prevent.stop="focusInput"
      )
      template(v-else)
        icon-ui.clear-btn(
          v-if="value"
          name="operations/close_line"
          :size="20"
          slot="after"
          @mousedown.native.prevent.stop="clear"
        )
        icon-ui.clear-btn(
          v-else
          name="operations/chevron-up_small-line"
          slot="after"
          @mousedown.native.prevent.stop="blurDropdown"
        )
</template>
<script>
import { toBold } from '~/utils/intersections';
import uniqBy from 'lodash.uniqby';

import DropdownDs from '~/components/dropdown';
import IconUi from '~/components/common/icon';
import InputUi from '~/components/common/input';
import LabeledInput from '~/components/labeled-input';

export default {
  components: {
    LabeledInput,
    InputUi,
    DropdownDs,
    IconUi,
  },

  props: {
    source: {
      type: Function,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    rawData: [],
    isActive: false,
    keyInput: 0,
  }),

  watch: {
    value: {
      handler() {
        if (this.value) {
          this.source(this.value)
            .then((data) => {
              this.rawData = uniqBy(data, 'name');
            })
            .catch(() => {
              this.rawData = [];
            });
        } else {
          this.rawData = [];
        }
      },
      immediate: true,
    },
  },

  methods: {
    toBold,

    clear() {
      this.$refs.dropdown.select(undefined);
      this.$emit('select', '');

      this.focusInput();
    },

    blurDropdown() {
      this.$refs.dropdown.blur();
    },

    focusInput() {
      setTimeout(() => this.$refs.input.focus(), 100);
    },

    onInput(value) {
      this.$emit('select', value);
    },

    onSelect(option) {
      if (option) {
        this.blurDropdown();
      }

      this.$emit('select', option);
      this.$nextTick(() => {
        this.keyInput++;
      }); // force update
    },

    onEnter() {
      if (this.rawData.length) {
        this.$refs.dropdown.select(this.rawData[0]);
      }
    },
  },
};
</script>
<style lang="stylus">
.ds-dropdown-field
  .ui-input
    Input(Medium Outlined)
  .clear-btn
    position relative
    right 24px
    margin-right -24px
    cursor pointer
    &:hover
      path
        fill Gray(D32)
</style>
