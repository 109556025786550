import Vue from 'vue';

import { setCart } from '~/modules/cart/api';
import { PopupsService } from '~/modules/popups/services/popups-service';
import { PopupsContainerModel } from '~/modules/popups/models';
import ShowNotificationEditCart from '~/components/popups/showNotificationEditCart';
import ShowNotificationEditPrice from '~/components/popups/showNotificationEditPrice';

export const CheckProductAmountMixin = Vue.extend({
  methods: {
    async checkAmount(products) {
      let newCart = [];
      let errorAvailable = false;

      try {
        newCart = products
          .filter((product) => product.amount > 0)
          .map((product) => {
            if (product.sku.availableAmount < product.amount) {
              errorAvailable = true;
              return {
                ...product,
                oldAmount: product.amount,
                amount: product.sku.availableAmount,
              };
            }
            return product;
          });
        if (errorAvailable) {
          await setCart(newCart.map((_) => ({ amount: _.amount, skuId: _.sku.id })));
          PopupsService.open({
            type: PopupsContainerModel.ETypeWrapper.BOTTOM_RIGHT,
            component: ShowNotificationEditCart,
          });
        }
      } finally {
        this.$store.dispatch('cart/update', newCart);
      }
    },
    checkPrice(newArr, oldArr) {
      let errorPrice = false;
      newArr.forEach((newProduct) => {
        oldArr.forEach((oldProduct) => {
          if (newProduct.sku.id === oldProduct.sku.id && newProduct.sku.sellPrice !== oldProduct.sku.sellPrice) {
            errorPrice = true;
          }
        });
      });
      if (errorPrice) {
        PopupsService.open({
          type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_STICKY,
          component: ShowNotificationEditPrice,
          propsData: {
            withCloseButton: false,
          },
        });
      }
    },
  },
});
