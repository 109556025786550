const ABBREVIATIONS = ['ке', 'кэ', 'ke', 'кe', 'kэ'];
const STOP_WORDS = [
  'kazanexpress',
  'казаньэкспрес',
  'казаньэкспресc',
  'казанэкспрес',
  'казанекспресc',
  'казаньexpress',
];

export function filterContent(content: string, isHtml = false): string {
  if (isHtml && process.client) {
    const document = new DOMParser().parseFromString(content, 'text/html');
    return Array.from(document.body.children)
      .filter((node) => {
        const words = node.innerHTML.split(/[\s,.!?]+/);

        return (
          !STOP_WORDS.some((word) => node.outerHTML.toLowerCase().includes(word.toLowerCase())) &&
          !ABBREVIATIONS.some((abbs) => words.some((w) => w.toLowerCase() === abbs.toLowerCase()))
        );
      })
      .map((node) => node.outerHTML)
      .join('');
  }

  return content
    .split('.')
    .filter((part) => {
      const words = part.split(/[\s,.!?]+/);

      return (
        !STOP_WORDS.some((word) => part.toLowerCase().includes(word.toLowerCase())) &&
        !ABBREVIATIONS.some((abbs) => words.some((w) => w.toLowerCase() === abbs.toLowerCase()))
      );
    })
    .join('.');
}
