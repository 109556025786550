import { LOUIS_API_HOST, LOUIS_KEY } from '~/shop-config';

const UPLOAD_URL = `${LOUIS_API_HOST}/upload`;

interface IResponse<T> {
  payload: T;
  error: string;
}

export enum EUploadImageTags {
  Feedback = 'feedback',
}

export interface IUploadedImage {
  key: string;
  originalUrl: string;

  [key: string]: unknown;
}

const FETCH_HEADERS = {
  Authorization: LOUIS_KEY,
};

const handleError = (error: IResponse<unknown>['error']) => {
  console.error(error);
};

export const uploadImage = async (file, tags: string[]): Promise<IUploadedImage | undefined> => {
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('tags', tags.join(','));

    const response = await fetch(UPLOAD_URL, {
      method: 'POST',
      headers: FETCH_HEADERS,
      body: formData,
    });
    const data: IResponse<IUploadedImage> = await response.json();

    if (data.error) {
      handleError(data.error);
      return;
    }
    return data.payload;
  } catch (error) {
    if (process.client) {
      console.log(error);
    }
    throw error;
  }
};
