export default (number, textForms /* ['минута', 'минуты', 'минут'] */) => {
  number = Math.abs(number) % 100;
  const lastDigit = number % 10;
  if (number > 10 && number < 20) {
    return textForms[2];
  }
  if (lastDigit > 1 && lastDigit < 5) {
    return textForms[1];
  }
  if (lastDigit === 1) {
    return textForms[0];
  }
  return textForms[2];
};
