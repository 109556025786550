import Vue from 'vue';
import { mapState } from 'vuex';
import { getDadataIpLocate } from '~/api/getDadataIpLocate';

import { StaticDataMixin } from '../staticData';
import { getDefaultCity } from '~/store/checkout';

export const SetCityMixin = Vue.extend({
  mixins: [StaticDataMixin],
  computed: {
    ...mapState('checkout', ['city']),
    ...mapState('deliveryMap', ['fiasCities']),
  },
  methods: {
    async setCity() {
      if ((this.city && (this.city.name || this.city.city)) || process.server) {
        // already selected or in server
        return;
      }

      try {
        const data = await getDadataIpLocate();
        if (data) {
          if (data.data.city) {
            const city = this.fiasCities.find((city) => data.data.city == city.name);
            if (city) {
              this.$store.dispatch('checkout/updateCity', {
                name: data.data.city_with_type,
                ...city,
              });
              this.$store.dispatch('checkout/updateDeliveryType', 'DELIVERY_POINT'); // NO KE
            } else {
              this.$store.dispatch('checkout/updateCity', {
                name: data.data.city_with_type,
                city: data.data.city,
                ke: false,
              });
              this.$store.dispatch('checkout/updateDeliveryType', 'RUSSIAN_POST');
            }
          }
        } else {
          this.$store.dispatch('checkout/updateCity', getDefaultCity());
          this.$store.dispatch('checkout/updateDeliveryType', 'RUSSIAN_POST');
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
