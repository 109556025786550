import { ExpectedDeliveryInput } from '~/apollo-api/types';
import { client, gql } from './index';

export const expectedDelivery = async (deliveryInput: ExpectedDeliveryInput) => {
  const {
    data: { expectedDelivery },
  } = await client.query({
    query: gql`
      query expectedDelivery($deliveryInput: ExpectedDeliveryInput!) {
        expectedDelivery(deliveryInput: $deliveryInput) {
          maxDays
          price
        }
      }
    `,
    variables: {
      deliveryInput,
    },
    errorPolicy: 'none',
  });
  return expectedDelivery;
};
