<template lang="pug">
.select-address
  .input-wrapper
    .header(v-if='!media.isMobileOrTablet && city.name !== partnerCity') Населенный пункт
      .choose-city(@click='changeTab') {{ cityName }}
        icon-ui(name='navigation/correct' :size='20')
    input-ui(
      ref='input'
      :value='filteredText'
      placeholder='Поиск по адресу'
      autocomplete='home city'
      v-bind='$attrs'
      @keyup="onInput"
    )
      icon-ui.clear-btn(v-if='!isActive' name='navigation/search_sharp',fill='#C2C4CC', slot='after')
      template(v-else-if='filteredText.length > 0 ? true : false')
        icon-ui.clear-btn(
          name='operations/close_line'
          :size='20'
          slot='after'
          @mousedown.native.prevent.stop='clear'
        )
  #delivery-points-card-wrapper.card-wrapper
    .address-card(
      v-for='point in addressList'
      :key='point.id'
      @click='selectPoint(point)'
      :id='point.id'
      :class='{ active: currentPoint && currentPoint.id === point.id }'
    )
      .title Пункт выдачи заказов {{ point.isPost ? 'в почтовом отделении' : 'ПСБ маркет' }}
      .info
        icon-ui(name='navigation/city_line' :size='20')
        span {{ point.value }}
      template(v-if='collapseInfo(point)')
        .info(v-if='point.additionalField')
          icon-ui(name='navigation/faq_line' :size='20')
          span.additional
            div(v-for='field in additionalFields(point)' :key='additionalFields + Math.random()') {{ field }}
        .info
          icon-ui(name='navigation/clock_sharp' :size='20')
          span {{ point.workingTime }}
        .info
          icon-ui(name='navigation/payed_info' :size='20')
          span Доставка: {{ point.isPayed ? '200 ₽' : 'бесплатно' }}
</template>

<script>
import IconUi from '~/components/common/icon';
import InputUi from '~/components/common/input';
import { mapState } from 'vuex';
import { StaticDataMixin } from '~/mixins/staticData';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
export default {
  components: {
    InputUi,
    IconUi,
  },
  mixins: [StaticDataMixin],
  inject: ['media'],
  data() {
    return {
      filteredText: '',
      keyInput: 0,
      isActive: false,
      currentPoint: null,
      partnerCity: CITY_WITH_PARTNER_DP,
      // addressList: [],
    };
  },
  computed: {
    ...mapState('deliveryMap', {
      city: (state) => state.deliveryMapCity,
      cities: (state) => state.fiasCities,
    }),
    points() {
      const current = this.cities.find((point) =>
        this.city.name ? this.city.name == point.city : this.city.city == point.city,
      );
      const partner = this.city.city
        ? this.city.city.includes(CITY_WITH_PARTNER_DP)
        : this.city.name.includes(CITY_WITH_PARTNER_DP);
      if (current) {
        return current.points;
      } else if (partner) {
        return this.cities.reduce((acc, obj) => acc.concat(obj.points), []);
      }
      return [];
    },
    addressList() {
      return this.points.filter((address) => {
        return address.value.toLowerCase().includes(this.filteredText.toLowerCase());
      });
    },
    cityName() {
      const cityArr = this.city.name.split(',');
      return cityArr[cityArr.length - 1];
    },
  },
  watch: {
    city() {
      const wrapper = document.getElementById('delivery-points-card-wrapper');
      if (!this.media.isMobileOrTablet && this.city.name !== this.partnerCity) {
        wrapper.style.maxHeight = '415px';
      } else if (!this.media.isMobileOrTablet) {
        wrapper.style.maxHeight = '448px';
      } else if (this.city.name !== this.partnerCity) {
        wrapper.style.maxHeight = 'calc(100vh - 440px)';
      } else {
        wrapper.style.maxHeight = 'calc(100vh - 400px)';
      }
    },
  },
  mounted() {
    this.$eventManager.on('show-point', this.showPoint);
    const wrapper = document.getElementById('delivery-points-card-wrapper');
    if (!this.media.isMobileOrTablet && this.city.name !== this.partnerCity) {
      wrapper.style.maxHeight = '415px';
    } else if (!this.media.isMobileOrTablet) {
      wrapper.style.maxHeight = '448px';
    } else if (this.city.name !== this.partnerCity) {
      wrapper.style.maxHeight = 'calc(100vh - 440px)';
    } else {
      wrapper.style.maxHeight = 'calc(100vh - 400px)';
    }
  },
  beforeDestroy() {
    this.$eventManager.off('show-point', this.showPoint);
  },
  methods: {
    clear() {
      this.focusInput();
      this.filteredText = '';
    },
    onInput(e) {
      this.filteredText = e.target.value;
    },
    additionalFields(point) {
      return point.additionalField ? point.additionalField.split('\n').filter((val) => val.length > 0) : null;
    },
    showPoint(point) {
      const wrapper = document.getElementById('delivery-points-card-wrapper');
      this.currentPoint = document.getElementById(point.id);
      wrapper.scrollTo({
        top: this.currentPoint.offsetTop - this.currentPoint.offsetHeight * 2,
        left: 0,
        behavior: 'smooth',
      });
    },
    collapseInfo(point) {
      if (this.media.isMobileOrTablet) {
        return true;
      }

      return this.currentPoint && this.currentPoint.id === point.id;
    },
    selectPoint(point) {
      this.currentPoint = document.getElementById(point.id);
      this.$eventManager.emit('select-point', point);
    },
    changeTab() {
      this.$emit('change-tab', 1);
    },
  },
};
</script>

<style lang="stylus">
.select-address {
  +Media(Mobile, Tablet) {
    height: 100%;
  }

  .input-wrapper {
    padding: 0px 24px 16px 24px;

    +Media(Mobile, Tablet) {
      padding: 0px 16px 16px 16px;
    }
  }

  .header {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #8B8E99;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 13px;

    .choose-city {
      display: flex;
      align-items: center;
      color: #2C2D84;
      gap: 4px;
      cursor: pointer;
    }
  }

  .label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .ui-input {
    Input(Large Outlined);
    width: 392px;
    display: flex;
    align-items: center;
    height: 48px;

    +Media(Mobile, Tablet) {
      width: 100%;
    }

    .clear-btn {
      position: relative;
      right: 28px;
      margin-right: -24px;
      cursor: pointer;

      &:hover {
        path {
          fill: Gray(D32);

          .label {
            Text(BodyM);
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .card-wrapper {
    overflow: scroll;

    +Media(Mobile, Tablet) {
      max-height: calc(100vh - 360px);
      // height calc(100vh - 330px)
    }

    .address-card {
      box-sizing: border-box;
      padding: 20px 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-bottom: 1px solid #E4E6EB;

      +Media(Mobile, Tablet) {
        padding: 16px;
      }

      &:hover {
        background: #F2F4F7;
        cursor: pointer;

        +Media(Mobile, Tablet) {
          background: white;
        }
      }

      &.active {
        background: #F2F4F7;

        +Media(Mobile, Tablet) {
          background: white;
        }
      }

      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }

      .info {
        display: flex;
        gap: 8px;
        font-size: 14px;
        line-height: 20px;

        .ui-icon {
          min-width: 20px;
        }

        .additional {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }

      .link {
        font-size: 12px;
        line-height: 18px;
        display: none;
        color: #2C2D84;
        align-items: center;

        +Media(Mobile, Tablet) {
          display: flex;
        }
      }
    }
  }
}
</style>
