export const checkError = (error: { [key: string]: string }): boolean => {
  let isError = false;
  for (const key in error) {
    if (error[key]) {
      isError = true;
      break;
    }
  }
  return isError;
};
