import Cookies from 'js-cookie';
import { mapState } from 'vuex';
import Vue from 'vue';

export const AuthenticatedMixin = Vue.extend({
  data() {
    return {
      authenticated: false,
      accessToken: '',
    };
  },

  computed: {
    ...mapState('user', ['isAuth']),
  },

  watch: {
    isAuth() {
      this.checkToken();
    },
  },

  mounted() {
    this.checkToken();
  },

  methods: {
    checkToken() {
      const tokens = Cookies.get('auth');
      if (tokens) {
        this.authenticated = !!JSON.parse(tokens).accessToken;
        this.accessToken = JSON.parse(tokens).accessToken;
      } else {
        this.authenticated = false;
        this.accessToken = '';
      }
    },
  },
});
