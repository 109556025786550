import { client, gql } from '~/apollo-api';
import { Favorite, FavoritesFilter, addFavorite, removeFavorite, SkuGroupCardFragment } from '~/apollo-api/types';
import { skuGroupCardFragment } from '~/apollo-api/fragments/catalog-cards/skuGroupCard';

export const getFavorite = async (filter: FavoritesFilter): Promise<SkuGroupCardFragment[] | undefined> => {
  const { data } = await client.query<Favorite | null>({
    query: gql`
      query GetFavorite($filter: FavoritesFilter!) {
        skuGroupFavorites(filter: $filter) {
          ...SkuGroupCardFragment
        }
      }
      ${skuGroupCardFragment}
    `,
    variables: {
      filter,
    },
  });

  return data?.skuGroupFavorites;
};

export const add = async (id: number[]) => {
  const { data } = await client.mutate<addFavorite>({
    mutation: gql`
      mutation addFavorite($skuGroupIds: [Int!]!) {
        addFavoriteSkuGroups(skuGroupIds: $skuGroupIds)
      }
    `,
    variables: {
      skuGroupIds: id,
    },
  });

  return data?.addFavoriteSkuGroups;
};

export const remove = async (id: number[]) => {
  const { data } = await client.mutate<removeFavorite>({
    mutation: gql`
      mutation removeFavorite($skuGroupIds: [Int!]!) {
        removeFavoriteSkuGroups(skuGroupIds: $skuGroupIds)
      }
    `,
    variables: {
      skuGroupIds: id,
    },
  });

  return data?.removeFavoriteSkuGroups;
};

export const getSkuGroupsByIdProduct = async (id: number) => {
  const {
    data: { product },
  } = await client.query({
    query: gql`
      query ProductFavorite($id: Int!) {
        product(id: $id) {
          skuGroups {
            id
            favorite
            photos {
              key
              link(trans: PRODUCT_240) {
                high
              }
            }
            characteristicValues {
              id
              characteristic {
                id
                title
              }
              title
              value
            }
          }
        }
      }
    `,
    variables: {
      id,
    },
  });

  return product;
};

const mockFilter: FavoritesFilter = {
  page: 0,
  size: 200,
  sortBy: 'PRICE',
  order: 'ASCENDING',
};

export const getSkuIdsForMerge = async (mock = mockFilter) => {
  const { data } = await client.query<Favorite | null>({
    query: gql`
      query GetFavoriteForMerge($mock: FavoritesFilter!) {
        skuGroupFavorites(filter: $mock) {
          id
        }
      }
    `,
    variables: {
      mock,
    },
  });

  return data?.skuGroupFavorites;
};
