import { CreateOrderInput } from '~/apollo-api/types';
import { client, gql } from './index';
import { skuFields } from './fragments/skuFields';

let isFirst = false;

export const createOrder = async (orderInput: CreateOrderInput) => {
  if (isFirst) {
    return null;
  }
  isFirst = true;
  try {
    const { data } = await client.mutate({
      mutation: gql`
        mutation createPsbOrder($orderInput: CreateOrderInput!) {
          createPsbOrder(orderInput: $orderInput) {
            id
            paymentUrl
            issueCode
            price
            status
            orderItems {
              id
              status
              amount
              purchasePrice
              paymentPrice
              returnedAmount
            }
          }
        }
      `,

      variables: {
        orderInput,
      },
    });
    return data?.createPsbOrder;
  } finally {
    isFirst = false;
  }
};

export const product = async (productId: number) => {
  const {
    data: {
      product: { skuList },
    },
  } = await client.query({
    query: gql`
      query getProductSkuList($productId: Int!) {
        product(id: $productId) {
          skuList {
            ...skuFields
            weight
          }
        }
      }
      ${skuFields}
    `,
    variables: {
      productId,
    },
  });

  return skuList;
};

export const cart = async () => {
  const {
    data: { cart },
  } = await client.query({
    query: gql`
      query getAuthCart {
        cart {
          amount
          sku {
            ...skuFields
            weight
          }
        }
      }
      ${skuFields}
    `,
  });

  return cart;
};
