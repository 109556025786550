
import Vue from 'vue';

import LabeledUi from '~/components/labeled.vue';

export default Vue.extend({
  name: 'TextareaUi',
  components: {
    LabeledUi,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    onInput(value: string): void {
      this.$emit('input', value);
    },
  },
});
