
import Vue from 'vue';

import { dateToLocale } from '~/modules/common/date';

export default Vue.extend({
  name: 'FeedbackDate',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formattedDate(): string {
      return dateToLocale(new Date(this.value), { day: 'numeric', month: 'long', year: 'numeric' });
    },
  },
});
