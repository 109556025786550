import { daDataToken } from '~/shop-config';

export const DADATA_HEADERS: RequestInit = {
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${daDataToken}`,
  },
};
