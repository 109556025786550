
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

export default class DesktopBottomRightWrapper extends Vue {
  @Prop({ type: Boolean, default: false }) readonly isClosed!: boolean;

  @Emit('close')
  onClose() {
    return null;
  }
}
