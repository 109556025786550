import mediaSizes from './sizes.json';
import { TMediaSizes } from './layout-rules';
import { MediaHelperMixin, IMediaHelper } from '~/plugins/layoutable/mediaHelper';

const getWidth = (isServer: boolean, isMobileOrTablet): number => {
  let sizes: number[] | null = null;
  if (isServer) {
    if (isMobileOrTablet) {
      sizes = mediaSizes.Mobile;
    } else {
      sizes = mediaSizes.DesktopMax;
    }
  }
  return sizes ? sizes[sizes.length - 1] : window.innerWidth;
};
const getHeight = (isServer: boolean): number => (isServer ? 1080 : window.innerHeight);

interface IMedia {
  size: TMediaSizes | null;
  clientWidth: number;
  clientHeight: number;
}

export type TMedia = IMedia & IMediaHelper;

export const MediaMixin = MediaHelperMixin.extend({
  data(): IMedia {
    return {
      size: null,
      clientWidth: getWidth(this.$isServer, this?.$device?.isMobileOrTablet),
      clientHeight: getHeight(this.$isServer),
    };
  },
  computed: {
    $sizes(): { [key in TMediaSizes]?: (width: number) => boolean } {
      return Object.keys(mediaSizes).reduce((ac, cur) => {
        const curMedia = mediaSizes[cur][0];
        const nextMedia = mediaSizes[cur][1];

        ac[cur] = (current: number) => current >= curMedia && (nextMedia === undefined || current < nextMedia);

        return ac;
      }, {});
    },
  },
  methods: {
    _recalculateMedia() {
      this.clientWidth = getWidth(this.$isServer, this?.$device?.isMobileOrTablet);
      this.clientHeight = getHeight(this.$isServer);

      for (const size in this.$sizes) {
        const result = this.$sizes[size](this.clientWidth);
        this.size = result ? (size as TMediaSizes) : this.size;

        if (result) {
          break;
        }
      }
    },
  },
  watch: {
    size: {
      handler() {
        this.$emit('media-changed');
      },
      immediate: true,
    },
  },
  mounted() {
    this.$emit('media-init');
    this._recalculateMedia();
    window.addEventListener('resize', this._recalculateMedia);
    window.addEventListener('orientationchange', this._recalculateMedia);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this._recalculateMedia);
    window.removeEventListener('orientationchange', this._recalculateMedia);
  },
  provide() {
    return {
      media: this.$data,
    };
  },
});
