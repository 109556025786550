import { gql } from '~/apollo-api';

export const categoryFragment = gql`
  fragment CategoryFragment on Category {
    id
    icon
    parent {
      id
      title
    }
    seo {
      header
      metaTag
    }
    title
    adult
  }
`;

export const categoryShortFragment = gql`
  fragment CategoryShortFragment on Category {
    id
    parent {
      id
      title
    }
    children {
      id
      title
      parent {
        id
        title
      }
    }
    title
  }
`;
