<template lang="pug">
.ds-progress
  .progress-line(:style="style")
    .item(
      v-for="(item,index) in items"
      :key="item"
      :class="{ 'disabled': index > point }"
    )
      .point
      .title {{ item }}
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    point: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    style() {
      return {
        '--percentage-l': `${(this.point / this.items.length) * 100}%`,
        '--percentage-r': `${((this.point + 1) / this.items.length) * 100}%`,
      };
    },
  },
};
</script>
<style lang="stylus">
@keyframes slideProgressWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes slideProgressHeight {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.ds-progress
  .progress-line
    --percentage-l 0
    --percentage-r 0
    --angle 90deg
    --height 4px
    --width 100%
    --border-radius 2px
    position relative
    height var(--height)
    width var(--width)
    display flex
    justify-content space-between
    background Gray(L48)
    border-radius var(--border-radius)

    transition all .3s ease
    &::after
      content ''
      border-radius var(--border-radius)
      width var(--width)
      height var(--height)
      position absolute
      left 0
      top 0
      animation .5s slideProgressWidth ease
      background linear-gradient(var(--angle), Green(L8) 0%, Green(L8) var(--percentage-l), Gray(L48) var(--percentage-r), Gray(L48) 100%)
    .item
      display flex
      width 100%
      position relative
      z-index 1
      .point
        position absolute
        height 8px
        width 8px
        border-radius 4px
        top -2px
        background Green(L8)
      .title
        Text(CaptionM)
        white-space nowrap
        color TextPrimary()
        position relative
        top 8px
      &.disabled
        .point
          display none
        .title
          color TextSecondary()
  +Media(Tablet)
    .progress-line
      --height 2px
      .item
        .point
          height 6px
          width 6px
        .title
          top 10px
  +Media(Mobile)
    .progress-line
      --angle 180deg
      --width 2px
      --height 100%
      flex-direction column
      &::after
        animation .5s slideProgressHeight ease
      .item
        .point
          top 0
          left -2px
          width 6px
          height 6px
        .title
          white-space nowrap
          top 0
          left 18px
          transform translateY(-25%)
</style>
