import { basicToken } from '~/shop-config';
// @todo: redirect to signin: https://app.asana.com/0/0/1200927439306673/f

import { fromPromise } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { refreshToken } from '../refreshToken';
import { refreshToken_refreshToken } from '../types';
import Cookies from 'js-cookie';

// onError link – catch error for refresh tokens
export const errorLink = onError(
  // it won't work as long as we use REST and checkTokens everywhere with throw exceptions!

  ({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.path && err.path.length > 0 && err.path[0] === 'refreshToken') {
          const oldHeaders = operation.getContext().headers;
          window
            ? window.$eventManager.emit('logout')
            : operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Basic ${basicToken}`,
                },
              });
          return undefined;
        }
        if (!err?.extensions?.error) {
          continue;
        }
        switch (err.extensions.error) {
          case 'Unauthorized':
          case 'invalid_token':
            return fromPromise(
              refreshToken(JSON.parse(Cookies.get('auth' as string)).refreshToken as string).catch((error) => {
                const oldHeaders = operation.getContext().headers;
                window
                  ? window.$eventManager.emit('logout')
                  : operation.setContext({
                      headers: {
                        ...oldHeaders,
                        authorization: `Basic ${basicToken}`,
                      },
                    });
                console.error('unsuccessful try to refresh tokens', error);
                return;
              }),
            )
              .filter((value) => {
                return Boolean(value);
              })
              .flatMap((tokens) => {
                tokens = tokens as refreshToken_refreshToken;
                const oldHeaders = operation.getContext().headers;

                // set new tokens to store
                if (process.client) {
                  window.$eventManager.emit('updateTokens', tokens);
                }

                // modify the operation context with a new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${tokens.accessToken}`,
                  },
                });

                // retry the request, returning the new observable
                return forward(operation);
              });
          case 'DOWNSTREAM_SERVICE_ERROR':
            console.error(err.extensions.code, ':', err.message);
            return undefined;
        }
        switch (err.extensions.code) {
          case 'UNAUTHORIZED':
            return fromPromise(
              refreshToken(JSON.parse(Cookies.get('auth' as string)).refreshToken as string).catch((error) => {
                const oldHeaders = operation.getContext().headers;
                window
                  ? window.$eventManager.emit('logout')
                  : operation.setContext({
                      headers: {
                        ...oldHeaders,
                        authorization: `Basic ${basicToken}`,
                      },
                    });
                console.error('unsuccessful try to refresh tokens', error);
                return;
              }),
            )
              .filter((value) => {
                return Boolean(value);
              })
              .flatMap((tokens) => {
                tokens = tokens as refreshToken_refreshToken;
                const oldHeaders = operation.getContext().headers;

                // set new tokens to store
                if (process.client) {
                  window.$eventManager.emit('updateTokens', tokens);
                }

                // modify the operation context with a new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${tokens.accessToken}`,
                  },
                });

                // retry the request, returning the new observable
                return forward(operation);
              });
        }
      }
    }
    return undefined;
  },
);
