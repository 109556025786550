import {
  DshopMain_dshopMain_categories,
  DshopMain_dshopMain_categories_children_children,
  DshopMain_dshopMain_categories_image,
} from '~/apollo-api/types';

interface Category extends Omit<DshopMain_dshopMain_categories, 'image' | 'children'> {
  image?: DshopMain_dshopMain_categories_image | null;
  children?: DshopMain_dshopMain_categories_children_children[];
}

const CATALOG_PATH = '/catalog';

export const buildCategoryUrl = (category: Category) => `${CATALOG_PATH}/${category.id}`;
