import { mapCategoryList } from './event-product';
import { LISTITEMS } from './listitems';

export const listingEvent = function (products, itemListName) {
  const itemName = LISTITEMS[itemListName] ?? itemListName;
  return {
    event: 'view_item_list',
    ecommerce: {
      item_list_name: itemName,
      items: products.map((product, index) => {
        const sku = getSku(product);

        return {
          item_id: product.id + '',
          item_name: product.title,
          discount: (sku ? sku.fullPrice - sku.sellPrice : product.minFullPrice - product.minSellPrice) / 100,
          item_brand: 'Без бренда',
          price: (sku ? sku.fullPrice : product.minFullPrice) / 100,
          index: index,
          ...(product.category ? mapCategoryList(product.category) : {}),
        };
      }),
    },
  };
};

const getSku = function (product) {
  if (product.skuList === undefined) return null;
  return [...product.skuList].sort((a, b) => a.sellPrice - b.sellPrice)[0];
};
