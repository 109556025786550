<template lang="pug">
.checkout-error(v-show="message.length > 0 && showMessage")
  .ui-wrapper
    .error-wrapper
      .checkout-error-info
        .title Что-то пошло не так
        .message {{ message }}
          .message-link(@click="reload") Обновить страницу
      .checkout-error-close(@click="close")
        icon-ui(name="operations/error-close")
</template>
<script>
import IconUi from '~/components/common/icon';

import LinkUi from '~/components/common/link';

export default {
  components: {
    IconUi,
    LinkUi,
  },

  data() {
    return {
      showMessage: true,
      message: '',
    };
  },
  mounted() {
    this.$eventManager.on('change-message', this.change);
  },
  beforeDestroy() {
    this.$eventManager.off('change-message', this.change);
  },
  methods: {
    reload() {
      window.location.reload();
    },
    close() {
      this.showMessage = false;
    },
    change(message) {
      this.message = message;
    },
  },
};
</script>
<style lang="stylus">
.checkout-error
  padding 24px 0px
  background-color #FCE9D9
  margin-top -16px

  +Media(Tablet, Mobile)
    padding: 16px 0px

  .error-wrapper
    display flex
    justify-content space-between

    +Media(Tablet, Mobile)
      flex-wrap nowrap

  .checkout-error-info
    .title
      font-size 22px
      font-weight 500
      line-height 32px
      margin-bottom 14px

      +Media(Tablet, Mobile)
        line-height 28px
        margin-bottom 8px

    .message
      font-size 14px
      line-height 18px
      color #8B8E99
      display flex
      gap 16px

      +Media(Tablet, Mobile)
        flex-direction column

      .message-link
        color #EA5616
        cursor pointer

        &:visited
          color #EA5616

  .checkout-error-close
    cursor pointer
</style>
