<template lang="pug">
.ds-checklist
  .check(v-for="item in validations" :key="item.title" :class="{ 'success' : item.predicate(input) }")
    icon-ui(name="status/check_fill" size="19")
    .check-title {{ item.title }}
</template>

<script>
import IconUi from '~/components/common/icon';

export default {
  components: {
    IconUi,
  },
  props: {
    input: {
      type: String,
      default: '',
    },
    validations: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
.ds-checklist
  .check
    display flex
    align-items center
    align-content center
    margin-top 2px
    svg path
      fill Gray(L16)
      transition .3s fill ease-in-out
    &.success
      svg path
        fill Green(L4)
      .check-title
        color Gray(D32)
    .check-title
      Text(CaptionM)
      color Gray(L8)
      margin-left 5px
      width 100%
</style>
