<template lang="pug">
.delivery-point
  .ui-wrapper
    .content
      .title-menu Пункты выдачи заказов
      switch-all-tabs
      .header(v-if="city.name !== partnerCity") Населенный пункт
        .choose-city(@click="changeTab") {{ cityName }}
          icon-ui(name="navigation/correct" :size="20")
  .popup
    delivery-point-tabs
</template>
<script>
import { mapState } from 'vuex';
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import DeliveryPointTabs from './delivery-points-tab';
import { staticData } from '~/shop-config.js';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
import { CheckoutFieldMixin } from '~/mixins/business/checkout';
import switchAllTabs from './switch-all-tabs.vue';

export default {
  components: {
    DeliveryPointTabs,
    ButtonUi,
    IconUi,
    switchAllTabs,
  },
  mixins: [CheckoutFieldMixin],
  data: () => ({
    isShow: false,
    static: staticData,
    partnerCity: CITY_WITH_PARTNER_DP,
  }),
  computed: {
    ...mapState('deliveryMap', {
      city: (state) => state.deliveryMapCity,
      cities: (state) => state.fiasCities,
    }),
    points() {
      const current = this.cities.find((point) =>
        this.city.name ? this.city.name == point.city : this.city.city == point.city,
      );
      const partner = this.city.city
        ? this.city.city.includes(CITY_WITH_PARTNER_DP)
        : this.city.name.includes(CITY_WITH_PARTNER_DP);
      const allLocal = this.cities.reduce((acc, obj) => acc.concat(obj.points), []);

      const count = current ? current.points?.length : partner ? allLocal?.length : 0;
      return count;
    },
    cityName() {
      const cityArr = this.city.name.split(',');
      return cityArr[cityArr.length - 1];
    },
  },
  mounted() {
    this.$eventManager.on('update-value', this.close);
  },
  beforeDestroy() {
    this.$eventManager.off('update-value', this.close);
    this.close();
  },
  methods: {
    open() {
      this.isShow = true;
      document.body.classList.add('overflow');
    },
    close() {
      this.isShow = false;
      document.body.classList.remove('overflow');
    },
    chooseAnother() {
      this.$store.dispatch('checkout/updateDeliveryPoint', undefined);
      this.$store.dispatch('checkout/updateHiddenPoint', undefined);
      this.$store.dispatch('checkout/updateComment', '');
      this.open();
    },
    changeTab() {
      this.$emit('change-tab', 1);
    },
  },
};
</script>
<style lang="stylus">
.overflow
  +Media(Mobile,Tablet)
    overflow hidden
    flex-direction column

.delivery-point
  .header
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color #8B8E99
      display flex
      align-items: center
      gap 8px
      margin-bottom 16px
      .choose-city
        display flex
        align-items: center
        color #2C2D84
        gap 4px
        cursor pointer
  .popup
    Text(BodyM)
    z-index 1000
    left 0
    right 0
    bottom 0
    background #fff
    flex-grow 1
    overflow scroll
    .header
      position fixed
      z-index 100
      top 0
      background #fff
      width 100%
      display flex
      align-items center
      .ui-icon
        cursor pointer
        margin  12px
      .title
        Text(TitleS)
        width 100%
        margin-left 10px

    .ds-checkout-delivery-point-tabs
      position relative
      height min-content
      .ui-tabs
        .ui-tab-content
          margin-top 90px
        .tab-titles
          position fixed
          z-index 100
          top 40px
          left 0
          right 0
          background #fff
          padding  0 10px
          border-bottom none
          .ui-tab-title
            Text(BodyL Semibold)
            margin 0
            width 100%

  .non-selected
    .ui-button
      Button(Medium Primary)
      Text(BodyM Semibold)
      width 100%
      color #fff
</style>
