import { mapCategoryList } from './event-product';
import { LISTITEMS } from './listitems';

var item_index = null;
var item_list_name = null;

export const addToCartEvent = function (product, sku, amount) {
  let item = mapProductCartItem(product, sku, amount);
  if (item_list_name) {
    item.item_list_name = LISTITEMS[item_list_name] ?? item_list_name;
    item.item_index = item_index;
    clear();
  }

  return {
    event: 'add_to_cart',
    ecommerce: {
      value: (sku.sellPrice * amount) / 100,
      currency: 'RUB',
      items: [item],
    },
  };
};

export const mapProductCartItem = function (product, sku, amount) {
  let data = {
    item_id: product.id + '',
    item_name: product.title,
    discount: (sku.fullPrice - sku.sellPrice) / 100,
    item_brand: 'Без бренда',
    price: sku.fullPrice / 100,
    quantity: amount,
    item_variant:
      sku.characteristicValues.length > 1
        ? sku.characteristicValues[1]?.title
        : sku.characteristicValues[0]?.title || '',
  };
  if (sku.characteristicValues.length > 1) {
    data.item_size = sku.characteristicValues[0]?.title;
  }

  if (product.category) {
    data = Object.assign(data, mapCategoryList(product.category));
  }

  return data;
};

export const setCatalogItemIndex = function (value) {
  item_index = value;
};

export const setCatalogItemListName = function (value) {
  item_list_name = value;
};

export const getCatalogItemIndex = function (value) {
  item_index = value;
};

export const getCatalogItemListName = function (value) {
  item_list_name = value;
};

export const clear = function () {
  item_list_name = null;
  item_index = null;
};
