<template lang="pug">
nuxt-img.smooth(
  v-bind="$attrs"
  v-on="$listeners"
  loading="lazy"
  class="loaded"
)
</template>
<script>
export default {
  data: () => ({
    isLoad: false,
  }),
  methods: {
    onLoad() {
      this.isLoad = true;
    },
  },
};
</script>
<style lang="stylus">
img.smooth
  transition opacity .1s ease-out
  opacity 1
  width 100%
</style>
