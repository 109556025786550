<template lang="pug">
.ui-tab-title(@click="rootTabs.select(name)", :class="{ selected: rootTabs.selected === name }")
  slot
</template>

<script>
export default {
  inject: ['rootTabs'],
  props: {
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus">
.ui-tab-title
  Text(BodyL Regular)
  display flex
  align-items center
  justify-content center
  height 48px
  position relative
  cursor pointer
  color TextTertiary()
  width 100%
  &::after
    content ""
    opacity 1
    position absolute
    left 0
    right 0
    bottom 0
    height 1px
    background TextPrimary()
    will-change transform
    transform scaleY(0)
    transition transform .3s ease
  &.selected
    color TextPrimary()
    &::after
      transform scaleY(1)
</style>
