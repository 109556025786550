import { gql } from '~/apollo-api';

export const productCharacteristicsFragment = gql`
  fragment ProductCharacteristicsFragment on ProductCharacteristic {
    id
    title
    values {
      id
      title
      value
      photo {
        key
        link(trans: PRODUCT_240) {
          high
        }
        original {
          high
        }
      }
    }
  }
`;
