<template lang="pug">
.order-success
  .order-success-header
    .order-success-icon-wrapper
      icon-ui.order-success-icon(name="status/check_line")
    h1.order-success-title Спасибо! Заказ №{{ order.id }} оформлен
  .order-success-content(v-if="isDpDelivery")
    p Ваш заказ успешно оплачен. Вскоре мы&nbsp;начнём его собирать, после чего доставим в пункт выдачи заказов
    p <b>В&nbsp;ходе&nbsp;оформления в личном кабинете отобразится код. Назовите&nbsp;его Администратору ПВЗ при получении заказа.</b><br/>
    p <b>Подписывайтесь на &nbsp;<a href="https://t.me/psbmarket_official_bot?start=restart-ynsi0J8g_psbmarket_checkout">чат-бот ПСБ Маркет</a>&nbsp;в Telegram. Там мы размещаем информацию об акциях и скидках, а так же дарим подарки.</b>
  .order-success-content(v-if="isCourierDelivery")
    p Ваш заказ успешно оплачен. Вскоре мы&nbsp;начнём его собирать, после чего доставим курьером по&nbsp;указанному вами адресу.
    p В&nbsp;день доставки вам позвонит курьер, чтобы уточнить удобное для вас время доставки.
    p <b>Подписывайтесь на &nbsp;<a href="https://t.me/psbmarket_official_bot?start=restart-ynsi0J8g_psbmarket_checkout">чат-бот ПСБ Маркет</a>&nbsp;в Telegram. Там мы размещаем информацию об акциях и скидках, а так же дарим подарки.</b>
  .order-success-content(v-if="isRuPostDelivery")
    p Ваш заказ успешно оплачен. Вскоре мы&nbsp;начнём его собирать, после чего доставим в&nbsp;ближайшее для вас отделение Почты России.
    p <b>Вы&nbsp;получите SMS или push-уведомление, когда ваш заказ прибудет на&nbsp;место.</b>
    p <b>Подписывайтесь на &nbsp;<a href="https://t.me/psbmarket_official_bot?start=restart-ynsi0J8g_psbmarket_checkout">чат-бот ПСБ Маркет</a>&nbsp;в Telegram. Там мы размещаем информацию об акциях и скидках, а так же дарим подарки.</b>
  place-warning(v-if="isRuPostDelivery")
    icon-ui(name="navigation/info_line" size="30" slot="icon" fill="#999BA6")
    span(slot="title" class="order-warning__title") Обязательно проверяйте заказ при получении!
    template(slot="description")
      span(class="order-warning__description")  Если у вас есть претензии к товарам, то сообщите об этом сотруднику Почты.<br/>
      span(class="order-warning__description") Как получить товар на Почте, читайте
      link-ui.order-warning__link(to="https://psb_market1.usedocs.com/article/65991" target="__blank") &nbsp;здесь
  place-warning(v-if="isDpDelivery")
    icon-ui(name="navigation/info_line" size="30" slot="icon" fill="#999BA6")
    span(slot="title" class="order-warning__title") Проверьте посылку перед получением
    template(slot="description")
      link-ui.order-warning__link(to="/faq#get-method") Подробнее
      span(class="order-warning__description")  про получение и условия возврата заказа
  .order-success-button-group
    link-ui.order-success-button.primary(to="/") Продолжить покупки
    link-ui.order-success-button.outline(to="/cabinet/orders") К&nbsp;списку заказов
</template>

<script>
import PlaceWarning from '~/components/place-warning';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link.vue';

export default {
  components: {
    IconUi,
    LinkUi,
    PlaceWarning,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCourierDelivery() {
      return Boolean(this.order.delivery.address && this.order.delivery.city);
    },
    isDpDelivery() {
      return Boolean(this.order.delivery.deliveryPoint && this.order.delivery.deliveryPoint.address);
    },
    isRuPostDelivery() {
      return Boolean(this.order.delivery.address && this.order.delivery.ruPostId);
    },
  },
  created() {
    if (process.client) {
      this.$gtm.push({ ecommerce: null });
    }
  },
};
</script>

<style lang="stylus" scoped>
.order-success
  margin-bottom 56px
  max-width 810px
  +Media(Laptop)
    max-width 588px

  .order-success-header
    margin-bottom 24px
    display flex
    flex-direction column
    +Media(DesktopMax, Desktop, Laptop)
      margin-bottom 32px
      flex-direction row
      align-items center

  .order-success-icon-wrapper
    flex-shrink 0
    margin-bottom 16px
    width 48px
    height 48px
    display flex
    justify-content center
    align-items center
    border 2px solid Gray(D32)
    border-radius 50%
    +Media(DesktopMax, Desktop, Laptop)
      margin-right 24px
      margin-bottom 0
      width 72px
      height 72px

  .order-success-icon
    +Media(DesktopMax, Desktop, Laptop)
      width 40px
      height 40px

  .order-success-title
    Text(TitleS Long)
    +Media(DesktopMax, Desktop, Laptop)
      Text(HeadlineM Long)

  .order-success-content
    p
      Text(BodyM Long)
      margin-bottom 24px
      +Media(DesktopMax, Desktop, Laptop)
        Text(BodyL Long)
        margin-bottom 32px

      b
        font-weight bold

  .order-success-button-group
    display flex
    flex-direction column
    +Media(DesktopMax, Desktop, Laptop, Tablet)
      flex-direction row

  .order-success-button-group > .order-success-button:not(:last-child)
    margin-bottom 16px
    +Media(DesktopMax, Desktop, Laptop, Tablet)
      margin-right 16px
      margin-bottom 0

  .order-success-button
    width 100%
    +Media(DesktopMax, Desktop, Laptop, Tablet)
      width auto

    &.primary
      Button(Primary Large)

    &.outline
      Button(Outlined Large)
.order-warning__title
  font-family Inter
  font-size 14px
  font-weight 500
.order-warning__description
  font-family Inter
  font-size 14px
  font-weight 400;
.order-warning__link
  color #0071E3
  font-family Inter
  font-size 14px
  font-weight 400;
</style>
