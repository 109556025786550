<template lang="pug">
  .ds-labeled-icon.ds-component
    icon-ui.icon(v-bind="$attrs" :size="size" :name="name")
    .label(v-if="$slots.default")
      slot
    .icon-badge(v-if="badge") {{ badge }}
</template>

<script>
import IconUi from '~/components/common/icon';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    IconUi,
  },
  props: {
    badge: {
      type: [Number, String],
      default: undefined,
    },
    size: {
      type: [Number, String],
    },
    name: {
      type: String,
      required: true,
    },
  },
})
export default class LabeledIconComponent extends Vue {}
</script>

<style lang="stylus" scoped>
.ds-labeled-icon
  display inline-flex
  align-items center
  justify-content center
  position relative
  .icon
    +Media(Desktop, DesktopMax)
      margin-right 8px
  .icon-badge
    Text(BodyS Short)
    position absolute
    min-width 16px
    min-height 16px
    padding 0 1px
    display inline-flex
    align-items center
    justify-content center
    text-align center
    background-color OrangeBrand()
    border-radius .8em
    color #fff
    top -10px
    right -10px
    +Media(Laptop, Tablet)
      top -6px
      right -6px
    +Media(Mobile)
      top -3px
      right -4px
  .label
    Text(BodyM Short)
    +Media(Mobile, Tablet, Laptop)
      display none
    +Media(Desktop, DesktopMax)
      display block
</style>
