import { client } from '~/apollo-api/client';
import { CreateOrderInput } from '~/apollo-api/types';
import { gql } from './index';

export const getDeliveryOptions = async (orderInput: CreateOrderInput) => {
  const {
    data: { calcCourierDeliveryOffers },
  } = await client.query({
    query: gql`
      query Options($orderInput: CreateOrderInput!) {
        calcCourierDeliveryOffers(orderInput: $orderInput) {
          options {
            from
            id
            price
            to
          }
          date
        }
      }
    `,
    variables: {
      orderInput,
    },
  });

  return calcCourierDeliveryOffers;
};
