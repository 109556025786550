<template lang="pug">
.ds-order-properties
  header.header
    .title Заказ №{{ order.id }}
    .status(
      :class="order.status | styleFromStatus"
    ) {{ order.status | formatStatus }}

  .properties
    //- Order in proccess
    template(v-if="!finishStatuses.includes(order.status)")
      progress-ds(:items="progressItems" :point="progressPoint")
    //- Delivery Point
    template(v-if="order.issueCode && isDeliveryPoint && deliveryStatuses.includes(order.status)")
      button-ui.issue-code(v-if="media.isMobileOrTablet" @click.native.stop="showBarcode") Код получения заказа
      .line(v-else-if="deliveryStatuses.includes(order.status)")
        .name.code Код получения заказа
        .val.code {{ order.issueCode }}

    template(
      v-if="order.delivery && order.delivery.deliveryPoint && order.delivery.deliveryPoint.address"
    )
      .line
        .name Пункт выдачи заказов
        .val
          div {{ order.comment ? getFiasName : order.delivery.deliveryPoint.address }}
          ul(class="additional-list")
            li(v-if="additionalFields" v-for="item in additionalFields" :key="additionalFields + Math.random()") {{ item }}

        //- .val
        //-   .prop-title(v-html="dpAddress")
        //- button-ui.map-toggle(@click="toggleMap") {{ isShowMap ? 'Скрыть карту' : 'Показать на карте' }}
        //-   icon-ui(:name="isShowMap ? 'navigation/chevron-up_line' : 'navigation/chevron-down_line'" size="18" slot="after")
        //- client-only(v-if="isShowMap")
        //-   yandex-map.map(
        //-     :settings="settings"
        //-     :coords="coords(order.delivery.deliveryPoint)"
        //-     :controls="[]"
        //-   )
        //-     ymap-marker(
        //-       :markerId="order.delivery.deliveryPoint.id"
        //-       :coords="coords(order.delivery.deliveryPoint)"
        //-       :icon="marker"
        //-     )
      .line
        .name Режим работы
        .val {{ order.comment ? getFiasWorkTime : 'Уточните у технической поддержки' }}

      .line(v-if="order.dateCreated")
        .name Заказ хранится
        .val Заказ будет храниться в течение 5 дней после доставки в ПВЗ

    .line.ru-post-barcode(v-if="order.delivery && order.delivery.ruPostBarcode")
      .name.tooltip-card-title
        div(@click="switchTooltip" class="tooltip-trigger-icon")
          icon-ui(name="navigation/faq_line" fill="#C2C4CC" size="24")
        span Трек-номер
      .val.tooltip-card
        a(:href="`https://www.pochta.ru/tracking#${order.delivery.ruPostBarcode}`" target="_blank")
          button-ui {{ order.delivery.ruPostBarcode }}
            icon-ui(name="status/external_line" slot="after")
        transition(name="tooltip")
          div.tooltip-card-text(v-show="showTooltip")
            span Отслеживать заказ проще в приложении
            a.tooltip-card-link(:href="linkApplication(platform)" target="_blank" style="color:#0071E3") Почты России
            div(@click="switchTooltip" style="display: flex; cursor: pointer")
              icon-ui(name="navigation/close_line" fill="white")

    //- RuPost Delivery
    .line(v-if="order.delivery.__typename == DeliveryTypeForCard.RUPOST")
      .name Доставка почтой РФ
      .val {{ order.delivery.address }}
    //- Courier Delivery
    .line(v-if="order.delivery.__typename == DeliveryTypeForCard.COURIER")
      .name Доставка курьером
      .val {{ order.delivery.address }}

    .line(v-if="order.dateCreated")
      .name Заказ сделан
      .val.regular {{ dateOrder }}

    //- common
    .line
      //- @TODO plural, weight
      .name {{ order.orderItems.length }} {{ ending(order.orderItems.length, ['товар', 'товара', 'товаров']) }}
      .val {{ order.price | formatPrice }}
</template>

<script>
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import { currencyFormatMixin } from '~/mixins';
import ending from '~/modules/common/ending';
import { PlatformMixin } from '~/mixins/howPlatform';
import ProgressDs from '~/components/progress';
import { dateToLocale } from '~/modules/common/date';
import { progressMap, finishStatuses, orderStatuses, styleStatusesMap, deliveryStatuses } from '~/modules/orders/data';
import { DeliveryType, DeliveryTypeForCard } from '~/modules/checkout/types';
import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService } from '~/modules/popups/services/popups-service';
import BarcodePopup from '~/components/popups/barcode-popup';
import { StaticDataMixin } from '~/mixins/staticData';
import { GetFiasMixin } from '~/mixins/helpers/getFiasAddress';
// import { getDadataAddressName } from '~/api/getAddressNameWithFias';

export default {
  components: {
    ProgressDs,
    ButtonUi,
    IconUi,
  },
  filters: {
    formatStatus(val) {
      if (!val) {
        return '';
      }
      return orderStatuses[val];
    },
    sumDate(date, days) {
      if (!date) {
        return '';
      }
      const dateInMs = new Date(date).getTime();
      const daysInMs = 1000 * 60 * 60 * 24 * days;
      const totalDate = new Date(dateInMs + daysInMs);
      return `${dateToLocale(totalDate, {
        day: 'numeric',
        month: 'long',
      })} ${totalDate.getFullYear()}`;
    },
    styleFromStatus(val) {
      return styleStatusesMap[val] || 'outlined';
    },
  },
  mixins: [currencyFormatMixin, PlatformMixin, StaticDataMixin, GetFiasMixin],
  inject: ['media', 'isShowMapInit'],
  props: {
    order: {
      type: Object,
      default: () => undefined,
    },
  },
  data: () => ({
    ending,
    progressMap,
    finishStatuses,
    deliveryStatuses,
    isShowMap: false,
    DeliveryTypeForCard,
    showTooltip: false,
  }),
  computed: {
    deliveryType() {
      if (this.order.delivery.deliveryPoint) {
        return DeliveryType.DELIVERY_POINT;
      }
      if (this.order.delivery.todayDelivery !== undefined) {
        return DeliveryType.COURIER;
      }
      return DeliveryType.RUSSIAN_POST;
    },
    isDeliveryPoint() {
      return this.deliveryType === DeliveryType.DELIVERY_POINT;
    },
    progressItems() {
      return this.progressMap[this.deliveryType].items;
    },
    progressPoint() {
      return this.progressMap[this.deliveryType].toPoint.findIndex((_) => _.includes(this.order.status)) || 0;
    },
    dateOrder() {
      const date = new Date(this.order.dateCreated);
      return `
      ${dateToLocale(date, { month: 'long', day: 'numeric' })}
      ${date.getFullYear()},
      ${dateToLocale(date, { hour: '2-digit', minute: '2-digit' })}`; // without "г." in year
    },
    dpAddress() {
      const dp = this.order.delivery.deliveryPoint;
      if (!dp) {
        return '';
      }
      return `<span>${dp.address}</span><br><span class="regular">${dp.timeFrom} - ${dp.timeTo}, без выходных</span>`;
    },
    deliveryTypeCode(str) {
      return this.getDeliveryType(str);
    },
  },
  async mounted() {
    this.isShowMap = this.isShowMapInit;
    this.showTooltip = this.isSuccessful;
  },
  methods: {
    showBarcode() {
      if (!this.order.issueCode) {
        return;
      }
      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.CENTER,
        component: BarcodePopup,
        propsData: {
          id: this.order.id,
          code: this.order.issueCode,
        },
      });
    },
    toggleMap() {
      this.isShowMap = !this.isShowMap;
    },
    switchTooltip() {
      this.showTooltip = !this.showTooltip;
    },
  },
};
</script>

<style lang="stylus">
.ds-order-properties
  .header
    display flex
    align-items center
    margin 16px 0 24px
    +Media(Mobile)
      justify-content space-between
  .title
    Text(TitleM)
    color Gray(D32)
    white-space nowrap
    margin-right 40px
    +Media(Mobile, Tablet)
      Text(TitleS)
  .status
    white-space nowrap
    Text(BodyM Semibold)
    color Green(L8)
    outline 1px solid Green(L8)
    &.success
      outline none
      color white
      background-color Green(LT8)
    &.secondary
      outline none
      color Gray(D32)
      background-color Gray(LT48)
    &.warning
      outline none
      color White()
      background-color Orange(LT2)
    padding 10px 8px
    min-height 32px
  .properties
    margin-bottom 24px
    width 100%
    color Gray(D32)
    .issue-code
      Button(Medium Outlined)
      width 100%
      margin-bottom 16px
    .line
      Text(BodyM Long)
      display flex
      margin-bottom 16px
      &.ru-post-barcode
        padding 10px 0
        .val  a
          color Gray(D32)
          .ui-button
            Button()
            Text(BodyM Long Semibold)
            padding 0
      &:last-child
        margin-bottom 0
      .name
        min-width 232px
        width 232px
        margin-right 40px
        &::after
          content ':'
      .code
        font-size 18px
        font-weight: 600
      .val
        Text(Semibold)
        &.regular
          font-weight 400
        .regular
          display block
          font-weight 400
          margin-top 2px
        .map-toggle
          Button()
          Text(CaptionM Semibold)
          padding 8px 0
          color Gray(D32)
          .slot.after
            padding-left 0
            padding-top 2px
            svg path
              fill Gray(D32)
      .code
        font-size 18px
        font-weight: 600
      +Media(Mobile, Tablet)
        flex-wrap wrap
        justify-content space-between
        .name
          margin-right 0
          margin-bottom 8px
          width auto
          min-width auto
    .map
      height 335px
      margin-bottom 24px
    .ds-progress
      +Media(Laptop, Desktop, DesktopMax)
        width 532px
        margin 44px 0 48px
      +Media(Tablet)
        width 100%
        margin 24px 0 48px
      +Media(Mobile)
        height 104px
        margin 24px 0
.additional-list
  list-style disc inside
  font-weight normal
  margin-top 16px
</style>
