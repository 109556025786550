import { formatNumber } from '~/utils/index';
const gramsInKilo = 1000;

const gramsLabel = 'гр';
const kilosLabel = 'кг';

export const weightFormat = (weightInGrams: number) => {
  if (weightInGrams < 1000) {
    return `${formatNumber(weightInGrams.toFixed(0))} ${gramsLabel}`;
  } else {
    return `${formatNumber((weightInGrams / gramsInKilo).toFixed(1))} ${kilosLabel}`;
  }
};
