<template lang="pug">
.ds-main-blocks-profit
  .ui-wrapper(v-if="static.isShowProfits")
    .blocks
      block-profit(
        v-for="block in static.profits"
        :key="block.subtitle"
        :icon="block.icon"
        :title="block.title"
        :subtitle="block.subtitle"
      )
</template>

<script>
import BlockProfit from './block-profit';
import { StaticDataMixin } from '~/mixins/staticData';

export default {
  components: {
    BlockProfit,
  },
  mixins: [StaticDataMixin],
};
</script>

<style lang="stylus">
.ds-main-blocks-profit
  //- TODO: theme
  background Gray(LT50)
  .blocks
    display flex
    flex-wrap nowrap
    align-content space-between
    width 100%
    column-count 2
    padding 53px 0
    +Media(Tablet,Laptop)
      flex-wrap wrap
    +Media(Mobile)
      flex-direction column
</style>
