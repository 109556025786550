import { uri } from '~/apollo-api/client/http-link';

export const getDefaultCityMap = () => ({
  ke: true,
  id: !uri.includes('dev') ? 320 : 184,
  cityId: !uri.includes('dev') ? 320 : 184,
  name: 'Каменск-Шахтинский',
  minFreeDeliveryPriceCourier: 1000,
  minFreeDeliveryPriceDp: 10000,
  deliveryPoints: [
    {
      id: !uri.includes('dev') ? 63486 : 63073,
      address: 'г. Каменск-Шахтинский, ул. Ворошилова, д. 4',
      deliveryDate: 1692910800000,
      hasDressingRoom: true,
      latitude: 48.331363,
      longitude: 40.267472,
      timeFrom: '09:00',
      timeTo: '20:00',
      __typename: 'DeliveryPoint',
    },
  ],
  __typename: 'City',
  city: 'Каменск-Шахтинский',
});

export const state = () => ({
  deliveryMapCity: getDefaultCityMap(),
  storedCity: null,
  fiasCities: [],
});

export const mutations = {
  updateDeliveryMapCity(state, payload) {
    state.deliveryMapCity = payload;
  },
  updateStoredCity(state, payload) {
    state.storedCity = payload;
  },
  updateFiasCities(state, payload) {
    state.fiasCities = payload;
  },
};
export const actions = {
  updateDeliveryMapCity({ commit }, payload) {
    commit('updateDeliveryMapCity', payload);
  },
  updateStoredCity({ commit }, payload) {
    commit('updateStoredCity', payload);
  },
  updateFiasCities({ commit }, payload) {
    commit('updateFiasCities', payload);
  },
};
