
import Vue, { PropType } from 'vue';

import { FavoriteQueryInput } from '~/apollo-api/types';

import SearchSortDs from '~/modules/favorite/components/filters/search-sort.vue';

export default Vue.extend({
  components: {
    SearchSortDs,
  },
  props: {
    queryInput: {
      type: Object as PropType<FavoriteQueryInput>,
      required: true,
    },
  },
  mounted() {
    this.observeOptions();
  },
  methods: {
    observeOptions() {
      const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('sticky', e.intersectionRatio < 1), {
        threshold: [1],
        rootMargin: '0px 100px 0px 100px',
      });
      observer.observe(this.$el as HTMLElement);
    },
  },
});
