import { ApolloClient, ApolloLink, NormalizedCacheObject, DefaultOptions } from '@apollo/client/core';

import { authLink } from './auth-link';
import { httpLink } from './http-link';
import { errorLink } from './error-link';

import { cache } from './cache';
import { dshopName } from '~/shop-config';
import { version } from '~/package.json';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]), // chain with links
  cache,
  name: dshopName,
  defaultOptions,
  version,
});
