<template lang="pug">
  .photo-uploader-container
    img-smooth.photo-uploader-file(v-for="photo in photos" :key="photo" :src="photo")
    label.photo-uploader(:class="{ disabled: disabled }")
      input.photo-uploader-input(type="file" :accept="accept" @change="onChange($event)")
      icon-ui(name="operations/attach-line")
</template>

<script>
import IconUi from '~/components/common/icon';

const DEFAULT_ACCEPT = ['image/jpg', 'image/jpeg', 'image/png'];
const DEFAULT_MAX_SIZE = 5 * 1024 ** 2; // 5 МБ

export default {
  name: 'PhotoUploader',
  components: { IconUi },
  props: {
    acceptedTypes: {
      type: Array,
      default: () => DEFAULT_ACCEPT,
    },
    maxSize: {
      type: Number,
      default: DEFAULT_MAX_SIZE,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    photos: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    accept() {
      return this.acceptedTypes.join(',');
    },
  },
  methods: {
    onChange(event) {
      const files = Array.from(event.currentTarget.files).filter((file) => DEFAULT_ACCEPT.includes(file.type));
      this.$emit('change', files[0]);
    },
  },
};
</script>

<style lang="stylus" scoped>
.photo-uploader-container
  display flex
  flex-wrap wrap
  gap 4px

.photo-uploader
  position relative
  height 72px
  width 72px
  display flex
  align-items center
  justify-content center
  border 1px solid Divider()
  cursor pointer
  +Media(Tablet, Mobile)
    height 64px
    width 64px

  &.disabled
    pointer-events none

.photo-uploader-input
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  opacity 0

.photo-uploader-files
  display flex

.photo-uploader-file
  height 72px
  width 72px
  object-fit cover
  object-position center
  border 1px solid Divider()
  +Media(Tablet, Mobile)
    height 64px
    width 64px
</style>
