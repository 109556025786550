<template lang="pug">
.ds-checkout-delivery-info(v-if="city")
  //- checkout-fragment(label="Способ получения" v-if="isDp")
  //-   checkout-delivery-switch(@select="onSelectType")

  template(v-if="selectedDeliveryType")
    template(v-if="selectedDeliveryType.id === DeliveryType.DELIVERY_POINT")
      checkout-fragment(:label="labelDp")
        checkout-delivery-point

    template(v-if="selectedDeliveryType.id === DeliveryType.COURIER")
      checkout-fragment(label="Адрес получателя")
        checkout-address
      checkout-fragment(label="Дата и время доставки")
        checkout-date-time
    template(v-if="selectedDeliveryType.id === DeliveryType.RUSSIAN_POST")
      checkout-fragment(label="Доставка Почтой России (в отделение)")
        checkout-address
        //-checkout-postcode
</template>

<script>
import CheckoutFragment from './checkout-fragment';
import CheckoutDeliverySwitch from './checkout-delivery-switch';
import CheckoutDeliveryPoint from './checkout-delivery-point';
import CheckoutAddress from './checkout-address';
import CheckoutDateTime from './checkout-date-time';
import CheckoutPostcode from './checkout-postcode';
import ending from '~/modules/common/ending';
import { DeliveryType } from '~/modules/checkout/types';
import { cityIn } from 'lvovich';

import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
import { StaticDataMixin } from '~/mixins/staticData';
import { mapState } from 'vuex';

export default {
  components: {
    CheckoutFragment,
    CheckoutDeliverySwitch,
    CheckoutDeliveryPoint,
    CheckoutAddress,
    CheckoutDateTime,
    CheckoutPostcode,
  },
  mixins: [StaticDataMixin],
  data: () => ({
    DeliveryType,
    selectedDeliveryType: {
      id: DeliveryType.RUSSIAN_POST, // NO KE
    },
    CITY_WITH_PARTNER_DP,
  }),
  computed: {
    ...mapState('checkout', ['city']),
    labelDp() {
      if (!this.city) {
        return '';
      }
      const count = this.city && this.city.points ? this.city?.points.length : 0;
      const title = this.city ? (this.city.city ? this.city.city : this.city.name) : '';
      const parts = title.split(' ');
      return `В ${cityIn(parts[parts.length - 1])} ${count} ${ending(count, ['пункт', 'пункта', 'пунктов'])} выдачи`;
    },
    isDp() {
      if (!this.city) {
        return false;
      }
      return this.city.points && this.city.points.length;
    },
  },
  watch: {
    city: {
      immediate: true,
      handler() {
        if (this.city.customCity) {
          this.selectedDeliveryType = { id: DeliveryType.DELIVERY_POINT };
          this.$store.dispatch('checkout/updateDeliveryType', DeliveryType.DELIVERY_POINT);
        } else {
          this.selectedDeliveryType = { id: DeliveryType.RUSSIAN_POST };
          this.$store.dispatch('checkout/updateDeliveryType', DeliveryType.RUSSIAN_POST);
        }
      },
    },
  },
  methods: {
    onSelectType(val) {
      this.selectedDeliveryType = val;
    },
  },
};
</script>

<style lang="stylus">
.ds-checkout-delivery-info
  .ui-input-grid
    +Media(Laptop)
      max-width 510px
    +Media(Desktop)
      max-width 425px
    +Media(DesktopMax)
      max-width 485px
</style>
