import { refreshToken as RefreshToken } from '~/apollo-api/types';
import { client, gql } from './index';
export const refreshToken = async (token: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const { data } = await client.mutate<RefreshToken>({
    mutation: gql`
      mutation refreshToken($token: String!) {
        refreshToken(refreshToken: $token) {
          accessToken
          refreshToken
        }
      }
    `,
    variables: {
      token,
    },
  });
  return data ? data.refreshToken : null;
};
