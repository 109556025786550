export { cart, product } from '../../apollo-api/checkout';
import { createOrder as createOrderQuery } from '../../apollo-api/checkout';
import { ContactsInput, DeliveryInput, OrderItemInput } from '~/apollo-api/types';

export const createOrder = async (
  orderItems: OrderItemInput[],
  contacts: ContactsInput,
  delivery: DeliveryInput,
  promoCode?: string,
  comment?: string,
) => {
  const orderInput = {
    contacts,
    delivery,
    orderItems,
    comment,
    promoCode,
  };
  Object.keys(orderInput).forEach((key) => {
    if (orderInput[key] === undefined) {
      delete orderInput[key];
    }
  });
  const createOrder = createOrderQuery(orderInput);
  return createOrder;
};
