
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import PopupClose from '~/modules/popups/popup-close.vue';

@Component({
  components: {
    PopupClose,
  },
})
export default class DesktopTopRightNotify extends Vue {
  @Prop({ type: Boolean, default: true }) readonly withCloseButton!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isClosed!: boolean;

  @Emit('close')
  onClose() {
    return null;
  }
}
