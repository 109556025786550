export const phoneTransform = (value) => {
  if (!value) {
    return '';
  }
  if (value.startsWith('8')) {
    return `+7${value.substring(1)}`;
  }
  if (value.startsWith('+7') || value.startsWith('7')) {
    return value;
  }
  const truePhone = value.replace('+7', '').replace(/[^0-9]/g, '');
  return truePhone.slice(-10);
};

export const cutPhone = (phone) => {
  if (!phone) {
    return '';
  }
  const newPhone = phone.replace(/\D/g, '');
  return `${newPhone.replace('7', '').slice(0, 3)}***${newPhone.slice(newPhone.length - 3)}`;
};
