import { FilterInputType } from '~/apollo-api/types';

/**
 * @param filter - содержит тип, id и значение: RANGE~0:9000~999900
 */
export const getFiltersFromUrl = (filter: string) => {
  const [key, value] = filter.split(':');
  const [filterType, id] = key.split('~');
  const [min, max] = value.split('~');

  if (filterType === FilterInputType.RANGE) {
    return {
      filterType,
      id: Number(id),
      range: {
        min: Number(min),
        max: Number(max),
      },
    };
  }
};
