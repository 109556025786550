<template lang="pug">
.company-page
  .ql-editor(v-if="text && text.aboutCompany" v-html="text.aboutCompany")
</template>
<script>
import { dshopTexts } from '~/apollo-api/dshopTexts';
import MainSeoText from '~/components/main-seo-text.vue';

export default {
  components: {
    MainSeoText,
  },

  data() {
    return {
      text: null,
    };
  },

  mounted() {
    this.dshopTexts();
  },
  methods: {
    async dshopTexts() {
      this.text = await dshopTexts('aboutCompany');
    },
  },
};
</script>
<style lang="stylus">
.company-page
  margin-top 25px
</style>
