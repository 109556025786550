import { signIn as SignIn } from '~/apollo-api/types';
import { client, gql } from './index';

export const signIn = async (username: string, password: string) => {
  const { data } = await client.mutate<SignIn>({
    mutation: gql`
      mutation signIn($username: String!, $password: String!) {
        signIn(username: $username, password: $password) {
          accessToken
          refreshToken
          customer {
            id
            contacts {
              firstName
              phone
              lastName
              email
            }
          }
        }
      }
    `,
    variables: {
      username,
      password,
    },
  });

  return data?.signIn;
};
