import EventManager from 'js-simple-events';

export default (context, inject) => {
  const em = new EventManager();
  inject('eventManager', em);
  context.$eventManager = em;
  if (process.client) {
    window.$eventManager = em;
  }
};
