import { gql } from '~/apollo-api';

export const productBadgesFragment = gql`
  fragment ProductBadgesFragment on ProductBadge {
    ... on BottomTextBadge {
      id
      text
      textColor
      backgroundColor
    }
  }
`;
