<template lang="pug">
.ds-component.ds-menu-vertical
  .mobile-toggler(v-if="media.isMobileOrTablet")
    button-ui(@click="toggleMenu") {{ currentMenuItem.title }}
      icon-ui(
        name="operations/chevron-down_small-line"
        slot="after"
        :size="32"
        :class="{ open: isMenuOpen }"
      )

  ul.menu-list(
    v-if="menu.length && (!media.isMobileOrTablet || isMenuOpen)"
  )
    li.menu-item(
      v-for="(menuItem, i) in menu"
      :key="`cMenuItem_${i}`"
    )
      nuxt-link.menu-link(
        v-if="menuItem.link !== '#'"
        :to="menuItem.link"
        :class="{'active': menuItem === currentMenuItem}"
      ) {{ menuItem.title }}
      .menu-link(
        v-else
        @click.stop="menuItem.handler"
      ) {{ menuItem.title }}
</template>

<script>
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import ButtonUi from '~/components/common/button';

export default {
  name: 'VerticalMenu',
  components: {
    IconUi,
    LinkUi,
    ButtonUi,
  },
  inject: ['media'],
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    currentMenuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isMenuOpen: false,
  }),
  watch: {
    $route: {
      deep: true,
      handler() {
        this.isMenuOpen = false;
      },
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style lang="stylus">
.ds-menu-vertical
  .menu-link
    Text(BodyL Link)
    display block
    padding 14px 0
    color TextSecondary()
    cursor pointer
    +Media(Tablet, Mobile)
      Text(BodyM)
      padding 10px 0
    &.router-link-exact-active
      +Media(Tablet, Mobile)
        Text(Semibold)
    &.active
      color TextPrimary()

  .menu-item
    +Media(Laptop, Desktop, DesktopMax)
      &:not(:last-child)
        border-bottom 1px solid Divider()

  .mobile-toggler
    +Media(Tablet, Mobile)
      Text(TitleM Link)
      display inline-block
      margin-bottom 32px

    .ui-button
      Button()
      padding 0

    .ui-icon
      transition .15s transform
      &.open
        transform rotate(180deg)

  .menu-list
    +Media(Tablet)
      column-count 2
</style>
