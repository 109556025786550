<template lang="pug">
.ds-labeled-input
  labeled-ds
    template(#label)
      slot(name="label") {{ label }}

    input-ui(
      v-bind="$attrs"
      v-on="$listeners"
    )
      slot(name="after" slot="after")
      slot(name="before" slot="before")
</template>

<script>
import InputUi from '~/components/common/input';
import LabeledDs from '~/components/labeled';

export default {
  components: {
    InputUi,
    LabeledDs,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus">
.ds-labeled-input
  .ui-input
    Input(Medium Outlined)
</style>
