
import Vue, { PropType } from 'vue';

import { ProductPage_productPage_feedbacks_reply } from '~/apollo-api/types';

import FeedbackDateDs from '~/components/feedbacks/feedback-date.vue';

export default Vue.extend({
  name: 'FeedbackReply',
  components: {
    FeedbackDateDs,
  },
  props: {
    reply: {
      type: Array as PropType<ProductPage_productPage_feedbacks_reply[]>,
      required: true,
    },
  },
});
