import { gql, client } from './index';
import { ProductPageVariables } from '~/apollo-api/types';
import { productBadgesFragment } from '~/apollo-api/fragments/productBadges';
import { productCharacteristicsFragment } from '~/apollo-api/fragments/productCharacteristics';

export const getProductPage = async (variables: ProductPageVariables): Promise<any> => {
  const {
    data: { productPage },
  } = await client.query({
    query: gql`
      query ProductPage(
        $productPageId: Int!
        $filters: [FeedbackFilterType!]
        $page: Int!
        $size: Int!
        $sort: FeedbackSortType!
      ) {
        productPage(id: $productPageId) {
          id
          product {
            id
            title
            description
            shortDescription
            attributes
            photos {
              key
              link(trans: PRODUCT_240) {
                high
              }
              original {
                high
              }
            }
            category {
              id
              title
              parentList {
                id
                title
              }
            }
            skuList {
              id
              fullPrice
              sellPrice
              skuTitle
              availableAmount
              weight
              photo {
                key
                link(trans: PRODUCT_240) {
                  high
                }
              }
              characteristicValues {
                id
                title
                value
                characteristic {
                  id
                  title
                }
              }
            }
            skuGroups {
              id
              favorite
              characteristicValues {
                id
                characteristic {
                  id
                  title
                }
                title
                value
              }
            }
            comments {
              type
              value
            }
            characteristics {
              ...ProductCharacteristicsFragment
            }
            feedbackQuantity
            rating
            badges {
              ...ProductBadgesFragment
            }
          }
          feedbacks(filters: $filters, page: $page, size: $size, sort: $sort) {
            id
            anonymous
            customerName
            content
            pros
            cons
            datePublished
            rating
            reply {
              id
              content
              dateCreated
              shop {
                title
                avatar {
                  high
                }
              }
            }
            photos {
              key
              link(trans: PRODUCT_240) {
                high
              }
              original {
                high
              }
            }
            sku {
              characteristicValues {
                characteristic {
                  id
                  title
                }
                id
                title
                value
              }
            }
          }
        }
      }
      ${productBadgesFragment}
      ${productCharacteristicsFragment}
    `,
    variables,
  });
  return productPage;
};
