<template lang="pug">
label.ui-component.ui-checkbox(
  :class=`{
    'is-checked': value,
    'is-disabled': disabled,
    'is-focus': focus
    
  }`
)
  span.ui-checkbox--indicator
  span.ui-checkbox--label
    slot
  input(
    type="checkbox"
    aria-hidden="true"
    :checked="value"
    :disabled="disabled"
    @change="$emit('input', $event.target.checked)"
    @focus="focus = true"
    @blur="focus = false"
  )
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      focus: false,
    };
  },
};
</script>
<style lang="stylus">
.ui-checkbox
  Checkbox()
  .ui-checkbox--label
    CheckboxLabel()
  .ui-checkbox--indicator
    CheckboxIndicator()
  &.is-checked .ui-checkbox--indicator
    CheckboxIndicator(Checked)
</style>
