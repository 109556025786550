/* eslint-disable */
//@ts-nocheck
import layoutRules, { TLayout } from './layout-rules';
import { useInjects } from '~/plugins/injectable';

export const DefaultLayout: TLayout = 'default';

const activeLayoutInServer = (isMobileOrTablet, layouts) => {
  if (isMobileOrTablet) {
    return layouts.includes('mobile') ? 'mobile' : layouts.find((l) => ['m', 't'].includes(l)) || DefaultLayout;
  } else {
    return layouts.includes('desktop') ? 'desktop' : layouts.find((l) => ['d', 'dm', 'l'].includes(l)) || DefaultLayout;
  }
};
// @ts-ignore
export default (layouts: TLayout[] = [DefaultLayout]) =>
  useInjects<{ media: any; $device: any }>(['media']).extend({
    data() {
      return {
        customLayoutRules: layoutRules,
        // eslint-disable-next-line
        // @ts-ignore
        activeLayout: activeLayoutInServer(this.$device.isMobileOrTablet, layouts),
      };
    },
    computed: {
      asyncComponent(): any {
        const hasLayout = layouts.includes(this.activeLayout);
        return () => this.import(hasLayout ? this.activeLayout : DefaultLayout);
      },
    },
    methods: {
      calculateCurrentLayout(): TLayout {
        if (this.$isServer) {
          // eslint-disable-next-line
          // @ts-ignore
          return activeLayoutInServer(this.$device.isMobileOrTablet, layouts);
        } else {
          return (
            (Object.keys(this.customLayoutRules).find(
              (r) => this.customLayoutRules[r].includes(this.media.size) && layouts.includes(r as TLayout),
            ) as TLayout) || DefaultLayout
          );
        }
      },
      switchLayout() {
        this.activeLayout = this.calculateCurrentLayout();
      },
    },
    mounted() {
      if (process.client) {
        this.switchLayout();
      }
    },
    watch: {
      media: {
        deep: true,
        handler() {
          this.switchLayout();
        },
      },
    },
    render(createElement) {
      return createElement(this.asyncComponent);
    },
  });
