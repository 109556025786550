import { gql, client } from './index';

import { DisplayType } from './types';

export const mainBanners = async (displayType: DisplayType = DisplayType.DESKTOP) => {
  //hack for apollo-types
  const newQuery = `
  query MainBanners($displayType: DisplayType!){
    dshopMain(type: $displayType) {
      banners {
        description
        link
        title
        target
        params
        image {
          high
        }
      }
    }
  }`;

  const { data } = await client.query({
    query: gql(newQuery),
    variables: { displayType },
  });

  return data.dshopMain?.banners;
};
