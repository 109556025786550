
import Vue from 'vue';

import LinkUi from '~/components/common/link.vue';
import ButtonUi from '~/components/common/button.vue';
import FeedbackRatingDs from '~/components/feedbacks/feedback-rating.vue';
import FeedbackDateDs from '~/components/feedbacks/feedback-date.vue';
import FeedbackReplyDs from '~/components/feedbacks/feedback-reply.vue';

export default Vue.extend({
  name: 'CabinetFeedback',
  components: {
    LinkUi,
    ButtonUi,
    FeedbackRatingDs,
    FeedbackDateDs,
    FeedbackReplyDs,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    feedback: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productLink() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `/product/${this.product.sku.product.id}`;
    },
  },
  methods: {
    deleteFeedback() {},
  },
});
