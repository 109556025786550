export const loginSuccessEvent = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_login_submit_success',
    eventCategory: 'personal_account',
    eventAction: 'login',
    eventLabel: 'submit_success',
    properties: {
      phone_part: phone,
    },
  };
};

export const loginErrorEvent = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_login_submit_failed',
    eventCategory: 'personal_account',
    eventAction: 'login',
    eventLabel: 'submit_failed',
    properties: {
      phone_part: phone,
    },
  };
};
