<template lang="pug">
.ds-mobile-menu-container
  transition(name="slide")
    aside.ds-mobile-menu(v-if="isMenuOpen")
      button-ui.nav-button(@click="toggleCatalog")
        icon-ui.nav-icon-before(name="navigation/catalog_sharp" size="28")
        span.nav-text Каталог товаров
        icon-ui.nav-icon-after(name="navigation/chevron-right_sharp" size="16")
      .bottom
        button-ui.nav-button(v-if="!authenticated" @click="onCabinetClick")
          icon-ui.nav-icon-before(name="navigation/cabinet_sharp" size="24")
          span.nav-text Войти
          .nav-icon-after
        button-ui.nav-button(v-if="authenticated" @click="onLogout")
          icon-ui.nav-icon-before(name="navigation/logout" size="24")
          span.nav-text Выйти из аккаунта
          .nav-icon-after
        ul
          li.list-item(v-for="link in links" :key="link.title")
            link-ui.list-link(:to="link.to") {{ link.title }}

  catalog-ds(
    :is-catalog-open="isCatalogOpen"
    :is-menu-open="isMenuOpen"
    @toggle="toggleCatalog"
    @close="$emit('close')"
  )

  .shadow(v-if="isMenuOpen" @click="$emit('close-menu')")
</template>

<script>
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import CatalogDs from '~/components/header/catalog';
import { HeaderActionsMixin } from '~/mixins/header-actions-mixin';
import { StaticDataMixin } from '~/mixins/staticData';

export default {
  components: {
    LinkUi,
    ButtonUi,
    IconUi,
    CatalogDs,
  },
  mixins: [HeaderActionsMixin, StaticDataMixin],
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    isCatalogOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    links() {
      return [
        // { to: '/faq#how-pay', title: 'Оплата и доставка' },
        // { to: '/faq#return-money', title: 'Правила возврата' },
        { to: { name: 'delivery-points' }, title: 'Пункты выдачи' },
        { to: { name: 'about-company' }, title: 'О компании' },
        { to: this.static.support.chat, title: 'Служба поддержки' },
      ];
    },
  },
  methods: {
    toggleCatalog() {
      this.$emit('toggle');
    },
    onLogout() {
      this.$store.dispatch('user/logOut');
      this.$store.dispatch('main/cleanProductFavoriteStatus');
      this.$store.dispatch('viewedProducts/remove');
      this.$router.push({ name: 'signin' });
    },
  },
};
</script>

<style lang="stylus">
$header = 48px
$subheader = 40px

.slide-enter-active
.slide-leave-active
  transition transform .3s ease-in-out
.slide-enter
.slide-leave-to
  transform translateX(-100%)
  transition all .3s ease-in-out 0s

.ds-mobile-menu-container
  .shadow
    position fixed
    height 100vh
    width 100%
    left 0
    z-index 3
    background #e5e5e5
    opacity .5

.ds-mobile-menu
  MobileMenu()
  z-index 4
  display: flex
  flex-direction column
  justify-content: space-between
  .bottom
    background-color: #F2F4F7
    margin-left: -16px
    margin-bottom: -16px
    padding-left: 16px;
    padding-bottom: 16px;
    .list-item
      padding: 8px 0
      .list-link
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color #8B8E99
  .nav-button
    width 100%
    height 60px
    padding 16px 16px 16px 0
    > div
      display flex
      justify-content space-between
      width 100%
    .nav-text
      Text(BodyL Short)
      color: TextPrimary()
    .nav-icon-before
      margin-right 12px
    .nav-icon-after
      margin-left auto
      path
        fill: #999BA6

  .list-title
    Text(TitleS Medium Short Secondary)
    margin-top 32px
    margin-bottom 8px


  .logout-button
    Text(BodyL Short)
    padding 12px 0
    color #F00000
</style>
