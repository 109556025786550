import { setupPasswordVariables as SetupPasswordVariables, setupPassword as SetupPassword } from '~/apollo-api/types';
import { client, gql } from './index';

export const setupPassword = async ({ phone, password, code }: SetupPasswordVariables) => {
  const { data } = await client.mutate<SetupPassword>({
    mutation: gql`
      mutation setupPassword($phone: String!, $password: String!, $code: Int!) {
        setupPassword(phone: $phone, password: $password, code: $code) {
          accessToken
          refreshToken
          customer {
            contacts {
              firstName
              phone
              lastName
              email
              sex
            }
          }
        }
      }
    `,
    variables: {
      phone,
      code,
      password,
    },
  });

  return data?.setupPassword;
};
