<template lang="pug">
.delivery-points-page(v-if="findCity")
  client-only
    .ui-wrapper(v-if="!media.isMobileOrTablet")
      .title-menu Пункты выдачи заказов
    select-city(v-if="media.isMobileOrTablet && current === 'city'" @change-tab="changeTab")
    .map-wrapper(v-if="!media.isMobileOrTablet")
      side-wrapper
      delivery-map
    mobile(v-else-if="current === 'points'" @change-tab="changeTab")
</template>

<script>
import MenuVertical from '~/components/menu-vertical';
import { SeoDataMixin } from '~/mixins/seoData';
import { mapState } from 'vuex';
import DeliveryMap from './components/delivery-map.vue';
import SideWrapper from './components/side-wrapper.vue';
import Mobile from './components/mobile.vue';
import selectCity from './components/select-city.vue';
import { StaticDataMixin } from '~/mixins/staticData';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
export default {
  name: 'DeliveryPointsPage',
  components: {
    MenuVertical,
    DeliveryMap,
    SideWrapper,
    Mobile,
    selectCity,
  },
  mixins: [SeoDataMixin, StaticDataMixin],
  inject: ['media'],
  data() {
    return {
      mobileState: ['points', 'city'],
      current: 'points',
    };
  },
  computed: {
    ...mapState('deliveryMap', {
      city: (state) => state.deliveryMapCity,
      comment: (state) => state.comment,
    }),
    findCity() {
      if (this.city.name.includes(CITY_WITH_PARTNER_DP)) {
        return true;
      }
      return this.localCities.find((item) => this.city.name === item);
    },
  },
  methods: {
    changeTab(index) {
      this.current = this.mobileState[index];
    },
  },
};
</script>

<style lang="stylus">
.delivery-points-page
  margin-top 8px

  // .ui-wrapper
  //   display flex
  //   +Media(Tablet, Mobile)
  //     flex-direction column
  .title-menu
    Text(HeadlineS)
    font-weight bold
    margin-bottom 24px
    +Media(Mobile, Tablet)
      font-family: Inter;
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      margin-bottom 16px

  .map-wrapper
    position relative
</style>
