<template lang="pug">
.ds-guarantees.ds-component
  .ui-wrapper
      .item
        icon-ui.icon(name="status/postmail_line")
        .label Доставка#[br]по всей России
      .item
        icon-ui.icon(name="status/return_line")
        .label 7 дней на возврат#[br]без споров и нареканий
      .item
        icon-ui.icon(name="status/secure-payment_line")
        .label Безопасная оплата
</template>

<script>
import IconUi from '~/components/common/icon';

export default {
  components: {
    IconUi,
  },
};
</script>

<style lang="stylus">
.ds-guarantees
  background-color ButtonGrayDisabled()
  padding 24px 0

  .ui-wrapper
    display flex
    justify-content center
    flex-wrap wrap
    +Media(Mobile)
      flex-direction column

  .icon
    padding 7px
    box-sizing content-box
    border-radius 50%
    border 1px solid TextPrimary()

  .item
    display flex
    align-items center
    flex auto 0 0
    &:not(:last-child)
      margin-right 40px
      +Media(Mobile)
        margin-right 0
        margin-bottom 16px

  .label
    Text(CaptionM Short)
    margin-left 16px
    color TextPrimary()
    +Media(Mobile, Tablet)
      Text(CaptionM Regular)
</style>
