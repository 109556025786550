<template lang="pug">
component(
  :is="asyncComponent"
  :is-menu-open="isMenuOpen"
  :is-catalog-open="isCatalogOpen"
  :is-show-close-icon="isShowCloseIcon"
  :categories="sortedCategories"
  @close="$emit('close')"
)
</template>

<script>
import { mapGetters } from 'vuex';
import Layoutable from '~/plugins/layoutable/mixin';

export default {
  mixins: [Layoutable(['mobile', 'desktop'])],

  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    isCatalogOpen: {
      type: Boolean,
      default: false,
    },
    isShowCloseIcon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('catalog', ['sortedCategories']),
  },

  methods: {
    async import(layoutName) {
      return await import('./' + layoutName);
    },
  },
};
</script>
