import { ESuggestBlocks, ITextSuggestBlock, TTextSuggest } from '../models';

export { SUGGEST_CONFIG } from './config';

export const CACHE_SEARCH_CAPACITY = 50;

export const TEXT_BLOCK_MAP: Record<TTextSuggest, ITextSuggestBlock> = {
  [ESuggestBlocks.Cache]: {
    icon: 'navigation/clock_sharp',
    title: 'Вы недавно искали',
    clearable: true,
  },
  [ESuggestBlocks.Text]: {
    icon: 'navigation/search_sharp',
    title: '',
  },
  [ESuggestBlocks.Popular]: {
    icon: 'navigation/search_sharp',
    title: 'Популярное',
  },
};
