<template lang="pug">
  .catalog-icon(:style="style" :class="{'open' : isOpen }")
    .rect
      .inner
    .top
    .bottom
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: '#FFF',
    },
  },
  computed: {
    style() {
      return {
        '--out': this.background,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
.catalog-icon
  --out #FFF
  inner = #2B2C84
  size = 28px
  stroke = 2px
  tWrap = .11s
  tRot = .11s
  tInn = tWrap * 0.2
  tInnDelay = tWrap * 0.8
  tick = 0.001ms
  easeClose = ease-out
  easeOpen = ease-in
  height size
  width size
  position relative
  cursor pointer
  *
    cursor pointer
  .rect
    transition all tWrap tRot easeClose, transform tRot tick easeClose
    position absolute
    width size* 0.75
    height size* 0.5
    border stroke solid inner
    border-radius stroke
    background inner
    box-sizing border-box
    transform translate(-50%, 0)
    top round(size* 0.416 - stroke* 0.5)
    left 50%
    .inner
      transition all tWrap tRot easeClose
      background-color var(--out)
      width 100%
      height 100%
      border-radius stroke* 0.5
      margin auto
  .bottom
    transition all tWrap tRot easeClose, transform tRot tick easeClose
    position absolute
    width size * 0.666
    height 0
    background inner
    border-top stroke solid inner
    border-top-left-radius stroke
    border-top-right-radius stroke
    top size* 0.25
    transform translate(-50%, 0)
    left 50%
  .top
    transition all tWrap tRot easeClose, opacity tick tRot
    position absolute
    width size * 0.583
    height 0
    border-top stroke solid inner
    border-top-left-radius stroke
    border-top-right-radius stroke
    top size* 0.125
    transform translate(-50%, 0)
    left 50%
  &.open
    .rect
      transition all tWrap easeOpen, transform tRot tWrap easeOpen, border-bottom-width tInn tInnDelay
      height 0
      border-bottom-width 0
      border-top stroke solid inner
      background inner
      top 50%
      transform-origin 50% 50%
      transform translate(-50%, -0.5 *stroke) rotate(-45deg)
    .bottom
      transition all tWrap easeOpen, transform tRot tWrap easeOpen, border-radius tWrap* 0.07 tWrap* 0.8
      top 50%
      width size* 0.6875 + stroke
      transform-origin 50% 50%
      transform translate(-50%, -0.5 *stroke) rotate(45deg)
      border-radius stroke
    .top
      transition all tWrap easeOpen, opacity tick tWrap easeOpen
      top 50%
      opacity 0
</style>
