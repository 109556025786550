
import { Mixins } from 'vue-mixin-decorator';
import { Component, Inject } from 'vue-property-decorator';
import MobileBottomWrapper from './mobile-bottom-wrapper.vue';
import DesktopCenterWrapper from './desktop-center-wrapper.vue';

import { PopupWrapperMixin } from '~/modules/popups/mixins/popups-wrapper';
import { PopupsContainerModel } from '~/modules/popups/models';

@Component({
  components: {
    MobileBottomWrapper,
    DesktopCenterWrapper,
  },
})
export default class PopupCenterWrapper extends Mixins(PopupWrapperMixin(PopupsContainerModel.ETypeWrapper.CENTER)) {
  @Inject() media;
}
