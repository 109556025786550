import { updateUser as UpdateUser } from '~/apollo-api/types';

import { client, gql } from './index';

export const updateUser = (contacts) => {
  return client.mutate<UpdateUser>({
    mutation: gql`
      mutation updateUser($contacts: ContactsInput!) {
        updateUser(contacts: $contacts) {
          firstName
          phone
          lastName
          email
        }
      }
    `,
    variables: {
      contacts,
    },
  });
};
