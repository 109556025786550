<template lang="pug">
.ds-checkout-address
  .ui-input-grid
    .ui-input-row
      checkout-address-street(
        :street="value"
        @select="onStreetUpdate"
        :error="isShowWarnings && error && error.length && !isValidHouse ? error : ''"
        :error-prompt="isShowWarnings && error && error.length && !isValidStreet ? error : ''")
    .ui-input-row.ui-input-grid
      labeled-input(
        label="Квартира:"
        :value="value.flat"
        @input="onFlatUpdate"
      )
</template>

<script>
import { mapState } from 'vuex';
import CheckoutAddressStreet from './checkout-address-street';
import LabeledInput from '~/components/labeled-input';
import { CheckoutFieldMixin } from '~/mixins/business/checkout';
import DropdownDs from '~/components/dropdown';

export default {
  components: {
    CheckoutAddressStreet,
    DropdownDs,
    LabeledInput,
  },
  mixins: [CheckoutFieldMixin],
  data: () => ({
    field: 'address',
    house: '',
  }),
  computed: {
    ...mapState('checkout', {
      value: (state) => state.address,
    }),
    ...mapState('checkout', {
      checkStreetName: (state) =>
        state.checkStreets && state.checkStreets.length > 0 ? state.checkStreets[0].name : null,
    }),
    ...mapState('checkout', {
      addressCheck: (state) => (state.checkStreets && state.checkStreets.length > 0 ? state.checkStreets : []),
    }),
    ...mapState('checkout', ['isShowWarnings', 'city']),
    isValidStreet() {
      return !(this.addressCheck.findIndex((elem) => elem.street === this.value.street) === -1);
    },
    isValidHouse() {
      return !!this.value.house;
    },
    isValid() {
      return this.isValidStreet && this.isValidHouse;
    },
    error() {
      if (!this.isValidStreet && this.checkStreetName) {
        return 'Не нашли такой адрес, возможно вы имели в виду';
      } else if (!this.isValidHouse) {
        return 'Введите адрес целиком до номера дома';
      } else if (!this.isValidStreet) {
        return 'Не нашли такой адрес';
      }
      return '';
    },
  },
  methods: {
    updateAddress(payload) {
      this.$store.commit('checkout/updateAddress', payload);
    },
    updateField(field, value) {
      this.updateAddress({
        ...this.value,
        [field]: value,
      });
    },
    onStreetUpdate(value, house, street_with_type, house_type, settlement_with_type) {
      this.updateField('street', value);
      this.updateField('house', house);
      this.updateField('street_with_type', street_with_type);
      this.updateField('house_type', house_type);
      this.updateField('settlement_with_type', settlement_with_type);
      this.house = house;
    },
    onFlatUpdate(value) {
      this.updateField('flat', value);
    },
  },
};
</script>

<style lang="stylus">
.ds-checkout-address
  .error
    Text(CaptionM)
    color Red()
    margin-top 8px
</style>
