<template lang="pug">
.ui-component.ui-radio-extended
  .radio-item(
    v-for="item in items"
    @click="selectItem(item)"
    :class="{ selected: value && value.id === item.id, disabled: item.disabled }"
  )
    template(v-if="$scopedSlots.item")
      slot(name="item" v-bind="item")
    template(v-else)
      input(type="radio" :name="name" :id="item.id" :checked="value && value.id === item.id")
      label(:for="item.id") {{ item.label }}
</template>

<script>
export default {
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
    },
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler() {
        this.checkDisabledItems();
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.checkDisabledItems();
      },
    },
  },
  methods: {
    checkDisabledItems() {
      if (this.value && this.items.find((el) => el.id === this.value.id)?.disabled) {
        this.selectItem(this.items.find((el) => !el?.disabled));
      }
    },
    selectItem(item) {
      if (item?.disabled) {
        return;
      }
      if (typeof item !== 'object') {
        this.$emit(
          'change',
          this.items.find((el) => el.id === item),
        );
      } else {
        this.$emit('change', item);
      }
    },
  },
};

export * from './models';
</script>
