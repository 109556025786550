
import { Mixins } from 'vue-mixin-decorator';
import { Component, Inject } from 'vue-property-decorator';
import DesktopBottomRightWrapper from '~/modules/popups/wrappers/desktop-bottom-right-wrapper.vue';
import MobileBottomWrapper from './mobile-bottom-wrapper.vue';

import { PopupWrapperMixin } from '~/modules/popups/mixins/popups-wrapper';
import { PopupsContainerModel } from '~/modules/popups/models';

@Component({
  components: {
    DesktopBottomRightWrapper,
    MobileBottomWrapper,
  },
})
export default class PopupBottomRightWrapper extends Mixins(
  PopupWrapperMixin(PopupsContainerModel.ETypeWrapper.BOTTOM_RIGHT),
) {
  @Inject() media;
}
