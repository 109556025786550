import {
  getOrder as GetOrder,
  getOrders as GetOrders,
  getOrdersVariables as GetOrdersVariables,
} from '~/apollo-api/types';
import { client, gql } from './index';
import { ordersData } from './fragments/ordersData';

export const getOrder = async (orderId: number) => {
  const { data } = await client.mutate<GetOrder>({
    mutation: gql`
      query getOrder($orderId: Int!) {
        order(orderId: $orderId) {
          ...ordersData
          contacts {
            email
            firstName
            lastName
            phone
          }
        }
      }
      ${ordersData}
    `,
    variables: {
      orderId,
    },
  });

  return data?.order;
};

export const orders = async ({ page, size, statuses }: GetOrdersVariables) => {
  const { data } = await client.mutate<GetOrders>({
    mutation: gql`
      query getOrders($page: Int, $size: Int, $statuses: [OrderStatus!]) {
        orders(page: $page, size: $size, statuses: $statuses) {
          ...ordersData
        }
      }
      ${ordersData}
    `,
    variables: {
      page,
      size,
      statuses,
    },
  });

  return data?.orders;
};
