<template lang="pug">
.ds-extendable-offer(v-if="productsList && productsList.length")
  client-only
    banner-section-ds(v-if="showBanners(id)" :section="selectBanners(id)" :key="id")
  .title-container
    link-ui.title(:to="offerUrl") {{ title }}
      icon-ui.title-icon(name="navigation/chevron-right-fill_sharp")
  .products
    product-card-ds(
      v-for="(product, index) in productsList"
      ref="observer-listing"
      :key="product.id"
      :cardData="product"
      :item-list-name="`Подборка: ${title}`"
      :item-index="index"
      @favorite-change='favoriteChange'
    )
  .more-button-container
    button-ui.more-button(@click="onClick" v-if="products.length >= mediaSize") {{ isLoaded ? 'Посмотреть всю подборку' : 'Показать ещё' }}
      icon-ui.more-button-icon(v-if="!isLoaded" name="navigation/chevron-down-fill_sharp")
</template>

<script>
import { getExtendableOffer } from '~/apollo-api/offer';

import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import ProductCardDs from '~/components/product-card';
import BannerSectionDs from './banner-section.vue';
import { uri } from '~/apollo-api/client/http-link';
import { PRODUCTS_SIZE } from '~/modules/offer/data';
import { observerMixin } from '~/plugins/gtm-datalayer/observer';
import { PopupsService } from '~/modules/popups/services/popups-service';
import { PopupsContainerModel } from '~/modules/popups/models';
import FavoriteSkuPopup from '~/components/popups/favorite-sku-popup';
import { getProduct } from '~/apollo-api/getProduct';
import { add, remove } from '~/apollo-api/favorite';
import AddedToFavorite from '~/components/popups/add-favorite-popup.vue';

export default {
  components: {
    IconUi,
    ButtonUi,
    LinkUi,
    ProductCardDs,
    BannerSectionDs,
  },
  mixins: [observerMixin],
  inject: ['media'],

  props: {
    id: {
      type: Number,
      default: -1,
    },
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    isLoaded: false,
    moreList: [],
    productsList: [],
  }),

  computed: {
    offerUrl() {
      return `/offer/${this.id}`;
    },
    mediaSize() {
      return PRODUCTS_SIZE[this.media.size];
    },
  },
  watch: {
    products: {
      handler: function (value) {
        if (value.length <= this.mediaSize) {
          this.isLoaded = false;
        } else {
          this.isLoaded = true;
        }

        this.productsList = value.map((el) => {
          const fav = el.skuGroups.length ? el.skuGroups.filter((skuGroup) => skuGroup.favorite).length > 0 : false;
          return {
            ...el,
            favorite: fav,
          };
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    checkFavorite(arr) {
      return;
    },
    onClick() {
      if (this.isLoaded) {
        this.$router.push(this.offerUrl);
        return;
      }

      this.loadProducts();
    },
    async loadProducts() {
      try {
        const response = await getExtendableOffer({
          id: this.id,
          size: PRODUCTS_SIZE[this.media.size],
          page: PRODUCTS_SIZE[this.media.size],
        });
        this.moreList = response.productsPage.content.map((el) => {
          const fav = el.skuGroups.length ? el.skuGroups.filter((skuGroup) => skuGroup.favorite).length > 0 : false;
          return {
            ...el,
            favorite: fav,
          };
        });
        this.$store.dispatch('main/updateOffers', {
          id: this.id,
          content: [...this.productsList, ...this.moreList],
        });
        this.productsList = [...this.productsList, ...this.moreList];
        this.isLoaded = true;
      } catch (e) {
        console.error(e);
      }
    },
    showBanners(id) {
      return !uri.includes('dev') ? id === 70002 || id === 70003 : id === 8 || id === 7;
    },
    selectBanners(id) {
      return !uri.includes('dev')
        ? id === 70003
          ? 'MAIN.POPULAR'
          : 'MAIN.NEW'
        : id === 8
        ? 'MAIN.POPULAR'
        : 'MAIN.NEW';
    },
    observerIntersect() {
      this.$gtm.push({ ecommerce: null });
      this.$gtm.push(this.$dataLayer.listingEvent(this.productsList, `Подборка: ${this.title}`));
    },
    showAddedToFavoritePopup(added) {
      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_NOTIFY,
        component: AddedToFavorite,
        propsData: {
          added,
        },
      });
    },
    async favoriteChange(data) {
      const product = await getProduct(data[1]);
      if (product.skuGroups.length === 1) {
        if (!data[2]) {
          this.showAddedToFavoritePopup(false);
          await remove(product.skuGroups[0].id);
          this.$gtm.push(this.$dataLayer.removeFromFavoriteEvent(product, product.skuList[0]));
        } else {
          this.showAddedToFavoritePopup(true);
          this.$gtm.push(this.$dataLayer.addToFavoriteEvent(product, product.skuList[0]));
          await add(product.skuGroups[0].id);
        }

        this.$store.dispatch('main/updateProductFavoriteStatus', data);
        this.$store.dispatch('viewedProducts/updateProduct', data);
        return;
      }
      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.CENTER,
        component: FavoriteSkuPopup,
        withReturnData: true,
        propsData: {
          ...product,
          id: data[1],
        },
      });
    },
  },
};
</script>

<style lang="stylus">
.ds-extendable-offer
  margin-bottom 56px
  display flex
  flex-direction column
  +Media(Tablet, Mobile)
    margin-bottom 40px

  .title-container
    margin-bottom 8px
    padding 24px 0
    +Media(Tablet, Mobile)
      padding 8px 0
    .title
      Text(TitleL Medium Secondary)
      display inline-flex
      align-items center
      color TextPrimary()
      +Media(Tablet, Mobile)
        Text(TitleS Medium Short Secondary)
        padding 5px 0
      &:hover .title-icon
        transform translate(8px)
      &-icon
        margin-left 4px
        transition transform 0.2s

  .products
    Products()

  .more-button-container
    Products()
  .more-button
    Button(Secondary Large)
    margin-top 56px
    height 48px
    grid-column 2 / 5
    +Media(Laptop)
      grid-column 1 / 5
    +Media(Tablet)
      margin-top 48px
      grid-column 1 / 4
    +Media(Mobile)
      margin-top 40px
      grid-column 1 / 3
    &-icon
      margin-left 4px
</style>
