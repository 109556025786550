import { DEFAULT_SORT_VALUE } from './sort-data';

export { SORT_DATA, DEFAULT_SORT_VALUE } from './sort-data';

export const DEFAULT_PAGINATION = {
  page: 0,
  size: 20,
};

export const DEFAULT_QUERY_INPUT = {
  sort: DEFAULT_SORT_VALUE,
  pagination: DEFAULT_PAGINATION,
};
