const MAX_OBSERVER_LENGTH = 10;
export const observerMixin = {
  data: () => ({
    observerOptions: {
      rootMargin: '0px',
      threshold: '0.1', // 10% видимости
    },
    observer: null,
    observerFor: 'observer-listing',
    observerForElement: null,
  }),
  mounted() {
    if (process.client) {
      this.observerForElement = this.observerInit();
      this.observer = new IntersectionObserver((entryies) => {
        var count = 0;
        for (let entry of entryies) {
          if (entry && entry.isIntersecting) {
            count++;
            if (this.observerFor != 'observer-listing') {
              this.observerIntersect(entry.target);
              this.observer.unobserve(entry.target);
            }
          }
        }
        if (this.observerFor == 'observer-listing' && count) {
          this.observer.disconnect();
          this.observerForElement = null;
          this.observerIntersect();
        }
      }, this.observerOptions);

      if (this.observerForElement) {
        for (let el of this.observerForElement) this.observer.observe(el.$el ?? el);
      }
    }
  },
  beforeDestroy() {
    if (process.client) {
      this.observer.disconnect();
      this.observerForElement = null;
    }
  },
  methods: {
    observerIntersect() {},
    observerInit() {
      if (process.client) {
        if (!this.$refs[this.observerFor]) {
          return null;
        }

        if (Array.isArray(this.$refs[this.observerFor])) {
          return this.$refs[this.observerFor].slice(0, MAX_OBSERVER_LENGTH);
        }

        return this.$refs[this.observerFor];
      }
    },
  },
};
