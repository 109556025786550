import Vue from 'vue';

import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService } from '~/modules/popups/services/popups-service';

export const PopupWrapperMixin = (type: PopupsContainerModel.ETypeWrapper) =>
  Vue.extend({
    inject: ['media'],
    props: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component: {
        type: [Object, Function],
        default: () => ({}),
      },
      propsData: {
        default: null,
      },
    },
    data: () => ({
      isClosed: false,
      popupsService: PopupsService,
    }),
    methods: {
      close(data: any = null) {
        this.popupsService.close(type, data);
        this.isClosed = true;
      },
    },
  });
