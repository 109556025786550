export const ProdCategories = [
  {
    id: 3286,
    title: 'Электроника',
  },
  {
    id: 628,
    title: 'Бытовая техника',
  },
  {
    id: 804,
    title: 'Бытовая химия и личная гигиена',
  },
  {
    id: 2765,
    title: 'Товары для дома',
  },
  {
    id: 165,
    title: 'Автотовары',
  },
  {
    id: 2484,
    title: 'Строительство и ремонт',
  },
  {
    id: 1957,
    title: 'Одежда',
  },
  {
    id: 1832,
    title: 'Обувь',
  },
  {
    id: 378,
    title: 'Аксессуары',
  },
  {
    id: 1577,
    title: 'Красота',
  },
  {
    id: 973,
    title: 'Детские товары',
  },
  {
    id: 2287,
    title: 'Спорт и отдых',
  },
  {
    id: 892,
    title: 'Дача, сад и огород',
  },
  {
    id: 3160,
    title: 'Зоотовары',
  },
  {
    id: 1355,
    title: 'Канцтовары',
  },
  {
    id: 1146,
    title: 'Хобби и творчество',
  },
  {
    id: 1480,
    title: 'Книги',
  },
  {
    id: 1274,
    title: 'Здоровье',
  },
];

export const DevCategories = [
  {
    id: 47998,
    title: 'Test',
  },
  {
    id: 47993,
    title: 'Для приемки на wms',
  },
  {
    id: 47954,
    title: 'Холодильники для косметики',
  },
  {
    id: 32911,
    title: 'Бытовая техника',
  },
  {
    id: 32659,
    title: 'Аксессуары',
  },
  {
    id: 32427,
    title: 'Автотовары',
  },
  {
    id: 33200,
    title: 'фикс выпил Дача, сад и огород',
  },
  {
    id: 33109,
    title: 'Бытовая химия и личная гигиена',
  },
  {
    id: 33540,
    title: 'Здоровье',
  },
  {
    id: 33286,
    title: 'Детские товары',
  },
  {
    id: 33403,
    title: 'Хобби и творчество',
  },
  {
    id: 33615,
    title: 'Канцтовары',
  },
  {
    id: 34132,
    title: 'Обувь',
  },
  {
    id: 33739,
    title: 'Книги',
  },
  {
    id: 33846,
    title: 'Красота',
  },
  {
    id: 35511,
    title: 'Зоотовары',
  },
  {
    id: 34280,
    title: 'Одежда',
  },
  {
    id: 34635,
    title: 'Спорт и отдых',
  },
  {
    id: 30556,
    title: 'Главная-тест',
  },
  {
    id: 34848,
    title: 'Строительство и ремонт',
  },
  {
    id: 35642,
    title: 'Электроника',
  },
  {
    id: 35091,
    title: 'Товары для взрослых',
  },
  {
    id: 35118,
    title: 'Товары для дома',
  },
  {
    id: 36022,
    title: 'выфвфывфывфывфы',
  },
  {
    id: 36023,
    title: 'мчмям',
  },
  {
    id: 36024,
    title: 'Тестирую "Бренд"',
  },
  {
    id: 36025,
    title: 'Продукты питания',
  },
  {
    id: 36228,
    title: '   Игры и софт',
  },
  {
    id: 41935,
    title: 'ыаываыав',
  },
];
