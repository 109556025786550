import { seoData, SEO_DATA_PAGES } from '~/shop-config';

export const SeoDataMixin = {
  data() {
    return {
      seoData,
      seoDataPages: SEO_DATA_PAGES,
    };
  },
};
