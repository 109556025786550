
import ClickOutside from 'vue-click-outside';
import { Component, Emit, Inject, Vue } from 'vue-property-decorator';
import IconUi from '../common/icon.vue';

import { cartData } from '~/apollo-api/types';

import ButtonUi from '~/components/common/button.vue';
import LinkUi from '~/components/common/link.vue';

const FADEOUT_DELAY = 5000;

@Component({
  components: {
    ButtonUi,
    LinkUi,
    IconUi,
  },
  directives: {
    ClickOutside,
  },
})
export default class ShowNotificationEditCart extends Vue {
  @Inject() media;

  @Emit('close')
  onClose() {}

  cartProducts!: cartData[];
  // eslint-disable-next-line no-undef
  fadeoutTimer?: NodeJS.Timeout;

  mounted() {
    this.fadeoutTimer = setTimeout(() => {
      this.onClose();
    }, FADEOUT_DELAY);
  }

  beforeDestroy() {
    if (this.fadeoutTimer) {
      clearTimeout(this.fadeoutTimer);
    }
  }
}
