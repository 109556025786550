<template lang="pug">
.ds-order-properties
  header.header(v-if="!isSuccessful")
    .title Заказ №{{ order.id }}
    .status(
      :class="order.status | styleFromStatus"
    ) {{ order.status | formatStatus }}

  .properties
    //- Order in proccess
    template(v-if="!finishStatuses.includes(order.status)")
      progress-ds(:items="progressItems" :point="progressPoint")
    //- Delivery Point
    template(v-if="order.issueCode && isDeliveryPoint && deliveryStatuses.includes(order.status)")
      button-ui.issue-code(v-if="media.isMobileOrTablet" @click.native.stop="showBarcode") Код получения заказа
      .line(v-else-if="deliveryStatuses.includes(order.status)")
        .name.code Код получения заказа
        .val.code {{ order.issueCode }}

    template(
      v-if="order.delivery && order.delivery.deliveryPoint && order.delivery.deliveryPoint.address"
    )
      .line(v-if="order")
        .name Пункт выдачи заказов
        .val
          div {{ order.comment ? getFiasName : order.delivery.deliveryPoint.address }}
          ul(class="additional-list")
            li(v-if="additionalFields" v-for="item in additionalFields" :key="additionalFields + Math.random()") {{ item }}
      //-     .prop-title
      //-       span {{ order.delivery.deliveryPoint.address }}
      //-       br
      //-       span.regular {{ order.delivery.deliveryPoint.timeFrom }} - {{ order.delivery.deliveryPoint.timeTo }}, без выходных</span>
      //-     button-ui.map-toggle(@click="toggleMap") {{ isShowMap ? 'Скрыть карту' : 'Показать на карте' }}
      //-       icon-ui(:name="isShowMap ? 'navigation/chevron-up_line' : 'navigation/chevron-down_line'" size="18" slot="after")
      //- client-only(v-if="isShowMap")
      //-   yandex-map.map(
      //-     :settings="settings"
      //-     :coords="coords(order.delivery.deliveryPoint)"
      //-     :controls="[]"
      //-   )
      //-     ymap-marker(
      //-       :markerId="order.delivery.deliveryPoint.id"
      //-       :coords="coords(order.delivery.deliveryPoint)"
      //-       :icon="marker"
      //-     )
      .line
        .name Режим работы
        .val {{ order.comment ? getFiasWorkTime : 'Уточните у технической поддержки' }}

      .line(v-if="order.dateCreated")
        .name Заказ хранится
        //- .val 5 дней, до {{ order.dateCreated | sumDate(5) }}
        .val Заказ будет храниться в течение 5 дней после доставки в ПВЗ

    .line.ru-post-barcode(v-if="order.delivery && order.delivery.ruPostBarcode")
      .name.tooltip-card-title
        div(@click="switchTooltip" class="tooltip-trigger-icon")
          icon-ui(name="navigation/faq_line" fill="#C2C4CC" size="24")
        span Трек-номер
      .val.tooltip-card
        a(:href="`https://www.pochta.ru/tracking#${order.delivery.ruPostBarcode}`" target="_blank")
          button-ui {{ order.delivery.ruPostBarcode }}
            icon-ui(name="status/external_line" slot="after")
        transition(name="tooltip")
          div.tooltip-card-text(v-show="showTooltip")
            span Отслеживать заказ проще в приложении
            a.tooltip-card-link(:href="linkApplication(platform)" target="_blank" style="color:#0071E3") Почты России
            div(@click="switchTooltip" style="display: flex; cursor: pointer")
              icon-ui(name="navigation/close_line" fill="white")

    //- RuPost Delivery
    .line(v-if="order.delivery && order.delivery.address && order.delivery.ruPostId")
      .name Доставка почтой РФ
      .val {{ order.delivery.address }}
    //- Courier Delivery
    .line(v-if="order.delivery && order.delivery.address && order.delivery.city")
      .name Доставка курьером
      .val {{ order.delivery.address }}

    .line(v-if="order.dateCreated")
      .name Заказ сделан
      .val.regular {{ dateOrder }}
    .line.contacts(v-if="order.contacts")
      .name Получатель заказа
      .val
        .prop-title
          span {{ order.contacts.firstName }} {{ order.contacts.lastName }}
          br
          span.regular {{ order.contacts.phone | formatPhone }}, {{ order.contacts.email }}

    //- common
    .line.short
      //- @TODO plural, weight
      .name {{ order.orderItems.length }} {{ ending(order.orderItems.length, ['товар', 'товара', 'товаров']) }}
      .val.regular {{ order.orderItems | sumPrice | formatPrice }}
    .line.short
      .name Доставка
      .val.regular(v-if="isPayedPoint") 200 ₽ (при получении)
      .val.regular(v-else-if="order.delivery.originalDeliveryPrice") {{ order.delivery.originalDeliveryPrice | formatPrice }}
      .val.regular(v-else) бесплатно
    .line.short(v-if="discountPrice !== 0")
      .name Применен промокод
      .val.regular {{ discountPrice | formatPrice }}
    //- @todo promocode
    .line.short
      .name Итого
      .val {{ order.price | formatPrice }} &nbsp;
        span(v-if="isPayedPoint") (без учета доставки)
</template>
<script>
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import { currencyFormatMixin } from '~/mixins';
import ending from '~/modules/common/ending';
// import { MapMixin } from '~/mixins/map';
import { PlatformMixin } from '~/mixins/howPlatform';
import ProgressDs from '~/components/progress';
import { dateToLocale } from '~/modules/common/date';
import { phoneFormatMixin } from '~/mixins/phone';
import { progressMap, finishStatuses, orderStatuses, styleStatusesMap, deliveryStatuses } from '~/modules/orders/data';
import { DeliveryType } from '~/modules/checkout/types';
import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService } from '~/modules/popups/services/popups-service';
import BarcodePopup from '~/components/popups/barcode-popup';
import { GetFiasMixin } from '~/mixins/helpers/getFiasAddress';
import { StaticDataMixin } from '~/mixins/staticData';

export default {
  components: {
    ProgressDs,
    ButtonUi,
    IconUi,
  },
  filters: {
    formatStatus(val) {
      if (!val) {
        return '';
      }

      return orderStatuses[val];
    },
    sumPrice(skus) {
      return skus.reduce((acc, curr) => acc + curr.purchasePrice * curr.amount, 0);
    },
    sumDate(date, days) {
      if (!date) {
        return '';
      }

      const dateInMs = new Date(date).getTime();
      const daysInMs = 1000 * 60 * 60 * 24 * days;

      const totalDate = new Date(dateInMs + daysInMs);
      return `${dateToLocale(totalDate, {
        day: 'numeric',
        month: 'long',
      })} ${totalDate.getFullYear()}`;
    },
    styleFromStatus(val) {
      return styleStatusesMap[val] || 'outlined';
    },
  },
  mixins: [currencyFormatMixin, phoneFormatMixin, PlatformMixin, GetFiasMixin, StaticDataMixin],
  inject: ['media', 'isShowMapInit'],
  props: {
    order: {
      type: Object,
      default: () => undefined,
    },
    isSuccessful: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ending,
    progressMap,
    finishStatuses,
    deliveryStatuses,
    isShowMap: false,
    showTooltip: false,
  }),
  computed: {
    deliveryType() {
      if (this.order.delivery.deliveryPoint) {
        return DeliveryType.DELIVERY_POINT;
      }
      if (this.order.delivery.todayDelivery !== undefined) {
        return DeliveryType.COURIER;
      }
      return DeliveryType.RUSSIAN_POST;
    },
    isDeliveryPoint() {
      return this.deliveryType === DeliveryType.DELIVERY_POINT;
    },
    progressItems() {
      return this.progressMap[this.deliveryType].items;
    },
    progressPoint() {
      return this.progressMap[this.deliveryType].toPoint.findIndex((_) => _.includes(this.order.status)) || 0;
    },
    dateOrder() {
      const date = new Date(this.order.dateCreated);
      return `
        ${dateToLocale(date, { month: 'long', day: 'numeric' })}
        ${date.getFullYear()},
        ${dateToLocale(date, { hour: '2-digit', minute: '2-digit' })}
      `; // without "г." in year
    },
    discountPrice() {
      const total =
        this.order.orderItems.reduce((acc, curr) => acc + curr.purchasePrice * curr.amount, 0) +
        this.order.delivery.originalDeliveryPrice;
      return this.order.price - total;
    },
  },
  mounted() {
    this.isShowMap = this.isShowMapInit;
    this.showTooltip = this.isSuccessful;
  },
  methods: {
    showBarcode() {
      if (!this.order.issueCode) {
        return;
      }

      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.CENTER,
        component: BarcodePopup,
        propsData: {
          id: this.order.id,
          code: this.order.issueCode,
        },
      });
    },
    toggleMap() {
      this.isShowMap = !this.isShowMap;
    },
    switchTooltip() {
      this.showTooltip = !this.showTooltip;
    },
  },
};
</script>
<style lang="stylus">
.ds-order-properties
  .header
    display flex
    align-items center
    margin 16px 0 24px
    +Media(Mobile)
      justify-content space-between

  .title
    Text(TitleM)
    color Gray(D32)
    white-space nowrap
    margin-right 40px
    +Media(Mobile, Tablet)
      Text(TitleS)

  .status
    Text(BodyM Semibold)
    white-space nowrap
    color Green(L8)
    outline 1px solid Green(L8)
    &.success
      outline none
      color white
      background-color Green(LT8)
    &.secondary
      outline none
      color Gray(D32)
      background-color Gray(LT48)
    &.warning
      outline none
      color White()
      background-color Orange(LT2)
    min-height 32px
    padding 10px 8px

  .properties
    margin-bottom 24px
    width 100%
    color Gray(D32)
    .issue-code
      Button(Medium Outlined)
      width 100%
      margin-bottom 16px
    .line
      Text(BodyM Long)
      display flex
      margin-bottom 16px
      &.contacts
        margin-bottom 40px
      &.short
        margin-bottom 8px
      &.ru-post-barcode
        padding 10px 0
        .val  a
          color Gray(D32)
          .ui-button
            Button()
            Text(BodyM Long Semibold)
            padding 0
      &:last-child
        margin-bottom 0

      .name
        min-width 232px
        width 232px
        margin-right 40px
        &::after
          content ':'
      .code
        font-size 18px
        font-weight: 600
      .val
        Text(Semibold)
        .regular
          display block
          font-weight 400
          margin-top 4px
        .map-toggle
          Button()
          Text(CaptionM Semibold)
          padding 8px 0
          color Gray(D32)
          .slot.after
            padding-left 0
            padding-top 2px
            svg path
              fill Gray(D32)
      .code
        font-size 18px
        font-weight: 600
      +Media(Mobile, Tablet)
        flex-wrap wrap
        justify-content space-between
        .name
          margin-right 0
          margin-bottom 8px
          width auto
          min-width auto
    .map
      height 335px
      margin-bottom 24px
    .ds-progress
      +Media(Laptop, Desktop, DesktopMax)
        width 532px
        margin 44px 0 48px
      +Media(Tablet)
        width 100%
        margin 24px 0 48px
      +Media(Mobile)
        height 104px
        margin 24px 0
.tooltip-card
  position relative
  display inline-block
  width 100%

.tooltip-card .tooltip-card-text
  background-color rgba(31, 32, 38, 0.9);
  color #fff;
  border-radius 8px;
  padding 9px 12px;
  position absolute;
  z-index 1;
  bottom 120%;
  left 0;
  display flex
  justify-content space-between
  align-items center
  font-weight 400
  font-size 14px
  span
    margin-right 10px
  a.tooltip-card-link
    text-decoration none;

.tooltip-card .tooltip-card-text::after {
  content ""
  position absolute
  top 100%
  left 7px
  border-width 5px
  border-style solid
  border-color rgba(31, 32, 38, 0.9) transparent transparent transparent
}
.tooltip-card-title
  display flex
  align-items center
.tooltip-enter-active, .tooltip-leave-active {
  transition: opacity .5s;
}
.tooltip-enter, .tooltip-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.tooltip-trigger-icon
  display flex
  align-items center
  cursor pointer

.additional-list
  list-style disc inside
  font-weight normal
  margin-top 16px
</style>
