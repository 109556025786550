import { DADATA_HEADERS } from '~/api/dadata';

export enum EDadataService {
  Fio = 'fio',
  Address = 'address',
  Party = 'party',
  Email = 'email',
  Bank = 'bank',
}

export type TDadataResponse = {
  value: string;
  unrestricted_value: string;
  data: {
    value: string;
    street_type_full: string;
    street: string;
    settlement: string;
    settlement_with_type: string;
    city: string;
    region_with_type: string;
    country: string;
    [key: string]: unknown;
  };
};

const DADATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/';

export const getDadataOptions = async ({
  type,
  query,
  count = 20,
  options = {},
}: {
  type: EDadataService;
  query: string;
  count?: number;
  options?: Record<string, unknown>;
}): Promise<Array<TDadataResponse>> => {
  const fetchOptions = {
    ...DADATA_HEADERS,
    method: 'POST',
    body: JSON.stringify({ query, count, ...options }),
  };
  try {
    const response = await fetch(`${DADATA_URL}${type}`, fetchOptions);
    const { suggestions } = await response.json();
    return suggestions;
  } catch (e) {
    console.error(e);
  }
  return [];
};
