import { DirectiveOptions } from 'vue';

export const name = 'badge';

export const directive: DirectiveOptions = {
  bind(el, binding) {
    const badgeElement = document.createElement('span');
    badgeElement.classList.add('badge');
    badgeElement.innerHTML = binding.value;
    badgeElement.style.display = binding.value ? '' : 'none';

    el.classList.add('with-badge');
    el.appendChild(badgeElement);
  },
  componentUpdated(el, binding) {
    const badgeElement = el.querySelector('.badge') as HTMLSpanElement;
    if (badgeElement) {
      badgeElement.innerHTML = binding.value;
      badgeElement.style.display = binding.value ? '' : 'none';
    }
  },
  unbind(el) {
    const badgeElement = el.querySelector('.badge');
    if (badgeElement) {
      el.removeChild(badgeElement);
    }
  },
};
