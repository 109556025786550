import { FeedbackCreate } from '~/apollo-api/types';
import { client, gql } from './index';

export const saveFeedback = async (feedbackCreate: FeedbackCreate) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation SaveFeedback($feedbackCreate: FeedbackCreate) {
        saveFeedback(input: $feedbackCreate) {
          id
        }
      }
    `,
    variables: {
      feedbackCreate,
    },
  });

  return data;
};
