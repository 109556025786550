
import { Component } from 'vue-mixin-decorator';
import { Emit, Prop, Vue } from 'vue-property-decorator';

import PopupClose from '~/modules/popups/popup-close.vue';

@Component({
  components: {
    PopupClose,
  },
})
export default class MobileBottomWrapper extends Vue {
  @Prop({ type: Boolean, default: true }) withCloseButton!: boolean;
  @Prop({ type: Boolean, default: false }) isClosed!: boolean;

  mounted() {
    document.body.style.overflow = 'hidden';
  }

  beforeDestroy() {
    document.body.style.overflow = 'auto';
  }

  @Emit('close')
  onClose() {
    return null;
  }
}
