<template lang="pug">
nuxt-link.ui-component.ui-link(
  v-if="isInternal"
  :to="to"
  :exact="exact"
  :target="target"
)
  slot
a.ui-component.ui-link(
  v-else
  :href="to"
  :target="target"
)
  slot
</template>

<script>
export default {
  name: 'LinkUi',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: null,
    },
  },
  computed: {
    isInternal() {
      return typeof this.to !== 'string' || !/^\w+:/.test(this.to);
    },
  },
};
</script>
<style lang="stylus">
.ui-link
  Text(ClearLink)
</style>
