import { gql } from './index';
import { cachedQuery } from './utils';

export const getCategoryById = (categoryId: number) => {
  return cachedQuery({
    query: gql`
      query Category($categoryId: Int!) {
        category(categoryId: $categoryId) {
          id
          title
          parentList {
            id
            title
          }
          children {
            id
            title
            parent {
              title
              id
            }
          }
        }
      }
    `,
    variables: { categoryId },
    parser: (data) => data.category,
  });
};
