import Vue from 'vue';

export const PlatformMixin = Vue.extend({
  computed: {
    platform() {
      if (this.$device.isMobileOrTablet && this.$device.isApple) {
        return 'ios';
      } else if (this.$device.isMobileOrTablet && this.$device.isAndroid) {
        return 'android';
      } else {
        return 'desktop';
      }
    },
  },
  methods: {
    linkApplication(platform) {
      if (platform == 'desktop') {
        return 'https://www.pochta.ru/support/web-mobile-services/mobile-application';
      } else if (platform == 'android') {
        return 'https://play.google.com/store/apps/details?id=com.octopod.russianpost.client.android';
      } else {
        return 'https://apps.apple.com/ru/app/%D0%BF%D0%BE%D1%87%D1%82%D0%B0-%D1%80%D0%BE%D1%81%D1%81%D0%B8%D0%B8/id771700826';
      }
    },
  },
});
