<template lang="pug">
  .ds-block-profit
    .icon
      slot(name="icon")
        icon-ui(:name="icon", size="40")
    .text
      .title(v-if="title")
        slot(name="title") {{ title }}
      .subtitle
        slot(name="subtitle") {{ subtitle }}
</template>

<script>
import IconUi from '~/components/common/icon';

export default {
  components: {
    IconUi,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus">
.ds-block-profit
  display block
  padding 0.7rem
  width 25%
  .icon
    margin 0 auto 30px
    height 72px
    width 72px
    box-sizing content-box
    //- TODO: theme
    border 2px solid Gray(A15)
    border-radius 50%
    display flex
    align-items center
    justify-content center
    //- TODO: theme
    path
      fill OrangeBrand()
  .text
    text-align center
    .title
      Text(BodyL Semibold)
    .subtitle
      Text(BodyM)
      text-wrap normal
      margin-top 1rem
      margin-left 0.3rem
      margin-right 0.3rem
      +Media(Laptop)
        margin-right 2.75rem
        margin-left 2.75rem
        text-center justify

  +Media(Mobile)
    width 100%

  +Media(Tablet,Laptop)
    width 50%

  +Media(Mobile,Tablet)
    .icon
      height 40px
      width 40px
      .ui-icon
        height 24px
        width 24px
        margin 8px
    display flex
    justify-content flex-start
    padding 0
    .text
      margin-left 1rem
      margin-right 1rem
      margin-top 0.5rem
      text-align left
      width 100%
      .title
        margin-top 0.3rem
      .subtitle
        margin-left 0
        margin-right 0
        position relative
        top -0.5em
</style>
