import createPersistedState from 'vuex-persistedstate';

import { version } from '~/package.json';
// const LOCAL_STORAGE_KEYS = ['cart', 'checkout', 'user', 'main', 'viewedProducts', 'orders'];

export const LOCAL_STORAGE_KEY = 'vuex';

export default ({ store }) => {
  let oldVers = localStorage.getItem('version');
  if (version != oldVers) {
    localStorage.setItem('version', version);
    const oldStorage = localStorage.getItem('vuex');
    if (oldStorage) {
      localStorage.removeItem('vuex');
    }
  }
  createPersistedState({
    paths: [
      'catalog',
      'main.showAdult',
      'main.askAdult',
      'main.deliveryInfo',
      'main.banners',
      'main.dshopTexts',
      'main.offers',
      'checkout',
      'viewedProducts',
      'deliveryMap',
      'user',
    ],
    key: LOCAL_STORAGE_KEY,
    fetchBeforeUse: true,
  })(store);

  createPersistedState({
    paths: ['cart'],
    key: 'cart',
  })(store);
};
