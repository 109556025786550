<template lang="pug">
.checkout-address-street
  dropdown-ds(
    ref="dropdown"
    :list="streets"
    value-attribute="fias_id"
    label-attribute="name"
    :value="currentCity"
    @select="onSelect"
    @focus.native.capture="onTrigger(true)"
    @blur.native.capture="onTrigger(false)"
  )
    template(v-slot:option="props")
      div.option
        div.option-string(v-html="toBold(props.option)")
        span.option-substring {{ props.option.substring }}
    labeled-input(
      :key="keyInput"
      label="Адрес пункта выдачи"
      :is-error="!isValid && tryCheck"
      :value="filteredText"
      placeholder="Введите адрес до номера дома"
      autocomplete="false"
      @input="onInput"
      @keypress.enter="onEnter"
    )
      icon-ui.clear-btn(
        v-if="filteredText && isActive"
        name="operations/close_line"
        :size="20"
        @mousedown.native.prevent.stop="clear"
        slot="after"
      )
      icon-ui.clear-btn(
        v-if="!isActive"
        name="operations/chevron-down_small-line"
        slot="after"
      )
      icon-ui.clear-btn(
        v-if="!filteredText && isActive"
        name="operations/chevron-up_small-line"
        slot="after"
        @mousedown.native.prevent.stop="blurDropdown"
      )
  .error(v-if='!isValid && tryCheck') Вы ввели некорректный адрес пункта выдачи
  .description(:class="!isValid ? 'error-top' : ''")
    div Введите точный адрес пункта выдачи
  .ql-editor(v-html="dshopTexts?.additionalTexts[1]?.value")
</template>
<script>
import { mapState } from 'vuex';

import IconUi from '~/components/common/icon';
import InputUi from '~/components/common/input';
import DropdownDs from '~/components/dropdown';
import LabeledInput from '~/components/labeled-input';
import { StaticDataMixin } from '~/mixins/staticData';
import { toBold } from '~/utils/intersections';
import { getDadataOptions, EDadataService } from '~/api/getDadataOptions';
import { CheckoutFieldMixin } from '~/mixins/business/checkout';
export default {
  components: {
    LabeledInput,
    InputUi,
    DropdownDs,
    IconUi,
  },
  mixins: [StaticDataMixin, CheckoutFieldMixin],
  props: {
    street: {
      type: Object,
      default: () => {},
    },
    error: {
      type: String,
      default: () => '',
    },
    errorPrompt: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    isActive: false,
    keyInput: 0,
    currentCity: {
      name: '',
      fias_id: '',
    },
    filteredText: '',
    streets: [],
    field: 'comment',
    value: '',
    tryCheck: false,
  }),
  computed: {
    ...mapState('checkout', ['comment']),
    ...mapState('main', ['dshopTexts']),
    ...mapState('deliveryMap', ['fiasCities']),
    isValid() {
      if (this.filteredText.length > 0 && this.currentCity) {
        for (var i = 0; i < this.fiasCities.length; i++) {
          if (this.fiasCities[i].id === this.currentCity.fias_id) {
            return true;
          }
        }
      }
      return false;
    },
  },
  watch: {
    async filteredText(value) {
      if (value === '') {
        this.updateCity();
      }

      await this.updateAddress();
    },
    isActive(value) {
      setTimeout(() => {
        if (this.streets.length > 0) {
          if (!value && this.currentCity.name != this.filteredText && this.streets[0].name != this.filteredText) {
            this.updateCity({ name: this.filteredText, city: this.filteredText });
          } else if (!value && this.streets[0].name === this.filteredText) {
            this.updateCity(this.streets[0]);
          }
        }
      }, 300);
    },
  },
  mounted() {
    if (this.$refs.dropdown) {
      this.$refs.dropdown.select(this.currentCity);
    }
  },
  methods: {
    toBold(option) {
      return toBold(this.filteredText, option.name);
    },
    clear() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.select(undefined);
      }

      this.filteredText = '';
      this.blurDropdown();
    },
    blurDropdown() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.blur();
      }
    },
    onInput(value) {
      this.tryCheck = true;
      this.filteredText = value;
    },
    onSelect(option) {
      if (option) {
        this.filteredText = option.name;
        this.currentCity = option;
        this.blurDropdown();
      }

      this.updateCity(option);
    },
    onEnter() {
      if (this.streets.length > 0 && this.$refs.dropdown) {
        this.$refs.dropdown.select(this.streets[0]);
      }
    },

    updateCity(payload) {
      if (payload) {
        this.currentCity = payload;
        this.value = payload;
        this.$store.commit('checkout/updateComment', payload.fias_id);
      }
      this.$nextTick(() => {
        // force update
        this.keyInput++;
      });
    },
    onTrigger(active) {
      this.isActive = active;
    },
    async updateAddress() {
      try {
        this.streets = (
          await getDadataOptions({
            type: EDadataService.Address,
            query: this.filteredText,
            options: {
              from_bound: { value: 'city' },
              to_bound: {
                value:
                  this.filteredText.includes('Алчевск') || this.filteredText.includes('Мариуполь') ? 'flat' : 'house',
              },
              restrict_value: true,
            },
          })
        )
          .filter((dadataValue) => dadataValue.data.city)
          .map((dadataValue) => {
            return {
              name: `${dadataValue.data.city}${
                dadataValue.data.street_with_type
                  ? ', ' + dadataValue.data.street_with_type
                  : dadataValue.data.settlement_with_type
                  ? ', ' + dadataValue.data.settlement_with_type
                  : ''
              }${dadataValue.data.house ? ', ' + dadataValue.data.house_type + dadataValue.data.house : ''}${
                dadataValue.data.flat ? ', ' + dadataValue.data.flat_type + ' ' + dadataValue.data.flat : ''
              }`,
              fias_id: dadataValue.data.fias_id,
              substring: dadataValue.value,
            };
          });
      } catch (error) {
        console.error(error);
        this.streets = [];
      }
    },
  },
};
</script>
<style lang="stylus">
.checkout-address-street
  .ds-dropdown
    display flex
    width 100%
    align-items center
    .drop-target
    .ds-labeled
      width 100%
    .ui-input
      Input(Medium Outlined)
      display flex
      width 100%
    .clear-btn
      position relative
      right 28px
      margin-right -24px
      cursor pointer
      &:hover
        path
          fill Gray(D32)
    .option
      &-string
        color: #27282A
        font-size: 14px
        line-height: 120%
      &-substring
        color: #62656A
        font-size: 11px
        line-height: 125%
  .error
    Text(CaptionM)
    color Red()
    margin-top 8px
    .prompt
      color: #002CF0
      cursor pointer
  .description
    Text(CaptionM)
    color TextSecondary()
    margin-top 8px
    &.error-top
      margin-top 8px
</style>
