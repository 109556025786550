const findIntersectionFromStart = (a, b) => {
  for (let i = a.length; i > 0; i--) {
    const d = a.substring(0, i);
    const j = b.indexOf(d);
    if (j >= 0) {
      return { pos: j, len: i };
    }
  }
  return { pos: 0, len: 0 };
};

export const findIntersection = (a, b) => {
  let bestResult = { pos: 0, len: 0 };
  for (let i = 0; i < a.length - 1; i++) {
    const result = findIntersectionFromStart(a.substring(i), b);
    if (result) {
      if (!bestResult) {
        bestResult = result;
      } else if (result.len > bestResult.len) {
        bestResult = result;
      }
    }
    if (bestResult && bestResult.len >= a.len - i) {
      break;
    }
  }
  return bestResult;
};

export const toBold = (searched, text) => {
  if (!text) {
    return '';
  }
  if (!searched) {
    return text;
  }
  const words = searched?.toLowerCase().trim().replace(/\[|\]/g, ''); // prevent regex collision
  // .replace(/  +/g, ' '); // delete multiple spaces
  const intersection = findIntersection(words, text.toLowerCase());
  if (searched.length / intersection.len >= 2) {
    return text;
  }
  const intersectionWords = text.substring(intersection.pos, intersection.pos + intersection.len);
  text = text.replace(
    new RegExp(intersectionWords, 'i'),
    `<strong style="font-weight: bold">${intersectionWords}</strong>`,
  );
  return text;
};
