import { gql } from '../index';

export const ordersData = gql`
  fragment ordersData on Order {
    status
    id
    issueCode
    price
    dateCreated
    paymentUrl
    comment
    delivery {
      originalDeliveryPrice
      price
      ... on CourierDelivery {
        price
        address
        todayDelivery
        city {
          id
        }
      }
      ... on DpDelivery {
        deliveryPoint {
          id
          address
          latitude
          longitude
          timeFrom
          timeTo
        }
      }
      ... on RuPostDelivery {
        address
        ruPostBarcode
        ruPostId
      }
    }
    orderItems {
      id
      amount
      image {
        link(trans: PRODUCT_240) {
          high
        }
      }
      purchasePrice
      status
      sku {
        sellPrice
        fullPrice
        product {
          id
          title
          shortDescription
          category {
            title
            parentList {
              title
            }
          }
        }
        characteristicValues {
          characteristic {
            id
            title
          }
          id
          title
          value
        }
      }
      feedback {
        id
        rating
      }
      paymentPrice
    }
  }
`;
