import { gql } from '~/apollo-api';

export const defaultCardFragment = gql`
  fragment DefaultCardFragment on CatalogCard {
    adult
    favorite
    feedbackQuantity
    id
    minFullPrice
    minSellPrice
    favorite
    offer {
      due
      icon
      text
      textColor
    }
    ordersQuantity
    productId
    rating
    title
  }
`;
