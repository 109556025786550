import { render, staticRenderFns } from "./product-card-feedback.vue?vue&type=template&id=97827e3c&scoped=true&lang=pug"
import script from "./product-card-feedback.vue?vue&type=script&lang=ts"
export * from "./product-card-feedback.vue?vue&type=script&lang=ts"
import style0 from "./product-card-feedback.vue?vue&type=style&index=0&id=97827e3c&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97827e3c",
  null
  
)

export default component.exports