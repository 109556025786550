import cookie from 'cookie';
import { orderSuccessEvent, alterPurchase } from './event-order';
import { loginErrorEvent, loginSuccessEvent } from './event-login';
import { registrationRequestSms, registrationPasswordSubmit, registrationRequestSmsFailed } from './event-signup';
import { productOpenEvent } from './event-product';
import { addToCartEvent, setCatalogItemIndex, setCatalogItemListName } from './event-addtocart';
import { addToFavoriteEvent, setFavoriteItemIndex, setFavoriteItemListName } from './event-addtofavorite';
import { removeFromCartEvent } from './event-removefromcart';
import { removeFromFavoriteEvent } from './event-removefromfavorite';
import { selectProductEvent } from './event-selectproduct';
import { selectBannerEvent, viewBannerEvent } from './event-banner';
import { beginCheckoutEvent } from './event-begincheckout';
import { addShippingEvent } from './event-addshipping';
import { goToPaymentEvent } from './event-gotopayment';
import { listingEvent } from './event-listing';
import { cartOpenEvent } from './event-cart';
import { favoriteOpenEvent } from './event-favorite';
import {
  recoveryRequestSmsSuccess,
  recoverySubmitSmsFailes,
  recoverySubmitSmsRetry,
  registrationSubmitSmsSuccess,
  recoveryView,
  registrationSubmitSmsRetry,
  registrationFailed,
} from './event-personalaccount';
import { viewAuthorization } from './event-auth';

export default (context, inject) => {
  let userId = null;
  let innerHTML = `window.dataLayer=window.dataLayer||[];window.dataLayer.push({originalLocation:document.location.protocol+"//"+document.location.hostname+document.location.pathname+document.location.search});`;
  if (process.server) {
    userId = cookie.parse(context.req.headers.cookie || '')['userId'] || '';
  } else {
    const localUser = localStorage.getItem('vuex');
    if (localUser && localUser.user) {
      userId = JSON.parse(localUser).user?.customer?.id;
    }
  }

  if (userId) {
    innerHTML = `window.dataLayer=window.dataLayer||[];window.dataLayer.push({user_id:'${userId}',originalLocation:document.location.protocol+"//"+document.location.hostname+document.location.pathname+document.location.search});`;
  }

  const scriptId = 'gtm-dataLayer-init';
  const dataLayerInitScript = {
    hid: scriptId,
    innerHTML: innerHTML,
    type: 'text/javascript',
    charset: 'utf-8',
    defer: true,
  };
  if (process.client) {
    // const excludedPaths =
    //   document.location.href.includes('product') ||
    //   document.location.href.includes('cart') ||
    //   document.location.href.includes('checkout') ||
    //   document.location.href.includes('delivery-points');

    window.addEventListener('DOMContentLoaded', () => {
      // if (excludedPaths) {
      //   const callback = (mutationsList, observer) => {
      //     for (let mutation of mutationsList) {
      //       if (mutation.type === 'childList') {
      //         for (let addedNode of mutation.addedNodes) {
      //           if (addedNode.nodeType === Node.ELEMENT_NODE && addedNode.id === 'hde-contact-container') {
      //             const widget = document.getElementById('hde-contact-container');
      //             widget.style.visibility = 'hidden';
      //             observer.disconnect();
      //           }
      //         }
      //       }
      //     }
      //   };
      //   const observer = new MutationObserver(callback);
      //   const config = {
      //     childList: true,
      //   };
      //   const targetNode = document.body;
      //   observer.observe(targetNode, config);
      // }

      context.app.$gtm.init('GTM-MF65JF8');
      context.app.head.script = context.app.head.script || [];
      context.app.head.script.unshift(dataLayerInitScript);
      context.app.head.__dangerouslyDisableSanitizersByTagID =
        context.app.head.__dangerouslyDisableSanitizersByTagID || {};
      context.app.head.__dangerouslyDisableSanitizersByTagID[scriptId] = ['innerHTML'];
    });
  }

  if (process.client) {
    context.app.router.afterEach(() => {
      // if (document) {
      //   const widget = document.getElementById('hde-contact-container');
      //   const excludedPaths =
      //     document.location.href.includes('product') ||
      //     document.location.href.includes('cart') ||
      //     document.location.href.includes('checkout') ||
      //     document.location.href.includes('delivery-points');
      //   if (widget) {
      //     if (excludedPaths) {
      //       widget.style.visibility = 'hidden';
      //     } else {
      //       widget.style.visibility = 'visible';
      //     }
      //   }
      // }
      context.app.$gtm.push({
        user_id: context.app.store.state.user.customer.id || '',
        originalLocation:
          document.location.protocol +
          '//' +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
      });
    });
  }

  context.$dataLayer = {
    orderSuccessEvent,
    alterPurchase,
    loginSuccessEvent,
    productOpenEvent,
    addToCartEvent,
    setCatalogItemIndex,
    setCatalogItemListName,
    removeFromCartEvent,
    selectProductEvent,
    selectBannerEvent,
    viewBannerEvent,
    beginCheckoutEvent,
    addShippingEvent,
    goToPaymentEvent,
    listingEvent,
    cartOpenEvent,
    addToFavoriteEvent,
    setFavoriteItemIndex,
    setFavoriteItemListName,
    favoriteOpenEvent,
    removeFromFavoriteEvent,
    registrationRequestSms,
    viewAuthorization,
    registrationSubmitSmsRetry,
    registrationRequestSmsFailed,
    recoverySubmitSmsFailes,
    registrationPasswordSubmit,
    loginErrorEvent,
    recoveryView,
    recoveryRequestSmsSuccess,
    registrationSubmitSmsSuccess,
    recoverySubmitSmsRetry,
    registrationFailed,
  };
  inject('dataLayer', context.$dataLayer);
};
