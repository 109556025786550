<template lang="pug">
  .cart-footer
    //- @todo implement
    //- .similar-products
      //- .similar-products-title С этими товарами покупают
      //- .similar-products-list
        //- product-card-ui(:has-actions="false").similar-product
        //- product-card-ui(:has-actions="false").similar-product
        //- product-card-ui(:has-actions="false").similar-product
        //- product-card-ui(:has-actions="false").similar-product
    .to-checkout-wrapper
      button-ui.to-checkout(v-if="this.$store.state.cart.list.length" :disabled="hasMinPriceLimit" @click.native="clickSubmit") Перейти к оформлению
</template>

<script>
import IconUi from '~/components/common/icon';
import ButtonUi from '~/components/common/button';
import ProductCardUi from '~/components/product-card';

export default {
  components: {
    IconUi,
    ButtonUi,
    ProductCardUi,
  },

  computed: {
    hasMinPriceLimit() {
      const minPrice = this.$store.getters['main/freeDeliveryMinPrice'];
      const price = this.$store.state.cart.list.reduce(
        (sum, product) => sum + product.sku.sellPrice * product.amount,
        0,
      );

      return minPrice > price > 0;
    },
  },

  methods: {
    clickSubmit() {
      if (!this.hasMinPriceLimit) {
        this.$router.push({ name: 'checkout' });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.cart-footer
  +Media(Mobile)
    padding 0 8px
  +Media(Mobile, Tablet)
    margin-bottom 82px

  .similar-products
    +Media(Mobile, Tablet)
      margin-bottom 8px
      border-bottom 1px solid Divider()

  .similar-products-title
    Text(TitleM Semibold)
    margin 24px 0
    +Media(Mobile, Tablet)
      Text(BodyL Semibold)

  .similar-products-list
    display flex
    justify-content space-between
    +Media(Mobile, Tablet)
      flex-wrap wrap

  .similar-product
    flex-grow 0
    flex-shrink 0
    +Media(DesktopMax)
      width 310px
      &:not(:last-child)
        margin-right 40px
    +Media(Desktop)
      width 270px
      &:not(:last-child)
        margin-right 40px
    +Media(Laptop)
      width 212px
      &:not(:last-child)
        margin-right 16px
    +Media(Tablet)
      width 190px
      &:not(:last-child)
        margin-right 12px
        margin-bottom 32px

    +Media(Mobile)
      width 148px
      &:not(:nth-child(even))
        margin-right 8px
        margin-bottom 32px

  .to-checkout-wrapper
    +Media(Laptop, Desktop, DesktopMax)
      display none
    +Media(Mobile, Tablet)
      position fixed
      bottom 0
      left 0
      width 100%
      background #fff
      box-shadow 0px -1px 0px rgba(0, 0, 0, 0.1)
      .to-checkout
        Button(Primary)
        Text(BodyM Semibold)
        padding 14px
        width calc(100% - 32px)
        color #fff
        margin 8px 16px 32px
</style>
