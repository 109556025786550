import { gql } from '~/apollo-api';
import { productBadgesFragment } from '~/apollo-api/fragments/productBadges';
import { defaultCardFragment } from './defaultCard';

export const skuGroupCardFragment = gql`
  fragment SkuGroupCardFragment on SkuGroupCard {
    ...DefaultCardFragment
    photos {
      key
      link(trans: PRODUCT_540) {
        high
        low
      }
      previewLink: link(trans: PRODUCT_240) {
        high
        low
      }
    }
    badges {
      ...ProductBadgesFragment
    }
    characteristicValues {
      id
      value
      title
      characteristic {
        values {
          id
          title
          value
        }
        title
        id
      }
    }
  }
  ${defaultCardFragment}
  ${productBadgesFragment}
`;
