import Vue from 'vue';
import sizesDefault from './sizes.json';
import { TMediaSizes } from './layout-rules';

interface ISizeHelper {
  name: string;
  sizes: TMediaSizes[];
}
type TSizeHandler = (val: TMediaSizes) => boolean;

const sizesCustom: ISizeHelper[] = [
  {
    name: 'isMobileOrTablet',
    sizes: ['Mobile', 'Tablet'],
  },
];
const sizes: ISizeHelper[] = [
  ...sizesCustom,
  ...Object.keys(sizesDefault).map((s) => {
    return {
      name: 'is' + s,
      sizes: [s] as TMediaSizes[],
    };
  }),
];

const handlers: TSizeHandler[] = [];
const factoryHandler = function (sizes: TMediaSizes[]): TSizeHandler {
  return (val) => sizes.some((size) => size === val);
};

sizes.forEach((s) => {
  handlers[s.name] = factoryHandler(s.sizes);
});

export interface IMediaHelper {
  [key: string]: any;
}

export const MediaHelperMixin = Vue.extend({
  data(): IMediaHelper {
    const data = {};
    sizes.forEach((s) => {
      data[s.name] = false;
    });
    return data;
  },
  watch: {
    size(val) {
      sizes.forEach((s) => {
        this[s.name] = handlers[s.name](val);
      });
    },
  },
});
