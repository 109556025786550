<template lang="pug">
.cart-content
  template(v-if="cartProducts.length")
    h1.cart-title Корзина
    .cart-list-wrapper
      .cart-list(v-if="cartProducts.length")
        card-list(
          :available-products="availableProducts"
          :unavailable-products="unavailableProducts"
          :outProducts="outProducts"
          @remove="removeProduct"
          @change="changeAmount"
          @remove-many="removeProducts"
        )
      summary-ui.summary(
        v-if="cartProducts.length"
        :is-weight-limits-disable="isWeightLimitsDisable"
        :has-unavailable-products="hasUnavailableProducts"
        :is-load="isLoad"
      )
  template(v-else)
    no-content
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SummaryUi from './summary';
import NoContent from './no-content';
import CartCity from './cart-city.vue';
import CardList from './card-list.vue';
import { throttle } from '~/modules/common/throttle';
import { setCart } from '~/modules/cart/api';
import { AuthenticatedMixin } from '~/mixins/authenticated';

const throttleDelay = 200;

export default {
  components: {
    SummaryUi,
    NoContent,
    CartCity,
    CardList,
  },
  mixins: [AuthenticatedMixin],
  data() {
    return {
      isLoad: false,
      throttleCard: null,
    };
  },

  computed: {
    ...mapGetters('cart', ['getList']),
    cartProducts() {
      return this.getList;
    },
    isWeightLimitsDisable() {
      return true;
    },
    availableProducts() {
      return this.isWeightLimitsDisable
        ? this.cartProducts.filter((_) => _.sku.availableAmount >= 1)
        : this.cartProducts.filter((_) => _.sku.weight !== null && _.sku.availableAmount >= 1);
    },
    unavailableProducts() {
      return this.isWeightLimitsDisable ? [] : this.cartProducts.filter((_) => _.sku.weight === null);
    },
    outProducts() {
      return this.cartProducts.filter((_) => _.sku.availableAmount === 0);
    },
    hasUnavailableProducts() {
      return !!this.unavailableProducts.length;
    },
  },

  created() {
    const price = this.cartProducts.reduce((sum, product) => sum + product.sku.sellPrice * product.amount, 0);
    this.$gtm.push({ ecommerce: null });
    this.$gtm.push(this.$dataLayer.cartOpenEvent(this.cartProducts, price));
  },

  methods: {
    ...mapActions({
      setUserCart: 'cart/update',
      updateCartItem: 'cart/changeAmount',
      removeItem: 'cart/remove',
    }),
    async updateCart(cartData) {
      if (this.accessToken) {
        await this.setCartThrottle(cartData).then(() => (this.isLoad = false));
      } else {
        this.isLoad = false;
      }
    },
    async setCartThrottle(cartData) {
      clearInterval(this.throttleCard);
      try {
        this.throttleCard = setTimeout(async () => {
          await setCart(cartData);
        }, throttleDelay);
      } catch (e) {
        console.error(e);
      }
    },
    async removeProduct(id) {
      this.isLoad = true;
      await this.updateCart([{ amount: 0, skuId: id }]);
      this.removeItem(id);
    },
    async removeProducts(ids) {
      this.isLoad = true;
      const cartItemInputs = this.cartProducts.filter((_) => ids.every((id) => id !== _.sku.id));

      this.setUserCart(cartItemInputs);

      await this.updateCart(ids.map((id) => ({ amount: 0, skuId: id })));
    },
    async changeAmount(payload) {
      this.isLoad = true;
      if (payload.amount > 0 && payload.amount <= payload.product.sku.availableAmount) {
        await this.updateCart([{ amount: payload.amount, skuId: payload.product?.sku?.id }]);
        this.updateCartItem(payload);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.cart-content
  .cart-list
    flex-grow 1

    .ds-cart-city
      margin-bottom 16px

  .cart-list-wrapper
    display flex
    align-items flex-start

    +Media(Mobile, Tablet)
      flex-direction column
      align-items stretch

  .summary
    +Media(Laptop, Desktop, DesktopMax)
      position sticky
      top 20px

    +Media(DesktopMax)
      flex 377px 0 0
      margin-left 60px

    +Media(Desktop)
      flex 327px 0 0
      margin-left 60px

    +Media(Laptop)
      flex 368px 0 0
      margin-left 48px

  .cart-title
    Text(TitleM Medium Short Secondary)
    color TextPrimary()
    margin 24px 0
</style>
