export const progressMap = {
  DELIVERY_POINT: {
    items: ['Создан', 'Оплачен', 'Собирается', 'В доставке'],
    toPoint: [['CREATED'], ['ACTIVE'], ['RECEIVED', 'PROCESSED'], ['IN_DELIVERY'], ['DELIVERED']],
  },
  RUSSIAN_POST: {
    items: ['Создан', 'Оплачен', 'Собирается', 'Доставка Почтой России'],
    toPoint: [
      ['CREATED'],
      ['ACTIVE'],
      ['RECEIVED', 'PROCESSED'],
      ['IN_DELIVERY', 'IN_RP_DELIVERY', 'ISSUED', 'DELIVERED'],
    ],
  },
  COURIER: {
    items: ['Создан', 'Оплачен', 'Собирается', 'В доставке', 'Доставка курьером'],
    toPoint: [
      ['CREATED'],
      ['ACTIVE'],
      ['RECEIVED', 'PROCESSED'],
      ['IN_DELIVERY'],
      ['IN_COURIER_DELIVERY', 'DELIVERED'],
    ],
  },
};

export const orderStatuses = {
  ACTIVE: 'В обработке',
  CANCELED: 'Отменен',
  COMPLETED: 'Получен',
  CREATED: 'Ожидает оплаты',
  DELIVERED: 'Можно забирать',
  IN_DELIVERY: 'В пути',
  /* IN_COURIER_DELIVERY: 'Передан курьеру', */
  /* IN_RP_DELIVERY: 'Передано Почте России', */
  /* DELIVERED_RP: 'Ждёт вас на почте', */
  NOTIFICATION: '',
  NOT_CREATED: '',
  PROCESSED: 'Обработан',
  RECEIVED: 'В обработке',
  RETURNED: 'Возвращён',
};

export const styleStatuses = Object.freeze({
  PROCESSED: 'outlined',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
});

export const styleStatusesMap = {
  ACTIVE: styleStatuses.PROCESSED,
  CANCELED: styleStatuses.SECONDARY,
  COMPLETED: styleStatuses.SECONDARY,
  CREATED: styleStatuses.WARNING,
  DELIVERED: styleStatuses.SUCCESS,
  IN_DELIVERY: styleStatuses.PROCESSED,
  /* IN_COURIER_DELIVERY: 'success', */
  /* IN_RP_DELIVERY: 'outlined', */
  /* DELIVERED_RP: 'success', */
  NOTIFICATION: styleStatuses.SECONDARY,
  NOT_CREATED: styleStatuses.SECONDARY,
  PROCESSED: styleStatuses.PROCESSED,
  RECEIVED: styleStatuses.PROCESSED,
  RETURNED: styleStatuses.SECONDARY,
};

export const finishStatuses = ['CANCELED', 'RETURNED', 'COMPLETED', 'DELIVERED_RP'];
export const deliveryStatuses = ['COMPLETED', 'DELIVERED', 'IN_DELIVERY', 'PROCESSED', 'RECEIVED'];

export const activeStatuses = Object.keys(orderStatuses).filter((o) => !finishStatuses.includes(o));

export const delayWithoutPaidMilliSeconds = 1000 * 60 * 30; // 30 minutes
