<template lang="pug">
.ds-order-product(@click="onClick")
  .order-product-main
    .order-photo-wrapper
      img-smooth.order-photo(:src="photo" :alt="orderItem.sku.product.title || ''")
    .info
      .title {{ orderItem.sku.product.title }}
      ul.properties(v-if="orderItem.sku.characteristicValues.length")
        li(
          v-for="char in orderItem.sku.characteristicValues"
          :key="char.characteristic.title"
        )
          span.property-key {{ char.characteristic.title }}:
          span {{ char.title }}
      p.description(v-else) {{ orderItem.sku.product.shortDescription }}
      .price
        .separated {{ `${orderItem.amount} шт. x ` }}{{ truePrice(orderItem) | formatPrice }}
        .full {{ orderItem.amount * truePrice(orderItem) | formatPrice }}
  .order-feedback
    feedback-rating-ds.order-feedback-rating(v-if="orderItem.feedback" :value="orderItem.feedback.rating" readonly)
    button-ui.order-feedback-button(v-else-if="isVisibleAddFeedbackButton" @click.stop="$emit('add-feedback')") Оставить отзыв
  slot
</template>

<script>
import { OrderItemStatus } from '~/apollo-api/types';

import ButtonUi from '~/components/common/button';
import FeedbackRatingDs from '~/components/feedbacks/feedback-rating.vue';
import { currencyFormatMixin } from '~/mixins';
import { staticData } from '~/shop-config';

export default {
  components: {
    ButtonUi,
    FeedbackRatingDs,
  },
  mixins: [currencyFormatMixin],
  props: {
    orderItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isShowReviews: staticData.isShowReviews,
  }),
  computed: {
    photo() {
      return (
        this.orderItem?.image?.link?.high ||
        (this.orderItem.sku?.photos ? this.orderItem.sku?.photos[0].link.high : this.orderItem.sku?.photo.link.high)
      );
    },
    isVisibleAddFeedbackButton() {
      return [OrderItemStatus.COMPLETED, OrderItemStatus.RETURNED].includes(this.orderItem.status);
    },
  },
  methods: {
    onClick() {
      const { href } = this.$router.resolve({ name: 'product', params: { id: this.orderItem.sku.product.id } });
      window.open(href, '_blank');
    },
    truePrice(order) {
      return order.purchasePrice
        ? order.purchasePrice
        : order.sku.sellPrice
        ? order.sku.sellPrice
        : order.sku.fullPrice;
    },
  },
};
</script>
<style lang="stylus">
.ds-order-product
  width 100%
  cursor pointer
  +Media(Mobile)
    padding 8px 0
  +Media(DesktopMax, Desktop, Laptop, Tablet)
    padding 16px 0
    display flex
    align-items center

  .order-product-main
    flex-grow 1
    display flex
    align-items center

  .order-photo-wrapper
    flex-shrink 0
    width 96px
    height 128px
    +Media(Mobile, Tablet)
      width 72px
      height 96px

    .order-photo
      width 100%
      height 100%
      object-fit contain

  .info
    display block
    width 100%
    margin-left 24px
    .title
      Text(BodyM)

    .properties
      Text(BodyS Short)
      color TextSecondary()
      margin 8px 0

    .description
      Text(CaptionM)
      color TextSecondary()
      margin-top 9px
      overflow hidden
      display -webkit-box
      -webkit-line-clamp 2
      line-clamp 2
      -webkit-box-orient vertical

    .price
      display flex
      justify-content space-between
      align-items center
      margin-top 10px
      .separated
        Text(CaptionM)
        color TextSecondary()

      .full
        Text(BodyM Semibold)
        text-align right

  .order-feedback
    +Media(Mobile)
      margin-top 8px
    +Media(DesktopMax, Desktop, Laptop, Tablet)
      margin-left 24px
      flex-basis 130px
      flex-shrink 0

  .order-feedback-rating
    display flex
    justify-content center

  .order-feedback-button
    Button(Outlined Small)
    +Media(Mobile)
      width 100%
</style>
