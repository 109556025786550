import Vue from 'vue';
import { mapState } from 'vuex';
export const GetFiasMixin = Vue.extend({
  computed: {
    ...mapState('deliveryMap', ['fiasCities']),
    getFiasName() {
      const flatFias = this.fiasCities.flatMap((city) => city.points);
      const name = flatFias.find((_) => _.id === this.order.comment);
      return name ? name.value : this.order.comment;
    },
    getFiasWorkTime() {
      const flatFias = this.fiasCities.flatMap((city) => city.points);
      const name = flatFias.find((_) => _.id === this.order.comment);
      if (name && name.workingTime) {
        return name.workingTime;
      } else {
        return 'Уточните у технической поддержки';
      }
    },
    additionalFields() {
      const flatFias = this.fiasCities.flatMap((city) => city.points);
      const name = flatFias.find((_) => _.id === this.order.comment);
      return name && name.additionalField ? name.additionalField.split('\n').filter((item) => item.length > 0) : null;
    },
    isPayedPoint() {
      const flatFias = this.fiasCities.flatMap((city) => city.points);
      const name = flatFias.find((_) => _.id === this.order.comment);
      return name && name.isPayed;
    },
  },
});
