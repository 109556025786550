import { gql } from '../index';

export const skuFields = gql`
  fragment skuFields on Sku {
    id
    product {
      id
      title
      shortDescription
    }
    availableAmount
    characteristicValues {
      characteristic {
        id
        title
      }
      title
      value
    }
    sellPrice
    fullPrice
    photos {
      link(trans: PRODUCT_240) {
        high
      }
    }
  }
`;
