
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService, TOpenedPopups } from '~/modules/popups/services/popups-service';
import PopupCenterWrapper from '~/modules/popups/wrappers/popup-center-wrapper.vue';
import PopupTopRightWrapper from '~/modules/popups/wrappers/popup-top-right-wrapper.vue';
import PopupTopRightNotify from '~/modules/popups/wrappers/popup-top-right-notify.vue';
import PopupBottomRightWrapper from '~/modules/popups/wrappers/popup-bottom-right-wrapper.vue';

@Component({
  name: 'popups-container',
})
export default class PopupsContainer extends Vue {
  unsubscribe$ = new Subject<boolean>();
  popups: TOpenedPopups = {};
  mapTypeComponent = {
    [PopupsContainerModel.ETypeWrapper.CENTER]: PopupCenterWrapper,
    [PopupsContainerModel.ETypeWrapper.TOP_RIGHT_STICKY]: PopupTopRightWrapper,
    [PopupsContainerModel.ETypeWrapper.TOP_RIGHT_NOTIFY]: PopupTopRightNotify,
    [PopupsContainerModel.ETypeWrapper.BOTTOM_RIGHT]: PopupBottomRightWrapper,
  };

  get hasPopups(): boolean {
    return Object.keys(this.popups).length > 0;
  }

  get popupTopRightNotify(): boolean {
    if (Object.keys(this.popups).length > 0) {
      return Object.keys(this.popups)[0] == 'PopupTopRightNotify';
    }
    return false;
  }

  @Watch('$route', { deep: true })
  onRouteChange(newRoute, oldRoute) {
    if (newRoute.name !== oldRoute.name) {
      PopupsService.closeRouteChangePopups();
    }
  }

  created() {
    if (process.client) {
      PopupsService.openedPopupsStream$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((openedPopups: TOpenedPopups) => (this.popups = { ...openedPopups }));
    }
  }

  beforeDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
