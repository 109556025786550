import { DADATA_HEADERS } from '~/api/dadata';

export type GeoDadataResponse = {
  value: string;
  unrestricted_value: string;
  data: {
    city: string;
    [key: string]: unknown;
  };
};

export type QueryGeoData = {
  lat: number;
  lon: number;
};

const DADATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address';

export const getDadataGeoAdress = async (query: QueryGeoData): Promise<Array<GeoDadataResponse>> => {
  const fetchOptions = {
    ...DADATA_HEADERS,
    method: 'POST',
    body: JSON.stringify(query),
  };
  try {
    const response = await fetch(`${DADATA_URL}`, fetchOptions);
    const { suggestions } = await response.json();
    return suggestions;
  } catch (e) {
    console.error(e);
  }
  return [];
};
