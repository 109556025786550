
import Vue, { PropType } from 'vue';

import { SkuGroupCardFragment } from '~/apollo-api/types';

import ProductCardDs from '~/components/product-card.vue';

import { IFormattedProduct } from '~/modules/search/models';

import { staticData } from '~/shop-config';
import { observerMixin } from '~/plugins/gtm-datalayer/observer';

export default Vue.extend({
  components: {
    ProductCardDs,
  },
  mixins: [observerMixin],
  props: {
    products: {
      type: Array as PropType<SkuGroupCardFragment[] | null>,
      default: null,
    },
    itemListName: {
      type: String,
      default: null,
    },
  },
  computed: {
    formattedProducts(): IFormattedProduct[] {
      if (!this.products) {
        return [];
      }

      return this.products.map((product, index) => {
        const catalogCard = product;
        return {
          index,
          id: catalogCard.productId,
          title: catalogCard.title,
          photos: catalogCard.photos,
          minSellPrice: catalogCard.minSellPrice,
          minFullPrice: catalogCard.minFullPrice,
          skuGroupId: catalogCard.id,
          skuGroup: {
            characteristicIds: catalogCard.characteristicValues.map((value) => value.characteristic.id),
            characteristicValueIds: catalogCard.characteristicValues.map((value) => value.id),
          },
          feedbackQuantity: catalogCard.feedbackQuantity,
          rating: catalogCard.rating,
          badges: staticData.isShowBadges ? catalogCard.badges : [],
          favorite: catalogCard.favorite,
          characteristic: catalogCard.characteristicValues,
        };
      });
    },
  },
  mounted() {
    const price = this.formattedProducts.reduce((sum, product) => sum + product.minSellPrice * 1, 0);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.$gtm.push({ ecommerce: null });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.$gtm.push(this.$dataLayer.favoriteOpenEvent(this.formattedProducts, price));
  },
  methods: {
    observerIntersect() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$gtm.push({ ecommerce: null });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$gtm.push(this.$dataLayer.listingEvent(this.formattedProducts, this.itemListName));
    },
  },
});
