import { gql } from '../index';

export const cartData = gql`
  fragment cartData on CartItem {
    amount
    sku {
      availableAmount
      id
      fullPrice
      sellPrice
      skuTitle
      photo {
        link(trans: PRODUCT_240) {
          high
        }
      }
      characteristicValues {
        title
        value
        characteristic {
          title
        }
      }
      product {
        id
        title
        shortDescription
        category {
          id
          title
          parentList {
            id
            title
          }
        }
      }
      weight
    }
  }
`;
