
import Vue, { PropType } from 'vue';
import { EUploadImageTags, IUploadedImage, uploadImage } from '~/api/images';
import { saveFeedback } from '~/apollo-api/saveFeedback';

import { getOrder_order_orderItems } from '~/apollo-api/types';

import PopupClose from '~/modules/popups/popup-close.vue';

import { StaticDataMixin } from '~/mixins/staticData';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';
import PhotoUploaderUi from '~/components/common/photo-uploader.vue';
import TextareaUi from '~/components/common/textarea.vue';
import CheckboxUi from '~/components/common/checkbox.vue';
import FeedbackRatingDs from '~/components/feedbacks/feedback-rating.vue';
import AddFeedbackPopupSuccess from '~/components/popups/add-feedback-popup-success.vue';
import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService } from '~/modules/popups/services/popups-service';

interface IAddFeedbackPopupPropsData {
  orderItems: getOrder_order_orderItems[];
  orderItemIndex: number;
}

const RATING_VALUE: Record<number, string> = {
  1: 'Ужасно',
  2: 'Плохо',
  3: 'Нормально',
  4: 'Хорошо',
  5: 'Отлично',
};
const MAX_FILES_COUNT = 4;

const DEFAULT_RATING = 0;
const DEFAULT_FORM = {
  content: '',
  pros: '',
  cons: '',
  anonymous: false,
};

export default Vue.extend({
  name: 'AddFeedbackPopup',
  components: {
    PopupClose,
    PhotoUploaderUi,
    ButtonUi,
    IconUi,
    TextareaUi,
    CheckboxUi,
    FeedbackRatingDs,
  },
  mixins: [StaticDataMixin],
  props: {
    propsData: {
      type: Object as PropType<IAddFeedbackPopupPropsData>,
      required: true,
    },
  },
  data() {
    return {
      rating: DEFAULT_RATING,
      form: DEFAULT_FORM,
      files: [] as Array<IUploadedImage>,
      isSubmitting: false,
      isSuccess: false,
      currentIndex: 0,
    };
  },
  computed: {
    ratingValue(): string {
      return RATING_VALUE[this.rating];
    },
    disabledFileUploader(): boolean {
      return this.files.length >= MAX_FILES_COUNT;
    },
    disabledSubmit(): boolean {
      return !this.rating || this.isSubmitting;
    },
    filesKeys(): string[] {
      return this.files.map((file) => file.key);
    },
    filesUrls(): string[] {
      return this.files.map((file) => file.originalUrl);
    },
  },
  watch: {
    currentIndex() {
      if (this.isSuccess) {
        this.$el.closest('.popup')?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }

      this.rating = DEFAULT_RATING;
      this.form = DEFAULT_FORM;
      this.files = [];
    },
  },
  created() {
    this.currentIndex = this.propsData.orderItemIndex;
  },
  methods: {
    async onFileUpload(file) {
      try {
        const response = await uploadImage(file, [EUploadImageTags.Feedback]);

        if (response) {
          this.files = [...this.files, response];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onSubmit() {
      this.isSubmitting = true;

      try {
        await saveFeedback({
          orderItemId: this.propsData.orderItems[this.currentIndex].id,
          rating: this.rating,
          photos: this.filesKeys,
          ...this.form,
        });
        this.isSuccess = true;

        this.currentIndex = this.propsData.orderItems.findIndex(
          (orderItem, index) => index > this.currentIndex && !orderItem.feedback,
        );
        if (this.currentIndex == -1) {
          PopupsService.open({
            type: PopupsContainerModel.ETypeWrapper.CENTER,
            component: AddFeedbackPopupSuccess,
            propsData: {
              withCloseButton: false,
            },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.form.content = '';
        this.form.pros = '';
        this.form.cons = '';
        this.form.anonymous = false;
        this.isSubmitting = false;
      }
    },
    onClose() {
      this.$emit('close', this.isSuccess);
    },
  },
});
