
import VueBarCode from 'vue-barcode';
import { Component, Prop, Vue } from 'vue-property-decorator';

export type TBarCodePopupProps = {
  id: string;
  code: string;
};

@Component({
  components: {
    VueBarCode,
  },
})
export default class BarcodePopup extends Vue {
  @Prop(Object) propsData!: TBarCodePopupProps;

  get correctCode(): string {
    return `10-${Array(10 - this.propsData.id.toString().length)
      .fill(0)
      .join('')}${this.propsData.id}`;
  }
}
