<template lang="pug">
.faq-page
  .ui-wrapper
    .ql-editor(v-if="text && text.faq" v-html="text.faq")
</template>
<script>
import { dshopTexts } from '~/apollo-api/dshopTexts';
import { SeoDataMixin } from '~/mixins/seoData';

export default {
  mixins: [SeoDataMixin],

  data() {
    return {
      text: null,
    };
  },
  head() {
    return this.seoData[this.seoDataPages.ALL](this.$route.path);
  },

  mounted() {
    this.dshopTexts();
  },
  methods: {
    async dshopTexts() {
      this.text = await dshopTexts('faq');
    },
  },
};
</script>
<style lang="stylus">
.faq-page
  margin-top 25px
</style>
