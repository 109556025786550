import { uri } from '~/apollo-api/client/http-link';
import { flattenCategory } from '~/utils/flattenCategory';

export const state = () => ({
  categories: [],
  promoCategories: [],
});

export const mutations = {
  updateItem(state, { name, value }) {
    state[name] = value;
  },
  updateState(state, payload) {
    state = payload;
  },
};

export const actions = {
  updateItem({ commit }, payload) {
    commit('updateItem', payload);
  },
  updateOffers({ commit }, payload) {
    commit('updateOffers', payload);
  },
};

export const getters = {
  sortedCategories: (state) =>
    [...state.categories].filter(({ children }) => {
      children = children.filter(({ children }) => !children || !!children.length);
      return children.length > 0;
    }),
  flattenCategories: (state) => {
    return flattenCategory(state.categories);
  },
  flattenSortByDesc: (state) => {
    const electronicsId = !uri.includes('dev') ? '3286' : '35642';
    const appliancesId = !uri.includes('dev') ? '628' : '32911';
    const electronics = [state.categories.find((parent) => parent.id == electronicsId)];
    const appliances = [state.categories.find((parent) => parent.id == appliancesId)];
    return flattenCategory([...electronics, ...appliances]);
  },
};
