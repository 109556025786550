import { GetSuggestions_getSuggestions_blocks } from '~/apollo-api/types';

export enum ESuggestBlocks {
  Cache = 'CacheHistorySuggestionsBlock',
  Text = 'TextSuggestionsBlock',
  Popular = 'PopularSuggestionsBlock',
}

export interface ICacheHistorySuggestionsBlock {
  __typename: ESuggestBlocks.Cache;
  values: string[] | null;
}

export type TSuggestionsBlocks = GetSuggestions_getSuggestions_blocks | ICacheHistorySuggestionsBlock;

export type TUniversalSuggestionsBlockValues = string;

export interface ISuggestWindowMedias {
  mobile: number;
  desktop: number;
}

export type TSuggestConfig = Record<ESuggestBlocks, ISuggestWindowMedias>;

export type TUnionInterfaceBlocks = ITextSuggest;

export type TUnionTypeBlocks = {
  type: ESuggestBlocks;
  values: TUnionInterfaceBlocks[];
};

export interface IBaseSuggest {
  title: string;
  isInFocus?: boolean;
  isVisible?: boolean;
  suggestId?: number;
}

export type TTextSuggest = ESuggestBlocks.Text | ESuggestBlocks.Cache | ESuggestBlocks.Popular;

export interface ITextSuggest extends IBaseSuggest {
  type: TTextSuggest;
}

export interface ITextSuggestBlock {
  icon: string;
  title: string;
  clearable?: boolean;
}
