import {
  MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_badges,
  MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_photos,
} from '~/apollo-api/types';

export interface IFormattedProduct {
  id: number;
  title: string;
  photos: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_photos[];
  minSellPrice: number;
  minFullPrice: number;
  skuGroupId: number;
  skuGroup: {
    characteristicIds: number[];
    characteristicValueIds: number[];
  };
  feedbackQuantity: number;
  rating: number;
  badges: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_badges[];
}

export enum ESkuGroupType {
  CharacteristicId = 'CID',
  CharacteristicValueId = 'CVID',
}
