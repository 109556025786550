<template lang="pug">
.ds-card-list
  .card-list
    shop-card-ui.card-item(
      v-for="product in availableProducts"
      :key="product.sku.id"
      :product="product"
      v-on="$listeners"
    )
  template(v-if="unavailableProducts.length")
    .not-available-plate
      .label Недоступны для заказа
      button-ui(@click="deleteAllUnavailable") Удалить
    .card-list-unavailable
      shop-card-ui.card-item(
        v-for="product in unavailableProducts"
        :key="product.sku.id"
        :product="product"
        :is-not-available="true"
        v-on="$listeners"
      )
  template(v-if="outProducts.length")
    .not-available-plate
      .label Невозможно купить
    .card-list-unavailable
      shop-card-ui.card-item(
        v-for="product in outProducts"
        :key="product.sku.id"
        :product="product"
        :is-not-available="true"
        v-on="$listeners"
      )
</template>

<script>
import ButtonUi from '~/components/common/button';
import ShopCardUi from './card';

export default {
  components: {
    ButtonUi,
    ShopCardUi,
  },

  props: {
    availableProducts: {
      default: () => [],
      type: Array,
    },
    unavailableProducts: {
      default: () => [],
      type: Array,
    },
    outProducts: {
      default: () => [],
      type: Array,
    },
  },

  methods: {
    deleteAllUnavailable() {
      this.$emit(
        'remove-many',
        this.unavailableProducts.map((product) => product.sku.id),
      );
    },
  },
};
</script>

<style lang="stylus">
.ds-card-list
  .ds-shop-card:not(:last-child)
    border-bottom 1px solid Divider()

  .not-available-plate
    width 100%
    height 52px
    display flex
    justify-content space-between
    padding 17px 16px
    margin-top 9px
    background #F2F4F7

    .label
      Text(BodyM)

    .ui-button
      Button()
      Text(BodyM)
      color Red()
</style>
