
import Vue, { Component as TComponent } from 'vue';
import { Inject, Prop, Watch, Component } from 'vue-property-decorator';

import { GetSuggestions_getSuggestions_blocks, GetSuggestionsInput } from '~/apollo-api/types';
import { getSuggestions } from '~/apollo-api/getSuggestions';

import { TMedia } from '~/plugins/layoutable/media';

import { SUGGEST_CONFIG } from '~/modules/search-suggestions/constants';
import {
  ESuggestBlocks,
  ICacheHistorySuggestionsBlock,
  TSuggestionsBlocks,
  TUnionInterfaceBlocks,
  TUnionTypeBlocks,
} from '~/modules/search-suggestions/models';
import { unionBlockMapper } from '~/modules/search-suggestions/utils';

import { BlockBase, BlockCategory } from './blocks';

const DEBOUNCE_TIME = 300;
const VISIBLE_RATIO_THRESHOLD = 0.5;

const SHORT_VIEW_SUGGEST_BLOCK_TYPES: ESuggestBlocks[] = [
  ESuggestBlocks.Cache,
  ESuggestBlocks.Text,
  ESuggestBlocks.Popular,
];

@Component
export default class SearchSuggestionsBlock extends Vue {
  @Inject('media') media!: TMedia;

  @Prop({
    type: String,
    default: '',
  })
  searchQuery!: string;

  isUpdatingData = false;
  searchResult: GetSuggestions_getSuggestions_blocks[] = [];
  unitedResult: TSuggestionsBlocks[] = [];
  multilevelResults: TUnionTypeBlocks[] = [];
  // eslint-disable-next-line no-undef
  requestTimeout: NodeJS.Timeout | null = null;
  flatResults: TUnionInterfaceBlocks[] = [];
  suggestIndex = -1;
  observer: IntersectionObserver | null = null;
  isMouseHoverEnabled = true;
  alreadyIntersectedSuggests: number[] = [];
  $store!: any;

  get isSuggestCountMoreThanViewBlock() {
    const visibleCount = this.flatResults.filter((item) => item.isVisible).length;

    return visibleCount < this.flatResults.length;
  }

  selectSuggestion(block: TUnionInterfaceBlocks) {
    this.$emit('select-suggestion', block);
  }

  moveScrollPosition(index: number) {
    if (index > -1 && this.flatResults[index].isInFocus && !this.flatResults[index].isVisible) {
      const scrollable = this.$refs.scrollable as HTMLElement;
      const scrollableHeight: number = scrollable.offsetHeight;
      const target = document.querySelector(`.search-suggestions-block .cell-${this.flatResults[index].suggestId}`);
      const targetSpaceFormTop: number = (target as HTMLElement).offsetTop;
      const targetHeight: number = (target as HTMLElement).offsetHeight;
      const isElementHidden: boolean = targetSpaceFormTop + targetHeight > scrollableHeight;

      scrollable.scrollTo({
        top: isElementHidden ? targetSpaceFormTop + targetHeight - scrollableHeight : targetSpaceFormTop,
      });
    }
  }

  updateFocusSuggest(index: number): void {
    this.flatResults = this.flatResults.map((item) => ({
      ...item,
      isInFocus: false,
    }));

    if (index > -1) {
      this.flatResults[index].isInFocus = true;
    }

    this.moveScrollPosition(index);
    this.prepareSuggests();
  }

  keyUp() {
    this.isMouseHoverEnabled = !this.isSuggestCountMoreThanViewBlock;
    const totalSuggests = this.flatResults.length - 1;

    if (this.suggestIndex === -1) {
      this.suggestIndex = totalSuggests;
    } else if (this.suggestIndex > 0) {
      this.suggestIndex -= 1;
    } else if (this.suggestIndex === 0) {
      this.suggestIndex = totalSuggests;
    }

    this.updateFocusSuggest(this.suggestIndex);
    this.$emit('focus-suggestion', this.flatResults[this.suggestIndex]);
  }

  keyDown() {
    this.isMouseHoverEnabled = !this.isSuggestCountMoreThanViewBlock;
    const totalSuggests = this.flatResults.length - 1;

    if (this.suggestIndex === -1) {
      this.suggestIndex = 0;
    } else if (this.suggestIndex < totalSuggests) {
      this.suggestIndex += 1;
    } else if (this.suggestIndex === totalSuggests) {
      this.suggestIndex = 0;
    }

    this.updateFocusSuggest(this.suggestIndex);
    this.$emit('focus-suggestion', this.flatResults[this.suggestIndex]);
  }

  mouseHover(event: TUnionInterfaceBlocks) {
    if (this.isMouseHoverEnabled) {
      this.suggestIndex = this.flatResults.findIndex((_) => _.suggestId === event.suggestId);
      this.updateFocusSuggest(this.suggestIndex);
    }
  }

  getComponentByBlockType(blockType: TUnionTypeBlocks): TComponent {
    const components = {
      [ESuggestBlocks.Cache]: BlockBase,
      [ESuggestBlocks.Text]: BlockBase,
      [ESuggestBlocks.Popular]: BlockBase,
    };

    return components[blockType.type];
  }

  async getSuggests(inputValue: string): Promise<GetSuggestions_getSuggestions_blocks[]> {
    const windowMedia = this.media.isMobileOrTablet ? 'mobile' : 'desktop';

    const suggestionsQuery: GetSuggestionsInput = {
      text: inputValue,
      textSuggestionsLimit: SUGGEST_CONFIG[ESuggestBlocks.Text][windowMedia],
      popularSuggestionsLimit: SUGGEST_CONFIG[ESuggestBlocks.Popular][windowMedia],
    };

    try {
      return (await getSuggestions(suggestionsQuery)) as GetSuggestions_getSuggestions_blocks[];
    } catch (e) {
      return [];
    }
  }

  getCacheSuggestionBlock(inputValue: string): ICacheHistorySuggestionsBlock {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cache: string[] = this.$store.state.user.searchHistory;
    const results: string[] = cache.filter((_) => _.includes(inputValue.toLowerCase()));
    const cacheLimit: number = SUGGEST_CONFIG[ESuggestBlocks.Cache][this.media.isMobileOrTablet ? 'mobile' : 'desktop'];

    return {
      __typename: ESuggestBlocks.Cache,
      values: results.slice(0, cacheLimit) as string[] | null,
    };
  }

  updateResultsWithCache(): void {
    this.unitedResult = [];
    const cache = this.getCacheSuggestionBlock(this.searchQuery);

    this.searchResult.map((block) => {
      if (block.__typename === ESuggestBlocks.Text) {
        return {
          ...block,
          values: block.values?.filter((item) => !cache.values?.includes(item)),
        };
      }
      if (block.__typename === ESuggestBlocks.Popular) {
        return {
          ...block,
          popularSuggestions: block.popularSuggestions?.filter((item) => !cache.values?.includes(item)),
        };
      }
    });

    this.unitedResult = [cache, ...this.searchResult];

    if (!this.searchQuery) {
      this.unitedResult = this.unitedResult.filter((block) =>
        SHORT_VIEW_SUGGEST_BLOCK_TYPES.includes(block.__typename as ESuggestBlocks),
      );
    }
  }

  makeResultsFlat(): void {
    this.flatResults = [];

    this.unitedResult.forEach((item) => {
      this.flatResults = [...this.flatResults, ...unionBlockMapper(item)];
    });

    this.flatResults = this.flatResults.map((value, index) => {
      return {
        ...value,
        isInFocus: false,
        isVisible: false,
        suggestId: index + 1,
      };
    });
  }

  makeResultsMultilevel(results: TUnionInterfaceBlocks[]): TUnionTypeBlocks[] {
    const suggestsOrder = this.unitedResult.map((item) => item.__typename);

    const blocks: TUnionTypeBlocks[] = Object.values(ESuggestBlocks).map((typename) => ({
      type: typename,
      values: results.filter((_) => _.type === typename),
    }));

    return blocks.sort((blockA, blockB) => suggestsOrder.indexOf(blockA.type) - suggestsOrder.indexOf(blockB.type));
  }

  prepareSuggests(): void {
    this.multilevelResults = this.makeResultsMultilevel(this.flatResults).filter(
      (_: TUnionTypeBlocks) => _.values?.length,
    );
    this.isUpdatingData = false;
  }

  async init() {
    this.alreadyIntersectedSuggests = [];
    this.suggestIndex = -1;
    if (window.IntersectionObserver && this.observer) {
      this.observer.disconnect();
    }
    this.searchResult = await this.getSuggests(this.searchQuery);
    this.updateResultsWithCache();
    this.makeResultsFlat();
    this.prepareSuggests();
    await this.addObserver();
  }

  created() {
    this.init();
  }

  async addObserver() {
    await this.$nextTick();
    if (window.IntersectionObserver && Boolean(this.multilevelResults?.length)) {
      const options = {
        root: this.$refs.scrollable as HTMLElement,
        rootMargin: '0px',
        threshold: VISIBLE_RATIO_THRESHOLD,
      };

      this.observer = new IntersectionObserver(this.onIntersecting, options);
      const cells = document.querySelectorAll('.cell');

      cells.forEach((el) => this.observer?.observe(el));
    }
  }

  onIntersecting(entries) {
    entries.forEach((entry) => {
      const classList = entry.target.classList;
      const targetClassName = Array.from(classList) as string[];
      const id = targetClassName.find((text) => text.includes('cell-'))?.split('-')[1];

      if (!this.flatResults.length) return;
      const index = this.flatResults.findIndex((item) => item.suggestId === Number(id));

      this.flatResults[index]['isVisible'] = entry.isIntersecting;

      if (!this.alreadyIntersectedSuggests.includes(this.flatResults[index].suggestId as number)) {
        this.alreadyIntersectedSuggests.push(this.flatResults[index].suggestId as number);
      }
    });
  }

  onScroll() {
    if (this.media.isMobileOrTablet) {
      this.$emit('mobile-scroll');
    }
  }

  beforeDestroy() {
    if (window.IntersectionObserver && !!this.observer) {
      this.observer.disconnect();
    }
  }

  @Watch('searchQuery')
  searchQueryWatcher() {
    this.isUpdatingData = true;
    if (this.requestTimeout) {
      clearTimeout(this.requestTimeout);
    }
    this.requestTimeout = setTimeout(() => {
      this.init();
    }, DEBOUNCE_TIME);
  }

  @Watch('$store.state.user.searchHistory')
  cacheWatcher() {
    this.updateResultsWithCache();
    this.makeResultsFlat();
    this.prepareSuggests();
  }

  @Watch('flatResults.length')
  flatResultsLength(suggestCount) {
    this.$emit('has-any-suggests', !!suggestCount);
  }
}
