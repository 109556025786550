export const phoneFormat = (phone, format = '+7 (###) ###-##-##', marker = '#') => {
  let phoneIndex = 0;
  let formatIndex = 0;
  format = Array.from(format);
  for (; formatIndex < format.length; formatIndex++) {
    if (format[formatIndex] === marker) {
      format[formatIndex] = phone[phoneIndex];
      phoneIndex++;
      if (phoneIndex === phone.length) {
        break;
      }
    }
  }
  return format.slice(0, formatIndex + 1).join('');
};
