import { mapProductCartItem } from './event-addtocart';

export const cartOpenEvent = function (orderItems, price) {
  return {
    event: 'view_cart',
    ecommerce: {
      value: price / 100,
      currency: 'RUB',
      items: orderItems.map((item) => {
        return mapProductCartItem(item.sku.product, item.sku, item.amount);
      }),
    },
  };
};
