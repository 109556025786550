<template lang="pug">
client-only
  .cabinet-page
    .ui-wrapper
      .sidebar(v-if="!isSuccessful")
        menu-vertical(
          :menu="menu"
          :current-menu-item="currentMenuItem"
        )
      .content
        .title-menu(v-if="!media.isMobileOrTablet && !isSuccessful") {{ currentMenuItem.title }}
        nuxt-child
</template>

<script>
import MenuVertical from '~/components/menu-vertical';
export default {
  name: 'Cabinet',
  components: {
    MenuVertical,
  },
  inject: ['media'],
  middleware: 'authenticated',
  data() {
    return {
      isSuccessful: false,
    };
  },
  computed: {
    currentMenuItem() {
      return this.menu.find((_) => _.link.name === this.$route.name || _.children?.includes?.(this.$route.name));
    },
    menu() {
      return [
        {
          link: { name: 'cabinet-orders' },
          title: 'Мои заказы',
          children: ['cabinet-order'],
        },
        // TODO: бэк не готов
        // {
        //   link: { name: 'cabinet-feedbacks' },
        //   title: 'Мои отзывы',
        // },
        {
          link: { name: 'cabinet-settings' },
          title: 'Настройки профиля',
        },
        {
          link: '#',
          title: 'Выйти',
          handler: () => {
            this.$store.dispatch('user/logOut');
            this.$store.dispatch('main/cleanProductFavoriteStatus');
            this.$store.dispatch('viewedProducts/remove');
            this.$router.push({ name: 'signin' });
          },
        },
      ];
    },
  },
  watch: {
    '$route.path'() {
      this.isSuccessful = this.hasSuccessfulParam();
    },
  },
  created() {
    this.isSuccessful = this.hasSuccessfulParam();
  },
  methods: {
    hasSuccessfulParam() {
      return Boolean(this.$route.query.successful);
    },
  },
};
</script>

<style lang="stylus">
.cabinet-page
  margin-top 25px

  .ui-wrapper
    display flex
    +Media(Tablet, Mobile)
      flex-direction column

  .sidebar
    flex-shrink 0
    .ds-menu-vertical
      +Media(Laptop, Desktop, DesktopMax)
        position sticky
        top 20px
    +Media(DesktopMax)
      min-width 290px
      margin-right 60px
    +Media(Desktop, Laptop)
      min-width 250px
      margin-right 60px

  .content
    flex-grow 1
    .title-menu
      Text(TitleL Medium Secondary)
      +Media(Tablet, Mobile)
        Text(TitleM Medium Short Secondary)
</style>
