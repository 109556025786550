import { client } from '~/apollo-api/client';
import { GetSuggestions, GetSuggestions_getSuggestions, GetSuggestionsInput } from '~/apollo-api/types';
import { gql } from './index';

export const getSuggestions = async (
  getSuggestionsInput: GetSuggestionsInput,
): Promise<GetSuggestions_getSuggestions['blocks']> => {
  const {
    data: {
      getSuggestions: { blocks },
    },
  } = await client.query<GetSuggestions>({
    query: gql`
      query GetSuggestions($getSuggestionsInput: GetSuggestionsInput!) {
        getSuggestions(query: $getSuggestionsInput) {
          blocks {
            ... on PopularSuggestionsBlock {
              popularSuggestions
            }
            ... on TextSuggestionsBlock {
              values
            }
          }
        }
      }
    `,
    variables: {
      getSuggestionsInput,
    },
  });

  return blocks;
};
