import { mapProductItem } from './event-product';
import { LISTITEMS } from './listitems';

export const selectProductEvent = function (product, sku, itemListName, itemIndex) {
  let item = mapProductItem(product, sku);

  if (null !== itemIndex) {
    item.index = itemIndex;
  }

  const itemName = LISTITEMS[itemListName] ?? itemListName;

  return {
    event: 'select_item',
    ecommerce: {
      item_list_name: itemName,
      items: [item],
    },
  };
};
