import { mapCategoryList } from './event-product';

export const orderSuccessEvent = function (order) {
  return {
    event: 'purchase',
    ecommerce: {
      transaction_id: order.id,
      value: order.price / 100,
      shipping: order.delivery && order.delivery.price ? order.delivery.price / 100 : 0,
      currency: 'RUB',
      purchase_funnel: 'Купить из корзины',
      items:
        order.orderItems.length > 0
          ? order.orderItems.map(mapOrderItem)
          : [
              {
                item_id: 'ND000001',
                item_name: 'no_item',
              },
            ],
      fail_order: order.fail_product ? true : false,
    },
  };
};

function mapOrderItem(item) {
  return {
    item_id: item.sku.product.id + '',
    item_name: item.sku.product.title,
    discount: (item.sku.fullPrice - item.paymentPrice) / 100,
    item_brand: 'Без бренда',
    item_variant: item.sku.characteristicValues[0]?.title ?? '',
    price: item.sku.fullPrice / 100,
    quantity: item.amount,
    item_size: item.sku.characteristicValues[1]?.title ?? '',
    ...mapCategoryList(item.sku.product.category),
  };
}

export const alterPurchase = function (orderId) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'purchase_alter',
    eventCategory: 'Ecommerce',
    eventAction: 'purchase_alter',
    eventLabel: orderId,
  };
};
