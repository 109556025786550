
import { Component, Vue } from 'vue-property-decorator';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';

@Component({
  components: {
    ButtonUi,
    IconUi,
  },
})
export default class PopupClose extends Vue {}
