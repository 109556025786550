<template lang="pug">
.ds-checkout-postcode
  .ui-input-grid
    .ui-input-row
      labeled-input(label="Почтовый индекс:" autocomplete="postal-code" :value="postcode" @input="onUpdate")
      div
</template>
<script>
import { mapState } from 'vuex';
import LabeledInput from '~/components/labeled-input';

export default {
  components: {
    LabeledInput,
  },
  computed: {
    ...mapState('checkout', ['postcode']),
  },
  methods: {
    onUpdate(val) {
      this.$store.dispatch('checkout/updatePostcode', val);
    },
  },
};
</script>
<style lang="stylus">
.ds-checkout-postcode
  margin-top 24px
  +Media(Mobile, Tablet)
    margin-top 16px
</style>
