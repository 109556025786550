import { DADATA_HEADERS } from '~/api/dadata';

export type TDadataResponse = {
  value: string;
  unrestricted_value: string;
  data: {
    city: string;
    [key: string]: unknown;
  };
};

const DADATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address';

export const getDadataIpLocate = async (): Promise<TDadataResponse> => {
  try {
    const response = await fetch(DADATA_URL, {
      ...DADATA_HEADERS,
      method: 'GET',
    });
    const data = await response.json();
    return data.location;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
