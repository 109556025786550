import { gql } from '~/apollo-api';

export const facetFragment = gql`
  fragment FacetFragment on Facet {
    filter {
      id
      title
      type
      measurementUnit
      description
    }
    buckets {
      filterValue {
        id
        description
        image
        name
      }
      total
    }
    range {
      min
      max
    }
  }
`;
