<template lang="pug">
.ds-favorite-page
  .ui-wrapper
    h1.favorite-title Избранное
  favorite-ds(search-type="favorite")
</template>

<script>
import { StaticDataMixin } from '~/mixins/staticData';

import ending from '~/modules/common/ending';

import FavoriteDs from '~/modules/favorite/components/index.vue';
import { isEqualArray } from '~/modules/common/equal-array';

export default {
  components: {
    FavoriteDs,
  },
  mixins: [StaticDataMixin],
  computed: {
    sort() {
      return +this.$route.query.sort || 0;
    },
  },

  beforeDestroy() {
    this.$eventManager.off('updateQuery', this.onUpdateQuery);
  },

  mounted() {
    this.$eventManager.on('updateQuery', this.onUpdateQuery);
  },

  methods: {
    onUpdateQuery(payload) {
      const name = payload.name === 'dshopFilterCategories' ? 'cat' : payload.name;
      const value = payload.value;
      if (isEqualArray(this.$route.query[name], value)) {
        // does not update same value
        return;
      }
      const query = { ...this.$route.query };
      if (value === undefined) {
        delete query[name];
      } else {
        query[name] = value;
      }
      this.$router.push({
        query,
        params: { toPosition: { y: Math.min(window.scrollY, this.$refs.searchResult.$refs.content.offsetTop) } },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-favorite-page
  .favorite-title
    Text(TitleL)
    margin-bottom 32px
    margin-top 32px

    +Media(Mobile, Tablet)
      margin-top 12px
      margin-bottom 14px
</style>
