import { mapProductCartItem } from './event-addtocart';
import { DELIVERYTYPES } from './listitems';

export const addShippingEvent = function (orderItems, price, shippingTier, shippingCity, purchaseFunnel) {
  return {
    event: 'add_shipping_info',
    ecommerce: {
      value: price / 100,
      currency: 'RUB',
      shipping_tier: DELIVERYTYPES[shippingTier] ?? '',
      shipping_city: shippingCity,
      purchase_funnel: purchaseFunnel,
      items: orderItems.map((item) => {
        return mapProductCartItem(item.sku.product, item.sku, item.amount);
      }),
    },
  };
};
