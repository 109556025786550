<template lang="pug">
.contacts-page
  .ql-editor(v-if="text && text.contacts" v-html="text.contacts")
</template>

<script>
import { StaticDataMixin } from '~/mixins/staticData';
import { phoneFormatMixin } from '~/mixins/phone';
import { SeoDataMixin } from '~/mixins/seoData';
import { dshopTexts } from '~/apollo-api/dshopTexts';

export default {
  mixins: [StaticDataMixin, phoneFormatMixin, SeoDataMixin],
  data() {
    return {
      text: null,
    };
  },
  mounted() {
    this.dshopTexts();
  },
  methods: {
    async dshopTexts() {
      this.text = await dshopTexts('contacts');
    },
  },
};
</script>
<style lang="stylus">
.contacts-page
  margin-top 25px
</style>
