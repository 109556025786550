<template lang="pug">
.ds-labeled
  label
    slot(name="label") {{ label }}

  slot
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus">

.ds-labeled
  label
    display block
    margin-bottom 8px
</style>
