import Vue from 'vue';
import { getCart } from '~/modules/cart/api';
import { CheckProductAmountMixin } from '~/mixins/business/checkProductAmount';
import { AuthenticatedMixin } from '~/mixins/authenticated';

export const CartMixin = Vue.extend({
  mixins: [CheckProductAmountMixin, AuthenticatedMixin],
  watch: {
    accessToken: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.updateCartProducts(val);
        }
      },
    },
  },
  methods: {
    async updateCartProducts() {
      if (!this.accessToken) {
        return;
      }

      let cartProducts = this.$store.getters['cart/getList'];

      try {
        cartProducts = await getCart(this.accessToken);
      } finally {
        this.checkPrice(cartProducts, this.$store.getters['cart/getList']);
        this.checkAmount(cartProducts);
      }
    },
  },
});
