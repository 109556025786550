export const registrationSubmitSmsRetry = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_submit_sms_retry',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'submit_sms_retry',
    properties: {
      phone_part: phone,
    },
  };
};

export const registrationFailed = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_failed',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'submit_sms_failed',
    properties: {
      phone_part: phone,
    },
  };
};

export const recoveryView = function () {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_recovery_view',
    eventCategory: 'personal_account',
    eventAction: 'recovery',
    eventLabel: 'view',
  };
};

export const recoveryRequestSmsSuccess = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_recovery_request_sms_success',
    eventCategory: 'personal_account',
    eventAction: 'recovery',
    eventLabel: 'request_sms_success',
    properties: {
      phone_part: phone,
    },
  };
};

export const recoveryRequestSmsFailed = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_recovery_request_sms_failed',
    eventCategory: 'personal_account',
    eventAction: 'recovery',
    eventLabel: 'request_sms_failed',
    properties: {
      phone_part: phone,
    },
  };
};

export const registrationSubmitSmsSuccess = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_submit_sms_success',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'submit_sms_success',
    properties: {
      phone_part: phone,
    },
  };
};

export const recoverySubmitSmsFailes = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_recovery_submit_sms_failes',
    eventCategory: 'personal_account',
    eventAction: 'recovery',
    eventLabel: 'submit_sms_failes',
    properties: {
      phone_part: phone,
    },
  };
};

export const recoverySubmitSmsRetry = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_recovery_submit_sms_retry',
    eventCategory: 'personal_account',
    eventAction: 'recovery',
    eventLabel: 'submit_sms_retry',
    properties: {
      phone_part: phone,
    },
  };
};
