import { client, gql } from './index';
import { cartData } from './fragments/cartData';
import { addCartItems as AddCartItems, setCartItems as SetCartItems, CartItemInput } from '~/apollo-api/types';

export const getCart = async () => {
  const {
    data: { cart },
  } = await client.query({
    query: gql`
      query getCart {
        cart {
          ...cartData
        }
      }
      ${cartData}
    `,
  });

  return cart.map((_) => {
    return {
      ..._,
      oldAmount: 0,
    };
  });
};

export const setCart = async (cartItemInputs: CartItemInput[]) => {
  const { data } = await client.mutate<SetCartItems>({
    mutation: gql`
      mutation setCartItems($cartItemInputs: [CartItemInput!]!) {
        setCartItems(cartItemInputs: $cartItemInputs) {
          ...cartData
        }
      }
      ${cartData}
    `,
    variables: {
      cartItemInputs,
    },
  });

  return data?.setCartItems;
};

export const addToCart = async (cartItemInputs: CartItemInput) => {
  const { data } = await client.mutate<AddCartItems>({
    mutation: gql`
      mutation addCartItems($cartItemInputs: [CartItemInput!]!) {
        addCartItems(cartItemInputs: $cartItemInputs) {
          ...cartData
        }
      }
      ${cartData}
    `,
    variables: {
      cartItemInputs,
    },
  });

  return data?.addCartItems;
};
