
import Vue from 'vue';

import IconUi from '~/components/common/icon.vue';

type CSSStyles = Record<string, string>;

const MAX_RATING = 5;
const STAR_MARGIN = 2;

export default Vue.extend({
  name: 'FeedbackRating',
  components: {
    IconUi,
  },
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    size: {
      type: Number,
      default: 12,
    },
    value: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MAX_RATING,
    };
  },
  computed: {
    formattedValue(): number {
      return (this.value * 100) / MAX_RATING;
    },
    buttonStyles(): CSSStyles {
      const size = `${this.size}px`;
      return {
        height: size,
        width: size,
      };
    },
    starsContainerStyles(): CSSStyles {
      return {
        width: `${(this.size + STAR_MARGIN * 2) * 5 - STAR_MARGIN * 2}px`,
        height: `${this.size}px`,
      };
    },
    starsStyles(): CSSStyles {
      return {
        height: `${this.size}px`,
        backgroundSize: `${this.size + STAR_MARGIN * 2}px ${this.size}px`,
        backgroundPositionX: `${STAR_MARGIN * -1}px`,
      };
    },
    activeStarsStyles(): CSSStyles {
      return {
        ...this.starsStyles,
        width: `${this.formattedValue}%`,
      };
    },
  },
  methods: {
    onClick(index): void {
      this.$emit('select', index);
    },
  },
});
