<template lang="pug">
button-ui(class='action-button' :style="actionButton.style ? actionButton.style : '' ")
  link-ui(:to="actionButton.url" :target="actionButton.target || '_self'")
    span {{ actionButton.buttonText ? actionButton.buttonText : 'Подробнее' }}
</template>

<script>
import ButtonUi from '~/components/common/button.vue';
import LinkUi from '~/components/common/link';

export default {
  name: 'ActionButtonBanner',
  components: {
    ButtonUi,
    LinkUi,
  },
  props: {
    title: {
      type: String,
      default: 'Подробнее',
    },
    actionButton: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style lang="stylus" scoped>
.action-button
  Text(BodyM Semibold Short)
  Button(Medium)
  color TextPrimary()
  position absolute
  left 90px
  bottom 90px
  z-index: 999
  transition .3s ease-in-out
  background-color: ButtonGray()
  --bg-color-hover: OrangeBrandPressed()
  --color-hover: TextPrimary()
  --bg-color-active: OrangeBrandPressed()
  --color-active: TextPrimary()
  &:hover
    background-color var(--bg-color-hover)
    color var(color-hover)
    transform scale(1.05) !important
  &:active
    background-color var(--bg-color-active)
    color var(color-active)
    transform scale(1.05) !important
</style>
