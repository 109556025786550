export const registrationRequestSms = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_request_sms_success',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'request_sms_success',
    properties: {
      phone_part: phone,
    },
  };
};

export const registrationRequestSmsFailed = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_request_sms_failed',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'request_sms_failed',
    properties: {
      phone_part: phone,
    },
  };
};

export const registrationPasswordSubmit = function (phone) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_password_submit',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'password_submit',
    properties: {
      phone_part: phone,
    },
  };
};
