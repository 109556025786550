<template lang="pug">
.ds-card-min-price
  .title Минимальная сумма заказа {{ minPrice | formatPrice }}
  .subtitle Добавьте товары ещё на {{ minPrice - price | formatPrice }}
  .progress
    .progress-bar(:style="{ width: (price * 100 / minPrice) + '%' }")
  .prices
    .price {{ price | formatPrice }}
    icon-ui(name="navigation/geo" size="16")
    .price {{ minPrice | formatPrice }}
</template>

<script>
import { currencyFormatMixin } from '~/mixins';

import IconUi from '~/components/common/icon';

export default {
  components: {
    IconUi,
  },

  mixins: [currencyFormatMixin],

  props: {
    price: {
      type: Number,
      required: true,
    },
    minPrice: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-card-min-price
  background-color Gray(L48)
  padding 12px
  margin-bottom 16px

.title
  Text(BodyM Semibold Short)

.subtitle
  Text(BodyS Short)
  margin-top 4px

.progress
  margin-top 8px
  margin-bottom 8px
  background-color white
  height 4px
  border-radius 10px

  .progress-bar
    background-color OrangeBrand()
    height 4px
    border-radius 10px

.prices
  display flex

  .price
    Text(CaptionM Short)

  svg
    margin-left auto
    margin-right 4px
</style>
