<template lang="pug">
component(
  :is="asyncComponent"
)
</template>

<script>
import { StaticDataMixin } from '~/mixins/staticData';
import Layoutable from '~/plugins/layoutable/mixin';
// import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';

export default {
  mixins: [Layoutable(['mobile', 'desktop']), StaticDataMixin],
  created() {
    const points = this.$store.state.checkout?.city?.deliveryPoints;
    const city = this.$store.state.checkout?.city;

    if (city && city.points.length === 1) {
      this.$store.dispatch('checkout/updateHiddenPoint', city.points[0]);
      this.$store.commit('checkout/updateComment', city.points[0].id);
    }
    if (points && points.length > 0) {
      this.$store.dispatch('checkout/updateDeliveryPoint', points[0]);
      // this.$store.commit('checkout/updateComment', points[0].id);
    }
  },
  methods: {
    async import(layoutName) {
      return await import('./' + layoutName);
    },
  },
};
</script>
