<template lang="pug">
h1.ds-shop-heading {{ static.description }}
</template>
<script>
import { StaticDataMixin } from '~/mixins/staticData';
export default {
  mixins: [StaticDataMixin],
};
</script>
<style lang="stylus">
.ds-shop-heading
  text-align center
  color Gray(D32)
  margin-bottom 30px
  margin-top 52px
  +Media(Mobile)
    margin-top 19px
  +Media(Tablet)
    margin-top 20px
  +Media(Laptop)
    margin-top 32px

  +Media(Mobile, Tablet)
    Text(TitleM Semibold Long Secondary)
  +Media(Laptop, Desktop, DesktopMax)
    Text(HeadlineM Semibold Long Secondary)
</style>
