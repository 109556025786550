<template lang="pug">
.delivery-point-tabs
  tabs-ds(v-model="activeTab")
    tabs-title-ds(name="map" slot="title") На карте
    tabs-title-ds(name="list" slot="title") Списком
    tabs-content-ds(name="map" slot="content" :is-need-rerender="true")
      delivery-point-map
    tabs-content-ds(name="list" slot="content")
      delivery-point-list
</template>
<script>
import DeliveryPointList from './select-address';
import DeliveryPointMap from './delivery-map';
import TabsDs from '~/components/tabs';
import TabsTitleDs from '~/components/tabs/title';
import TabsContentDs from '~/components/tabs/content';

export default {
  components: { TabsContentDs, TabsDs, TabsTitleDs, DeliveryPointList, DeliveryPointMap },
  inject: ['media'],
  data: () => ({
    activeTab: 'map',
  }),
  beforeDestroy() {
    if (this.media.isMobileOrTablet && process.client) {
      //   document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  },
  mounted() {
    if (this.media.isMobileOrTablet && process.client) {
      //   document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    }
  },
};
</script>

<style lang="stylus">
.delivery-point-tabs
  .ui-tabs
    .tab-titles
      align-items center
      margin-bottom 16px
      border-bottom none
      display: flex
      .ui-tab-title
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;

        &:first-child
          margin-left auto

        +Media(Mobile, Tablet)
          margin 0px 16px !important

        &:not(:last-child)
          margin-right 16px
        +Media(Mobile, Tablet)
          margin 0px 16px !important
</style>
