<template lang="pug">
.ui-component.ui-input(:class="{ focus: isInFocus,  'error-input': isError || isOtherError }" :data-error="`${error || ''}`" :data-margin="`${isOtherError ? '30px' : '0'}`")
  .slot.before(v-if="$slots.before")
    slot(name="before")
  input(ref="input"
    v-bind="$attrs",
    v-facade="mask"
    :value="inputValue"
    v-on="inputListeners"
    @focus="onFocus"
    @blur="onBlur"
    :class="{ 'no-before': !$slots.before, 'no-after': !$slots.after }"
  )
  .slot.after(v-if="$slots.after || isError || isOtherError ")
    slot(name="after")
      icon-ui.error-icon(v-if="(isError || isOtherError) && !$slots.after" name="status/error_fill" :size="24" fill="#000000")
</template>

<script>
import { facade } from 'vue-input-facade';
import IconUi from './icon';
import { INPUT_MAX_LENGTH, MAX_LENGTH_ERROR_MESSAGE } from '~/utils/input.constant';

export const defaultInputListener = function (event) {
  const input = event.target;
  this.$emit('input', input.value || '');
};

export const createInputListeners = (input = defaultInputListener) =>
  function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    // `Object.assign` merges objects together to form a new object
    return {
      // We add all the listeners from the parent
      ...this.$listeners,
      // Then we can add custom listeners or override the
      // behavior of some listeners.
      // This ensures that the component works with v-model
      input: input.bind(vm),
    };
  };

export default {
  name: 'InputUi',
  components: {
    IconUi,
  },
  directives: {
    facade,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [String, Array, Object],
      default: '',
    },
    transform: {
      type: [Function],
      default: (text) => text || '',
    },
  },
  data() {
    return {
      inputValue: '',
      isInFocus: false,
      error: '',
      isOtherError: false,
    };
  },

  computed: {
    inputListeners() {
      return createInputListeners((e) => {
        const input = e.target;
        if (!input) {
          return;
        }
        const value = this.transform(input.value || '');
        input.value = value;
        this.inputValue = value;
        this.isOtherError = !!this.error;
        this.$emit('input', this.inputValue);
        this.$root.$emit('disabled', this.isOtherError);
      }).apply(this);
    },
  },

  watch: {
    value: {
      handler(v) {
        this.inputValue = String(v || '');
        if (this.inputValue.length > INPUT_MAX_LENGTH) {
          this.error = MAX_LENGTH_ERROR_MESSAGE;
        } else {
          this.error = '';
        }
      },
      immediate: true,
    },
  },

  created() {
    if (this.inputValue) {
      this.$emit('input', this.inputValue);
    }
  },
  methods: {
    /*
      Sets focus to input element
      @public
    */
    focus() {
      this.$refs.input.focus();
    },
    onBlur(event) {
      this.isInFocus = false;
      this.$emit('blur', event);
    },
    onFocus(event) {
      this.isInFocus = true;
      this.$emit('focus', event);
    },
  },
};
</script>
<style lang="stylus">
.ui-input
  Input()
  position relative
  margin-bottom attr(data-margin)
  &.error-input
    Input(Error)
    .error-icon
      margin-right 8px
      path
        fill Red()
    &.focus
      .error-icon
        display none
  &:before
    content attr(data-error)
    font-size 12px
    color Red()
    width 100%
    height attr(data-margin)
    position absolute
    bottom -20px
    left 16px
</style>
