<template lang="pug">
.ds-order-actions(v-if="actionData.actions.length || actionData.alert")
  .actions
    button-ui(v-for="action in actionData.actions" :class="action.style" :key="action.title" @click.native="action.handler") {{ action.title }}
  .alert(v-if="actionData.alert" v-html="actionData.alert")
</template>
<script>
import ButtonUi from '~/components/common/button';

export default {
  components: {
    ButtonUi,
  },
  props: {
    actionData: {
      type: Object /* { { title, style, handler }[], alert } */,
      default: () => ({}),
    },
  },
};
</script>
<style lang="stylus">
.ds-order-actions
  display flex
  align-items center
  .actions
    display flex
    align-items center
  .ui-button
    &:not(:first-child)
      margin-left 24px
  .alert
    margin-left 24px
  .ui-button
    Button(Medium)
    &.outlined
      Button(Outlined)
    &.primary
      Button(Primary)
  .alert
    Text(CaptionM Light)
    color Gray(L8)
    strong
      font-weight 600
      color Red()

  +Media(Mobile, Tablet, Laptop)
    display block
    .alert
      margin-left 0
      margin-top 24px
  +Media(Mobile, Tablet)
    .alert
      margin-top 16px
      br
        display none
</style>
