export const favoriteOpenEvent = function (orderItems, price) {
  return {
    event: 'view_wishlist',
    ecommerce: {
      value: price / 100,
      currency: 'RUB',
      items: orderItems.map((item) => {
        return mapFavoriteItem(item);
      }),
    },
  };
};

export const mapFavoriteItem = function (product) {
  let data = {
    item_id: product.id + '',
    item_name: product.title,
    discount: (product.minFullPrice - product.minSellPrice) / 100,
    item_brand: 'Без бренда',
    price: product.minFullPrice / 100,
    item_variant:
      product.characteristic.length > 1 ? product.characteristic[1]?.title : product.characteristic[0]?.title || '',
  };
  if (product.characteristic.length > 1) {
    data.item_size = product.characteristic[0]?.title;
  }

  return data;
};
