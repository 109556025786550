import {
  GetSuggestions_getSuggestions_blocks_PopularSuggestionsBlock,
  GetSuggestions_getSuggestions_blocks_TextSuggestionsBlock,
} from '~/apollo-api/types';
import {
  ESuggestBlocks,
  ICacheHistorySuggestionsBlock,
  TSuggestionsBlocks,
  TUnionInterfaceBlocks,
  TUniversalSuggestionsBlockValues,
} from '../models';

export function unionBlockMapper(block: TSuggestionsBlocks): TUnionInterfaceBlocks[] {
  const mapBlockValues = {
    [ESuggestBlocks.Text]: (block as GetSuggestions_getSuggestions_blocks_TextSuggestionsBlock).values,
    [ESuggestBlocks.Popular]: (block as GetSuggestions_getSuggestions_blocks_PopularSuggestionsBlock)
      .popularSuggestions,
    [ESuggestBlocks.Cache]: (block as ICacheHistorySuggestionsBlock).values,
  };
  const values = mapBlockValues[block.__typename] as TUniversalSuggestionsBlockValues[];

  if (!values?.length) return [];
  return (values as any).map((value) => {
    // @todo fix types
    return {
      type: block.__typename,
      title: value,
    };
  });
}
