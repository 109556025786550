<template lang="pug">
.ds-order-card
  order-card-properties-ds(:order="order")
  order-card-products-ds(:items="order.orderItems" @to-order="toOrder")
  order-actions-ds(:action-data="actionData")
</template>
<script>
import OrderCardPropertiesDs from './card-properties';
import OrderCardProductsDs from './card-products';
import OrderActionsDs from './actions';
import ending from '~/modules/common/ending';
import { delayWithoutPaidMilliSeconds } from '~/modules/orders/data';

export default {
  components: {
    OrderActionsDs,
    OrderCardPropertiesDs,
    OrderCardProductsDs,
  },
  provide() {
    return {
      isShowMapInit: this.isShowMapInit,
    };
  },
  props: {
    order: {
      type: Object,
      default: () => undefined,
    },
    isShowMapInit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    now: 0,
    interval: undefined,
  }),
  computed: {
    time() {
      if (delayWithoutPaidMilliSeconds - (this.now - this.order.dateCreated) < 0) {
        return '';
      }
      const delta = new Date(delayWithoutPaidMilliSeconds - (this.now - this.order.dateCreated));
      const minutes = delta.getMinutes();
      const seconds = delta.getSeconds();
      return `${minutes} ${ending(minutes, ['минуты', 'минут', 'минут'])} ${
        seconds ? `${seconds} ${ending(seconds, ['секунды', 'секунд', 'секунд'])}` : ''
      }`;
    },
    actionData() {
      const result = {
        actions: [
          {
            title: 'О заказе подробно',
            style: 'outlined',
            handler: () => {
              this.toOrder();
            },
          },
        ],
      };

      if (this.order.status === 'CREATED' && this.time.length) {
        // not paid
        result.actions.push({
          title: 'Оплатить',
          style: 'primary',
          handler: () => {
            location.assign(this.order.paymentUrl);
          },
        });
        result.alert = `<strong>Внимание!</strong> Этот заказ будет автоматически отменен,<br> если вы не оплатите его в течение ${this.time}`;
      }

      return result;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      this.now = new Date().getTime();
    }, 1000);
  },
  methods: {
    toOrder() {
      this.$router.push({ path: `order/${this.order.id}` });
    },
  },
};
</script>
<style lang="stylus">
.ds-order-card
  &:not(:last-child)
    border-bottom 1px solid Gray(A40)
  .ds-order-actions
    margin-top 24px
</style>
