<template lang="pug">
.ds-checkout-recipient(:class="{ 'is-auth': accessToken }")
  .auth-action(v-if="!accessToken")
    .label Покупали здесь ранее?
    button-ui.signin(@click.stop="openSignInPopup") Войти
  .ui-input-grid
    .fields
      .ui-input-row.ui-input-grid
        labeled-ds
          template(#label)
            slot(name="label") Фамилия:
          dropdown-field-ds(
            autocomplete="family-name"
            placeholder="Введите фамилию"
            :is-error="!isValidLastName && isShowWarnings && !isCorrectLastName"
            :source="fetchFios('surname', { gender: 'MALE' })"
            :value="value.lastName"
            @select="onLastNameUpdate"
          )
        labeled-ds
          template(#label)
            slot(name="label") Имя:
          dropdown-field-ds(
            autocomplete="given-name name"
            placeholder="Введите имя"
            :is-error="!isValidFirstName && isShowWarnings && !isCorrectFirstName"
            :source="fetchFios('name')"
            :value="value.firstName"
            @select="onFirstNameUpdate"
          )
      .error(v-if="fullNameError && fullNameError.length && isShowWarnings") {{ fullNameError }}
      .description Фамилия и имя полностью, как в паспорте.#[br]Может понадобиться при получении заказа
    .fields
      .ui-input-row.ui-input-grid
        labeled-input(
          label="Номер телефона:"
          :is-error="!isValidPhone && isShowWarnings"
          mask="+7 (###) ###-##-##"
          type="tel"
          autocomplete="tel"
          placeholder="+7 (___) ___-__-__"
          :value="value.phone"
          :transform="phoneTransform"
          @input="onPhoneUpdate"
        )
        labeled-ds
          template(#label)
            slot(name="label") Электронная почта:
          dropdown-field-ds(
            autocomplete="email"
            placeholder="Введите email"
            :is-error="!isValidEmail && isShowWarnings"
            :source="fetchEmails"
            :value="value.email"
            @select="onEmailUpdate"
          )
      .error(v-if="error && error.length && isShowWarnings") {{ error }}
      .description
        div Уведомим об изменениях статуса заказа и пришлем электронный чек
    .fields
      checkbox-ds.mail-accept(:value="value.isMailAccept" @input="onNotifylUpdate") Хочу получать уведомления об акциях и выгодных предложениях
</template>

<script>
import { mapState } from 'vuex';
import { EDadataService, getDadataOptions } from '~/api/getDadataOptions';

import SignInPopupMixin from '~/mixins/signInPopupMixin';
import { CheckoutFieldMixin } from '~/mixins/business/checkout';
import { phoneTransform } from '~/utils/phoneTransform';

import ButtonUi from '~/components/common/button';
import CheckboxDs from '~/components/common/checkbox';
import DropdownFieldDs from '~/components/dropdown-field';
import LabeledDs from '~/components/labeled';
import LabeledInput from '~/components/labeled-input';
import { AuthenticatedMixin } from '~/mixins/authenticated';

const validateEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value.toLowerCase(),
  );
const validatePhone = (value) => value.replace('+7', '').replace(/[^0-9]/g, '').length === 10;

export default {
  components: {
    ButtonUi,
    CheckboxDs,
    DropdownFieldDs,
    LabeledDs,
    LabeledInput,
  },
  mixins: [CheckoutFieldMixin, SignInPopupMixin, AuthenticatedMixin],
  data: () => ({
    field: 'recipient',
    phoneTransform,
  }),
  computed: {
    ...mapState('checkout', {
      value: (state) => state.recipient,
    }),
    ...mapState('checkout', ['isShowWarnings']),
    isValidPhone() {
      return validatePhone(this.value.phone);
    },
    isValidEmail() {
      return validateEmail(this.value.email);
    },
    isValidFirstName() {
      return !!this.value.firstName?.length;
    },
    isValidLastName() {
      return !!this.value.lastName?.length;
    },
    isCorrectFirstName() {
      return /^\D+$/.test(this.value.firstName);
    },
    isCorrectLastName() {
      return /^\D+$/.test(this.value.lastName);
    },
    fullNameError() {
      if (!this.isValidLastName && !this.isValidFirstName) {
        return 'Заполните фамилию и имя';
      }
      if (this.isValidFirstName && !this.isValidLastName) {
        return 'Заполните фамилию';
      }
      if (this.isValidLastName && !this.isValidFirstName) {
        return 'Заполните имя';
      }
      if (!this.isCorrectLastName && !this.isCorrectFirstName) {
        return 'Проверьте фамилию и имя';
      }
      if (this.isCorrectFirstName && !this.isCorrectLastName) {
        return 'Проверьте фамилию';
      }
      if (this.isCorrectLastName && !this.isCorrectFirstName) {
        return 'Проверьте имя';
      }
      return '';
    },
    error() {
      if (this.value.phone === '' && this.value.email === '') {
        return 'Заполните телефон и email';
      }

      if (this.isValidPhone && this.value.email === '') {
        return 'Заполните email';
      }

      if (this.isValidEmail && this.value.phone === '') {
        return 'Заполните телефон';
      }

      if (!this.isValidEmail && !this.isValidPhone) {
        return 'Неверный формат телефона и email';
      }
      if (!this.isValidPhone) {
        return 'Неверный формат телефона';
      }
      if (!this.isValidEmail) {
        return 'Неверный формат email';
      }
      return '';
    },
    isValid() {
      return this.isValidFirstName && this.isValidLastName && this.isValidPhone && this.isValidEmail;
    },
  },
  methods: {
    updateRecipient(payload) {
      this.$store.commit('checkout/updateRecipient', payload);
    },
    updateField(field, value) {
      this.updateRecipient({
        ...this.value,
        [field]: value,
      });
    },
    onFirstNameUpdate(value) {
      this.updateField('firstName', value);
    },
    onLastNameUpdate(value) {
      this.updateField('lastName', value);
    },
    onPhoneUpdate(value) {
      this.updateField('phone', value);
    },
    onEmailUpdate(value) {
      this.updateField('email', value);
    },
    onNotifylUpdate(value) {
      this.updateField('isMailAccept', value);
    },

    fetchFios(field, options = {}) {
      return (query) =>
        getDadataOptions({ type: EDadataService.Fio, query, count: 50, options }).then((items) => {
          return items
            .filter((item) => !!item.data[field])
            .map((item) => ({
              id: item.value,
              name: item.data[field],
            }));
        });
    },

    fetchEmails(query) {
      return getDadataOptions({ type: EDadataService.Email, query, count: 50 }).then((items) => {
        return items.map((item) => ({
          id: item.value,
          name: item.data.local + '@' + (item.data.domain || ''),
        }));
      });
    },
  },
};
</script>

<style lang="stylus">
.ds-checkout-recipient
  position relative
  &:not(.is-auth)
    margin-top -48px
    +Media(Mobile, Tablet)
      margin-top -36px
  .description
    Text(CaptionM)
    color TextSecondary()
    margin-top 8px
  .error
    Text(CaptionM)
    color Red()
    margin-top 8px
  .auth-action
    display flex
    align-items center
    justify-content flex-end
    width 100%
    margin-left auto
    margin-bottom 12px
    .label
      Text(CaptionM)
      color TextSecondary()
      margin-right 8px
      +Media(Mobile, Tablet)
        display none
    .signin
      Button(Small Outlined)

.mail-accept
  font-size 12px
  line-height: 16.8px
</style>
