import { ESuggestBlocks, TSuggestConfig, ISuggestWindowMedias } from '../models';

// @TODO: нужны значения из KE
const DEFAULT_SUGGEST_CONFIG_ITEM: ISuggestWindowMedias = {
  mobile: 4,
  desktop: 4,
};

export const SUGGEST_CONFIG: TSuggestConfig = {
  [ESuggestBlocks.Cache]: DEFAULT_SUGGEST_CONFIG_ITEM,
  [ESuggestBlocks.Text]: DEFAULT_SUGGEST_CONFIG_ITEM,
  [ESuggestBlocks.Popular]: DEFAULT_SUGGEST_CONFIG_ITEM,
};
