<template lang="pug">
.checkout-geo-position
  .checkout-geo-position-button(@click="onClick")
    icon-ui(v-if="suggestion && !loading" name="navigation/geolocation_fill" size="24")
    icon-ui(v-else-if="!loading" name="navigation/geolocation" size="24")
    spinner-ds(v-else-if="loading" size="24")
    .geo-position-text {{ geoPositionText }}
  .error(v-if="error && error.length > 0") {{ error }}
  </template>
<script>
import { mapState } from 'vuex';
import IconUi from '~/components/common/icon';
import SpinnerDs from '~/components/spinner';

import { getDadataGeoAdress } from '~/api/getDadataGeoAdress';
export default {
  components: {
    IconUi,
    SpinnerDs,
  },
  data() {
    return {
      error: '',
      loading: false,
      position: null,
      suggestions: [],
      suggestion: null,
    };
  },
  computed: {
    ...mapState('checkout', ['city', 'address']),
    geoPositionText() {
      return this.loading ? 'Ищем вас...' : 'Определить местоположение';
    },
    suggestionCity() {
      if (this.suggestion.data.fias_level !== '65') {
        return {
          id: this.suggestion.value,
          settlement: this.suggestion.data?.settlement,
          city: this.suggestion.data?.city,
          name: this.suggestion.data.city_with_type
            ? this.suggestion.data.city_with_type
            : this.suggestion.data.settlement_with_type,
          settlement_with_type: this.suggestion.data.settlement_with_type,
          isSettlement: !!this.suggestion.data.settlement_with_type,
          ke: false,
        };
      }
      return {
        id: this.suggestion.value,
        city: this.suggestion.data.city,
        settlement: this.suggestion.data?.settlement,
        name: this.suggestion.data.city_with_type,
        settlement_with_type: null,
        isSettlement: false,
        ke: false,
      };
    },
    suggestionStreet() {
      /**
       * case when we have street and house
       */
      if (this.suggestion.data.street && this.suggestion.data.house) {
        /**
         * @param area_with_type - need for searching Moscow addresses
         * case for Moscow with area_with_type
         */
        if (this.suggestion.data.city === 'Москва' && this.suggestion.data.area_with_type) {
          return {
            street: `${this.suggestion.data.area_with_type}, ${this.suggestion.street_with_type}, ${this.suggestion.house_type} ${this.suggestion.house}`,
            area_with_type: this.suggestion.data.area_with_type,
            house: this.suggestion.data.house,
          };
        } else {
          return {
            street: `${this.suggestion.data.street_with_type}, ${this.suggestion.data.house_type} ${this.suggestion.data.house}`,
            house: this.suggestion.data.house,
            house_type: this.suggestion.data.house_type,
            street_with_type: this.suggestion.data.street_with_type,
          };
        }
        /**
         * case when we have street
         */
      } else if (this.suggestion.data.street && !this.suggestion.data.house) {
        if (this.suggestion.data.city === 'Москва' && this.suggestion.data.area_with_type) {
          return {
            street: `${this.suggestion.data.area_with_type}, ${this.suggestion.street_with_type}`,
            area_with_type: this.suggestion.data.area_with_type,
            house: this.suggestion.data.house,
          };
        } else {
          return {
            street: this.suggestion.data.street_with_type,
            house: this.suggestion.data.house,
            house_type: this.suggestion.data.house_type,
            street_with_type: this.suggestion.data.street_with_type,
          };
        }
        //case for Ulan-Ude
      } else if (this.suggestion.data.fias_level === '65' && this.suggestion.data.house) {
        return {
          street: `${this.suggestion.data.settlement_with_type}, ${this.suggestion.house_type} ${this.suggestion.house}`,
          house: this.suggestion.data.house,
        };
      } else if (this.suggestion.data.fias_level === '65' && !this.suggestion.data.house) {
        return {
          street: `${this.suggestion.data.settlement_with_type}`,
          house: this.suggestion.data.house,
        };
      } else return null;
    },
  },
  methods: {
    async onClick() {
      this.loading = true;
      if (navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition(this.setPosition, this.setError);
      } else {
        this.setError();
      }
    },
    async setPosition(position) {
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').style.height = '100vh';
      this.position = {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      };
      try {
        this.suggestions = await getDadataGeoAdress(this.position);
        if (this.suggestions.length > 0) {
          this.suggestion = this.suggestions[0];

          this.$store.commit('checkout/updateCity', this.suggestionCity);
          this.$store.commit('checkout/updateCheckCities', [this.suggestionCity]);
          // Hack await updateCheckCities
          setTimeout(() => {
            this.$store.commit('checkout/updateAddress', this.suggestionStreet ? this.suggestionStreet : {});
            this.$store.commit('checkout/updateCheckStreets', [this.suggestionStreet ? this.suggestionStreet : {}]);
          });
        } else {
          this.setError(true);
        }
      } catch {
        this.setError(true);
      } finally {
        document.querySelector('body').style.overflow = 'auto';
        document.querySelector('body').style.height = 'auto';
        this.loading = false;
      }
    },
    setError(isSearch) {
      this.loading = false;
      if (isSearch) {
        this.error = 'Не удалось вас найти';
      } else this.error = 'Нет доступа к геолокации';
      setTimeout(() => {
        this.error = '';
      }, 3000);
    },
  },
};
</script>

<style lang="stylus">
.checkout-geo-position
  margin-top 14px
  &-button
    display flex
    gap 8px
    cursor pointer
    align-items center
  .error
    Text(CaptionM)
    color Red()
    margin-top 8px
    position absolute
</style>
