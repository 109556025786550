<template lang="pug">
.side-wrapper
  .ui-wrapper
    .element-wrapper
      switch-all-tabs
      select-city(v-if="current === 'select-city'" @change-tab="changeTab")
      select-address(v-if="current === 'select-address'" @change-tab="changeTab")
</template>

<script>
import SelectCity from './select-city.vue';
import SelectAddress from './select-address.vue';
import switchAllTabs from './switch-all-tabs.vue';
import { mapState } from 'vuex';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
export default {
  components: {
    SelectCity,
    SelectAddress,
    switchAllTabs,
  },
  data() {
    return {
      tabsState: ['select-address', 'select-city'],
      current: 'select-address',
    };
  },
  computed: {
    ...mapState('deliveryMap', {
      city: (state) => state.deliveryMapCity,
    }),
  },
  watch: {
    city() {
      if (this.city.name === CITY_WITH_PARTNER_DP) {
        this.current = this.tabsState[0];
      }
    },
  },
  methods: {
    changeTab(index) {
      this.current = this.tabsState[index];
    },
  },
};
</script>

<style lang="stylus">
.side-wrapper
  position absolute
  left 0
  top 32px
  z-index 10

  .element-wrapper
    background white
    width 440px
  .ui-wrapper
    +Media(Mobile)
      padding 0 0 0 16px !important

    +Media(Tablet)
      padding 0 0 0 24px !important

    +Media(Laptop)
      padding 0 0 0 32px !important

    +Media(Desktop)
      padding 0 0 0 40px !important

    +Media(DesktopMax)
      padding 0 0 0 calc((100vw - 1360px) / 2) !important
</style>
