<template lang="pug">
.checkout-composition
  order-product-ds(v-for="orderItem in products" :key="orderItem.sku.id" :order-item="orderItem")
</template>
<script>
import { mapState } from 'vuex';
import { currencyFormatMixin } from '~/mixins';
import OrderProductDs from '~/components/order-product';

export default {
  components: {
    OrderProductDs,
  },
  mixins: [currencyFormatMixin],
  computed: {
    ...mapState('checkout', ['products']),
  },
  methods: {
    onClick(id) {
      const { href } = this.$router.resolve({ name: 'product', params: { id } });
      window.open(href, '_blank');
    },
  },
};
</script>
<style lang="stylus">
.checkout-composition
  .ds-order-product
    &:not(:last-child)
      border-bottom 1px solid Divider()
</style>
