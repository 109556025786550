<template lang="pug">
.ds-checkout-delivery-switch
  radio-extended(
    :items="types"
    v-model="selectedDeliveryType"
    name="delivery-type"
    ref="deliveryTypeRadio"
    @hook:mounted="selectDeliveryType(types[0])"
  )
    template(#item="item")
      .radio-item-row
        .selector
        .label {{ item.label }}
      .radio-item-row.misc
        .description(v-if="item.minFreeDeliveryPriceCourier > totalPrice > 0")
          | Добавьте товары еще на {{ item.minFreeDeliveryPriceCourier - totalPrice | formatPrice }}
          | для бесплатной доставки
        .description(v-else) {{ item.description }}
        .delivery(v-if="item.delivery && item.delivery.length" v-html="item.delivery")
        .delivery-subtitle(v-if="item.id == 'DELIVERY_POINT'") Срок доставки может быть скорректирован

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RadioExtended from '~/components/common/radio-extended';
import MinPrice from '~/pages/cart/components/min-price';
import { currencyFormatMixin } from '~/mixins';
import { DeliveryType } from '~/modules/checkout/types';
import { dateToLocale } from '~/modules/common/date';
import { weightFormat } from '~/filters/weight';
import { cityIn } from 'lvovich';
import { MAX_WEIGHT_RUSSIAN_POST } from '~/shop-config';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';

import { StaticDataMixin } from '~/mixins/staticData';

const defaultSortTypes = [DeliveryType.DELIVERY_POINT, DeliveryType.COURIER, DeliveryType.RUSSIAN_POST];

const compareDefaultFunction = (a, b) => {
  return defaultSortTypes.findIndex((_) => a.id === _) - defaultSortTypes.findIndex((_) => b.id === _);
};

const options = { day: 'numeric', month: 'numeric' }; // dateToLocale options

const getPriceLabel = (date, dates, time) => {
  const priceComparator = (a, b) => a.price - b.price;
  if (!date) {
    if (!dates?.length) {
      return '';
    }
    const minPrice = [...dates.map((_) => _.options).flatMap((x) => x)].sort(priceComparator)[0].price / 100;
    return `от ${minPrice} ₽`;
  }
  if (time) {
    return `${time?.price / 100} ₽`;
  } else {
    const currentDateOptions = [...dates.find((_) => _.date === date.value).options];
    if (currentDateOptions.every((_) => _.price === currentDateOptions[0].price)) {
      return `${currentDateOptions[0]?.price / 100} ₽`;
    } else {
      const minPrice = currentDateOptions.sort(priceComparator)[0].price / 100;
      return `от ${minPrice} ₽`;
    }
  }
};

export default {
  components: {
    MinPrice,
    RadioExtended,
  },
  mixins: [currencyFormatMixin, StaticDataMixin],
  data() {
    return {
      selectedDeliveryType: null,
    };
  },
  computed: {
    ...mapState('checkout', {
      deliveryType: (state) => state.deliveryType || DeliveryType.RUSSIAN_POST,
    }),
    ...mapState('deliveryMap', ['fiasCities']),
    ...mapState('checkout', [
      'city',
      'date',
      'time',
      'expectedDelivery',
      'deliveryPoint',
      'deliveryOptions',
      'products',
      'hiddenPoint',
    ]),
    ...mapGetters('checkout', ['cityPoint', 'hasMaxWeightLimit', 'hasNonWeightProducts']),
    deliveryPoint() {
      let currentDate = new Date();

      return {
        id: DeliveryType.DELIVERY_POINT,
        label: this.city?.ke ? `Пункт выдачи заказов` : 'Пункт выдачи заказов',
        description: 'Заберите самостоятельно в пункте выдачи заказов ПСБ Маркет',
        disabled: !this.city.deliveryPoints,
        delivery: this.cityPoint
          ? `Доставка с ${dateToLocale(new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000), options)}${
              this.deliveryCodition
            }`
          : 'Данный способ недоступен для выбранного населенного&nbsp;пункта',
      };
    },

    deliveryCodition() {
      if (!this.hiddenPoint) {
        return '';
      }

      if (this.hiddenPoint?.isPayed) {
        return ', <strong>200 ₽</strong>';
      }

      return ',<strong> бесплатно</strong>';
    },
    russianPost() {
      let delivery;
      if (this.hasMaxWeightLimit) {
        delivery = `<span class="error-in-active">Вес заказа больше ${weightFormat(
          MAX_WEIGHT_RUSSIAN_POST,
          // eslint-disable-next-line no-irregular-whitespace
        )}.</br>Удалите часть товаров, чтобы оформить заказ</span>`;
      } else if (this.hasNonWeightProducts) {
        const label =
          this.products.length === 1
            ? 'Этот способ недоступен для доставки данного&nbsp;товара'
            : 'Этот способ недоступен для доставки части товаров в&nbsp;заказе';
        delivery = `<span class="error-in-active">${label}</span>`;
      } else if (this.expectedDelivery?.price && this.expectedDelivery?.maxDays) {
        const expected = new Date();
        expected.setDate(expected.getDate() + this.expectedDelivery.maxDays);
        delivery = `Доставка до ${dateToLocale(expected, options)}, <strong>${
          this.expectedDelivery.price / 100
        } ₽</strong>`;
      }
      return {
        id: DeliveryType.RUSSIAN_POST,
        label: 'Почта России',
        description: 'Доставим в ближайшее для вас отделение Почты России',
        disabled: false,
        delivery: delivery,
      };
    },
    // NO KE
    // courier() {
    //   const minFreeDeliveryPriceCourier = this.city.minFreeDeliveryPriceCourier;
    //   const priceLabel =
    //     !!minFreeDeliveryPriceCourier && this.totalPrice >= minFreeDeliveryPriceCourier
    //       ? 'бесплатно'
    //       : getPriceLabel(this.date, this.deliveryOptions, this.time);

    //   return {
    //     id: DeliveryType.COURIER,
    //     label: 'Курьером до двери',
    //     description: 'Доставим в удобный для вас день и интервал времени',
    //     disabled: !this.city.ke || !this.deliveryOptions.length,
    //     delivery:
    //       this.city.ke && this.deliveryOptions.length
    //         ? this.date?.value
    //           ? `Доставка ${dateToLocale(new Date(this.date.value), options)}, <strong>${priceLabel}</strong>`
    //           : `Доставка с ${dateToLocale(
    //               new Date(this.deliveryOptions[0]?.date),
    //               options,
    //             )}, <strong>${priceLabel}</strong>`
    //         : this.city.ke
    //         ? 'Доставка курьером временно недоступна'
    //         : 'Данный способ недоступен для выбранного населенного&nbsp;пункта',
    //     minFreeDeliveryPriceCourier,
    //   };
    // },
    types() {
      const current = this.fiasCities.find((city) =>
        this.city.name ? this.city.name.includes(city.name) : this.city.city.includes(city.city),
      );
      if (this.city && this.city.isCustom) {
        return [this.deliveryPoint];
      }
      if (this.city && this.city.customCity) {
        return [this.deliveryPoint];
      }
      return current
        ? [this.deliveryPoint, this.russianPost]
        : this.city.name.includes(CITY_WITH_PARTNER_DP) ||
          (this.city && this.city.city && this.city.city.includes(CITY_WITH_PARTNER_DP))
        ? [this.deliveryPoint]
        : [this.russianPost];
      // NO KE
      // return [this.deliveryPoint, this.russianPost, this.courier].sort(
      //   this.city.ke ? compareDefaultFunction : (a) => (a.id !== DeliveryType.RUSSIAN_POST ? 1 : -1),
      // );
    },
    totalPrice() {
      return this.products.map((_) => _.sku.sellPrice * _.amount).reduce((acc, cur) => acc + cur, 0);
    },
  },
  watch: {
    selectedDeliveryType() {
      this.$emit('select', this.selectedDeliveryType);
      this.$store.commit(
        'checkout/updateDeliveryType',
        this.selectedDeliveryType ? this.selectedDeliveryType.id : DeliveryType.DELIVERY_POINT,
      );
    },
    deliveryType(val) {
      this.selectDeliveryType(val);
    },
    city: {
      handler() {
        this.selectDeliveryType(this.types[0]);
      },
      immediate: true,
    },
  },
  methods: {
    selectDeliveryType(id = DeliveryType.RUSSIAN_POST) {
      if (this.$refs.deliveryTypeRadio) {
        this.$refs.deliveryTypeRadio.selectItem(id);
      }
    },
  },
};
</script>

<style lang="stylus">
.ds-checkout-delivery-switch
  .ui-radio-extended
    background-color #fff
    display grid
    column-gap 16px
    row-gap 16px
    +Media(Desktop, DesktopMax)
      grid-template-columns 1fr 1fr
      grid-auto-rows minmax(138px, max-content)
    +Media(Mobile, Tablet, Laptop)
      grid-template-columns 1fr
      grid-auto-rows minmax(120px, max-content)
    +Media(Mobile, Tablet)
      row-gap 8px
    +Media(Laptop)
      row-gap 16px

    .radio-item
      cursor pointer
      padding 24px 16px 16px 24px
      display flex
      flex-direction column
      box-shadow 0 0 0 1px Divider()
      +Media(Mobile, Tablet)
        padding 16px

      &:hover
        box-shadow 0 0 0 2px Divider()
      &.selected .error-in-active
        color Red()

      &.disabled
        cursor default
        &:hover
          box-shadow 0 0 0 1px Divider()
        .label
        .description
        .delivery
          color TextSecondary()


      &.selected
        box-shadow 0 0 0 2px TextPrimary()

        .selector
          background-color Gray(D32)

      &:not(.selected)
        .price
          color TextSecondary()

      .radio-item-row
        display flex
        justify-content space-between
        align-items center
        position relative
        padding-left 32px

      .selector
        position absolute
        left 0
        width 15px
        height 15px
        background-color #fff
        border 3px solid #fff
        box-shadow 0 0 0 1px Divider()
        border-radius 50%

      .label
        +Media(Laptop, Desktop, DesktopMax)
          Text(BodyL Semibold)
        +Media(Mobile, Tablet)
          Text(BodyM Semibold)

      .misc
        flex-grow 1
        display flex
        flex-direction column
        justify-content space-between
        align-items flex-start
        +Media(Laptop, Desktop, DesktopMax)
          margin-top 8px
        +Media(Mobile, Tablet)
          margin-top 10px
        .description
          Text(BodyM Regular Long)
          color TextSecondary()
        .delivery
          Text(BodyM Regular)
          margin-top 16px
          strong
            font-weight 600
        .delivery-subtitle
          color:#8B8E99;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          margin-top: 4px
</style>
