
import Vue from 'vue';

import CabinetFeedbackDs from './cabinet-feedback.vue';

export default Vue.extend({
  name: 'CabinetFeedbacks',
  components: {
    CabinetFeedbackDs,
  },
  data() {
    return {
      feedbacks: [],
      product: {
        sku: {
          product: {
            id: 69630,
            title: 'Женская рубашка Office',
            shortDescription: 'Объем: 20 мл',
          },
          photo: {
            link: {
              high: 'https://ke-images-dev.servicecdn.ru/buhf27rbkrvvjs5j3v5g/t_product_240_high.jpg',
            },
          },
        },
      },
    };
  },
});
