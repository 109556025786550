<template lang="pug">
.ds-restore
  template(v-if="state===states.Password")
    first-password-ds(:is-restore="true")
  template(v-if="state===states.Code")
    verify-phone-ds(:is-restore="true")
  template(v-if="state===states.Phone")
    .header-actions
      button-ui.to-signin(@click.stop="$emit('change-tab', 'signin')") Вход
        icon-ui(
          slot="before"
          name="navigation/chevron-left_line"
        )
    .restore-title Восстановление пароля
    .restore-subtitle Введите номер телефона, который вы использовали для входа

    .restore-form(@keyup.enter="proceed")
      .input-login.input-row
        labeled-input-ds(
          mask="+7 ### ###-##-##"
          v-model="phone"
          :is-error="!!error.length"
          ref="input"
          placeholder='+7'
          type="tel"
          autocomplete="tel"
          label="Номер телефона"
          :transform="phoneTransform"
        )
          .action(
            slot="after"
            v-if="isValidatePhone"
          )
            icon-ui.check(name="status/check_line")
      label.error(v-if="error.length" v-html="error")
      .form-actions.input-row
        button-ui(
          :disabled="!canProceed || isProccessing"
          @click="proceed"
        ) {{buttonMessage}}
          spinner-ds(
            slot="before"
            v-if="isProccessing"
            :size="20"
          )

</template>

<script>
import IconUi from '~/components/common/icon';
import SpinnerDs from '~/components/spinner';
import ButtonUi from '~/components/common/button';
import LabeledInputDs from '~/components/labeled-input';
import VerifyPhoneDs from './verify-phone';
import FirstPasswordDs from './first-password';
import { requestRestorePassword } from '~/apollo-api/requestRestorePassword';
import { SignUpState } from '~/store/user';
import { cutPhone, phoneTransform } from '~/utils/phoneTransform';
export default {
  components: {
    LabeledInputDs,
    IconUi,
    ButtonUi,
    VerifyPhoneDs,
    FirstPasswordDs,
    SpinnerDs,
  },
  inject: ['initPhone'],
  data() {
    return {
      phone: '',
      isProccessing: false,
      now: 0,
      intervalInstance: 0,
      intervalTime: 1000, // each second
      error: '',
      isFirst: true,
      codeSentCoolDown: 60000,
      states: SignUpState,
      phoneTransform,
    };
  },
  computed: {
    canProceed() {
      return this.isValidatePhone && this.now - this.lastCodeSentAt > this.codeSentCoolDown;
    },
    state() {
      return this.$store.state.user.registration.state;
    },
    lastCodeSentAt() {
      return this.$store.state.user.registration.lastCodeSentAt;
    },
    truePhone() {
      return this.phone.replace('+7', '').replace(/[^0-9]/g, '');
    },
    isValidatePhone() {
      return this.truePhone.length === 10;
    },
    buttonMessage() {
      if (this.lastCodeSentAt) {
        const timeAfterLastSend = this.now - this.lastCodeSentAt;
        if (timeAfterLastSend <= this.codeSentCoolDown - 1000) {
          return `Подождите ${Math.floor((this.codeSentCoolDown - timeAfterLastSend) / 1000)} с`;
        }
      }
      return this.isFirst ? 'Получить код в SMS' : 'Отправить код повторно';
    },
  },
  watch: {
    phone() {
      this.error = '';
    },
  },

  async mounted() {
    this.updateNow();
    this.intervalInstance = setInterval(this.updateNow.bind(this), 1000);
    if (this.initPhone?.length) {
      this.phone = this.initPhone;
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalInstance);
  },
  methods: {
    updateNow() {
      this.now = Math.ceil(new Date().getTime());
    },
    async proceed() {
      if (!this.canProceed) {
        return;
      }
      try {
        this.isProccessing = true;
        this.isFirst = false;
        await requestRestorePassword(this.truePhone);
        this.$store.dispatch('user/setCodeRequestTime', new Date().getTime());
        this.$store.dispatch('user/setRegistrationEmailPhone', { phone: this.phone });
        this.$store.dispatch('user/setState', SignUpState.Code);
        this.$gtm.push(this.$dataLayer.recoveryRequestSmsSuccess(cutPhone(this.phone)));
      } catch (error) {
        console.error(error);
        this.$gtm.push(this.$dataLayer.recoveryRequestSmsFailed(cutPhone(this.phone)));
        const errors = error.graphQLErrors.map((x) => x.extensions?.exceptionClass);
        if (errors.includes('AccountNotFoundException')) {
          this.error = 'Этот номер не зарегистрирован.<br>Пройдите регистрацию';
        } else if (errors.includes('TimeoutNotExpiredException')) {
          this.error = 'Превышено количество попыток';
        } else {
          this.error = 'Что-то пошло не так. Повторите попытку';
        }
        throw error;
      } finally {
        this.throttledSend = false;
        this.isProccessing = false;
        this.$store.dispatch('user/incrementAttempt');
      }
    },
  },
};
</script>

<style lang="stylus">
.ds-restore
  .header-actions

    .to-signin
      Button(Large Ghost)

      +Media(Mobile)
        padding-left 0
  .restore-title
    Text(TitleM Semibold)
    padding-top 40px
    text-align center
  .restore-subtitle
    Text(TitleS)
    margin-top 24px
    text-align center

  .restore-form
    .action
      padding 6px
      .check path
        fill Green(LT4)
    .input-row
      margin-top 24px

      label
        .ui-input
          margin-top 8px

      .ui-input
        Input(Outlined)

        +Media(Laptop, Desktop, DesktopMax)
          Input(Large)

        +Media(Mobile, Tablet)
          Input(Medium)

    .form-actions
      .ui-button
        Button(Large Primary)
        width 100%
        white-space nowrap
        .ds-spinner
          margin-right 10px
</style>
