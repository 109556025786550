import { requestRestorePassword as RequestRestorePassword } from '~/apollo-api/types';
import { client, gql } from './index';

export const requestRestorePassword = async (phone: string) => {
  const { data } = await client.mutate<RequestRestorePassword>({
    mutation: gql`
      mutation requestRestorePassword($phone: String!) {
        requestRestorePassword(phone: $phone)
      }
    `,
    variables: {
      phone,
    },
  });

  return data?.requestRestorePassword;
};
