<template lang="pug">
.ds-promo-code
  .toggler(:class="{ opened: isOpened }")
    button-ui(@click="promocode.length > 0 ? ()=>{} :  toggle()") {{ title }}
      icon-ui(name="navigation/chevron-down_line" slot="after")
  .content(v-if="isOpened")
    input-ui(:placeholder="placeholder" @input="onInput" :value="promocode.length > 0 ? promocode : promoData")
      spinner-ds(v-if="isPromoLoad" class="promo-spinner" :size="18" slot="after")
      icon-ui(v-if="!isPromoLoad && iconName.length > 0" :name="iconName" size="30" height="30" slot="after" :fill="iconName === 'status/error_fill' ? 'black' : 'none'")
    .message(v-if="message") {{ message }}
</template>

<script>
import IconUi from '~/components/common/icon';
import InputUi from '~/components/common/input';
import ButtonUi from '~/components/common/button';
import { checkPromoCode } from '~/apollo-api/checkPromoCode';
import SpinnerDs from '~/components/spinner';
import { CheckoutMixin } from '~/mixins/business/checkout';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    IconUi,
    ButtonUi,
    InputUi,
    SpinnerDs,
  },
  mixins: [CheckoutMixin],
  props: {
    title: {
      type: String,
      default: 'Есть промокод?',
    },
    placeholder: {
      type: String,
      default: 'Промокод',
    },
    amountProducts: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      isOpened: false,
      timer: null,
      iconName: '',
      message: null,
      promoData: '',
    };
  },
  computed: {
    ...mapState('checkout', ['promocode', 'discountAmount', 'isPromoLoad']),
    ...mapGetters('cart', ['getList']),
    orderList() {
      if (!this.getList?.length) {
        return [];
      }
      return this.getList.map((x) => ({
        amount: x.amount,
        purchasePrice: x.sku.sellPrice,
        skuId: x.sku.id,
      }));
    },
  },
  watch: {
    deliveryType: {
      handler: function () {
        clearTimeout(this.timer);
        if (this.promocode.length > 0 && this.orderItemsToApi.length > 0) {
          this.timer = setTimeout(() => {
            this.sendRequest(this.promoData);
          }, 500);
        }
      },
      deep: true,
      immediate: true,
    },
    amountProducts: {
      handler: function () {
        clearTimeout(this.timer);
        if ((this.promocode.length > 0 || this.promoData.length > 0) && this.orderItemsToApi.length > 0) {
          this.timer = setTimeout(() => {
            this.sendRequest(this.promoData);
          }, 100);
        }
      },
      deep: true,
      immediate: true,
    },
    city: {
      handler: function () {
        clearTimeout(this.timer);
        if (this.promocode.length > 0 && this.orderItemsToApi.length > 0) {
          this.timer = setTimeout(() => {
            this.sendRequest(this.promoData);
          }, 500);
        }
      },
      deep: true,
      immediate: true,
    },
    address: {
      handler: function () {
        clearTimeout(this.timer);
        if (this.promocode.length > 0 && this.orderItemsToApi.length > 0) {
          this.timer = setTimeout(() => {
            this.sendRequest(this.promoData);
          }, 500);
        }
      },
      deep: true,
      immediate: true,
    },
    isOpened() {
      // force update for iphone
      this.sendRequest(this.promocode);
    },
  },

  mounted() {
    if (this.promocode.length > 0) {
      this.isOpened = true;
    }
  },

  methods: {
    toggle() {
      this.isOpened = !this.isOpened;
    },
    async sendRequest(value) {
      this.$store.dispatch('checkout/updateIsPromoLoad', true);
      await checkPromoCode({
        delivery: this.delivery,
        orderItems: this.isCart ? this.orderList : this.orderItemsToApi,
        promoCode: value,
      })
        .then((data) => {
          this.message = data.message;
          if (data.success) {
            this.$store.dispatch('checkout/updatePromocode', value);
            this.$store.dispatch('checkout/updateDiscountAmount', data.discountAmount);
            this.iconName = 'status/check_fill';
          } else {
            this.$store.dispatch('checkout/updatePromocode', '');
            this.$store.dispatch('checkout/updateDiscountAmount', 0);
            this.iconName = 'status/error_fill';
          }
        })
        .catch((error) => {
          this.message = error;
          if (error.message && error.message.includes('authorized')) {
            this.message = 'Для использования промокода нужно войти в аккаунт';
          }
          this.iconName = 'status/error_fill';
          this.$store.dispatch('checkout/updatePromocode', '');
          this.$store.dispatch('checkout/updateDiscountAmount', 0);
        })
        .finally(() => {
          this.$store.dispatch('checkout/updateIsPromoLoad', false);
        });
    },
    onInput(value) {
      // value = value.toUpperCase();
      clearTimeout(this.timer);
      this.promoData = value.toUpperCase().trimEnd();
      if (value.length > 0 && this.orderItemsToApi.length > 0) {
        this.timer = setTimeout(() => {
          this.sendRequest(this.promoData);
        }, 500);
      } else if (this.orderItemsToApi.length === 0) {
        this.timer = setTimeout(() => {
          this.sendRequest(this.promoData);
        }, 2000);
      } else {
        this.$store.dispatch('checkout/updatePromocode', '');
        this.$store.dispatch('checkout/updateDiscountAmount', 0);
        this.iconName = '';
        this.message = '';
        return;
      }
    },
  },
};
</script>

<style lang="stylus">
.ds-promo-code
  background-color Gray(L50)
  .toggler
    .ui-button
      Button()
      width 100%

      +Media(Laptop, Desktop, DesktopMax)
        Text(BodyL)
        padding 14px 16px
      +Media(Mobile, Tablet)
        Text(BodyM)
        padding 12px 16px

      .slot.default
        flex-grow 1

    &.opened
      .ui-icon
        transform rotate(180deg)

  .content
    padding 0px 16px 14px 16px

    .ui-input
      Input(Medium)
      background-color White()
      width: 100%
    .ds-spinner
      margin-right: 6px

    .message
      margin-top 16px
      font-size 12px
      line-height 15px
      color #757575
</style>
