<template lang="pug">
.ds-checkout-fragment
  .fragment-label
    slot(name="label") {{ label }}

  .fragment-content
    slot
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus">
.ds-checkout-fragment
  +Media(Laptop, Desktop, DesktopMax)
    margin-top 64px
  +Media(Mobile, Tablet)
    margin-top 32px

  .fragment-label
    Text(TitleM Short Secondary)

  .fragment-content
    +Media(Laptop, Desktop, DesktopMax)
      margin-top 24px
    +Media(Mobile, Tablet)
      margin-top 16px
</style>
