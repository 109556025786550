import { Observable, of, Subject, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';

import { PopupsContainerModel } from '~/modules/popups/models';

import PopupContainerConfig = PopupsContainerModel.IPopupComponentConfig;

export type TOpenedPopups = Partial<
  Record<
    PopupsContainerModel.ETypeWrapper,
    {
      component: PopupContainerConfig['component'];
      propsData?: Record<string, any>;
      isRouterOpened?: boolean;
    }
  >
>;

class PopupsServiceClass {
  constructor() {
    this.openedPopupsDataStreams$ = Object.values(PopupsContainerModel.ETypeWrapper).reduce(
      (acc, popupType: PopupsContainerModel.ETypeWrapper) => ({ ...acc, [popupType]: new Subject() }),
      {},
    );
  }

  private openedPopups: TOpenedPopups = {};
  private openedPopupsDataStreams$: Partial<Record<PopupsContainerModel.ETypeWrapper, Subject<any>>> = {};
  private _openedPopupsStream$: Subject<TOpenedPopups> = new Subject<TOpenedPopups>();
  private isAnimationInProgress = false;

  get openedPopupsStream$(): Observable<any> {
    return this._openedPopupsStream$.asObservable();
  }

  /**
   * Открыть popup, modal итд. с позицией на экране.
   * @param config - Обьект. (есть сам компонент и так же тип компонента)
   */
  public open<ReturnDataType>(config: PopupContainerConfig): Observable<ReturnDataType> {
    if (this.isAnimationInProgress || this.openedPopups[config.type]) {
      this.closeImmediate(config.type);
    }

    this.openedPopups = {
      ...this.openedPopups,
      [config.type]: {
        component: config.component,
        propsData: config.propsData,
        isRouterOpened: Boolean(config.isRouterOpened),
      },
    };

    this._openedPopupsStream$.next(this.openedPopups);

    if (config.withReturnData) {
      return new Observable((subscriber: Subscriber<any>) =>
        this.openedPopupsDataStreams$[config.type].pipe(take(1)).subscribe((data: any) => {
          subscriber.next(data);
          subscriber.complete();
        }),
      );
    }

    return of();
  }

  public close(type: PopupsContainerModel.ETypeWrapper, data: any = null): void {
    delete this.openedPopups[type];
    this._openedPopupsStream$.next(this.openedPopups);
    this.openedPopupsDataStreams$[type].next(data);
  }

  public closeAllPopups(): void {
    Object.keys(this.openedPopups).forEach((type) => this.closeImmediate(type as PopupsContainerModel.ETypeWrapper));
  }

  public closeRouteChangePopups(): void {
    Object.keys(this.openedPopups).forEach((type) => {
      if (!this.openedPopups[type].isRouterOpened) {
        this.closeImmediate(type as PopupsContainerModel.ETypeWrapper);
      }
    });
  }

  private closeImmediate(type: PopupsContainerModel.ETypeWrapper, data: any = null): void {
    this.isAnimationInProgress = false;
    delete this.openedPopups[type];
    this._openedPopupsStream$.next(this.openedPopups);
    this.openedPopupsDataStreams$[type].next(data);
  }
}

export const PopupsService = new PopupsServiceClass();
