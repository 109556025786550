
import Vue, { PropType } from 'vue';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';

import { TEXT_BLOCK_MAP } from '~/modules/search-suggestions/constants';
import { ESuggestBlocks, ITextSuggestBlock, TTextSuggest } from '~/modules/search-suggestions/models';

export default Vue.extend({
  components: {
    ButtonUi,
    IconUi,
  },

  props: {
    query: {
      type: String,
      default: '',
    },
    block: {
      type: Object as PropType<any>,
      required: true,
    },
    isUpdatingData: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    blockType(): TTextSuggest {
      return this.block.type;
    },
    blockData(): ITextSuggestBlock {
      return TEXT_BLOCK_MAP[this.blockType];
    },
  },

  methods: {
    formatValue(value: string): string {
      if (this.isUpdatingData) return value;
      return value.replace(
        this.query.toLowerCase(),
        `<span style='font-weight: bold'>${this.query.toLowerCase()}</span>`,
      );
    },
    removeAll() {
      if (this.blockType === ESuggestBlocks.Cache) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$store.commit('user/clearSearchHistory');
      }
    },
    removeItem(value: string) {
      if (this.blockType === ESuggestBlocks.Cache) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$store.commit('user/removeSearchHistoryValue', value);
      }
    },
    selectSuggestion(value: string) {
      this.$emit('select-suggestion', {
        type: this.blockType,
        title: value,
      });
    },
    mouseenter(value) {
      this.$emit('hover', value);
    },
    mouseleave() {
      this.$emit('leave');
    },
  },
});
