
import { Vue } from 'vue-property-decorator';
import ButtonUi from '../common/button.vue';
import IconUi from '../common/icon.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  components: {
    ButtonUi,
    IconUi,
  },
  inject: ['media'],
  computed: {
    ...mapState('main', ['askAdult']),
  },
  methods: {
    allowAdult(val) {
      this.$store.commit('main/updateAdult', val);
      this.$emit('close');
    },
  },
});
