<template lang="pug">
.ds-top-categories
  h1.categories-title Популярные категории
  .categories(v-if="hasTopCategories")
    .category-item(
      v-for="category in topCategories"
      :key="category.id"
    )
      link-ui.category-link(:to="{ name: 'catalog', params: { dshopCategoryId: category.id } }")
        img-smooth.category-image(
          v-if="hasPhoto"
          :src="category.image.high"
        )
        .category-title(:class="{ 'category-title-with-photo': hasPhoto }")
          button-ui.category-title-button {{ category.title }}
            icon-ui.category-title-icon(name="navigation/chevron-right_line" slot="after")
  .categories(v-else)
    .category-item(
      v-for="category in 3"
      :key="category"
    )
      div.category-link
        .category-title
          button-ui.category-title-button
            icon-ui.category-title-icon(name="navigation/chevron-right_line" slot="after")
</template>

<script>
import { mapGetters } from 'vuex';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import ButtonUi from '~/components/common/button';

const MAX_CATEGORY_COUNT = 3;

export default {
  components: {
    LinkUi,
    IconUi,
    ButtonUi,
  },
  computed: {
    ...mapGetters('catalog', ['sortedCategories']),
    topCategories() {
      return this.sortedCategories.slice(0, MAX_CATEGORY_COUNT);
    },
    hasTopCategories() {
      return this.topCategories.length > 0;
    },
    hasPhoto() {
      return this.topCategories.every((category) => category.image);
    },
  },
};
</script>

<style lang="stylus">
.ds-top-categories
  margin 24px 0
  +Media(Mobile, Tablet)
    margin 16px 0 32px
  .categories-title
    Text(TitleL Medium Secondary)
    margin-bottom 24px
    +Media(Mobile, Tablet)
      Text(TitleS Medium Short Secondary)
      margin-bottom 16px
      padding 6px 0
  .categories
    display grid
    +Media(Laptop, Desktop, DesktopMax)
      grid-template-columns repeat(3, 1fr)
    +Media(Desktop, DesktopMax)
      grid-gap 40px
    +Media(Laptop)
      grid-gap 32px
    +Media(Mobile, Tablet)
      grid-gap 4px
  .category-item
    width 100%
  .category-link
    display flex
    flex-direction column
    width 100%
    height 100%
  .category-title
    display flex
    justify-content center
    .category-title-button
      Button(Secondary Medium)
      Text(BodyM Semibold Short)
      height 100%
      width 100%
      display flex
      justify-content space-between
      height 46px
      +Media(Mobile, Tablet)
        height 40px
    .category-title-icon
      flex-shrink 0

  +Media(Mobile, Tablet)
    .category-image
      display none

  +Media(Laptop, Desktop, DesktopMax)
    .category-image
      flex-grow 1
      display block
      margin-bottom 8px
      width 100%
      background #EFEFEF
    .category-title-with-photo
      .category-title-button
        Button(Large)
        Text(BodyL Semibold Short)
        &:hover:not(.disabled)
          background-color transparent
      .category-title-icon
        display none
.top-category-skeleton
  height: 98px
  +Media(Mobile, Tablet)
    height: 178.5px
</style>
