import mediaSizes from './sizes.json';

export type TMediaSizes = keyof typeof mediaSizes;

const LayoutMap = {
  mobile: ['Mobile', 'Tablet'] as TMediaSizes[],
  desktop: ['Laptop', 'Desktop', 'DesktopMax'] as TMediaSizes[],
  m: ['Mobile'] as TMediaSizes[],
  t: ['Tablet'] as TMediaSizes[],
  l: ['Laptop'] as TMediaSizes[],
  d: ['Desktop'] as TMediaSizes[],
  dm: ['DesktopMax'] as TMediaSizes[],
  default: ['Mobile', 'Tablet', 'Laptop', 'Desktop', 'DesktopMax'] as TMediaSizes[],
};

export type TLayout = 'default' | keyof typeof LayoutMap;

export default LayoutMap;
