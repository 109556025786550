import { resendCode as ResendCode } from '~/apollo-api/types';
import { client, gql } from './index';

export const resendCode = async (phone: string) => {
  const { data } = await client.mutate<ResendCode>({
    mutation: gql`
      mutation resendCode($phone: String!) {
        resendCode(phone: $phone)
      }
    `,
    variables: {
      phone,
    },
  });

  return data?.resendCode;
};
