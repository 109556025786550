import {
  verifyRestoreCode as VerifyRestoreCode,
  verifyRestoreCodeVariables as VerifyRestoreCodeVariables,
} from '~/apollo-api/types';
import { client, gql } from './index';

export const verifyRestoreCode = async ({ code, phone }: VerifyRestoreCodeVariables) => {
  const { data } = await client.mutate<VerifyRestoreCode>({
    mutation: gql`
      mutation verifyRestoreCode($code: Int!, $phone: String!) {
        verifyRestoreCode(code: $code, phone: $phone)
      }
    `,
    variables: {
      code,
      phone,
    },
  });

  return data?.verifyRestoreCode;
};
