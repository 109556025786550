import {
  restorePassword as RestorePassword,
  restorePasswordVariables as RestorePasswordVariables,
} from '~/apollo-api/types';
import { client, gql } from './index';

export const restorePassword = async ({ phone, password, code }: RestorePasswordVariables) => {
  const { data } = await client.mutate<RestorePassword>({
    mutation: gql`
      mutation restorePassword($phone: String!, $password: String!, $code: Int!) {
        restorePassword(phone: $phone, password: $password, code: $code) {
          accessToken
          refreshToken
          customer {
            contacts {
              firstName
              phone
              lastName
              email
              sex
            }
          }
        }
      }
    `,
    variables: {
      phone,
      code,
      password,
    },
  });

  return data?.restorePassword;
};
