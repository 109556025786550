
import { Component, Emit, Prop, Provide, Vue } from 'vue-property-decorator';

import { Product_product } from '~/apollo-api/types';

import Layoutable from '~/plugins/layoutable/mixin';

export type TSelectSkuPopupProps = Product_product;

@Component({
  mixins: [Layoutable(['mobile', 'desktop'])],
})
export default class FavoriteSkuPopup extends Vue {
  @Prop(Object) readonly propsData!: TSelectSkuPopupProps;
  @Provide() product = this.propsData;

  @Emit('close')
  onClose() {}

  async import(layout) {
    return await import('./' + layout);
  }
}
