
import ClickOutside from 'vue-click-outside';
import { Component, Emit, Vue } from 'vue-property-decorator';

const FADEOUT_DELAY = 2000;

@Component({
  directives: {
    ClickOutside,
  },
})
export default class AddedToCartPopup extends Vue {
  @Emit('close')
  onClose() {}

  // eslint-disable-next-line no-undef
  fadeoutTimer: NodeJS.Timeout | undefined;

  mounted() {
    this.fadeoutTimer = setTimeout(() => {
      this.onClose();
    }, FADEOUT_DELAY);
  }

  beforeDestroy() {
    if (this.fadeoutTimer) {
      clearTimeout(this.fadeoutTimer);
    }
  }
}
