<template lang="pug">
.checkout-delivery-summary(v-if="deliveryType && !isPayedOrder")
  template(v-if="deliveryType===DeliveryType.RUSSIAN_POST")
    .frame-delivery(v-if="isLoadingExpected || expectedDays")
      transition(name="fade" mode="out-in")
        .loading(v-if="isLoadingExpected" key="loading")
          spinner-ds(:size="26")
          .label Рассчитываем дату доставки

        .delivery-post(v-else-if="expectedDays" key="delivery")
          icon-ui.icon(name="status/postmail_line")
          .text
            .title
              strong Доставка Почтой России
            .subtitle доставим до {{ expectedDate() }}
  .frame-delivery(v-if="deliveryType===DeliveryType.COURIER && courierSubtitle && courierSubtitle.length" key="courier")
    icon-ui.icon(name="status/carrier_line")
    .text
      .title
        strong Доставка курьером
      .subtitle {{ courierSubtitle }}
  .frame-delivery(v-if="deliveryType===DeliveryType.DELIVERY_POINT" key="dp")
    icon-ui.icon(name="status/pickpoint_line")
    .text
      .title
        strong Бесплатная доставка
        br
        | в пункты выдачи {{ hiddenPoint && hiddenPoint.isPost ? 'в отделении Почты': staticData.ownDpName }}
        br
        br
        | {{ cityPoint ? deliveryDate : '' }}
      .subtitle {{ cityPoint ? 'Срок доставки может быть скорректирован' : '' }}
</template>

<script>
import IconUi from '~/components/common/icon';
import { mapGetters, mapState } from 'vuex';
import { dateToLocale } from '~/modules/common/date';
import { CanCheckoutMixin } from '~/mixins/business/checkout';
import { DeliveryType } from '~/modules/checkout/types';
import SpinnerDs from '~/components/spinner';
import { staticData } from '~/shop-config';

// dateToLocale options
const options = { day: 'numeric', month: 'long' };

export default {
  components: { IconUi, SpinnerDs },
  mixin: [CanCheckoutMixin],
  data: () => ({
    DeliveryType,
    staticData,
  }),
  computed: {
    ...mapState('checkout', ['deliveryType', 'city', 'address', 'date', 'time', 'isLoadingExpected', 'hiddenPoint']),
    ...mapState('checkout', {
      expectedDays: (state) => state.expectedDelivery.maxDays,
    }),
    ...mapGetters('checkout', ['cityPoint']),
    deliveryDate() {
      let currentDate = new Date();
      const deliveryDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000);
      const lastDate = new Date(currentDate.getTime() + 5 * 24 * 60 * 60 * 1000);
      const diffDays = Math.ceil((deliveryDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

      let totalString = 'Ожидаемая дата доставки — ';
      switch (diffDays) {
        case 0:
          totalString += ' сегодня, ';
          break;
        case 1:
          totalString += ' завтра, ';
          break;
        case 2:
          totalString += ' послезавтра, ';
          break;
        default:
          totalString += ' ';
      }
      totalString += `${deliveryDate.getDate()}-${dateToLocale(lastDate, options)}`;

      return totalString;
    },
    courierSubtitle() {
      if (this.deliveryType !== DeliveryType.COURIER || !this.date?.value) {
        return '';
      }
      let totalString = '';

      const deliveryDate = new Date(this.date.value);
      const diffDays = Math.ceil((deliveryDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

      let dayTitle;
      switch (diffDays) {
        case 0:
          dayTitle = ' сегодня, ';
          break;
        case 1:
          dayTitle = ' завтра, ';
          break;
        case 2:
          dayTitle = ' послезавтра, ';
          break;
        default:
          dayTitle = ' ';
      }
      totalString += `доставим${dayTitle}`;
      totalString += dateToLocale(deliveryDate, options);
      if (this.time?.label) {
        totalString += `, ${this.time.label}`;
      }
      return totalString;
    },
    isPayedOrder() {
      return this.hiddenPoint ? this.hiddenPoint.isPayed : false;
    },
  },
  methods: {
    expectedDate() {
      if (!this.expectedDays) {
        return '';
      }
      const now = new Date();
      const expectedDate = new Date(now);
      expectedDate.setDate(expectedDate.getDate() + this.expectedDays);
      return dateToLocale(expectedDate, options);
    },
  },
};
</script>
<style lang="stylus">
.fade-enter-active
.fade-leave-active
  transition opacity .5s

.fade-enter
.fade-leave-to
  opacity 0

.checkout-delivery-summary
  .loading
    display flex
    align-items center
    height 40px
    margin-left 7px
    .label
      Text(BodyM)
      margin-left 23px
  .delivery-post
    display flex
  .frame-delivery
    display flex
    border solid 1px Divider()
    margin-top 16px
    padding 24px
  .icon
    height 40px
    width 40px
    border 1.5px solid TextPrimary()
    border-radius 50%
    justify-content center
    align-items center
    padding 8px
  .text
    margin-left 16px
    .title
      Text(BodyM)
      strong
        font-weight 600
    .subtitle
      Text(CaptionM)
      color TextSecondary()
      margin-top 8px
</style>
