import { mapProductCartItem } from './event-addtocart';

export const removeFromCartEvent = function (product, sku, amount) {
  return {
    event: 'remove_from_cart',
    ecommerce: {
      value: sku.sellPrice / 100,
      currency: 'RUB',
      items: [mapProductCartItem(product, sku, amount)],
    },
  };
};
