export const LISTITEMS = {
  catalog: 'Каталоги',
  search: 'Результаты поиска',
  product: 'Товары',
  favorite: 'Избранное',
};

export const BANNERSLOTS = {
  'MAIN.MAIN': 'Главная страница: Первый экран:',
};

export const DELIVERYTYPES = {
  RUSSIAN_POST: 'почта России',
  DELIVERY_POINT: 'пункт выдачи',
  COURIER: 'курьер',
};
