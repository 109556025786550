export const state = () => ({
  list: [],
});

export const mutations = {
  updateList(state, payload) {
    state.list = payload;
  },
  updateItem(state, payload) {
    const foundOrders = state.list.find((el) => el.id === payload.id);

    if (foundOrders) {
      foundOrders.amount = payload.amount;
    }
  },
};

export const actions = {
  update({ commit }, payload) {
    commit('updateList', payload);
  },
  changeAmount({ commit }, payload) {
    commit('updateItem', payload);
  },
};

export const getters = {};
