<template lang="pug">
button.ui-component.ui-button(
  :class="{ disabled }"
  v-bind="$attrs"
  v-on="$listeners"
)
  .slot.before(v-if="hasSlot('before')")
    slot(name="before")
  .slot.default(v-if="hasSlot('default')")
    slot
  .slot.after(v-if="hasSlot('after')")
    slot(name="after")
</template>

<script>
export default {
  name: 'ButtonUi',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hasSlot(name) {
      return ['$scopedSlots', '$slots'].some((slotType) =>
        typeof name === 'string' ? !!this[slotType][name] : !!Object.keys(this[slotType]).find((_) => name.test(_)),
      );
    },
  },
};
</script>
<style lang="stylus">
.ui-button
  Button()
  Round(Small)
  .slot
    ButtonSlot()
    Round(Small)
</style>
