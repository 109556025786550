
import Vue, { PropType } from 'vue';

import { ProductBadgesFragment_BottomTextBadge } from '~/apollo-api/types';

import { StaticDataMixin } from '~/mixins/staticData';

export default Vue.extend({
  name: 'ProductBadges',
  mixins: [StaticDataMixin],
  props: {
    badges: {
      type: Array as PropType<ProductBadgesFragment_BottomTextBadge[]>,
      required: true,
    },
    fullPrice: {
      type: Number,
      required: true,
    },
    sellPrice: {
      type: Number,
      required: true,
    },
  },
  computed: {
    discountBadgeText(): string {
      const discount = Math.round(((this.fullPrice - this.sellPrice) / this.fullPrice) * 100);
      return discount ? `-${discount}%` : '';
    },
  },
  methods: {
    getBadgeStyles(badge) {
      return {
        backgroundColor: badge.backgroundColor,
        color: badge.textColor,
      };
    },
  },
});
