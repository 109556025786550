<template lang="pug">
  .checkout-header
    .ui-wrapper
      component.left-subheader(:is="asyncComponent")
      .actions
        link-ui.action(:to="static.support.chat")
          labeled-icon-ds.action-labeled-icon(name="navigation/chat_sharp" size="28") Чат поддержки
        button-ui.action(@click.stop="onCabinetClick")
          labeled-icon-ds.action-labeled-icon(name="navigation/cabinet_sharp" size="28") {{ username }}
</template>

<script>
import LabeledIconDs from '~/components/labeled-icon';
import Layoutable from '~/plugins/layoutable/mixin';
import LinkUi from '~/components/common/link';
import ButtonUi from '~/components/common/button';
import { StaticDataMixin } from '~/mixins/staticData';
import { HeaderActionsMixin } from '~/mixins/header-actions-mixin';

export default {
  components: { LabeledIconDs, LinkUi, ButtonUi },
  mixins: [Layoutable(['mobile', 'desktop']), StaticDataMixin, HeaderActionsMixin],
  methods: {
    async import(l) {
      return await import('./' + l);
    },
  },
};
</script>

<style lang="stylus">
.checkout-header
  height 48px
  display flex
  align-items center
  +Media(Tablet, Mobile)
    box-shadow 0 1px 0 rgba(black, 0.1)
  +Media(Laptop)
    height 80px
  +Media(DesktopMax, Desktop)
    height 96px

  .ui-wrapper
    display flex

    .actions
      margin-left auto
      display flex
      align-items center

    .action
      display flex
      align-items center
      justify-content center
      color BlueBrand()
      +Media(Tablet, Mobile)
        width 48px
        height 48px
      +Media(Laptop)
        width 54px
        height 40px
      +Media(DesktopMax, Desktop)
        min-width 120px
        height 48px
        padding 4px 16px
        border-radius 12px
        &:hover
          background-color #E9EBF0

      &:not(:last-child)
        +Media(DesktopMax, Desktop)
          margin-right 8px
        +Media(Laptop)
          margin-right 16px

    .action-labeled-icon
      +Media(DesktopMax, Desktop)
        svg
          margin-right 4px

      svg path
        fill BlueBrand()
</style>
