import { DefaulNumbertLocale, numberFormat } from './number';

export const KopsInRub = 100;
export const defaultPriceFormatOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0, // delete 0 after decimal points
};

export const format = (
  c: number,
  locales: string | string[] = DefaulNumbertLocale,
  options: Intl.NumberFormatOptions = defaultPriceFormatOptions,
) => numberFormat(locales, options).format(c / KopsInRub);

export const calculateDiscountPercentage = (price, discountedPrice) => {
  const difference = price - discountedPrice;
  const percentage = Math.round((difference / price) * 100);
  return `-${percentage}%`;
};
