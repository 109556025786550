
import Vue from 'vue';

import ending from '~/modules/common/ending';

import IconUi from '~/components/common/icon.vue';

export default Vue.extend({
  name: 'ProductCardFeedback',
  components: {
    IconUi,
  },
  props: {
    rating: {
      type: Number,
      required: true,
    },
    feedbackQuantity: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formattedRating(): string {
      return this.rating.toFixed(1);
    },
    formattedFeedbackQuantity(): string {
      return `${this.feedbackQuantity} ${ending(this.feedbackQuantity, ['оценка', 'оценки', 'оценок'])}`;
    },
  },
});
