export const getBucketData = async (url) => {
  const fetchOptions = {
    method: 'GET',
  };
  try {
    const response = await fetch(`${url}`, fetchOptions);
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
  }
};
