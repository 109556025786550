
import Vue, { PropType } from 'vue';
import ClickOutside from 'vue-click-outside';

import {
  FilterInput,
  FilterInputType,
  FilterType,
  MakeSearchFilters_makeSearch_facets,
  MakeSearchFilters_makeSearch_facets_filter,
  MakeSearchQueryInput,
} from '~/apollo-api/types';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';

import FilterRangedValueDs from '~/modules/search/components/filters/filter-blocks/filter-ranged-value.vue';

export const getComponentByFilterType = (type: FilterType) => {
  const filters = {
    [FilterType.RANGED_VALUE]: FilterRangedValueDs,
  };

  return filters[type];
};

export default Vue.extend({
  directives: { ClickOutside },
  components: {
    ButtonUi,
    IconUi,
  },
  props: {
    facet: {
      type: Object as PropType<MakeSearchFilters_makeSearch_facets>,
    },
    queryInput: {
      type: Object as PropType<MakeSearchQueryInput>,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    currentQueryInput(): FilterInput | undefined {
      return this.queryInput.filters.find((value) => Number(value.id) === this.facet.filter.id);
    },
    isNonEmpty(): boolean {
      return Boolean(this.currentQueryInput);
    },
  },
  methods: {
    getComponentByFilterType: getComponentByFilterType,
    updateQueryInput(
      filter: MakeSearchFilters_makeSearch_facets_filter,
      newValue: Partial<MakeSearchFilters_makeSearch_facets_filter>,
    ) {
      this.$emit('update-query-input', {
        filterType: filter.type === FilterType.RANGED_VALUE ? FilterInputType.RANGE : FilterInputType.LIST,
        id: filter.id,
        ...newValue,
      });
    },
    onToggle() {
      this.isOpen = !this.isOpen;
    },
    onClose() {
      this.isOpen = false;
    },
  },
});
