
import Vue, { PropType } from 'vue';
import ClickOutside from 'vue-click-outside';

import { MakeSearchFilters_makeSearch_category, MakeSearchFilters_makeSearch_categoryTree } from '~/apollo-api/types';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';
import LinkUi from '~/components/common/link.vue';
import ending from '~/modules/common/ending';

const COLUMNS_COUNT = 2;

export default Vue.extend({
  directives: {
    ClickOutside,
  },
  components: {
    ButtonUi,
    IconUi,
    LinkUi,
  },
  props: {
    category: {
      type: Object as PropType<MakeSearchFilters_makeSearch_category | null>,
      default: null,
    },
    categoryTree: {
      type: Array as PropType<MakeSearchFilters_makeSearch_categoryTree[]>,
      required: true,
    },
    isCatalog: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    subCategories(): MakeSearchFilters_makeSearch_categoryTree[] {
      if (!this.category) {
        return [];
      }
      if (this.isCatalog) {
        return this.categoryTree.map((value) => {
          return {
            category: value,
          } as any;
        });
      }

      return this.categoryTree.filter(
        (value) => value.category.parent && value.category.parent.id === this.category?.id,
      );
    },
    hasSubCategories(): boolean {
      return Boolean(this.subCategories.length);
    },
    separatedSubCategories(): MakeSearchFilters_makeSearch_categoryTree[][] {
      const chunkSize = Math.ceil(this.subCategories.length / COLUMNS_COUNT);
      const data: MakeSearchFilters_makeSearch_categoryTree[][] = [];
      for (let i = 0; i < this.subCategories.length; i += chunkSize) {
        const chunk = this.subCategories.slice(i, i + chunkSize);
        data.push(chunk);
      }

      return data;
    },
  },
  created() {
    this.isOpen = this.$device.isCrawler;
  },
  methods: {
    onToggle(): void {
      if (this.hasSubCategories) {
        this.isOpen = !this.isOpen;
      }
    },
    onClose(): void {
      this.isOpen = false;
    },
    getProducts(total) {
      const title = `${total} ${ending(total, ['товар', 'товара', 'товаров'])}`;
      return title;
    },
  },
});
