import { FavoriteSort } from '~/apollo-api/types';

export const SORT_DATA = {
  [FavoriteSort.PRICE_ASCENDING]: 'Дешевле',
  [FavoriteSort.PRICE_DESCENDING]: 'Дороже',
  [FavoriteSort.DATE_ADDED_DESCENDING]: 'Недавно добавленные',
  // [FavoriteSort.DISCOUNT_ASCENDING]: 'С большей скидкой',
};

export const DEFAULT_SORT_VALUE = FavoriteSort.PRICE_ASCENDING;
