import { mapProductFavoriteItem } from './event-addtofavorite';

export const removeFromFavoriteEvent = function (product, sku, amount) {
  return {
    event: 'remove_from_wishlist',
    ecommerce: {
      value: sku.sellPrice / 100,
      currency: 'RUB',
      items: [mapProductFavoriteItem(product, sku, amount)],
    },
  };
};
