import { Mixin } from 'vue-mixin-decorator';
import { Vue } from 'vue-property-decorator';

import SignInPopup, { TSignInPopupProps } from '~/components/popups/sign-in-popup.vue';
import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService } from '~/modules/popups/services/popups-service';

@Mixin
export default class SignInPopupMixin extends Vue {
  openSignInPopup(formData: TSignInPopupProps['formData'] = {}, props = {}) {
    PopupsService.open({
      type: PopupsContainerModel.ETypeWrapper.CENTER,
      component: SignInPopup,
      propsData: {
        formData,
        ...props,
      },
    });
  }
}
