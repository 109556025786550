<template lang="pug">
.ds-verify-phone(@keyup.enter="proceed")
  .header-actions
    button-ui.change-number(v-if="!isVerifyPhone" @click.stop="changeNumber") Изменить номер
      icon-ui(
        slot="before"
        name="navigation/chevron-left_line"
      )
  .verify-phone-container
    .verify-phone-title Введите 6-значный код подтверждения отправленный<br>на #[span.phone {{ phone }}]
    .verify-phone-form
      .input-login.input-row
        labeled-input-ds(
          type="number"
          mask="######"
          v-model="code"
          autofocus
          ref="input"
          label="Введите код из SMS:"
          autocomplete="one-time-code"
          :is-error="!!error.length"
        )
      label(v-if="error.length" v-html="error").error
      .form-actions.input-row
        button-ui.process(
          :disabled="!canProceed || isProccessing"
          @click="proceed"
        ) Подтвердить
          spinner-ds(
            slot="before"
            v-if="isProccessing"
            :size="20"
          )
    .verify-phone-footer
      button-ui.send-again(:disabled="!canSendAgain" @click.stop="tryResendCode") {{ buttonMessage }}
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IconUi from '~/components/common/icon';
import SpinnerDs from '~/components/spinner';
import ButtonUi from '~/components/common/button';
import LabeledInputDs from '~/components/labeled-input';
import { verifyPhone } from '~/apollo-api/verifyPhone';
import { resendCode } from '~/apollo-api/resendCode';
import { verifyRestoreCode } from '~/apollo-api/verifyRestoreCode';
import { SignUpState } from '~/store/user';
import { cutPhone } from '~/utils/phoneTransform';

export default {
  components: {
    LabeledInputDs,
    IconUi,
    ButtonUi,
    SpinnerDs,
  },
  props: {
    isRestore: {
      type: Boolean,
      default: false,
    },
    isVerifyPhone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: '',
      isProccessing: false,
      error: '',
      isOnCoolDown: false,
      isFirstAttempt: true,
      lastSent: 0,
      codeSentCoolDown: 60000,
      codeLength: 6,
      now: new Date().getTime(),
      isTry: 0,
    };
  },
  watch: {
    async code(val) {
      this.error = '';
      if (val.length === this.codeLength && this.isFirstAttempt) {
        this.isFirstAttempt = false;
        await this.proceed();
      }
    },
    attempt() {
      if (this.attempt >= 3) {
        this.isOnCoolDown = true;
        setTimeout(() => {
          this.isOnCoolDown = false;
        }, this.codeSentCoolDown);
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('user', {
      hasResendCodeRequest: (state) => state.registration.hasResendCodeRequest,
    }),
    canProceed() {
      return this.code.length === this.codeLength;
    },
    canSendAgain() {
      return this.now - this.lastCodeSentAt > this.codeSentCoolDown;
    },
    phone() {
      return this.$store.state.user.registration.phone;
    },
    truePhone() {
      return this.phone.replace('+7', '').replace(/[^0-9]/g, '');
    },
    trueCode() {
      return Number(this.code);
    },
    buttonMessage() {
      if (this.lastCodeSentAt) {
        const timeAfterLastSend = this.now - this.lastCodeSentAt;
        if (timeAfterLastSend <= this.codeSentCoolDown - 1000) {
          return `Отправить код повторно (${Math.floor((this.codeSentCoolDown - timeAfterLastSend) / 1000)})`;
        }
      }
      return 'Отправить код повторно';
    },
    lastCodeSentAt() {
      return this.$store.state.user.registration.lastCodeSentAt;
    },
    attempt() {
      return this.$store.state.user.registration.attempt;
    },
  },

  mounted() {
    this.intervalInstance = setInterval(this.updateNow.bind(this), 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalInstance);
  },
  methods: {
    ...mapActions({
      setHasResendCodeRequest: 'user/setHasResendCodeRequest',
    }),
    updateNow() {
      this.now = Math.ceil(new Date().getTime());
    },
    async tryResendCode() {
      if (!this.canSendAgain) {
        return;
      }
      this.isTry++;
      try {
        this.$store.dispatch('user/setCodeRequestTime', new Date().getTime());
        await resendCode(this.truePhone);
        if (this.isRestore) {
          this.$gtm.push(this.$dataLayer.recoverySubmitSmsRetry(cutPhone(this.phone)));
        } else {
          this.$gtm.push(this.$dataLayer.registrationSubmitSmsRetry(cutPhone(this.phone)));
        }
      } catch (error) {
        console.error(error);
        const errors = error.graphQLErrors.map((x) => x.extensions?.exceptionClass);
        if (errors.includes('TimeoutNotExpiredException')) {
          this.error = 'Превышено количество попыток';
        } else {
          this.error = 'Что-то пошло не так. Повторите попытку';
        }
        throw error;
      } finally {
        this.setHasResendCodeRequest(true);
      }
    },
    async proceed() {
      if (!this.canProceed) {
        return;
      }

      try {
        this.isProccessing = true;
        this.lastSent = new Date().getTime();
        if (this.isRestore || this.hasResendCodeRequest) {
          await verifyRestoreCode({
            code: this.trueCode,
            phone: this.truePhone,
          });
          this.$gtm.push(this.$dataLayer.registrationSubmitSmsSuccess(cutPhone(this.phone)));
        } else {
          await verifyPhone(this.trueCode);
          this.$gtm.push(this.$dataLayer.registrationSubmitSmsSuccess(cutPhone(this.phone)));
        }
        this.$store.dispatch('user/setCode', this.trueCode);
        this.$store.dispatch('user/setState', SignUpState.Password);
      } catch (error) {
        console.error(error);
        const errors = error.graphQLErrors.map((x) => x.extensions?.exceptionClass);
        this.$gtm.push(this.$dataLayer.registrationFailed(cutPhone(this.phone)));
        if (this.isRestore) {
          this.$gtm.push(this.$dataLayer.recoverySubmitSmsFailes(cutPhone(this.phone)));
        }
        if (errors.includes('VerificationCodeMismatchException')) {
          this.error = 'Неверный код';
        } else if (errors.includes('TimeoutNotExpiredException')) {
          this.error = 'Превышено количество попыток';
        } else {
          this.error = 'Что-то пошло не так. Повторите попытку';
        }
        throw error;
      } finally {
        this.isProccessing = false;
      }
    },
    changeNumber() {
      this.$store.dispatch('user/setState', SignUpState.Phone);
    },
  },
};
</script>

<style lang="stylus">
.ds-verify-phone
  background-color #fff

  .header-actions
    margin-bottom 32px

    .change-number
      Button(Large Ghost)

      +Media(Mobile)
        padding-left 0

  .verify-phone-container
    margin 0 auto

    +Media(DesktopMax)
      width 350px

    +Media(Tablet, Laptop, Desktop)
      width 310px

  .verify-phone-title
    Text(TitleS)
    text-align center

    .phone
      white-space nowrap

  .verify-phone-form
    .input-row
      margin-top 24px

      label
        .ui-input
          margin-top 8px

      .ui-input
        Input(Outlined)

        +Media(Laptop, Desktop, DesktopMax)
          Input(Large)

        +Media(Mobile, Tablet)
          Input(Medium)

        input[type='number']
          -moz-appearance textfield

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button
            -webkit-appearance none

    .form-actions
      .ui-button

        width 100%

        &.process
          Button(Large Primary)
          .ds-spinner
            margin-right 10px
        &.repeat
          Button(Large Ghost)

          .cool-down
            padding-left 8px
  .verify-phone-footer
    .send-again
      Text(BodyM Semibold)
      Button()
      width 100%
      margin 32px auto 0
      &.disabled
        color Gray(L16)
</style>
