import Vue from 'vue';
import { staticData } from '~/shop-config';
import { getBucketData } from '~/api/getBucketData';
import { uri } from '~/apollo-api/client/http-link';
import { mapState } from 'vuex';
export const StaticDataMixin = Vue.extend({
  data: () => ({
    static: staticData,
  }),
  computed: {
    ...mapState('deliveryMap', ['fiasCities']),
    localCities() {
      return this.fiasCities.flatMap((item) => item.city);
    },
  },
  methods: {
    async getPoints() {
      const url = `https://storage.yandexcloud.net/psb-pvz-bucket/${!uri.includes('dev') ? 'Pickpoints.json' : 'test'}`;
      await getBucketData(url).then((data) => {
        const currentDate = Math.ceil(new Date().getTime() / 1000);
        const cities = data.checkoutFias
          .map((city) => ({
            ...city,
            name: city.city,
            points: city.points.filter((point) => {
              if (point.dateOpened) {
                return currentDate > point.dateOpened;
              }
              return true;
            }),
            deliveryPoints: [city.kePoint],
          }))
          .filter((city) => city.points.length > 0);
        this.$store.dispatch('deliveryMap/updateFiasCities', cities);
      });
    },

    async getCustomCities() {
      const url = `https://storage.yandexcloud.net/psb-pvz-bucket/${
        !uri.includes('dev') ? 'CustomCities.json' : 'testCustomCities'
      }`;
      await getBucketData(url).then((data) => {
        this.static.customCities = data;
      });
    },

    async getDescCatalogList() {
      const url = `https://storage.yandexcloud.net/psb-pvz-bucket/DescCatalogList`;
      await getBucketData(url).then((data) => {
        this.static.DescCatalogList = data;
      });
    },
  },
});
