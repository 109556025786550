<template lang="pug">
button-ui.button-active-favorite(
  :disabled="disabled"
)
  icon-ui.add-icon(v-if="active" name="operations/add-to-favorites_filled" size="28")
  icon-ui.remove-icon(v-else name="operations/add-to-favorites_line" size="28" :class="gray ? 'gray' : ''")
</template>

<script>
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';

export default {
  name: 'ButtonFavorite',
  components: {
    IconUi,
    ButtonUi,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    gray: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="stylus" scoped>
.button-active-favorite
  .add-icon >>> path
    fill OrangeBrand()

  .remove-icon >>> path:first-child
    fill Black()

  &:hover .remove-icon >>> path
    fill OrangeBrand()

  .gray >>> path:first-child
    fill #C2C4CC
</style>
