import Vue from 'vue';

export const SelectedSkuMixin = Vue.extend({
  data: () => ({
    selectedSku: null,
  }),
  computed: {
    isUnavailable() {
      return !this.selectedSku || this.selectedSku.availableAmount === 0;
    },
  },
  watch: {
    selectedSku: {
      immediate: true,
      handler() {
        this.sendEventChange();
      },
    },
  },
  methods: {
    sendEventChange() {
      this.$eventManager.emit('change-sku-amount', { sku: this.selectedSku, amount: this.amount || 1 });
    },
    onSkuSelect(selectedSku) {
      this.selectedSku = selectedSku;
    },
  },
});
