import { getCart, setCart } from '~/modules/cart/api';
import Cookies from 'js-cookie';
export const mergeCart = async (context) => {
  const storage = Cookies.get('auth');
  if (!storage) {
    return;
  }

  const accessToken = JSON.parse(storage).accessToken;
  if (!accessToken) {
    return;
  }

  let cartLocal = context.rootState.cart.list;

  try {
    await setCart(cartLocal.map((_) => ({ amount: _.amount, skuId: _.sku.id })));
  } catch (e) {
    console.error(e);
  }

  try {
    cartLocal = await getCart(accessToken);
  } catch (e) {
    console.error(e);
  }

  context.dispatch('cart/update', cartLocal, { root: true });
};
