<template lang="pug">
.ds-dropdown(tabindex="1")
  .drop-target
    slot
  .drop-list(:class="{ 'static-list':  showList }")
    template(v-if="mappedList.length > 0")
      .drop-option(
        v-for="option in mappedList"
        :key="getOptionValue(option)"
        :class="{ selected: getOptionValue(option) === getOptionValue(value) }"
        @click="select(option)"
      )
        slot(name="option" :option="option")
          div {{ getOptionLabel(option) }}
</template>

<script>
import { v4 as guidGenerator } from 'uuid';
export default {
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    valueAttribute: {
      type: String,
      default: 'value',
    },
    labelAttribute: {
      type: String,
      default: 'label',
    },
    value: {
      type: undefined,
      default: undefined,
    },
    showList: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mappedList() {
      return this.list.map((el) => {
        if (typeof el !== 'object') {
          return {
            [this.valueAttribute]: el,
            [this.labelAttribute]: el,
          };
        } else {
          return el;
        }
      });
    },
  },
  methods: {
    getOptionValue(option) {
      return option ? option[this.valueAttribute] : undefined;
    },
    getOptionLabel(option) {
      return option ? option[this.labelAttribute] : undefined;
    },
    select(option) {
      this.$emit('select', option);
      this.$el.blur();
    },
    blur() {
      const focusedElements = this.$el.querySelectorAll(':focus');

      if (focusedElements.length > 0) {
        focusedElements.forEach((el) => el.blur());
      }
    },
  },
};
</script>

<style lang="stylus">
.ds-dropdown
  position relative

  &:focus-within,
  &:focus
    .drop-list
      display block

  .drop-list
    display none
    position absolute
    z-index 5
    right 0
    left 0
    top 100%
    box-shadow 0px 4px 16px rgba(0, 0, 0, 0.16), 0px 2px 8px rgba(0, 0, 0, 0.08)
    background-color #fff
    max-height 156px
    overflow-y auto

    .drop-option
      padding 8px 16px
      color: #27282A
      font-size: 14px
      line-height: 120%

      &:hover,
      &.selected
        cursor pointer
        background-color Gray(L48)

      &.selected
        Text(BodyM Semibold)
  .static-list
    display block
    position static
    box-shadow none
    margin-top 8px
    max-height: 400px
    .drop-option
      padding 12px 0px
</style>
