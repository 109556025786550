import { BANNERSLOTS } from './listitems';

export const selectBannerEvent = function (promotionName, section, index) {
  return bannerEvent('select_promotion', promotionName, section, index);
};

export const viewBannerEvent = function (promotionName, section, index) {
  return bannerEvent('view_promotion', promotionName, section, index);
};

const bannerEvent = function (event, promotionName, section, index) {
  const creativeSlot = (BANNERSLOTS[section] ?? '') + ' ' + index++;

  return {
    event: event,
    ecommerce: {
      promotion_name: promotionName,
      creative_slot: creativeSlot,
    },
  };
};
