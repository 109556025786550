
import Vue, { PropType } from 'vue';
import InfiniteLoading from 'vue-infinite-loading';
import { getProduct } from '~/apollo-api/getProduct';
// import { search, searchFilters } from '~/apollo-api/search';

import { getFavorite } from '~/apollo-api/favorite';

import { FavoriteList, FavoriteQueryInput } from '~/apollo-api/types';
import { throttle } from '~/modules/common/throttle';

import SpinnerUi from '~/components/spinner.vue';
import AddedToFavorite from '~/components/popups/add-favorite-popup.vue';

import FavoriteFormDs from '~/modules/favorite/components/favorite-form.vue';
import FavoriteProductsDs from '~/modules/favorite/components/favorite-products.vue';

import { DEFAULT_QUERY_INPUT, DEFAULT_PAGINATION } from '~/modules/favorite/constants';
import { FavoriteSortValue } from '~/apollo-api/types';
import { add, remove } from '~/apollo-api/favorite';
import { PopupsService } from '~/modules/popups/services/popups-service';
import { PopupsContainerModel } from '~/modules/popups/models';

const THROTTLE_TIME = 600;
const LOADING_DISTANCE = 300;

export default Vue.extend({
  components: {
    InfiniteLoading,
    SpinnerUi,
    FavoriteFormDs,
    FavoriteProductsDs,
  },
  props: {
    searchType: {
      type: String,
    },
  },
  data() {
    return {
      loadingDistance: LOADING_DISTANCE,
      queryInput: DEFAULT_QUERY_INPUT,
      data: null as FavoriteList | null,
      // eslint-disable-next-line @typescript-eslint/ban-types
      throttledInfiniteSearch: undefined as Function | undefined,
      isNeedFullUpdate: false,
    };
  },
  watch: {
    '$route.query': {
      handler() {
        this.queryInput = {
          ...this.queryInput,
          pagination: {
            ...this.queryInput.pagination,
          },
        };

        if (this.data) {
          this.resetInfiniteSearch();
        }
      },
    },
    queryInput: {
      deep: true,
      handler(newValue, oldValue) {
        this.updateQueryRoute(newValue, oldValue);
      },
    },
  },
  created() {
    this.setInitialQueryInput();
    this.throttledInfiniteSearch = throttle(this.onInfiniteSearch, THROTTLE_TIME);
  },
  methods: {
    resetInfiniteSearch() {
      if (this.data) {
        this.data = {
          ...this.data,
          items: [],
        };
      }
      this.isNeedFullUpdate = true;
      (this.$refs.infinite as any).stateChanger.reset();
    },
    async onInfiniteSearch($state) {
      if (!this.$isServer) {
        try {
          const response = await getFavorite({
            page: this.queryInput.pagination.page,
            size: this.queryInput.pagination.size,
            sortBy: FavoriteSortValue[this.queryInput.sort].sortBy,
            order: FavoriteSortValue[this.queryInput.sort].order,
          });

          if (!response) {
            this.data = null;
            $state.complete();
            return;
          }
          if (response.length) {
            this.queryInput = {
              ...this.queryInput,
              pagination: {
                ...this.queryInput.pagination,
                page: this.queryInput.pagination.page + 1,
              },
            };
            this.data = {
              items: !this.isNeedFullUpdate && this.data?.items ? [...this.data.items, ...response] : [...response],
            };
            this.isNeedFullUpdate = false;
            $state.loaded();
          } else {
            this.data = {
              items: !this.isNeedFullUpdate && this.data?.items ? this.data.items : response,
            };
            this.isNeedFullUpdate = false;
            $state.complete();
          }
          if (this.data) {
            this.data.items?.map((sku) => {
              this.$store.dispatch('main/updateProductFavoriteStatus', [sku.id, sku.productId, sku.favorite]);
              this.$store.dispatch('viewedProducts/updateProduct', [sku.id, sku.productId, sku.favorite]);
            });
          }
        } catch (error) {
          console.error(error);
          $state.error();
        }
      }
    },
    setInitialQueryInput() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { query } = this.$route;
      const queryInput = {};

      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty('sort')) {
        const { sort } = query;
        if (Array.isArray(sort)) {
          queryInput['sort'] = sort[0];
        } else {
          queryInput['sort'] = sort;
        }
      }

      this.updateQueryInput(queryInput);
    },
    updateQueryInput(newValue) {
      const newQueryInput = {
        ...this.queryInput,
        pagination: DEFAULT_PAGINATION,
      };

      this.queryInput = {
        ...newQueryInput,
        ...newValue,
      };
    },
    updateQueryRoute(newValue: FavoriteQueryInput, oldValue: FavoriteQueryInput) {
      const params = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...this.$route.params,
        toPosition: {
          y: Math.min(window.scrollY, this.$refs.main ? (this.$refs.main as HTMLElement).offsetTop : window.scrollY),
        },
      } as any;

      if (newValue.sort !== oldValue.sort) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$router.replace({
          params,
          query: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...this.$route.query,
            sort: newValue.sort,
          },
        });
      }
    },
    showAddedToFavoritePopup(added) {
      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_NOTIFY,
        component: AddedToFavorite,
        propsData: {
          added,
        },
      });
    },
    async favoriteChange(ids) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let product: any = null;
      await getProduct(ids[1])
        .then((prod) => (product = prod))
        .catch((err) => {
          console.log(err);
        });
      const sku = this.data?.items?.find((_) => _.id === ids[0]) || null;
      if (sku && sku.favorite) {
        await remove([ids[0]]);
        this.showAddedToFavoritePopup(false);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (product) {
          const dataLayerSku = product.skuList[0];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.$gtm.push(this.$dataLayer.removeFromFavoriteEvent(product, dataLayerSku));
        }
      } else {
        this.showAddedToFavoritePopup(true);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (product) {
          const dataLayerSku = product.skuList[0];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.$gtm.push(this.$dataLayer.addToFavoriteEvent(product, dataLayerSku));
        }
        await add([ids[0]]);
      }
      if (sku) {
        this.$store.dispatch('main/updateProductFavoriteStatus', [sku.id, sku.productId, !sku.favorite]);
        this.$store.dispatch('viewedProducts/updateProduct', [sku.id, sku.productId, !sku.favorite]);
        sku.favorite = !sku.favorite;
      }
    },
  },
});
