<template lang="pug">
.ds-order
  order-properties-ds(:order="order" :is-successful="isSuccessful")
  order-actions-ds(:action-data="actionData")
  .products
    .title-products {{ order.orderItems.length }} {{ ending(order.orderItems.length, ['товар', 'товара', 'товаров']) }}
    .content
      order-product-ds(
        v-for="(orderItem, index) in order.orderItems"
        :key="orderItem.id"
        :order-item="orderItem"
        @add-feedback="addFeedback(index)"
      )
</template>

<script>
import OrderPropertiesDs from './properties';
import OrderActionsDs from './actions';
import OrderProductDs from '~/components/order-product';
import ending from '~/modules/common/ending';
import { delayWithoutPaidMilliSeconds } from '~/modules/orders/data';
import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService } from '~/modules/popups/services/popups-service';
import AddFeedbackPopup from '~/components/popups/add-feedback-popup';

export default {
  components: {
    OrderProductDs,
    OrderActionsDs,
    OrderPropertiesDs,
  },

  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    isSuccessful: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ending,
    now: 0,
    interval: undefined,
  }),

  computed: {
    time() {
      if (delayWithoutPaidMilliSeconds - (this.now - this.order.dateCreated) < 0) {
        return '';
      }
      const delta = new Date(delayWithoutPaidMilliSeconds - (this.now - this.order.dateCreated));
      const minutes = delta.getMinutes();
      const seconds = delta.getSeconds();
      return `${minutes} ${ending(minutes, ['минуты', 'минут', 'минут'])} ${
        seconds ? `${seconds} ${ending(seconds, ['секунды', 'секунд', 'секунд'])}` : ''
      }`;
    },
    actionData() {
      const result = {
        actions: [],
      };
      if (this.order.status === 'CREATED' && this.time.length) {
        result.actions.push({
          title: 'Оплатить',
          style: 'primary',
          handler: () => {
            location.assign(this.order.paymentUrl);
          },
        });
        result.alert = `<strong>Внимание!</strong> Этот заказ будет автоматически отменен,<br> если вы не оплатите его в течение ${this.time}`;
      }
      return result;
    },
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  mounted() {
    this.interval = setInterval(() => {
      this.now = new Date().getTime();
    }, 1000);
  },

  methods: {
    addFeedback(orderItemIndex) {
      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.CENTER,
        component: AddFeedbackPopup,
        withReturnData: true,
        propsData: {
          orderItems: this.order.orderItems,
          orderItemIndex,
          withCloseButton: false,
        },
      }).subscribe(() => {
        this.$emit('update-order');
      });
    },
  },
};
</script>

<style lang="stylus">
.ds-order
  .ds-order-actions
    margin-bottom 24px
  .products
    border-top 1px solid Divider()
    .title-products
      Text(TitleM)
      margin 24px 0 16px
    .content
      .ds-order-product
        &:not(:first-child)
          border-top 1px solid Divider()
</style>
