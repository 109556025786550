<template lang="pug">
.ds-order-card-products
  .products-list
    nuxt-link.menu-link.product-img-wrapper(
      v-for="orderItem in items.slice(0, limitShow - 1)"
      :key="orderItem.id"
      :to="{ name: 'product', params: { id: orderItem.sku.product.id } }"
    )
      img-smooth.product-img(:src="orderItem.image.link.high")
    .menu-link.more(v-if="limitShow<items.length" @click="$emit('to-order')")
      img-smooth.product-img(:src="items[limitShow].image.link.high")
      .title +{{ items.length - limitShow }}
</template>
<script>
const widthDesktop = 96;
const widthMobile = 88;
const margin = 8;

export default {
  inject: ['media'],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    width: 0,
    limitShow: 7,
  }),
  watch: {
    width() {
      this.setLimitShow();
    },
    media: {
      deep: true,
      handler() {
        this.setWidth();
      },
    },
  },
  mounted() {
    this.setWidth();
  },
  methods: {
    setWidth() {
      this.width = this?.$el?.offsetWidth || 0;
    },
    setLimitShow() {
      const widthElement = this.media.isMobileOrTablet ? widthMobile : widthDesktop;
      let limitShow = Math.floor((this.width + margin) / (widthElement + margin));
      if (this.items.length - limitShow <= 1) {
        limitShow++;
      }
      this.limitShow = limitShow;
    },
  },
};
</script>
<style lang="stylus">
.ds-order-card-products
  .products-list
    display flex
    flex-wrap wrap
    margin-bottom 24px

    .product-img-wrapper
      flex-shrink 0
      +Media(DesktopMax, Laptop, Desktop)
        width 96px
        height 128px
      +Media(Mobile, Tablet)
        width 88px
        height 120px

    .product-img
      width 100%
      height 100%
      object-fit contain

    .menu-link
      &:not(:first-child)
        margin-left 8px
      &.more
        position relative
        cursor pointer
        .title
          Text(TitleM)
          color Gray(D8)
          position absolute
          top 50%
          transform translateY(-50%)
          white-space nowrap
          width 100%
          text-align center
        .product-img
          opacity .2
</style>
