
import { Component, Emit, Vue } from 'vue-property-decorator';
import ButtonUi from '../common/button.vue';

@Component({
  components: {
    ButtonUi,
  },
})
export default class PayedNotifyPopup extends Vue {
  @Emit('close')
  onClose() {
    this.$store.dispatch('checkout/updateShowWarnings', false);
    this.$root.$emit('checkout:complete-order');
  }
}
