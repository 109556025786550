
import { Mixins } from 'vue-mixin-decorator';
import { Component, Inject } from 'vue-property-decorator';
import DesktopTopRightNotify from '~/modules/popups/wrappers/desktop-top-right-notify.vue';
import MobileBottomAvailableWrapper from './mobile-bottom-available-wrapper.vue';

import { PopupWrapperMixin } from '~/modules/popups/mixins/popups-wrapper';
import { PopupsContainerModel } from '~/modules/popups/models';

@Component({
  components: {
    DesktopTopRightNotify,
    MobileBottomAvailableWrapper,
  },
})
export default class PopupTopRightNotify extends Mixins(
  PopupWrapperMixin(PopupsContainerModel.ETypeWrapper.TOP_RIGHT_NOTIFY),
) {
  @Inject() media;
}
