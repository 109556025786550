import { gql, client } from './index';

import { PromoCodeInput } from '~/apollo-api/types';

export const checkPromoCode = async (input: PromoCodeInput) => {
  const { data } = await client.query({
    query: gql`
      query CheckPromoCode($input: PromoCodeInput!) {
        checkPromoCode(input: $input) {
          discountAmount
          message
          success
        }
      }
    `,
    variables: { input },
  });

  return data.checkPromoCode;
};
