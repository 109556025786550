<template lang="pug">
.ds-swiper-container
  template(v-if="bannerList && bannerList.length")
    .ds-swiper
      swiper-container.swiper-container(v-if="bannerList.length > 1")
        .swiper-buttons(v-if="bannerList.length > 1")
          .swiper-button.swiper-button-prev(:class="`swiper-button-prev${sectionNameToClass}`")
            icon-ui(name="operations/chevron-left_large-line")
          .swiper-button.swiper-button-next(:class="`swiper-button-next${sectionNameToClass}`")
            icon-ui(name="operations/chevron-right_large-line")
        swiper.swiper(:ref="'swiper'+section" :key="options.slidesPerView" :options="options")
          swiper-slide.swiper-slide-default(v-for="(banner, index) in bannerList" :key="index" :index="index" ref="observer-banner")
            .banner
              template(v-if="banner.params")
                action-button-banner(v-if="banner.params.actionButton" :action-button="banner.params.actionButton" :title="banner.buttonText")
              link-ui.banner-link(v-if="banner.link" :to="banner.link" :target="banner.target || '_self'" @click.native="clickBanner(index)")
                img.swiper-lazy(:data-src="banner.image.high" :alt="banner.title || defaultTitle")
              img(v-else :data-src="banner.image.high" :alt="banner.title || defaultTitle")
          .ds-swiper-pagination(slot="pagination" v-if="bannerList.length > 1" :ref="section" :class="section")
      .banner(v-else v-for="(banner, index) in bannerList" :key="index" :index="index" ref="observer-banner")
        template(v-if="banner.params")
          action-button-banner(v-if="banner.params.actionButton" :action-button="banner.params.actionButton" :title="banner.buttonText")
        link-ui.banner-link(v-if="banner.link" :to="banner.link" :target="banner.target || '_self'" @click.native="clickBanner(index)")
          img(:src="banner.image.high" :alt="banner.title || defaultTitle")
        img(v-else :data-src="banner.image.high" :alt="banner.title || defaultTitle")
  .swiper-skeleton(v-else-if="section === 'MAIN.MAIN'")
    .banner
  div(v-else)
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import SwiperContainer from '~/components/swiper/swiper-container';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import ActionButtonBanner from '~/components/action-button-banner';
import { mapState } from 'vuex';
import { titles } from '~/shop-config';
import { checkJson } from '~/utils/checkJson';
import { observerMixin } from '~/plugins/gtm-datalayer/observer';
const MAX_SLIDE_WIDTH = 600;
const SLIDE_GAP = 8;
const SLIDE_CORNER_WIDTH = 12;
const SIDES_COUNT = 2;

const getSlidesPerView = (width) =>
  width < MAX_SLIDE_WIDTH + SIDES_COUNT * (SLIDE_GAP + SLIDE_CORNER_WIDTH)
    ? 1 + (SIDES_COUNT * (SLIDE_GAP + SLIDE_CORNER_WIDTH)) / (width - SIDES_COUNT * (SLIDE_GAP + SLIDE_CORNER_WIDTH))
    : width / MAX_SLIDE_WIDTH;

export default {
  components: {
    LinkUi,
    IconUi,
    Swiper,
    SwiperSlide,
    SwiperContainer,
    ActionButtonBanner,
  },
  mixins: [observerMixin],
  inject: ['media'],
  props: {
    section: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      observerFor: 'observer-banner',
    };
  },
  computed: {
    ...mapState('main', ['banners']),
    options() {
      const baseOptions = {
        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
        },
        lazy: true,
        loop: this.banners.length > 1,
        preloadImages: this.banners.length <= 2,
      };

      return {
        ...baseOptions,
        ...(this.media.isMobileOrTablet
          ? {
              centeredSlides: true,
              spaceBetween: SLIDE_GAP,
              slidesPerView: getSlidesPerView(this.media.clientWidth),
            }
          : {
              pagination: {
                el: '.ds-swiper-pagination',
                clickable: true,
              },
              navigation: {
                nextEl: `.swiper-button-next${this.sectionNameToClass}`,
                prevEl: `.swiper-button-prev${this.sectionNameToClass}`,
              },
            }),
      };
    },
    sectionNameToClass() {
      return this.section.toLowerCase().replace(/\./g, '-');
    },
    bannerList() {
      let filteredList = [];
      switch (this.section) {
        case 'MAIN.MAIN':
          filteredList = [
            ...this.banners.filter(this.filterBanner),
            ...this.banners.filter((banner) => !banner.params),
            ...this.banners.filter(this.filterEmptyBanner),
          ];
          break;
        default:
          filteredList = [...this.banners.filter(this.filterBanner)];
      }
      filteredList = JSON.parse(JSON.stringify(filteredList));
      return filteredList.map((banner) => {
        if (checkJson(banner.params)) {
          banner.params = JSON.parse(banner.params);
        }
        return banner;
      });
    },

    defaultTitle() {
      return titles.default;
    },
  },
  methods: {
    filterBanner(banner) {
      const bannerParams = checkJson(banner.params) ? JSON.parse(banner.params) : null;
      if (bannerParams) {
        return bannerParams.sections.findIndex((sectionName) => sectionName === this.section) > -1;
      }
      return false;
    },
    filterEmptyBanner(banner) {
      const bannerParams = checkJson(banner.params) ? JSON.parse(banner.params) : null;
      if (bannerParams) {
        return bannerParams.sections && bannerParams.sections.length === 0;
      }
      return false;
    },
    async clickBanner(index) {
      this.$gtm.push({ ecommerce: null });
      this.$gtm.push(this.$dataLayer.selectBannerEvent(this.bannerList[index].title, this.section, index));
    },
    observerIntersect(target) {
      const index = target.attributes['index'].value;

      this.$gtm.push({ ecommerce: null });
      this.$gtm.push(this.$dataLayer.viewBannerEvent(this.bannerList[index].title, this.section, index));
    },
  },
};
</script>

<style lang="stylus">
.swiper-wrapper

.ds-swiper-container
  .banner
    padding-bottom 33.088235294% //- 450 / 1360 px
    border none
    position: relative;
  +Media(Mobile, Tablet)
    padding 0 !important
    margin-top 12px
    .banner
      padding-bottom 55.5% //- 333 / 600 px
      border none
    .ds-swiper-pagination
      display none
.ds-swiper
  position relative
  margin 0 auto
  border-radius 8px
  transform translateZ(0)
  +Media(Mobile, Tablet)
    width 100%
    margin 0
  .swiper-container
    Round(Large)
    cursor pointer
  .swiper-buttons
      position absolute
      top 50%
      z-index 2
      width 100%
      +Media(Mobile, Tablet)
        display none
      .swiper-button
        Button()
        position absolute
        z-index 1
        width 48px
        height 48px
        display flex
        align-items center
        justify-content center
        border-radius 50%
        background rgba(#888888, 0.5)
        backdrop-filter blur(40px)
        transition all .3s ease-in-out
        &:hover
          background white
          svg
            fill TextPrimary()
        &::after
          content none
        svg
          fill rgba(#FFFFFF, 0.8)
        &-prev
          left 20px
        &-next
          right 20px
  .banner
    display block
    user-select none
    z-index 1
    height 0
    position relative
    .banner-link
      border none
      position absolute
      width 100%
      height 100%
      display block
    img
      border none !important
      position absolute
      width 100%
      height 100%
      display block
      user-select none
      pointer-events none
      background #EEE
      object-fit cover
      object-position center
      +Media(Mobile, Tablet)
        border-radius 12px

.ds-swiper-pagination
  width 100%
  margin-top 8px
  display flex
  justify-content center
  .swiper-pagination-bullet
    background rgba(#76797F, 0.6)
    &:not(:last-child)
      margin-right 8px
    &-active
      background OrangeBrand()

.swiper-skeleton
  background #EEE

.swiper-slide-default
  background #EFEFEF
  +Media(Mobile, Tablet)
    border-radius 12px

.swiper-lazy
  transition opacity .25s ease-out
  opacity 0
.swiper-lazy-loaded
  opacity 1
</style>
