<template lang="pug">
.ds-cart-no-content
  img.no-content-image(src="/images/empty_cart.png")
  .no-content-title В корзине пока нет товаров
  .no-content-content Присмотритесь к новинкам и подборкам на главной странице или воспользуйтесь поиском, если ищете что-то конкретное
  //- @todo: implement offers
  button-ui(@click.stop="$router.push({ name: 'home' })") Посмотреть подборки
  footer.no-content-footer(v-if="!authenticated")
    div #[link-ui(to="/signin") Войдите] в свой аккаунт, чтобы посмотреть товары, которые уже в корзине
</template>

<script>
import ButtonUi from '~/components/common/button';
import LinkUi from '~/components/common/link';
import { AuthenticatedMixin } from '~/mixins/authenticated';

export default {
  components: {
    ButtonUi,
    LinkUi,
  },
  mixins: [AuthenticatedMixin],
};
</script>

<style lang="stylus">
.ds-cart-no-content
  max-width 450px
  margin 42px auto
  text-align center
  .no-content-image
    width 100%
    +Media(Laptop, Desktop, DesktopMax)
      margin-bottom 32px
    +Media(Tablet, Mobile)
      margin-bottom 24px
  .no-content-title
    margin-bottom 8px
    +Media(Laptop, Desktop, DesktopMax)
      Text(TitleM)
    +Media(Tablet, Mobile)
      Text(TitleS)
  .no-content-content
    Text(BodyM Long)
  .no-content-footer
    Text(BodyM Long)
    color Gray(D8)
  .ui-button
    +Media(Laptop, Desktop, DesktopMax)
      Button(Medium Primary)
      margin 28px 0 48px
    +Media(Tablet, Mobile)
      Button(Small Primary)
      margin 28px 0 48px
  .ui-link
    Text(Link)
    color inherit
    text-decoration underline
</style>
