<template lang="pug">
.ds-spinner(:style="style") 
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: '#000',
    },
    stroke: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    style() {
      return {
        '--size': `${this.size}px`,
        '--color': `${this.color}`,
        '--stroke': `${this.stroke}px`,
      };
    },
  },
};
</script>
<style lang="stylus">
.ds-spinner
  border var(--stroke) solid var(--color)
  border-bottom-color transparent
  border-radius 50%
  width var(--size)
  height var(--size)
  animation spin 2s linear infinite

@keyframes spin
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)
</style>
