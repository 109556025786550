export const productOpenEvent = function (product) {
  const sku = getSku(product);

  return {
    event: 'view_item',
    ecommerce: {
      value: sku.sellPrice / 100,
      currency: 'RUB',
      items: [mapProductItem(product, sku)],
    },
  };
};

export const mapProductItem = function (product, sku) {
  const data = {
    item_id: product.id + '',
    item_name: product.title,
    discount: (sku.fullPrice - sku.sellPrice) / 100,
    item_brand: 'Без бренда',
    price: sku.fullPrice / 100,
    item_variant:
      sku.characteristicValues.length > 1
        ? sku.characteristicValues[1]?.title
        : sku.characteristicValues[0]?.title || '',
    ...mapCategoryList(product.category),
  };
  if (sku.characteristicValues.length > 1) {
    data.item_size = sku.characteristicValues[0]?.title;
  }

  return data;
};

export const getSku = function (product) {
  const skuGroup = product.skuGroup;
  const selected = {};
  for (const id in skuGroup.characteristicIds) {
    selected[skuGroup.characteristicIds[id]] = skuGroup.characteristicValueIds[id];
  }

  let sku = null;
  if (skuGroup.characteristicIds && skuGroup.characteristicValueIds) {
    sku = product.skuList.find((sku) => {
      for (const value of sku.characteristicValues) {
        if (selected[value.characteristic.id] && selected[value.characteristic.id] != value.id) {
          return false;
        }
      }
      return true;
    });
    if (sku) {
      return sku;
    }
  }

  sku = product.skuList.sort((a, b) => a.sellPrice - b.sellPrice)[0];
  return sku;
};

export const mapCategoryList = function (category) {
  const parentList = JSON.parse(JSON.stringify(category.parentList));
  let count = 1;
  let list = {};
  for (const cat of parentList) {
    list['item_category' + (count > 1 ? count : '')] = cat.title;
    count++;
  }
  list['item_category' + (count > 1 ? count : '')] = category.title;

  return list;
};
