<template lang="pug">
.ds-signin-tab
  tabs-ds(v-model="activeTab" :class="{'hide-tabs': isHideTabs }")
    template(v-slot:title)
      tabs-title-ds(name="signin") Вход
      tabs-title-ds(name="signup") Регистрация

    template(v-slot:content)
      tabs-content-ds(name="signin")
        //- SIGNIN COMPONENT
        signin-ds(ref="signin" @change-tab="changeTab")
      tabs-content-ds(name="signup")
        //- SIGNUP COMPONENT
        signup-ds(ref="signup" @change-tab="changeTab" :is-verify-phone="isVerifyPhone")
      tabs-content-ds(name="restore")
        //- RESTORE COMPONENT
        restore-ds(ref="signup" @change-tab="changeTab")
</template>
<script>
import TabsDs from '~/components/tabs';
import TabsTitleDs from '~/components/tabs/title';
import TabsContentDs from '~/components/tabs/content';

import SigninDs from './signin';
import SignupDs from './signup';
import RestoreDs from './restore';

import { SignUpState } from '~/store/user';

export default {
  components: {
    TabsDs,
    TabsTitleDs,
    TabsContentDs,
    SigninDs,
    SignupDs,
    RestoreDs,
  },
  props: {
    isVerifyPhone: {
      type: Boolean,
      default: false,
    },
    isSignup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'signin',
      states: SignUpState,
    };
  },
  computed: {
    state() {
      return this.$store.state.user.registration.state;
    },
    isHideTabs() {
      return (
        this.activeTab === 'restore' ||
        (this.activeTab === 'signup' && (this.state === this.states.Password || this.state === this.states.Code))
      );
    },
    initialTab() {
      return this.isVerifyPhone || this.isSignup ? 'signup' : 'signin';
    },
    initialState() {
      return this.isVerifyPhone ? SignUpState.Code : SignUpState.Phone;
    },
  },
  watch: {
    activeTab: {
      immediate: true,
      handler(val) {
        if (val !== 'signin') {
          this.$store.dispatch('user/setState', this.initialState);
        }
        if (val === 'restore') {
          this.$gtm.push(this.$dataLayer.recoveryView());
        }

        this.$nextTick(this.focus);
      },
    },
    state() {
      this.$nextTick(this.focus);
    },
  },
  created() {
    if (this.initialTab && this.activeTab !== this.initialTab) {
      this.changeTab(this.initialTab);
    }
    if (process.client) {
      this.$gtm.push(this.$dataLayer.viewAuthorization());
    }
  },
  methods: {
    changeTab(newTab) {
      this.activeTab = newTab;
    },
    focus() {
      // go to active tab element and focus first input
      if (this.$refs[this.activeTab]) {
        const inputs = this.$refs[this.activeTab].$el.getElementsByTagName('input');
        if (inputs.length >= 1) {
          inputs[0].focus();
        }
      }
    },
  },
};
</script>
<style lang="stylus">
.ds-signin-tab
  min-height 439px
  +Media(Mobile, Tablet)
    min-height 423px
  .hide-tabs
    .tab-titles
      display none
  .error
    color Red()
    font-size 12px
    line-height 125%
    letter-spacing 0.02em
    text-align left
    position relative
    top 6px
</style>
