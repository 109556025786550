import { DshopOfferVariables } from '~/apollo-api/types';
import { productBadgesFragment } from '~/apollo-api/fragments/productBadges';
import { gql } from './index';
import { cachedQuery } from './utils';

export const dShopOffer = (id: number) => {
  return cachedQuery({
    query: gql`
      query DShopOffer($id: Int!) {
        dshopOffer(offerId: $id) {
          id
          title
        }
      }
    `,
    variables: { id },
    parser: (data) => data.dshopOffer,
  });
};

export const getExtendableOffer = ({ id, page = 0, size = 20 }: { id: number } & Partial<DshopOfferVariables>) => {
  return cachedQuery({
    query: gql`
      query DshopOffer($offerId: Int!, $page: Int!, $size: Int!) {
        dshopOffer(offerId: $offerId) {
          id
          title
          productsPage(page: $page, size: $size) {
            content {
              id
              title
              shortDescription
              rating
              feedbackQuantity
              skuList {
                sellPrice
                fullPrice
                id
                characteristicValues {
                  title
                  value
                  id
                  characteristic {
                    id
                    title
                  }
                }
              }
              skuGroups {
                id
                favorite
              }
              photos {
                link(trans: PRODUCT_540) {
                  high
                }
              }
              badges {
                ...ProductBadgesFragment
              }
            }
          }
        }
      }
      ${productBadgesFragment}
    `,
    variables: { offerId: id, page, size },
    parser: (data) => data.dshopOffer,
  });
};
