<template lang="pug">
.ui-tab-content(v-if="isNeedRerender && rootTabs.selected === name", :name="name")
  div
    slot
.ui-tab-content(v-else-if="!isNeedRerender" v-show="rootTabs.selected === name", :name="name")
  div
    slot
</template>

<script>
export default {
  inject: ['rootTabs'],
  props: {
    name: {
      type: String,
      default: '',
    },
    isNeedRerender: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus">
.ui-tab-content
  width 100%
  word-break break-word
</style>
