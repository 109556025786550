import { signUp as SignUp } from '~/apollo-api/types';
import { client, gql } from './index';

export const signUp = async (phone: string) => {
  const { data } = await client.mutate<SignUp>({
    mutation: gql`
      mutation signUp($phone: String!) {
        signUp(phone: $phone) {
          accessToken
          refreshToken
          customer {
            id
            contacts {
              firstName
            }
          }
        }
      }
    `,
    variables: {
      phone,
    },
  });

  return data?.signUp;
};
