<template lang="pug">
.switch-all-wrapper
  .switch-all
    .header-switch(:class="{'current': active === 0}" @click="setActive(0)") Посмотреть все
    .header-switch( :class="{'current': active === 1}" @click="setActive(1)") Выбрать город
</template>

<script>
import { getDefaultCityMap } from '~/store/deliveryMap';
import { mapState } from 'vuex';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
export default {
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    ...mapState('deliveryMap', {
      city: (state) => state.deliveryMapCity,
      storedCity: (state) => state.storedCity,
    }),
    ...mapState('checkout', {
      defaultCity: (state) => state.defaultCity,
    }),
  },
  watch: {
    // active(val) {

    // },
    city() {
      if (this.city.name == CITY_WITH_PARTNER_DP) {
        this.active = 0;
      } else {
        this.active = 1;
      }
    },
  },
  mounted() {
    if (this.city.name == CITY_WITH_PARTNER_DP) {
      this.active = 0;
    } else {
      this.active = 1;
    }
  },
  methods: {
    setActive(val) {
      this.active = val;
      if (val) {
        const city = this.defaultCity;
        if (this.storedCity) {
          this.$store.commit('deliveryMap/updateDeliveryMapCity', this.storedCity);
        } else if (this.city.name === CITY_WITH_PARTNER_DP) {
          this.$store.commit('deliveryMap/updateDeliveryMapCity', city);
        }
      } else {
        const city = getDefaultCityMap();
        if (city.name !== this.city.name) {
          this.$store.commit('deliveryMap/updateDeliveryMapCity', city);
        }
      }
    },
  },
};
</script>

<style lang="stylus">
.switch-all-wrapper
  padding:24px 24px 16px 24px
  +Media(Mobile, Tablet)
    padding 0px 0px 16px 0px
  .switch-all
    height: 48px
    padding: 4px;
    gap: 4px;
    display flex
    align-items: center
    background: #F2F4F7
    cursor pointer

    .header-switch
      display flex
      align-items: center
      width: 50%
      height: 100%
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      justify-content: center
      color: #8B8E99

    .current
      background: #fff
      color: #1F1F26
</style>
