import Vue from 'vue';
// import { mapState } from 'vuex';
import { addToCart, setCart } from '~/modules/cart/api';
import { AuthenticatedMixin } from '../authenticated';
export const AddCartMixin = Vue.extend({
  mixins: [AuthenticatedMixin],
  computed: {
    cartProducts() {
      return this.$store.state.cart.list;
    },
  },
  methods: {
    async addOne() {
      await this.addToCart(this.product, this.selectedSku, 1);
    },
    setCartAmount(sameInCart, selectedSku, amount) {
      this.$store.dispatch('cart/changeAmount', {
        product: sameInCart,
        amount: amount,
      });
    },
    addSameToCart(sameInCart, selectedSku, amount) {
      this.$store.dispatch('cart/changeAmount', {
        product: sameInCart,
        amount: Math.min(sameInCart.amount + amount, selectedSku.availableAmount || 1),
      });
      addToCart([{ amount: 1, skuId: selectedSku.id }]);
    },
    //cart/remove
    async addToCart(product, selectedSku, amount, fromPopup) {
      if (!selectedSku) {
        return;
      }
      if (amount < 0) {
        return;
      }
      if (amount === 0) {
        this.$store.dispatch('cart/remove', selectedSku.id);
        await setCart([{ amount: 0, skuId: selectedSku.id }]);
        return;
      }

      const sameInCart = this.cartProducts.find((_) => _.sku.id === selectedSku.id);

      if (sameInCart?.amount === selectedSku.availableAmount) {
        // check for prevent increase in api
        return;
      }

      if (fromPopup && sameInCart) {
        this.addSameToCart(sameInCart, selectedSku, amount);
        return;
      }

      if (sameInCart) {
        this.setCartAmount(sameInCart, selectedSku, amount);
        setCart([{ amount, skuId: selectedSku.id }]);
        return;
      }

      if (this.accessToken) {
        let newCart = this.$store.state.cart.list;
        newCart = await addToCart(
          [
            {
              skuId: selectedSku.id,
              amount,
            },
          ],
          this.accessToken,
        );
        this.$store.dispatch('cart/update', newCart);
      } else {
        const newCartItem = {
          amount,
          sku: {
            product: {
              id: product.id,
              title: product.title,
              shortDescription: product.shortDescription,
              category: product.category,
            },
            ...selectedSku,
          },
        };
        this.$store.dispatch('cart/add', newCartItem);
      }
    },
  },
});
