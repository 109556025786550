import { Component } from 'vue';

export namespace PopupsContainerModel {
  export enum ETypeWrapper {
    CENTER = 'PopupCenterWrapper',
    TOP_RIGHT_STICKY = 'PopupTopRightWrapper',
    TOP_RIGHT_NOTIFY = 'PopupTopRightNotify',
    BOTTOM_RIGHT = 'PopupBottomRightWrapper',
  }
  export interface IPopupComponentConfig {
    type: PopupsContainerModel.ETypeWrapper;
    component: Component;
    propsData?: any;
    withReturnData?: boolean;
    isRouterOpened?: boolean;
  }
}
