import Vue from 'vue';
import { getDadataOptions, EDadataService } from '~/api/getDadataOptions';
import { DadataCitySearch } from '../models';

export const CitySearchMixin = Vue.extend({
  data: () => ({
    allCities: [] as DadataCitySearch[] | unknown[],
    filteredText: '',
  }),
  methods: {
    async updateCities() {
      try {
        this.allCities = (
          await getDadataOptions({
            type: EDadataService.Address,
            query: this.filteredText,
            count: 50,
            options: {
              from_bound: { value: 'area' },
              to_bound: { value: 'settlement' },
            },
          })
        )
          /**
           * @property fias_level - 65 low level in city
           * exclude settlement in city  because dadata doesn't search streets
           */
          .filter((dadataValue) => {
            return dadataValue.data.fias_level !== '65' && !(dadataValue.data.city && dadataValue.data.settlement);
          })
          .map((dadataValue) => ({
            id: dadataValue.value,
            city: dadataValue.data.city,
            settlement: dadataValue.data?.settlement,
            name: dadataValue.value,
            settlement_with_type: dadataValue.data.settlement_with_type,
            isSettlement: !!dadataValue.data.settlement_with_type,
            ke: false,
            region: dadataValue.data.region,
          }));
        this.$store.commit('checkout/updateCheckCities', this.allCities);
      } catch (error) {
        console.error(error);
        this.allCities = [];
      }
    },
  },
});
