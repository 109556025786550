import Cookies from 'js-cookie';
import Vue from 'vue';
import { Mixins } from 'vue-mixin-decorator';
import {
  DeliveryType,
  OrderItemInput,
  getCart_cart,
  getCart_cart_sku,
  getOrder_order_delivery_DpDelivery_deliveryPoint,
  DeliveryInput,
  expectedDelivery_expectedDelivery,
  CreateOrderInput,
} from '~/apollo-api/types';
import SignInPopupMixin from '~/mixins/signInPopupMixin';
import { DeliveryType as DeliveryTypeEnum } from '~/modules/checkout/types';
import { throttle } from '~/modules/common/throttle';
import { mapState } from 'vuex';
import { createOrder, cart, product } from '~/modules/checkout/api';
import { setCart } from '~/modules/cart/api';
import { Watch, Component } from 'nuxt-property-decorator';
import { expectedDelivery } from '~/apollo-api/expectedDelivery';

import { PopupsService } from '~/modules/popups/services/popups-service';
import { PopupsContainerModel } from '~/modules/popups/models';
import UpdateAmountCheckoutPopup from '~/components/popups/update-amount-checkout.vue';
import { StaticDataMixin } from '../staticData';

type CartItem = getCart_cart;
type Sku = getCart_cart_sku;
type DeliveryPoint = getOrder_order_delivery_DpDelivery_deliveryPoint;
type ExpectedDelivery = expectedDelivery_expectedDelivery;

export interface IOrderField {
  isValid: boolean;
  value: any;
}
export interface IOrderFieldMap {
  [key: string]: IOrderField;
}
type ExpectedDeliveryUndefinable = Partial<Pick<ExpectedDelivery, 'price'>> &
  Omit<ExpectedDelivery, 'price' | '__typename'>;

export async function useOrderItems(
  query: {
    productId?: string;
    skuId?: string;
    amount?: number;
  },
  store,
): Promise<{ items: CartItem[]; isCart: boolean }> {
  const tokens = Cookies.get('auth');
  if (query.skuId && query.productId && query.amount) {
    const skuList = await product(+query.productId);

    return {
      items: (skuList as Sku[])
        .filter((_) => String(_.id) == String(query.skuId))
        .map((sku) => [
          {
            amount: Number(query.amount),
            sku,
          },
        ])[0] as CartItem[],
      isCart: false,
    };
  } else if (tokens && (!!JSON.parse(tokens).accessToken as boolean)) {
    const cartResult = await cart();

    return {
      items: cartResult as CartItem[],
      isCart: true,
    };
  } else {
    return {
      items: store.state.cart.list || [],
      isCart: true,
    };
  }
}

export const CanCheckoutMixin = Vue.extend({
  data() {
    return {
      canCheckout: false,
    };
  },

  mounted() {
    this.$root.$on('checkout.canCheckout:change', this.onCanCheckoutChange);
    this.$on('checkout.canCheckout:initial', this.onCanCheckoutChange);
    this.$nextTick(() => {
      this.$root.$emit('checkout.canCheckout:request', this);
    });
  },

  beforeDestroy() {
    this.$root.$off('checkout.canCheckout:change', this.onCanCheckoutChange);
    this.$off('checkout.canCheckout:initial', this.onCanCheckoutChange);
  },

  methods: {
    onCanCheckoutChange(val: boolean) {
      this.canCheckout = val;
    },
  },
});

export const CheckoutFieldMixin = Vue.extend({
  data: () => ({
    field: `checkout-field-${new Date().getTime()}-${Math.random()}`,
  }),

  watch: {
    value: {
      deep: true,
      handler(value: any) {
        this.$root.$emit('checkout.field:update', {
          name: this.field,
          value,
          isValid: this['isValid'] !== undefined ? this['isValid'] : true,
        });
      },
    },
  },

  mounted() {
    this.$root.$emit('checkout.field:register', {
      name: this.field,
      value: this['value'] !== undefined ? this['value'] : null,
      isValid: this['isValid'] !== undefined ? this['isValid'] : true,
    });
  },

  beforeDestroy() {
    this.$root.$emit('checkout.field:remove', { name: this.field });
  },
});

const initExpectedDelivery: ExpectedDeliveryUndefinable = {
  price: undefined,
  maxDays: 0,
};

type TRecipient = {
  lastName: string;
  firstName: string;
  phone: string;
  email: string;
  isMailAccept: boolean;
};

@Component({
  computed: {
    ...mapState('checkout', [
      'deliveryType',
      'deliveryPoint',
      'hiddenPoint',
      'city',
      'address',
      'recipient',
      'date',
      'time',
      'comment',
      'promocode',
    ]),
    ...mapState('main', ['dshopTexts']),
  },
  mixins: [StaticDataMixin],
})
export class CheckoutMixin extends Mixins<SignInPopupMixin>(SignInPopupMixin, StaticDataMixin) {
  $store!: any;
  $route!: any;
  $router!: any;
  $eventManager!: any;
  orderItems: CartItem[] = [];
  isCart = false;
  registeredFields: IOrderFieldMap = {};
  expectedDelivery: Partial<ExpectedDelivery> = initExpectedDelivery;

  // computed of mapState
  comment!: string;

  promocode!: string;
  time!: {
    from: string;
    to: string;
    price: number;
    id: number;
    label: string;
  };
  deliveryType!: string;
  deliveryPoint!: DeliveryPoint;
  dshopTexts!: any;
  hiddenPoint!: any;
  city!: {
    ke: boolean;
    name: string;
    city?: string;
    settlement?: string;
    settlement_with_type?: string;
    id: number;
    cityId?: number;
    minFreeDeliveryPriceCourier: number;
  };
  address!: {
    street: string;
    flat: string;
    area_with_type?: string;
    street_with_type?: string;
    house_type?: string;
    house?: string;
    settlement_with_type?: string;
  };
  recipient!: TRecipient;
  date!: {
    value: number;
  };

  localCities!: string[];

  isNeedCompletePopup = false;

  unsubscribeLogged: () => void = () => {};

  get canCheckout(): boolean {
    const fields = Object.values(this.registeredFields);
    return fields.length > 0 && fields.every((el) => el.isValid);
  }

  get isKshPoint(): boolean {
    return !!(
      this.localCities.find((city) => {
        const cityArr = city.split(' ');
        const cityString = cityArr[cityArr.length - 1];
        return this.addressString.includes(cityString);
      }) && this.deliveryType === 'DELIVERY_POINT'
    );
  }

  get delivery(): DeliveryInput {
    return {
      address: this.isKshPoint ? 'Каменск-Шахтинский' : this.addressString,
      cityId: this.isKshPoint
        ? this.city.cityId
        : this.addressString.includes('Каменск-Шахтинский')
        ? this.city.id
        : null,
      date: this.date?.value ? String(this.date.value) : undefined,
      period: this.time?.label || null, // @todo ? .value
      deliveryPointId: this.addressString
        ? this.isKshPoint || this.addressString.includes('Каменск-Шахтинский')
          ? this.deliveryPoint
            ? this.deliveryPoint.id
            : null
          : null
        : null,
      deliveryOptionId: this.time?.id || null,
      isTodayDelivery: new Date(this.date?.value).getDay() === new Date().getDay(),
      price: this.price || 0,
      type: this.addressString
        ? this.isKshPoint || this.addressString.includes('Каменск-Шахтинский')
          ? ('DELIVERY_POINT' as DeliveryType)
          : (this.deliveryType as DeliveryType)
        : (this.deliveryType as DeliveryType),
    };
  }

  get orderItemsToApi(): OrderItemInput[] {
    if (!this.orderItems?.length) {
      return [];
    }
    return this.orderItems.map((x) => ({
      amount: x.amount,
      purchasePrice: x.sku.sellPrice,
      skuId: x.sku.id,
    }));
  }

  get price(): number | undefined | null {
    const totalPrice = this.orderItems.map((_) => _.sku.sellPrice * _.amount).reduce((acc, cur) => acc + cur, 0);

    switch (this.deliveryType) {
      case DeliveryTypeEnum.COURIER:
        return !!this.city.minFreeDeliveryPriceCourier && totalPrice >= this.city.minFreeDeliveryPriceCourier
          ? 0
          : this?.time?.price;
      case DeliveryTypeEnum.DELIVERY_POINT:
        return 0;
      case DeliveryTypeEnum.RUSSIAN_POST:
        return this.expectedDelivery?.price;
    }
  }

  get addressString() {
    if (this.city && this.city.name.includes('Москва') && this.address.area_with_type) {
      return `${this?.city?.name}, ${this.address.area_with_type}, ${this.address.street} ${
        this.address.flat ? `, Квартира ${this.address.flat}` : ''
      }`;
    } else if (this.city && (this.city.name.includes('Зеленоград') || this.city.name.includes('Улан-Удэ'))) {
      return `${this.city?.name}, ${this.address.street} ${this.address.flat ? `, Квартира ${this.address.flat}` : ''}`;
    } else if (
      this.city &&
      this.address &&
      this.address.street_with_type &&
      this.address.street_with_type.length > 0 &&
      this.address.house_type &&
      this.address.house_type.length > 0
    ) {
      return `${this.city?.name}, ${
        this.address.settlement_with_type ? `${this.address.settlement_with_type}, ` : ''
      } ${this.address?.street_with_type}, ${this.address?.house_type} ${this.address.house}${
        this.address.flat ? `, Квартира ${this.address.flat}` : ''
      }`;
    }
    return `${this.city?.name}, ${this.address.street} ${this.address.flat ? `, Квартира ${this.address.flat}` : ''}`;
  }

  get recipientsWithTruePhone(): TRecipient {
    return {
      email: this.recipient.email,
      lastName: this.recipient.lastName,
      firstName: this.recipient.firstName,
      phone: this.recipient.phone.replace('+7', '').replace(/[^0-9]/g, ''),
      isMailAccept: this.recipient.isMailAccept,
    };
  }

  get orderInput(): CreateOrderInput {
    return {
      contacts: this.recipientsWithTruePhone,
      delivery: this.delivery,
      orderItems: this.orderItemsToApi,
    };
  }

  async mounted() {
    this.$root.$on('checkout.field:register', this.onFieldRegister);
    this.$root.$on('checkout.field:update', this.onFieldUpdate);
    this.$root.$on('checkout.field:remove', this.onFieldRemove);
    this.$root.$on('checkout.canCheckout:request', this.onRequestCanCheckout);
    this.$root.$on('checkout:complete-order', this.completeOrder);
    this.$eventManager.on('registration-end', this.closeModalAndCompleteOrder);
    this.$eventManager.on('entry-end', this.closeModalAndCompleteOrder);
    this.$eventManager.on('open-signin-popup', this.openSigninPopup);
    this.$eventManager.on('close-popup', this.closePopup);
    await this.init();
    await this.checkProductsPriceAmount();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.unsubscribeLogged = this.$store.subscribeAction((_) => {
      if (_.type === 'user/setCustomer') {
        this.fillData(_.payload);
      }
    });
  }

  beforeDestroy() {
    this.$root.$off('checkout:complete-order', this.completeOrder);
    this.$eventManager.off('registration-end', this.closeModalAndCompleteOrder);
    this.$eventManager.off('entry-end', this.closeModalAndCompleteOrder);
    this.$eventManager.off('open-signin-popup', this.openSigninPopup);
    this.$eventManager.off('close-popup', this.closePopup);
    this.unsubscribeLogged();
  }

  throttleExpectedDelivery = throttle(this.updateExpectedDelivery.bind(this), 2000);

  async updateExpectedDelivery() {
    if (
      this.deliveryType === DeliveryTypeEnum.DELIVERY_POINT ||
      !this.address.house ||
      !this.address.street ||
      !this.city ||
      !this.orderItems.length
    ) {
      this.expectedDelivery = initExpectedDelivery;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$store.dispatch('checkout/updateLoadingExpected', false);
      return;
    }
    const input = {
      delivery: {
        address: this.addressString,
        price: 0,
        type: this.deliveryType as DeliveryType,
      },
      orderItems: this.orderItemsToApi,
    };
    try {
      this.expectedDelivery = await expectedDelivery(input);
    } catch (e) {
      this.expectedDelivery = initExpectedDelivery;
      throw e;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$store.dispatch('checkout/updateLoadingExpected', false);
  }

  onRequestCanCheckout(component: Vue) {
    component.$emit(`checkout.canCheckout:initial`, this.canCheckout);
  }

  closePopup() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.$route.name !== 'signin') {
      PopupsService.close(PopupsContainerModel.ETypeWrapper.CENTER);
    }
  }

  onFieldRemove({ name }) {
    this.$delete(this.registeredFields, name);
  }

  onFieldRegister({ name, value, isValid }) {
    const newField: IOrderField = {
      isValid,
      value,
    };

    this.$set(this.registeredFields, name, newField);
  }

  onFieldUpdate({ name, value, isValid }) {
    const newField: IOrderField = {
      isValid,
      value,
    };

    this.$set(this.registeredFields, name, newField);
  }

  async init() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { items, isCart } = await useOrderItems(this.$route.query, this.$store);
    if (isCart && !items.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$router.push({ name: 'home' });
    }
    this.$set(this, 'orderItems', items);
    this.$set(this, 'isCart', isCart);
  }

  async checkProductsPriceAmount() {
    if (!this.isCart) return;
    const newArr = new Map();

    this.$store.state.cart.list.forEach((el) => {
      newArr.set(el.sku.id, { ...el });
    });

    const result = this.orderItems
      .map((_) => {
        const newEl = {
          ..._,
          oldAmount: newArr.get(_.sku.id).amount,
          oldPrice: newArr.get(_.sku.id).sku.sellPrice,
        };
        if (
          newArr.get(_.sku.id).amount > _.sku.availableAmount &&
          newArr.get(_.sku.id).sku.sellPrice !== _.sku.sellPrice
        ) {
          return {
            ...newEl,
            changed: 'all',
            amount: _.sku.availableAmount,
          };
        } else if (newArr.get(_.sku.id).amount > _.sku.availableAmount) {
          return {
            ..._,
            ...newEl,
            changed: 'amount',
            amount: _.sku.availableAmount,
          };
        } else if (newArr.get(_.sku.id).sku.sellPrice !== _.sku.sellPrice) {
          return {
            ..._,
            ...newEl,
            changed: 'price',
          };
        }
      })
      .filter((el) => el);
    if (result.length) {
      const itemsCheckAmount = this.orderItems
        .map((item) => {
          return {
            ...item,
            amount: item.sku.availableAmount < item.amount ? item.sku.availableAmount : item.amount,
          };
        })
        .filter((item) => item.amount);
      this.$store.dispatch('cart/update', itemsCheckAmount);
      await setCart(
        result
          .filter((_) => _?.changed == 'all' || _?.changed == 'amount')
          .map((_) => ({ amount: _?.sku.availableAmount || 0, skuId: _?.sku.id || 0 })),
      );
      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_STICKY,
        component: UpdateAmountCheckoutPopup,
        propsData: {
          data: result,
        },
      });
    }
  }

  fillData(payload) {
    // if some field filled skip filling data
    if (
      Object.values(this.recipientsWithTruePhone).some(
        (_) => (typeof _ !== 'boolean' && _.length > 2) || typeof _ === 'boolean',
      )
    ) {
      return;
    }

    this.$store.dispatch('checkout/updateRecipient', payload.contacts);
  }

  async closeModalAndCompleteOrder() {
    if (this.$route.name !== 'signin') {
      PopupsService.close(PopupsContainerModel.ETypeWrapper.CENTER);
    }
    if (this.isNeedCompletePopup) {
      await this.completeOrder();
    }
  }

  async completeOrder() {
    let order = true;
    const tokens = Cookies.get('auth');
    const totalPrice = this.orderItems.map((_) => _.sku.sellPrice * _.amount).reduce((acc, cur) => acc + cur, 0);
    if (tokens && (!!JSON.parse(tokens).accessToken as boolean)) {
      this.$store.dispatch('checkout/updateLoadingComplete', true);
      try {
        const orderData = await createOrder(
          this.orderItemsToApi as OrderItemInput[],
          this.recipientsWithTruePhone,
          this.delivery,
          this.promocode?.length ? this.promocode : undefined,
          this.hiddenPoint && this.hiddenPoint.id ? this.hiddenPoint.id : this.comment,
        );
        order = orderData;

        if (orderData?.paymentUrl) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.$gtm.push({ ecommerce: null });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.$gtm.push(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.$dataLayer.goToPaymentEvent(
              this.orderItems,
              totalPrice,
              this.delivery.price ?? 0,
              this.$route.query.productId ? 'Купить в 1 клик' : 'Купить из корзины',
            ),
          );
          if (this.isCart) {
            await this.clearCart();
          }
          this.$store.dispatch('checkout/updatePromocode', '');
          this.$store.dispatch('checkout/updateDiscountAmount', 0);
          window.location.href = orderData.paymentUrl;
        }
      } catch (e) {
        const er = e as any;
        const errors = er.graphQLErrors.map((x) => x.extensions?.exceptionClass);
        if (errors.includes('PhoneNotVerifiedException')) {
          this.openSigninPopup(true, { isVerifyPhone: true });
        }
        if (
          er.graphQLErrors[0].message == 'Order creation is failed: No message available' ||
          er.graphQLErrors[0].message ==
            'Order creation is failed: Количество доступного товара в заказе изменилось. Проверьте корзину'
        ) {
          if (this.isCart) {
            await this.init();
            await this.checkProductsPriceAmount();
          }
        }
        console.log(er);
        this.$eventManager.emit('change-message', er.graphQLErrors[0].message);
        window.scrollTo(0, 0);
      } finally {
        if (!order) {
          setTimeout(() => {
            this.$store.dispatch('checkout/updateLoadingComplete', false);
          }, 11000);
        } else {
          this.$store.dispatch('checkout/updateLoadingComplete', false);
        }
      }
      //TODO: raise error if products have changed!
    } else {
      this.openSigninPopup(true);
    }
  }

  openSigninPopup(isNeedComplete = false, options = {}) {
    this.isNeedCompletePopup = isNeedComplete;
    this.openSignInPopup(
      {
        initPhone: this.recipientsWithTruePhone.phone,
      },
      options,
    );
  }

  async clearCart() {
    try {
      await setCart(this.orderItems.map((item) => ({ amount: 0, skuId: item.sku.id })));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$store.dispatch('cart/update', []);
    } catch (e) {
      console.error(e);
    }
  }

  @Watch('deliveryType', { deep: true, immediate: true })
  @Watch('city', { deep: true, immediate: true })
  @Watch('address', { deep: true, immediate: true })
  async expectedDeliveryParamsWatcher() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$store.dispatch('checkout/updateLoadingExpected', true);
    await this.throttleExpectedDelivery();
    this.expectedDeliveryWatcher(this.expectedDelivery);
  }

  @Watch('price', { immediate: true })
  priceWatcher(val) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$store.dispatch('checkout/updateDeliveryPrice', val);
  }

  @Watch('expectedDelivery', { immediate: true })
  expectedDeliveryWatcher(val) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$store.dispatch('checkout/updateExpectedDelivery', val);
  }

  @Watch('orderItems', { immediate: true })
  orderItemsWatcher(val) {
    if (this.addressString.includes('Каменск-Шахтинский') || this.isKshPoint) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$store.dispatch('checkout/updateDeliveryOptions', this.orderInput);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (process.client) {
      this.$store.dispatch('checkout/updateProducts', val);
    }
  }

  @Watch('canCheckout', { immediate: true })
  canCheckoutWatcher(val) {
    this.$root.$emit('checkout.canCheckout:change', val);
  }

  @Watch('city', { deep: true, immediate: true })
  cityWatcher(val) {
    if (!val) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$store.dispatch('checkout/updateDate');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$store.dispatch('checkout/updateTime');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$store.commit('checkout/updateDeliveryOptions', []);
    } else if (val.name.includes('Каменск-Шахтинский') || this.isKshPoint) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$store.dispatch('checkout/updateDeliveryOptions', this.orderInput);
    }
  }
}
