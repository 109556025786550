<template lang="pug">
.ds-max-weight-plate
  .icon
    icon-ui(name="status/error_line" size="28" fill="#FA6800")
  .message
    .title-plate Оплата доставки при получении
    .subtitle-plate Стоимость доставки оплачивается отдельно при получении товара в пункте выдачи заказов.
</template>
<script>
import IconUi from '~/components/common/icon';

import { MAX_WEIGHT_RUSSIAN_POST } from '~/shop-config';

import { weightFormatMixin } from '~/mixins';
export default {
  components: {
    IconUi,
  },
  mixins: [weightFormatMixin],
  data: () => ({
    MAX_WEIGHT_RUSSIAN_POST,
  }),
};
</script>
<style lang="stylus">
.ds-max-weight-plate
  margin 16px 0
  border-radius 12px
  padding 16px
  display flex
  justify-content space-between
  column-gap 8px
  background #FFF8E6
  .title-plate
    Text(BodyM Short)
    color TextPrimary()
    text-align left
  .subtitle-plate
    Text(BodyS Short)
    color TextSecondary()
    margin-top 4px
    line-height 18px
</style>
