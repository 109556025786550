import { Sort } from '~/apollo-api/types';

export const SORT_DATA = {
  [Sort.BY_RELEVANCE_DESC]: 'По популярности',
  [Sort.BY_PRICE_ASC]: 'Сначала дешевле',
  [Sort.BY_PRICE_DESC]: 'Сначала дороже',
  [Sort.BY_RATING_DESC]: 'Сначала с высоким рейтингом',
  [Sort.BY_DATE_ADDED_ASC]: 'Сначала новинки',
  [Sort.BY_ORDERS_NUMBER_DESC]: 'Сначала хиты продаж',
};

export const DEFAULT_SORT_VALUE = Sort.BY_RELEVANCE_DESC;
export const DESC_SORT_VALUE = Sort.BY_PRICE_DESC;
