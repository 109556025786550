import { verifyPhone as VerifyPhone } from '~/apollo-api/types';
import { client, gql } from './index';

export const verifyPhone = async (code: number) => {
  const { data } = await client.mutate<VerifyPhone>({
    mutation: gql`
      mutation verifyPhone($code: Int!) {
        verifyPhone(code: $code) {
          phoneVerified
          id
        }
      }
    `,
    variables: {
      code,
    },
  });

  return data?.verifyPhone;
};
