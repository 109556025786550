export const state = () => ({
  products: [],
});
const MAX_VIEWED_PRODUCTS = 6;
export const mutations = {
  removeList(state) {
    state.products = [];
  },
  addItem(state, payload) {
    const filteredState = state?.products?.filter((item) => item.id !== payload.id);
    filteredState.unshift(payload);
    state.products = filteredState;

    if (state.products.length >= MAX_VIEWED_PRODUCTS) {
      state.products.splice(MAX_VIEWED_PRODUCTS);
    }
  },
  checkList(state) {
    state.products.filter((item) => new Date() * 1000 * 60 * 60 - new Date(item.addedOn) <= 24);
  },
  updateProducts(state, payload) {
    state.products = payload;
  },
  updateProduct(state, payload) {
    state.products = state.products.map((item) => {
      if (item.id === payload[1]) {
        if (payload[0]) {
          let skuGroup = item.skuGroups.find((skuGroup) => skuGroup.id === payload[0]);
          skuGroup.favorite = payload[2];
        } else {
          item.skuGroups[0].favorite = payload[2];
        }
      }
      return item;
    });
  },
};

export const actions = {
  check({ commit }, payload) {
    commit('checkList', payload);
  },
  add({ commit }, payload) {
    commit('addItem', payload);
  },
  remove({ commit }) {
    commit('removeList');
  },
  update({ commit }, payload) {
    commit('updateProducts', payload);
  },
  updateProduct({ commit }, payload) {
    commit('updateProduct', payload);
  },
};

export const getters = {};
