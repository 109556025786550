import { getDeliveryOptions } from '~/apollo-api/getDeliveryOptions';
import { MAX_WEIGHT_RUSSIAN_POST } from '~/shop-config';

export const setAddress = ({ street, flat, house, street_with_type, house_type, settlement_with_type } = {}) => {
  return {
    street: street || '',
    flat: flat || '',
    house: house || '',
    street_with_type: street_with_type || '',
    house_type: house_type || '',
    settlement_with_type: settlement_with_type || '',
  };
};

export const setRecipient = ({ firstName, lastName, phone, email, isMailAccept } = {}) => {
  return {
    firstName: firstName || '',
    lastName: lastName || '',
    phone: phone || '',
    email: email || '',
    isMailAccept: isMailAccept || false,
  };
};

export const getDefaultCity = () => ({
  name: 'г Москва',
  city: 'Москва',
  id: 'г Москва',
  ke: false,
});

export const state = () => ({
  address: setAddress(),
  postcode: '',
  recipient: setRecipient(),
  contacts: {},
  city: getDefaultCity(),
  deliveryPoint: undefined,
  deliveryOptions: [],
  deliveryType: 'RUSSIAN_POST',
  date: '',
  time: '',
  expectedDelivery: null,
  isLoadingExpected: false,
  isPromoLoad: false,
  isLoadingComplete: false,
  isShowWarnings: false,
  warningCount: 0,
  deliveryPrice: undefined,
  products: [],
  discountAmount: undefined,
  promocode: '',
  comment: '',
  checkCities: [],
  checkStreets: [],
  defaultCity: null,
  hiddenPoint: undefined,
});

export const mutations = {
  updatePostcode(state, payload) {
    state.postcode = payload;
  },
  updateDefaultCity(state, payload) {
    state.defaultCity = payload;
  },
  updateHiddenPoint(state, payload) {
    state.hiddenPoint = payload;
  },
  updateDeliveryPrice(state, payload) {
    state.deliveryPrice = payload;
  },
  updateProducts(state, payload) {
    state.products = payload;
  },
  updateCheckCities(state, payload) {
    state.checkCities = payload;
  },
  updateCheckStreets(state, payload) {
    state.checkStreets = payload;
  },
  updatePromocode(state, payload) {
    state.promocode = payload;
  },
  updateExpectedDelivery(state, payload) {
    state.expectedDelivery = payload;
  },
  updateLoadingExpected(state, payload) {
    state.isLoadingExpected = payload;
  },
  updateLoadingComplete(state, payload) {
    state.isLoadingComplete = payload;
  },
  updateShowWarnings(state, payload) {
    state.isShowWarnings = payload;
  },
  updateWarningCount(state) {
    state.warningCount++;
  },
  updateAddress(state, payload) {
    state.address = setAddress(payload);
  },
  updateRecipient(state, payload) {
    state.recipient = setRecipient(payload);
  },
  updateDeliveryType(state, payload) {
    state.deliveryType = payload;
  },
  updateDate(state, payload) {
    state.date = payload;
  },
  updateTime(state, payload) {
    state.time = payload;
  },
  updateCity(state, payload) {
    state.city = payload;
  },

  updateDeliveryPoint(state, payload) {
    state.deliveryPoint = payload;
  },
  updateDeliveryOptions(state, payload) {
    state.deliveryOptions = payload;
  },
  updateComment(state, payload) {
    state.comment = payload;
  },
  updateDiscountAmount(state, payload) {
    state.discountAmount = payload;
  },
  updateIsPromoLoad(state, payload) {
    state.isPromoLoad = payload;
  },
};

export const actions = {
  clearCheckoutData({ commit }) {
    commit('updateRecipient');
    commit('updateAddress');
    commit('updatePostcode', '');
    commit('updateDate', '');
    commit('updateTime', '');
    commit('updateComment', '');
    commit('updatePromocode', '');
    commit('updateDiscountAmount', undefined);
    commit('updateDefaultCity', null);
    commit('updateHiddenPoint', null);
  },
  updatePostcode({ commit }, payload) {
    commit('updatePostcode', payload);
  },
  updateDefaultCity({ commit }, payload) {
    commit('updateDefaultCity', payload);
  },
  updateHiddenPoint({ commit }, payload) {
    commit('updateHiddenPoint', payload);
  },
  updateDiscountAmount({ commit }, payload) {
    commit('updateDiscountAmount', payload);
  },
  updateIsPromoLoad({ commit }, payload) {
    commit('updateIsPromoLoad', payload);
  },
  updateDeliveryPrice({ commit }, payload) {
    commit('updateDeliveryPrice', payload);
  },
  updatePromocode({ commit }, payload) {
    commit('updatePromocode', payload);
  },
  updateProducts({ commit }, payload) {
    commit('updateProducts', payload);
  },
  updateCheckCities({ commit }, payload) {
    commit('updateCheckCities', payload);
  },
  updateCheckStreets({ commit }, payload) {
    commit('updateCheckStreets', payload);
  },
  updateExpectedDelivery({ commit }, payload) {
    commit('updateExpectedDelivery', payload);
  },
  updateLoadingExpected({ commit }, payload) {
    commit('updateLoadingExpected', payload);
  },
  updateShowWarnings({ commit }, payload) {
    commit('updateShowWarnings', payload);
  },
  updateWarningCount({ commit }) {
    commit('updateWarningCount', 1);
  },
  updateLoadingComplete({ commit }, payload) {
    commit('updateLoadingComplete', payload);
  },
  updateAddress({ commit }, payload) {
    commit('updateAddress', payload);
  },
  updateRecipient({ commit }, payload) {
    commit('updateRecipient', payload);
  },
  updateDeliveryType({ commit }, payload) {
    commit('updateDeliveryType', payload);
  },
  updateDate({ commit }, payload) {
    commit('updateDate', payload);
  },
  updateTime({ commit }, payload) {
    commit('updateTime', payload);
  },
  updateCity({ commit }, payload) {
    commit('updateCity', payload);
  },
  updateDeliveryPoint({ commit }, payload) {
    commit('updateDeliveryPoint', payload);
  },
  updateComment({ commit }, payload) {
    commit('updateComment', payload);
  },
  async updateDeliveryOptions({ commit }, params) {
    try {
      const options = await getDeliveryOptions(params);
      commit('updateDeliveryOptions', options);
    } catch (e) {
      commit('updateDeliveryOptions', []);
      throw e;
    }
  },
};

export const getters = {
  cityPoint: (state) => state.deliveryPoint || state.city?.deliveryPoints?.[0],
  hasMaxWeightLimit: (state) =>
    state.products.reduce((acc, product) => product.sku.weight * product.amount + acc, 0) > MAX_WEIGHT_RUSSIAN_POST,
  hasNonWeightProducts: (state) => state.products.some((product) => product.sku.weight === null),
};
